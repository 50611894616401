import { useEffect } from 'react';

import { AwardBidLabels, documentLabels } from '../../../../../shared/constants';
import {
  DocumentUploadPanelWrapper,
  PanelLabel,
  PanelLabelSmall,
  PanelText,
} from '../../../../../shared/styles';
import {
  useSelectedBidId,
  useSetSelectedBidIdFromRoute,
} from '../../../../../shared/hooks/useSelectedBidId';

import { AddRequiredDocumentsPanel } from '../../../awardbid/list-required-docs/AddRequiredDocumentsPanel';
import { ArrowTitle } from '../../../../layout/titles';
import { bidAwardeeLabels } from './constants';
import { BidAwardeeSelect } from '../../../../common/bid/BidAwardeeSelect';
import { getVendorDocumentUploadMessage } from '../../../awardbid/add-vendor-docs/utils';
import { RequiredDocumentResponsesTable } from '../../../awardbid/list-required-docs/RequiredDocumentResponsesTable';
import { useBidAwardee } from '../../../../../shared/hooks/useBidAwardee';
import { useRequiredDocumentResponses } from '../../../../../shared/hooks';
import { useRequiredDocumentTypes } from '../../../../../shared/hooks/award-bid/useRequiredDocumentTypes';
import { useSupplierMemberId } from '../../../../../shared/hooks/useMemberInfo';
import { vendorDocLabels } from '../../../awardbid/add-vendor-docs/constants';
import { VendorDocumentsTable } from '../../../awardbid/add-vendor-docs/VendorDocumentsTable';
import { VendorDocumentUploadForm } from '../../../awardbid/add-vendor-docs/VendorDocumentUploadForm';

export function BidAwardeesView() {
  /// App state
  const { selectedBidId } = useSelectedBidId();
  const { setSupplierMemberId, supplierMemberId } = useSupplierMemberId();

  // Update the selected selectedBidId from the current url params.
  useSetSelectedBidIdFromRoute();

  const { refreshRequiredDocumentResponses: refreshRequiredDocResponses } =
    useRequiredDocumentResponses();

  const { bidAwardeeList, refreshBidAwardeeList, selectedBidAwardee, setSelectedBidAwardee } =
    useBidAwardee();

  const { refreshRequiredDocTypes, setRequiredDocTypes } = useRequiredDocumentTypes();

  // useEffect Hooks
  useEffect(() => {
    refreshRequiredDocResponses();
  }, [selectedBidId, refreshRequiredDocResponses, selectedBidAwardee, supplierMemberId]);

  /**
   * Set the selectedBidAwardee to the first in the list,
   * if an item from the list isn't already selected.
   */
  useEffect(() => {
    if (
      !selectedBidAwardee ||
      !bidAwardeeList.find(
        ({ awardedToMemberId }) => selectedBidAwardee.awardedToMemberId === awardedToMemberId,
      )
    ) {
      setSelectedBidAwardee(bidAwardeeList[0]);
    }
  }, [bidAwardeeList, selectedBidAwardee, setSelectedBidAwardee]);

  // Required doc types
  // Refresh requiredDocType Recoil state on mount, reset when unmounted.
  // TODO: wrap this function into the custom hook.
  useEffect(() => {
    refreshRequiredDocTypes();
    return () => {
      setRequiredDocTypes([]);
    };
  }, [refreshRequiredDocTypes, setRequiredDocTypes]);

  /**
   * Select a default `selectedBidAwardee` and `selectedmemberId`.
   * TODO: move to bidAwardee hook.
   */
  useEffect(() => {
    if (!selectedBidAwardee && bidAwardeeList[0]) {
      setSelectedBidAwardee(bidAwardeeList[0]);
      setSupplierMemberId(bidAwardeeList[0].awardedToMemberId);
    }
  }, [bidAwardeeList, selectedBidAwardee, setSelectedBidAwardee, setSupplierMemberId]);

  /**
   * Refresh awardee list
   */
  useEffect(() => {
    refreshBidAwardeeList();
  }, [refreshBidAwardeeList, selectedBidAwardee]);

  // Display titles
  const vendorSpecificDocPanelTitle = `${AwardBidLabels.awardDocsSpecificVendorTitlePrefix} ${
    selectedBidAwardee?.supplierName || vendorDocLabels.theSelectedAwardee
  } `;
  const requiredDocPanelTitle = `${AwardBidLabels.RequiredDocsTitlePrefix} Awardees `;

  return (
    <>
      <ArrowTitle label={bidAwardeeLabels.bidAwardees} />

      <BidAwardeeSelect />

      {/* Vendor Specific Award Docs */}
      <PanelLabel>{vendorSpecificDocPanelTitle}</PanelLabel>
      <VendorDocumentsTable readOnly />

      <DocumentUploadPanelWrapper visible>
        <PanelLabelSmall>{`${documentLabels.sendDocumentsTo} ${
          selectedBidAwardee?.supplierName || ''
        }`}</PanelLabelSmall>

        <PanelText>{getVendorDocumentUploadMessage(selectedBidAwardee?.supplierName)}</PanelText>

        <VendorDocumentUploadForm />
      </DocumentUploadPanelWrapper>

      {/* Docs Required from all Awardees */}
      <PanelLabel>{requiredDocPanelTitle}</PanelLabel>
      <RequiredDocumentResponsesTable deleteEnabled editEnabled />
      <AddRequiredDocumentsPanel />
    </>
  );
}
