import React, { memo, useEffect, useState } from 'react';
import AddBidWizardButtons from './AddBidWizardButtons';
import { Field, Formik } from 'formik';
import renderTextAreaField from '../controls/inputs/textarea';
import renderToggleBtn from '../controls/inputs/togglebtn';
import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import { addbidParamType } from '../../../types/addbid';
import { WizardPage } from '../../../types/wizard';
import { ModalPopUp } from '../modals/ModalPopUp';
import { Buttons } from '../../customcontrols/index';
import { toastFn } from '../../../utils/helpers';

interface PropsTypes {
  handleSubmit?: any;
  valid?: Record<string, unknown> | undefined;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  addbid?: addbidParamType;
  setAddBidDetails?: any;
  currentwizard?: WizardPage;
  setBreadcrumb?: any;
  bidId?: number;
  submitAddBidUpdateMemo?: any;
  currentBidName?: string;
  SkipNext?: any;
  initialValues: any;
}

function Prebidconference(props: PropsTypes) {
  const {
    valid,
    pristine,
    submitting,
    addbid,
    setAddBidDetails,
    currentwizard,
    setBreadcrumb,
    bidId,
    submitAddBidUpdateMemo,
    currentBidName,
    SkipNext,
    initialValues,
  } = props;
  const {
    editable,
    prebidconference,
    updatedConferenceMessage = false,
    modalNotifyPreBid = false,
  } = addbid || {};
  const { pbcMemo, preBidMandatory } = prebidconference || {};

  useEffect(() => {
    return () => {
      setAddBidDetails({ halfWayCancel: false });
    };
  }, [setAddBidDetails]);

  useEffect(() => {
    if (currentwizard) {
      setBreadcrumb({
        component: (
          <AddBidControl
            valid={valid}
            pristine={pristine}
            submitting={submitting}
            bidId={bidId}
            pristineOpt={true}
          />
        ),
        // TODO: Create consistency between 'Update Bid' and 'Bid Creation'.
        // How do we use these terms? How can we make all titles consistent?
        page: currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation',
        title: currentBidName
          ? `Bid Update - ${currentwizard.name}`
          : `Bid Creation - ${currentwizard.name}`,
        altname: currentBidName
          ? `Bid Update - ${currentwizard.name}`
          : `Bid Creation - ${currentwizard.name}`,
        name: currentBidName ? currentBidName : 'Bid Creation',
        id: bidId,
        menuactive: 'bids',
      });
    }
  }, [bidId, currentBidName, currentwizard, pristine, setBreadcrumb, submitting, valid]);

  const placeholder =
    'September 10, 2022 10:00 AM County Courthouse 999 Anystreet Room 999 Anytown, XX. www.urlforvideoconference.com';
  const [formData, setFormData] = useState({});

  const onSubmitFn = (data: any) => {
    if (data) {
      if (editable && ['all', 'active'].includes(editable)) {
        if (editable === 'active' && updatedConferenceMessage) {
          setAddBidDetails({ modalNotifyPreBid: true, updatedConferenceMessage: false });
          setFormData(data);
        } else {
          if(data.preBidMandatory === true && data.pbcMemo.length <= 0){
            toastFn('error', 'Enter Pre-Bid Conference', 'PreBidConference');
          }
          else{
              submitAddBidUpdateMemo({ bidId, data });
          }
        }
      } else {
        SkipNext();
      }
    }
  };

  const handleNotifyclick = (isNotify: boolean) => {
    submitAddBidUpdateMemo({ bidId, isNotify, data: formData });
    setAddBidDetails({ modalNotifyPreBid: !modalNotifyPreBid });
  };

  return (
    <>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmitFn}>
        {formikProps => {
          const { handleSubmit, setFieldValue } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-lg-12 '>
                  <div className='innerColIndent'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <Field
                          label='Mandatory'
                          title='Mandatory'
                          name='preBidMandatory'
                          component={renderToggleBtn}
                          handleChange={(name: string, value: boolean) => {
                            setAddBidDetails({
                              updatedConferenceMessage: value !== preBidMandatory ? true : false,
                            });
                            setFieldValue('preBidMandatory', value);
                            setAddBidDetails({ preBidMandatory: value });
                          }}
                          optional={true}
                          disabled={
                            (editable && !['all', 'active'].includes(editable)) || !editable
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <div className='col-lg-6'>
                        <Field
                          label='Your Entry'
                          classNames='class'
                          placeholder={placeholder}
                          name='pbcMemo'
                          component={renderTextAreaField}
                          disabled={
                            (editable && !['all', 'active'].includes(editable)) || !editable
                              ? true
                              : false
                          }
                          remainShow={true}
                          maxLength={1024}
                          onChange={(name: string, value: any) => {
                            setFieldValue('pbcMemo', value);
                            setAddBidDetails({
                              updatedConferenceMessage: value !== pbcMemo ? true : false,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <AddBidWizardButtons {...props} />
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      <ModalPopUp
        title={'Pre-Bid Conference'}
        size='md'
        isOpen={modalNotifyPreBid}
        closeModal={() => handleNotifyclick(false)}
      >
        <p>Do you want to notify planholders of the updated Pre-Bid Conference information?</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='No'
          title='No'
          type='button'
          onClick={() => handleNotifyclick(false)}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Yes'
          title='Yes'
          type='button'
          onClick={() => handleNotifyclick(true)}
        />
      </ModalPopUp>
    </>
  );
}
export default memo(Prebidconference);
