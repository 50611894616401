import { useState } from 'react';

import { formatPhone, isValidPhone } from '../../utils/helpers/';
import { phoneValErrorMsg } from '../../utils/constants';

import { TextInput, TextInputProps } from './TextInput';

type PhoneInputProps = Omit<TextInputProps, 'errorMsg'>;

export const PhoneInput = (props: PhoneInputProps) => {
  const errorMsg = props.value && !isValidPhone(props.value) ? phoneValErrorMsg : undefined;

  const [displayValue, setDisplayValue] = useState(formatPhone(props.value || ''));

  function onChange(value: string) {
    setDisplayValue(value);
    const strippedValue = value.replace(/[^\d]/g, ''); // strips out all but numbers.
    if (props.onChange) {
      props.onChange(strippedValue);
    }
  }
  function onBlur() {
    setDisplayValue(formatPhone(props.value || ''));
  }

  const phoneProps = {
    ...props,
    errorMsg,
    onChange,
    onBlur,
    value: displayValue,
  };

  return <TextInput {...phoneProps}></TextInput>;
};
