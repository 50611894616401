import { atom } from 'recoil';
import dayjs from 'dayjs';

import { Product } from '../../types/subscriptions';
import { UserType } from 'src/types/accountinfo';

interface Address {
  abbreviation?: string;
  address1?: string;
  address2?: string;
  addressType?: string;
  city?: string;
  countryId?: number;
  countyId?: number;
  countyName?: string;
  phoneNumber?: string;
  postalCode?: string;
  receiveEmail?: string;
  shortName?: string;
  stateId?: number;
  stateName?: string;
  website?: string;
}

export interface AccountInfoState {
  /** Days left for customer to renew, if applicable */
  daysToRenew: number;
  /** Date current expiration expires, if applicable */
  expiryDate: dayjs.Dayjs;
  /** An array of addresses associated with this account */
  memberInfo: Address[];
  /** The products to which this account is currently subscribed */
  products: Product[];
  /** A list of products added by the user in their current session */
  newProductIds: number[];
  /** Unclear, an array of membership levels & their status */
  services: { membershipLevelId: number; subscriptionStatusType: string }[];
  /** The type of user based on selected free agency & paid subscription status */
  userType?: UserType;
}

export interface ProfileInfoState {
  /** The member's card-on-file, if applicable */
  card: {
    cardholderName: string;
    cardLastFour: number;
    cardType: string;
    expirationMonth: number;
    expirationYear: number;
    isAutoRenewalOn: boolean;
  };
  /**
   * Whether the user has a card-on-file (SUCCESS) or not (FAILED),
   * or if we are still awaiting a result from our check (PENDING)
   */
  result: 'SUCCESS' | 'PENDING' | 'FAILED';
}

export const defaultAccountInfo: AccountInfoState = {
  daysToRenew: 0,
  expiryDate: dayjs(),
  memberInfo: [],
  products: [],
  newProductIds: [],
  services: [],
};

/**
 * Contains general information about the user's account
 * Contains information previously in the accountInfo.accountInfo Redux state
 */
export const accountInfoState = atom<AccountInfoState>({
  key: 'accountInfoState',
  default: defaultAccountInfo,
});

export const defaultProfileInfo: ProfileInfoState = {
  card: {
    cardholderName: '',
    cardLastFour: 0,
    cardType: '',
    expirationMonth: 0,
    expirationYear: 0,
    isAutoRenewalOn: false,
  },
  result: 'PENDING',
};

/**
 * Contains information about customer's card-on-file, if available
 * Contains the information previously held in the accountInfo.profileInfo Redux state
 */
export const profileInfoState = atom<ProfileInfoState>({
  key: 'profileInfoState',
  default: defaultProfileInfo,
});
