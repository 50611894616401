import { ComponentType, ReactNode } from 'react';
import { SharedState } from '../store/reducers/shared';
import { SortDirection } from '../shared/hooks/useSort';

export type sharedTypes = SharedState;

export type sharedactionTypes = { payload: Record<string, unknown> | undefined };

export type trackeventsTypes = {
  eventId: number;
  eventType: string | Record<string, unknown> | [];
  parentId: number;
  value: string | Record<string, unknown> | [];
  accountId: number;
  date: string | Date;
  opsAccountId: number;
};

export type reactselectTypes = { label: string; value: string };

/**
 * Base, generic types for composed types and interfaces.
 */

export type AccountId = { accountId: number }; // TOOD: enforce either string or number?
export type ApiResponse<T> = { result: T };
export type BidId = { bidId: number }; // TOOD: enforce either string or number?
export type Data<T> = { data: T };
export type DataTestId = { dataTestId?: string };
export type Description = { description: string };
export type Direction = { direction?: SortDirection };
export type Disabled = { disabled?: boolean };
export type Documents<T> = { documents: Partial<T>[] };
export type DueDate = { dueDate?: string };
export type FileParam = { file: File };
export type Id<T = string> = { id?: T };
export type Inactive = { inactive?: boolean };
export type Label<T = string> = { label: T };
export type MemberId = { memberId: number }; // TOOD: enforce either string or number?
export type Message<T = string> = { message: T };
export type OnClick = { onClick?: NoOpFunction };
export type ReadOnly = { readOnly?: boolean };
export type ResponseId = { responseId: number };
export type Selected = { selected: boolean };
export type Status<StatusType = string> = { status: StatusType };
// export type SvgIcon = { icon?: ComponentType<SvgIconProps> };
export type Text = { text?: ReactNode };
export type Title = { title: string };
export type Visible = { visible: boolean }; // For show/hide components to be toggled
export type Width = { width?: number };

export type StatusMessageAPIResponse = Status & Message<StatusMessage>;

/**
 * Generic Type for a Function receiving a prop object with an `Id` type.
 * @description
 *    IdType - defaults to string | number
 *    ReturnType - defaults to `void`
 */
export type IdPropFunction<IdType = string | number, ReturnType = void> = (
  props: Id<IdType>,
) => ReturnType;

/**
 * Generic No Operation function with optional props
 * @description
 *    PropsType - defaults to void
 */
export type NoOpFunction<PropsType = any> = (props?: PropsType) => void;

export type ToastReason = 'success' | 'error' | '';
export type ToastParameters = {
  reason: ToastReason;
  message?: string;
  id?: string | number;
  autoClose?: number | false;
};

export enum StatusMessage {
  Error = 'Error',
  Success = 'Success',
}
