import { HTMLProps } from 'react';

import { components } from 'react-select';
import styled from 'styled-components';

import {
  BorderColor,
  BorderThickness,
  FontFamily,
  ParagraphFontSize,
  TextColor,
} from '../../styles';

interface SelectStyledProps extends HTMLProps<HTMLDivElement> {
  error?: Record<string, unknown>;
}

/**
 * The styling is based off the Input control, with some adjustments to work with react-select
 */
export const DSSelectStyled = styled.div<SelectStyledProps>`
  border: 0.0625rem solid;
  border-color: ${props => (props.error ? BorderColor.Error : BorderColor.Base)};
  border-radius: 0.25rem;
  color: ${TextColor.Base};
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Base};
  font-style: normal;
  font-weight: 400;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;
  word-spacing: normal;

  &:focus {
    outline: none;
  }
`;

/**
 * Below are overrides that will be passed to react-select as props
 */
export const styleOverrides = {
  /** formats and animates the dropdown arrow on the right side of the dropdown */
  // Cannot easily extract react-select's style types, hence the explicity any.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    color: TextColor.Base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  /** Creates an outline for the dropdown list */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuList: (styles: any) => {
    return {
      ...styles,
      border: `${BorderThickness.Base} solid ${BorderColor.Base}`,
      marginLeft: '-0.0625rem',
      marginRight: '-0.0625rem',
    };
  },
  /** Changes background to teal for selected item, and ensures no highlighting when neither touched nor selected */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (styles: any, state: any) => {
    return {
      ...styles,
      backgroundColor: state.isSelected ? 'rgba(51, 149, 164, 0.2)' : null,
      color: TextColor.Base,
    };
  },
};

const { Option } = components;
/** Adds teal check mark to the left of selected single item. This is passed into components prop. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IconOption = (props: any): JSX.Element => (
  <Option {...props}>
    {(props.data && props.data.value) ===
    (props.selectProps && props.selectProps.value && props.selectProps.value.value) ? (
      <span
        aria-hidden='true'
        className='mdi mdi-check-bold'
        style={{ color: `${TextColor.Action}` }}
      ></span>
    ) : (
      <span aria-hidden='true' className='ml-4'></span>
    )}
    {' ' + props.data.label}
  </Option>
);
