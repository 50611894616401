import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { H3 } from '@demandstar/components/styles';

import { useAccountInfo, useRefreshAccountInfo } from 'src/shared/hooks/useAccountInfo';
import { useProfileInfo, useRefreshProfileInfo } from 'src/shared/hooks/useProfileInfo';

import { AddressInfo } from 'src/components/common/formcomponents/AddressLookup';
import { CheckoutErrorType } from 'src/components/products/review-order/useCheckout';
import { Page } from '../styles';
import { postalCodeLookup } from 'src/utils/helpers/postalcodeLookup';
import { ReviewOrderForm } from 'src/components/products/review-order/ReviewOrderForm';
import { useSetBreadcrumb } from 'src/shared/hooks';

const CheckoutWrapper = styled.div`
  min-width: 10rem;
  padding: 2rem;
  width: 66.67%;
`;

/**
 * @description page for upgrade checkout
 * @returns JSX.Element
 *
 * @example <ReviewUpgrade />
 */
export const ReviewUpgrade = () => {
  useRefreshProfileInfo();
  useRefreshAccountInfo();
  const { accountInfo } = useAccountInfo();
  const {
    profileInfo: { card, result },
  } = useProfileInfo();
  const hasProfile = useMemo(() => {
    return result === 'SUCCESS';
  }, [result]);

  const [paymentError, setPaymentError] = useState(false);

  const [billingAddress, setBillingAddress] = useState<AddressInfo | undefined>(undefined);

  const address = useMemo(() => {
    return accountInfo?.memberInfo?.find(x => x.addressType === 'BA');
  }, [accountInfo?.memberInfo]);

  const getBillingAddress = useCallback(async () => {
    // Don't overwrite billingAddress if already set
    if (billingAddress) return;

    if (address?.postalCode) {
      const postalCodeResult = await postalCodeLookup(address?.postalCode);

      if (postalCodeResult) {
        const addr: AddressInfo = {
          city: postalCodeResult.selectedCity,
          state: { label: postalCodeResult.selectedState.name },
          county: {
            countryId: postalCodeResult.selectedCounty.countryId,
            id: postalCodeResult.selectedCounty.id,
            label: postalCodeResult.selectedCounty.name,
            name: postalCodeResult.selectedCounty.name,
            value: postalCodeResult.selectedCounty.value,
          },
          countyId: postalCodeResult.selectedCounty.id,
          countyName: postalCodeResult.selectedCounty,
          country: postalCodeResult.selectedCountry,
          countryId: postalCodeResult.selectedCountry.value,
          stateId: postalCodeResult.selectedState.id,
          stateName: {
            id: postalCodeResult.selectedState.id,
            name: postalCodeResult.selectedState.name,
            countryId: postalCodeResult.selectedState.countryId,
            abbr: postalCodeResult.selectedState.abbr,
            label: postalCodeResult.selectedState.label,
            value: postalCodeResult.selectedState.id,
          },
          address1: address.address1 ?? '',
          address2: address.address2 ?? '',
          addressType: address.addressType ?? '',
          postalCode: address.postalCode,
        };
        setBillingAddress(addr);
      }
    }
  }, [address, billingAddress]);

  const handleError = useCallback((errorType: CheckoutErrorType) => {
    if (errorType !== CheckoutErrorType.None) {
      setPaymentError(true);
    }
  }, []);

  useSetBreadcrumb({
    page: 'Checkout',
    title: 'Checkout',
    altname: 'Checkout',
    name: 'Checkout',
  });

  useEffect(() => {
    getBillingAddress();
  }, [getBillingAddress]);

  useEffect(() => {
    if (card?.cardLastFour && hasProfile) setPaymentError(true);
  }, [card?.cardLastFour, hasProfile]);

  return (
    <Page>
      <CheckoutWrapper>
        <H3 className='arrowWrapper'>Checkout</H3>
        <ReviewOrderForm
          defaultAddress={billingAddress}
          handleError={handleError}
          paymentError={paymentError}
        />
      </CheckoutWrapper>
    </Page>
  );
};
