import styled from 'styled-components';
import { TextColor } from '.';

enum Font {
  FiraSans = "'Fira Sans', sans-serif",
  SourceSansPro = "'Source Sans Pro', sans-serif",
}

export enum FontFamily {
  Base = Font.SourceSansPro,
  Header = Font.FiraSans,
}

/** Enum used for weighting fonts */
export enum FontWeight {
  Bold = '700',
  Header = '500',
  Base = '400',
}

enum FontSize {
  /** Small - 14.22px */
  Small = '0.889rem',
  /** Base - 16px */
  Base = '1rem',
  /** Large - 18px */
  Large = '1.125rem',
  /** H1 - 28.83px */
  H1 = '1.802rem',
  /** H2 - 25.63px */
  H2 = '1.602rem',
  /** H3 - 22.78px */
  H3 = '1.424rem',
  /** H4 - 20.25px */
  H4 = '1.266rem',
}

/** Enum used for sizing page headers */
export enum HeaderFontSize {
  H1 = FontSize.H1,
  H2 = FontSize.H2,
  H3 = FontSize.H3,
  H4 = FontSize.H4,
  H5 = FontSize.Large,
  H6 = FontSize.Base,
}

/** Enum used for sizing in paragraph, spans, & general page text */
export enum ParagraphFontSize {
  Small = FontSize.Small,
  Base = FontSize.Base,
  Large = FontSize.Large,
}

/** Enum used for table data sizing */
export enum TableFontSize {
  Header = FontSize.Small,
  BaseData = FontSize.Base,
  SmallData = FontSize.Small,
}

/** Enum used for font sizing of various <input /> types and components */
export enum InputFontSize {
  Placeholder = FontSize.Base,
  Label = FontSize.Small,
  Button = FontSize.Large,
}

/** Enum for CSS Line-height attributes */
export enum LineHeight {
  /** Used for small text */
  Small = '1.25rem',
  /** Used for general text, e.g. <p> tags */
  Base = '1.5rem',
  /** Used for subheadings (H2-H6 tags) */
  Subheader = '2rem',
  /** Used for primary page header (H1 tags) */
  Header = '3rem',
}

export const H2 = styled.h2`
  font-family: ${FontFamily.Header};
  font-size: ${HeaderFontSize.H2};
  line-height: ${LineHeight.Subheader};
  font-weight: ${FontWeight.Bold};
`;
export const H3 = styled.h3`
  font-family: ${FontFamily.Header};
  font-size: ${HeaderFontSize.H3};
  line-height: ${LineHeight.Subheader};
  font-weight: ${FontWeight.Bold};
`;

export const H4 = styled.h4`
  font-family: ${FontFamily.Header};
  font-size: ${HeaderFontSize.H4};
  line-height: ${LineHeight.Subheader};
  font-weight: ${FontWeight.Bold};
`;

export const H5 = styled.h5`
  font-family: ${FontFamily.Header};
  font-size: ${HeaderFontSize.H5};
  line-height: ${LineHeight.Subheader};
  font-weight: ${FontWeight.Bold};
`;

export const H6 = styled.h6`
  font-family: ${FontFamily.Header};
  font-size: ${HeaderFontSize.H6};
  line-height: ${LineHeight.Subheader};
  font-weight: ${FontWeight.Bold};
`;

export const Large = styled.span`
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Large};
  line-height: ${LineHeight.Base};
  font-weight: ${FontWeight.Base};

  a {
    font-size: ${ParagraphFontSize.Large};
  }
`;

export const Helper = styled.span`
  color: ${TextColor.Helper};
  font-weight: ${FontWeight.Base};
`;
