import { commonFieldNames } from '../../../../shared/constants';
import { getTypeSafeKey } from '../../../../utils';
import { Product } from 'src/types/subscriptions';

export enum FreeAgencyCopy {
  ChooseFreeAgency = 'Select a Free Agency',
  NoFreeAgency = "You haven't subscribed to a free agency yet! ",
  ToMakeChanges = '* To make changes to your free agency, please call us at ',
  UpgradeLink = '/app/suppliers/plans?ag=true',
}

export enum ProductsCopy {
  CardErrorHeader = "There's a problem with your credit card.",
  CardErrorMessage = 'The card you have on file could not be processed. Please update the information below to continue.',
  CartHeader = "You've added the following subscriptions to your membership:",
  CartTotal = 'Total to pay today:',
  Checkout = "You're almost there! Review the information on this page and enter a credit card, and get ready to find new business opportunities on DemandStar.",
  ExploreAvailableProductsText = 'Explore all available products',
  ExploreProductsText = 'Explore all products',
  IncludesCurrentAndAdded = "This includes your current products and anything you've added today.",
  NoProducts = "You're not currently subscribed to any products.",
  RenewsOn = 'Your account will renew on',
  SelectFreeAgencyText = 'Select a Free Agency',
  SomethingWentWrong = 'Something went wrong',
  TotalAnnualCost = 'Total Annual Cost',
}

export enum ProductsPaths {
  Checkout = '/subscription/renewal/checkout',
  CurrentSubscription = '/suppliers/account/subscriptions',
  ExploreProducts = '/subscription/renewal',
  SelectFreeAgency = '/suppliers/plans?ag=true',
}

export const productsTableHeaders: { [key: string]: string } = {
  [getTypeSafeKey<Product>('productName')]: 'Product',
  [getTypeSafeKey<Product>('productType')]: commonFieldNames.type,
  [getTypeSafeKey<Product>('price')]: 'Annual Cost',
};

export enum SubscriptionStatusLabels {
  Active = 'Active',
  Expired = 'Expired',
  Expiring = 'Expires',
  New = 'New!',
}

export enum SubscriptionStatusCopy {
  Autorenew = 'Auto-renew',
  ExpirationDate = 'Expiration Date:',
  ModifyOrCancel = "If you'd like to modify or cancel your subscription, please give us a call at ",
  Subscription = 'Subscription:',
}

export enum OpsActionsCopy {
  CancelAreYouSure = 'Are you sure you want to cancel the subscription?',
  CancelSubscription = 'Cancel Subscription',
  ComplimentaryOrderHeader = "Please note why you're adding a complimentary subscription:",
  ModifyFreeAgency = 'Modify Free Agency',
  ModifySubscription = 'Modify Subscription',
  PlaceComplimentaryOrder = 'Place Complimentary Order',
}
