import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import * as Api from '../services/limited';
import { formatPhoneNumber, setCookie, toastFn } from '../../utils/helpers';
import {
  limiteditemsTypes,
  limiteditemTypes,
  limitedtimezoneTypes,
  limitedTypes,
} from '../../types/limited';
import { tokenCookieName } from '../../utils/constants';
import Configurations from '../../settings';

const limitedToastID = 'da64f3de-9025-46ae-ac2f-2fbd775472f8';

function* watchgetLimitedAgencySummary() {
  yield takeLatest(actionTypes.GET_LIMITED_AGENCY_SUMMARY.TRIGGER, getLimitedAgencySummary);
}

function* getLimitedAgencySummary(action: limitedTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_AGENCY_SUMMARY.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getLimitedAgencySummary, action.payload);
    const payload = response.data.result || '';
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_SUMMARY.SUCCESS, payload });
  } catch {
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_SUMMARY.FAILURE });
  }
}

function* watchgetLimitedAgencyDocuments() {
  yield takeLatest(actionTypes.GET_LIMITED_AGENCY_DOCUMENTS.TRIGGER, getLimitedAgencyDocuments);
}

function* getLimitedAgencyDocuments(action: limitedTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_AGENCY_DOCUMENTS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getLimitedAgencyDocuments, action.payload);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_DOCUMENTS.SUCCESS, payload });
  } catch {
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_DOCUMENTS.FAILURE });
  }
}

function* watchgetLimitedAgencyLegal() {
  yield takeLatest(actionTypes.GET_LIMITED_AGENCY_LEGAL.TRIGGER, getLimitedAgencyLegal);
}

function* getLimitedAgencyLegal(action: { payload: { bidId: number } }): any {
  yield put({ type: actionTypes.GET_LIMITED_AGENCY_LEGAL.REQUEST, meta: action.payload });
  try {
    yield put({
      type: actionTypes.SET_LIMITED_DETAILS.TRIGGER,
      payload: { legalAddLoading: true },
    });
    const response = yield call(Api.getLimitedAgencyLegal, action.payload);
    const payload = { ...response.data.result, limitedBidId: action.payload.bidId } || '';
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_LEGAL.SUCCESS, payload });
    yield put({
      type: actionTypes.SET_LIMITED_DETAILS.TRIGGER,
      payload: { legalAddLoading: false },
    });
  } catch {
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_LEGAL.FAILURE });
  }
}

function* watchgetLimitedAgencyPlanholders() {
  yield takeLatest(actionTypes.GET_LIMITED_AGENCY_PLANHOLDERS.TRIGGER, getLimitedAgencyPlanholders);
}

function* getLimitedAgencyPlanholders(action: limitedTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_AGENCY_PLANHOLDERS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getLimitedAgencyPlanholders, action.payload);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_PLANHOLDERS.SUCCESS, payload });
  } catch {
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_PLANHOLDERS.FAILURE });
  }
}

function* watchgetLimitedAgencyAwards() {
  yield takeLatest(actionTypes.GET_LIMITED_AGENCY_AWARDS.TRIGGER, getLimitedAgencyAwards);
}

function* getLimitedAgencyAwards(action: limitedTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_AGENCY_AWARDS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getLimitedAgencyAwards, action.payload);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_AWARDS.SUCCESS, payload });
  } catch {
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_AWARDS.FAILURE });
  }
}

function* watchgetLimitedAgencyCommodities() {
  yield takeLatest(actionTypes.GET_LIMITED_AGENCY_COMMODITIES.TRIGGER, getLimitedAgencyCommodities);
}

function* getLimitedAgencyCommodities(action: limitedTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_AGENCY_COMMODITIES.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getLimitedAgencyCommodities, action.payload);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_COMMODITIES.SUCCESS, payload });
  } catch {
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_COMMODITIES.FAILURE });
  }
}

function* watchgetLimitedAgencySelfDeclarations() {
  yield takeLatest(
    actionTypes.GET_LIMITED_AGENCY_SELF_DECLARATIONS.TRIGGER,
    getLimitedAgencySelfDeclarations,
  );
}

function* getLimitedAgencySelfDeclarations(action: limitedTypes): any {
  yield put({
    type: actionTypes.GET_LIMITED_AGENCY_SELF_DECLARATIONS.REQUEST,
    meta: action.payload,
  });
  try {
    const response = yield call(Api.getLimitedAgencySelfDeclarations, action.payload);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_SELF_DECLARATIONS.SUCCESS, payload });
  } catch {
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_SELF_DECLARATIONS.FAILURE });
  }
}

// To get States list
export function* watchgetLimitedStatesList() {
  yield takeLatest(actionTypes.GET_LIMITED_STATES_LIST.TRIGGER, getLimitedStatesList);
}
function* getLimitedStatesList(action: limitedTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_STATES_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getLimitedStatesList, action.payload);
    let payload = (response.data && response.data.result) || [];
    if (payload.length > 0) {
      payload = payload.map((items: limiteditemsTypes) => ({
        ...items,
        label: items.name || items.title,
        value: items.id,
      }));
    }
    yield put({ type: actionTypes.GET_LIMITED_STATES_LIST.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIMITED_STATES_LIST.FAILURE,
      payload: { error, payload: action.payload },
    });
  }
}

// To get counties list
export function* watchgetLimitedCountiesList() {
  yield takeLatest(actionTypes.GET_LIMITED_COUNTIES_LIST.TRIGGER, getLimitedCountiesList);
}
function* getLimitedCountiesList(action: limitedTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_COUNTIES_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getLimitedCountiesList, action.payload);
    let payload = (response.data && response.data.result) || [];
    if (payload.length > 0) {
      payload = payload.map((items: limiteditemsTypes) => ({
        ...items,
        label: items.name || items.title,
        value: items.id,
      }));
    }
    yield put({ type: actionTypes.GET_LIMITED_COUNTIES_LIST.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIMITED_COUNTIES_LIST.FAILURE,
      payload: { error, payload: action.payload },
    });
  }
}

// To get Timezone list
export function* watchgetLimitedTimezoneList() {
  yield takeLatest(actionTypes.GET_LIMITED_TIMEZONE_LIST.TRIGGER, getLimitedTimezoneList);
}
function* getLimitedTimezoneList(action: limitedTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_TIMEZONE_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getLimitedTimezoneList, action.payload);
    let payload = (response.data && response.data.result) || [];
    if (payload.length > 0) {
      payload = payload.map((items: limiteditemTypes) => ({
        ...items,
        label: items.timeZoneName,
        value: items.timeZoneId,
      }));
    }
    yield put({ type: actionTypes.GET_LIMITED_TIMEZONE_LIST.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIMITED_TIMEZONE_LIST.FAILURE,
      payload: { error, payload: action.payload },
    });
  }
}

// To get Metro list
export function* watchgetLimitedMetroList() {
  yield takeLatest(actionTypes.GET_LIMITED_METRO_LIST.TRIGGER, getLimitedMetroList);
}
function* getLimitedMetroList(action: limitedtimezoneTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_METRO_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getLimitedMetroList, { zip: action.payload.zip });
    const payload = (response.data && response.data.result) || '';
    if (payload.metroId) {
      const limited = yield select(state => state.limited);
      const { stateslist = [] } = limited;
      const { setFieldValue } = action.payload;
      setFieldValue('country', {
        label: payload.countryId === 1 ? 'United States of America' : 'Canada',
        value: payload.countryId,
      });
      const newstate =
        stateslist.find(
          (item: { value: number }) => Number(item.value) === Number(payload.stateId),
        ) || '';
      setFieldValue('county', { label: payload.county, value: payload.countyId });
      setFieldValue('state', newstate);
      setFieldValue('city', payload.city);
      yield put({ type: actionTypes.GET_LIMITED_COUNTIES_LIST.TRIGGER, payload: payload.stateId });
      yield put({
        type: actionTypes.GET_LIMITED_STATES_LIST.TRIGGER,
        payload: { countryId: payload.countryId },
      });
      yield put({ type: actionTypes.GET_LIMITED_METRO_LIST.SUCCESS, payload });
    } else {
      yield put({ type: actionTypes.GET_LIMITED_METRO_LIST.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIMITED_METRO_LIST.FAILURE,
      payload: { error, payload: action.payload },
    });
  }
}

// To get Metro list
export function* watchsubmitLimitedAddMemberAccount() {
  yield takeLatest(
    actionTypes.SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT.TRIGGER,
    submitLimitedAddMemberAccount,
  );
}
function* submitLimitedAddMemberAccount(action: { payload: { values: any; form: any } }): any {
  yield put({ type: actionTypes.SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT.REQUEST, meta: action.payload });
  const { setSubmitting, setFieldError, setFieldTouched } = action?.payload?.form;
  try {
    const { values } = action.payload;
    const validatedata = {
      email: values.email,
      company: values.company,
    };
    const response = yield call(Api.submitLimitedIsCompanyOrAccountExists, validatedata);
    const payload = (response.data && response.data.result) || '';
    if (payload) {
      const { /* isBlackList,  */ companyExists, accountExists } = payload;
      if (payload && (companyExists || accountExists)) {
        let msg = 'Failed';
        if (companyExists) {
          msg = 'Company already exists';
          setFieldTouched('companyname', true, false);
          setFieldError('companyname', msg);
        }
        if (accountExists) {
          msg = 'Username / Email already exists';
          setFieldTouched('email', true, false);
          setFieldError('email', msg);
        }
        if (companyExists && accountExists) {
          msg = 'Company & Username / Email already exists';
        }
        yield put({ type: actionTypes.SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT.FAILURE });
        toastFn('error', msg, limitedToastID);
        setSubmitting(false);
      } else if (payload && !companyExists && !accountExists) {
        const { values } = action.payload;
        const {
          // username = '',
          firstname = '',
          lastname = '',
          phone = '',
          email = '',
          companyname = '',
          address1 = '',
          address2 = '',
          city = '',
          postalcode = '',
          phonenumber = '',
          country = '',
          state = '',
          county = '',
          website = '',
        } = values;
        const initial = `${firstname.charAt(0)}${lastname.charAt(0)}`;

        const finaldata = {
          MemberAddress: {
            Address1: address1,
            Address2: address2,
            City: city,
            StateID: state?.value,
            CountyID: county?.value,
            PostalCode: postalcode,
            AddressType: 'MA',
            CountryID: country?.value,
          },
          Member: {
            MemberId: 0,
            ShortName: companyname,
            ProductIds: ['11258'],
            Website: website,
            MemberStatus: 'AC',
            ScraperDomain: '',
            MemberType: 'SS',
          },
          MemberPhones: [
            {
              PhoneType: 'MN',
              PhoneNumber: phonenumber && formatPhoneNumber(phonenumber),
            },
          ],
          Account: {
            Username: email,
            Initials: initial.toUpperCase(),
            FirstName: firstname,
            LastName: lastname,
          },
          MemberContactPhone: {
            PhoneType: 'MN',
            PhoneNumber: phone && formatPhoneNumber(phone),
          },
          MemberContact: {
            Email: email,
            MainContact: true,
          },
        };

        const response = yield call(Api.submitLimitedAddMemberAccount, finaldata);
        const payloaddata = (response.data && response.data.result) || '';
        if (payloaddata && payloaddata.memberId) {
          yield put({
            type: actionTypes.SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT.SUCCESS,
            payload: payloaddata,
          });
        } else {
          yield put({ type: actionTypes.SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT.FAILURE });
          toastFn('error', 'Failed', limitedToastID);
          setSubmitting(false);
        }
      } else {
        setSubmitting(false);
        yield put({ type: actionTypes.SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT.FAILURE });
        toastFn('error', 'Failed', limitedToastID);
      }
    } else {
      setSubmitting(false);
      yield put({ type: actionTypes.SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT.FAILURE });
      toastFn('error', 'Failed', limitedToastID);
    }
  } catch (error) {
    setSubmitting(false);
    yield put({
      type: actionTypes.SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT.FAILURE,
      payload: { error, payload: action.payload },
    });
    toastFn('error', 'Failed', limitedToastID);
  }
}

// To get agency list
export function* watchgetLimitedWappagencies() {
  yield takeLatest(actionTypes.GET_LIMITED_WAPP_AGENCIES.TRIGGER, getLimitedWappagencies);
}
function* getLimitedWappagencies(action: limitedTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_WAPP_AGENCIES.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getLimitedWappagencies, action.payload);
    const payload = (response.data && response.data.result) || [];
    yield put({ type: actionTypes.GET_LIMITED_WAPP_AGENCIES.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIMITED_WAPP_AGENCIES.FAILURE,
      payload: { error, payload: action.payload },
    });
  }
}

// Submit Forgot Password
export function* watchsubmitLimitedForgotPassword() {
  yield takeLatest(actionTypes.SUBMIT_LIMITED_FORGOT_PASSWORD.TRIGGER, submitLimitedForgotPassword);
}
function* submitLimitedForgotPassword(action: {
  payload: { username: string; resetBy: string | undefined };
}): any {
  yield put({ type: actionTypes.SUBMIT_LIMITED_FORGOT_PASSWORD.REQUEST, meta: action.payload });

  try {
    const { resetBy = '' } = action.payload || {};
    const response = yield call(Api.submitLimitedForgotPassword, action.payload);
    const payload = (response.data && response.data.result) || '';
    yield put({ type: actionTypes.SUBMIT_LIMITED_FORGOT_PASSWORD.SUCCESS, payload });
    if (action.payload && resetBy && resetBy === 'ops') {
      toastFn('success', 'we will send an email with a password reset link.');
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_LIMITED_FORGOT_PASSWORD.FAILURE,
      payload: { error, payload: action.payload },
    });
  }
}

// Submit Forgot Email
export function* watchsubmitLimitedForgotEmail() {
  yield takeLatest(actionTypes.SUBMIT_LIMITED_FORGOT_EMAIL.TRIGGER, submitLimitedForgotEmail);
}
function* submitLimitedForgotEmail(action: limitedTypes): any {
  yield put({ type: actionTypes.SUBMIT_LIMITED_FORGOT_EMAIL.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.submitLimitedForgotEmail, action.payload);
    const payload = (response.data && response.data.result) || '';
    yield put({ type: actionTypes.SUBMIT_LIMITED_FORGOT_EMAIL.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_LIMITED_FORGOT_EMAIL.FAILURE,
      payload: { error, payload: action.payload },
    });
  }
}

// Submit Reset Password
export function* watchsubmitLimitedResetPassword() {
  yield takeLatest(actionTypes.SUBMIT_LIMITED_RESET_PASSWORD.TRIGGER, submitLimitedResetPassword);
}
function* submitLimitedResetPassword(action: limitedTypes): any {
  yield put({ type: actionTypes.SUBMIT_LIMITED_RESET_PASSWORD.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.submitLimitedResetPassword, action.payload);
    const payload = (response.data && response.data.token) || '';
    if (payload) {
      setCookie(tokenCookieName, response.data.token, 1);
      yield put({ type: actionTypes.SUBMIT_LIMITED_RESET_PASSWORD.SUCCESS, payload });
    } else {
      yield put({ type: actionTypes.SUBMIT_LIMITED_RESET_PASSWORD.SUCCESS, payload: '' });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_LIMITED_RESET_PASSWORD.FAILURE,
      payload: { error, payload: action.payload },
    });
  }
}

// get Reset Password username
export function* watchgetLimitedVerifySecurityCode() {
  yield takeLatest(
    actionTypes.GET_LIMITED_VERIFY_SECURITY_CODE.TRIGGER,
    getLimitedVerifySecurityCode,
  );
}
function* getLimitedVerifySecurityCode(action: limitedTypes): any {
  yield put({ type: actionTypes.GET_LIMITED_VERIFY_SECURITY_CODE.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getLimitedVerifySecurityCode, action.payload);
    const payload = (response.data && response.data.result) || '';
    yield put({ type: actionTypes.GET_LIMITED_VERIFY_SECURITY_CODE.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIMITED_VERIFY_SECURITY_CODE.FAILURE,
      payload: { error, payload: action.payload },
    });
  }
}

function* watchgetLimitedAgencyLanding() {
  yield takeLatest(actionTypes.GET_LIMITED_AGENCY_LANDING.TRIGGER, getLimitedAgencyLanding);
}

function* getLimitedAgencyLanding(action: { payload: { guid: string } }): any {
  yield put({ type: actionTypes.GET_LIMITED_AGENCY_LANDING.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getLimitedAgencyLanding, action.payload.guid);
    const payload = response.data.result;
    yield put({
      type: actionTypes.SET_LIMITED_DETAILS.TRIGGER,
      payload: { limitedBidsList: payload },
    });
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_LANDING.SUCCESS });
  } catch {
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_LANDING.FAILURE });
  }
}

function* watchgetLimitedAgencyDetails() {
  yield takeLatest(actionTypes.GET_LIMITED_AGENCY_DETAILS.TRIGGER, getLimitedAgencyDetails);
}

function* getLimitedAgencyDetails(action: {
  payload: { guid: string; memberId: number | string };
}): any {
  yield put({ type: actionTypes.GET_LIMITED_AGENCY_DETAILS.REQUEST, meta: action.payload });
  try {
    const { guid = '', memberId = '' } = action.payload;
    const response = yield call(Api.getLimitedAgencyDetails, { guid, memberId });
    const payload = response.data.result;
    if (payload && payload.escapedAgencyName) {
      yield put({
        type: actionTypes.SET_LIMITED_DETAILS.TRIGGER,
        payload: { limitedAgencyDetails: payload },
      });
      yield put({ type: actionTypes.GET_LIMITED_AGENCY_DETAILS.SUCCESS });
    } else {
      yield put({ type: actionTypes.GET_LIMITED_AGENCY_DETAILS.FAILURE });
      window.location.href = Configurations.REACT_APP_NETWORK_URL;
    }
  } catch {
    yield put({ type: actionTypes.GET_LIMITED_AGENCY_DETAILS.FAILURE });
    window.location.href = Configurations.REACT_APP_NETWORK_URL;
  }
}

export function* limitedSaga() {
  yield fork(watchgetLimitedAgencySummary);
  yield fork(watchgetLimitedAgencyDocuments);
  yield fork(watchgetLimitedAgencyLegal);
  yield fork(watchgetLimitedAgencyPlanholders);
  yield fork(watchgetLimitedAgencyAwards);
  yield fork(watchgetLimitedAgencyCommodities);
  yield fork(watchgetLimitedAgencySelfDeclarations);
  yield fork(watchgetLimitedStatesList);
  yield fork(watchgetLimitedCountiesList);
  yield fork(watchgetLimitedTimezoneList);
  yield fork(watchgetLimitedMetroList);
  yield fork(watchsubmitLimitedAddMemberAccount);
  yield fork(watchgetLimitedWappagencies);
  yield fork(watchsubmitLimitedForgotPassword);
  yield fork(watchsubmitLimitedForgotEmail);
  yield fork(watchsubmitLimitedResetPassword);
  yield fork(watchgetLimitedVerifySecurityCode);
  yield fork(watchgetLimitedAgencyLanding);
  yield fork(watchgetLimitedAgencyDetails);
}
