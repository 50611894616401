import styled from 'styled-components';

import { FontFamily, ParagraphFontSize } from './../styles';
import { pxToRem } from '../utils/conversions';
import { Inactive } from '../types';

const choicePx = 20;

export const choiceRem = pxToRem(choicePx);
export const choicePaddingRem = pxToRem(choicePx + 10);
export const halfChoiceRem = pxToRem(choicePx / 2);

/**
 * ChoiceLabel is used for radio and checkboxes labels.
 */
export const ChoiceLabel = styled.label<Inactive>`
  display: block;
  position: relative;
  padding: 0 0 0 ${choicePaddingRem};
  margin: ${halfChoiceRem} ${halfChoiceRem} 0 0;
  cursor: ${({ inactive }) => (inactive ? 'not-allowed' : 'pointer')};
  line-height: ${choiceRem};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Base};
`;
