import React, {
  ChangeEvent,
  ChangeEventHandler,
  FormEventHandler,
  ForwardedRef,
  forwardRef,
  TextareaHTMLAttributes,
  useLayoutEffect,
  useRef,
} from 'react';
import { FieldError } from 'react-hook-form';

import getSizingData, { SizingData } from './getSizingData';
import { useComposedRef, useWindowResizeListener } from './hooks';
import calculateNodeHeight from './calculateNodeHeight';
import { FieldContainer } from '../field-container';
import { TextAreaStyled } from './DSTextAreaStyles';

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

type Style = Omit<NonNullable<TextAreaProps['style']>, 'maxHeight' | 'minHeight'> & {
  height?: number;
};

type TextAreaHeightChangeMeta = {
  rowHeight: number;
};

export type DSTextAreaProps = Omit<
  TextAreaProps,
  'name' | 'onBlur' | 'onChange' | 'placeholder' | 'style'
> & {
  /** Cache measurements to improve performance */
  cacheMeasurements?: boolean;
  /** Test ID for testing-library */
  dataTestId?: string;
  /** Disable the field */
  disabled?: boolean;
  /** FieldError from react-hook-form */
  error?: FieldError;
  /** Field label displayed above the textarea */
  label?: string;
  /** The maximum length of characters allowed */
  maxLength?: number;
  /** The maximum number of rows the area can expand to */
  maxRows?: number;
  /** Message displayed below the label */
  message?: string;
  /** The minimum number of rows the area starts with */
  minRows?: number;
  /** Field name for the label and input */
  name: string;
  /** onBlur event callback */
  onBlur?: (value: string) => void;
  /** onChange event callback */
  onChange?: (value: string) => void;
  /** Callback that fires when the height changes */
  onHeightChange?: (height: number, meta: TextAreaHeightChangeMeta) => void;
  /** Placeholder text that shows when area is empty */
  placeholder?: string;
  /** Forwarded ref for RHF */
  ref?: ForwardedRef<HTMLTextAreaElement>;
  /** Style overrides */
  style?: Style;
};

/**
 * Textarea field with label and error state handling
 * @example <DSTextarea name="textarea-example" />
 */
export const DSTextArea = forwardRef(
  (
    {
      cacheMeasurements,
      dataTestId,
      disabled,
      error,
      label,
      maxLength = 524288, // Per Google, this is the actual default
      maxRows = 5,
      message,
      minRows = 2,
      name,
      onChange,
      onHeightChange,
      onBlur,
      placeholder,
      ...props
    }: DSTextAreaProps,
    ref?: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    if (process.env.NODE_ENV !== 'production' && props.style) {
      if ('maxHeight' in props.style) {
        throw new Error(
          'Using `style.maxHeight` for <DSTextarea/> is not supported. Please use `maxRows`.',
        );
      }
      if ('minHeight' in props.style) {
        throw new Error(
          'Using `style.minHeight` for <DSTextarea/> is not supported. Please use `minRows`.',
        );
      }
    }
    const isControlled = props.value !== undefined;
    const libRef = useRef<HTMLTextAreaElement | null>(null);
    const composedRef = useComposedRef(libRef, ref);
    const heightRef = useRef(0);
    const measurementsCacheRef = useRef<SizingData>();

    const resizeTextarea = () => {
      const node = libRef.current!;
      const nodeSizingData =
        cacheMeasurements && measurementsCacheRef.current
          ? measurementsCacheRef.current
          : getSizingData(node);

      if (!nodeSizingData) {
        return;
      }

      measurementsCacheRef.current = nodeSizingData;

      const [height, rowHeight] = calculateNodeHeight(
        nodeSizingData,
        node.value || node.placeholder || 'x',
        minRows,
        maxRows,
      );

      if (heightRef.current !== height) {
        heightRef.current = height;
        node.style.setProperty('height', `${height}px`, 'important');
        if (onHeightChange) {
          onHeightChange(height, { rowHeight });
        }
      }
    };

    const change: ChangeEventHandler<HTMLTextAreaElement> & FormEventHandler = (
      event: ChangeEvent<HTMLTextAreaElement>,
    ) => {
      if (!isControlled) {
        resizeTextarea();
      }

      if (onChange) {
        onChange(event.target.value.trimStart());
      }
    };

    const blur: ChangeEventHandler<HTMLTextAreaElement> & FormEventHandler = (
      event: ChangeEvent<HTMLTextAreaElement>,
    ) => {
      if (!isControlled) {
        resizeTextarea();
      }

      if (onBlur) {
        onBlur(event.target.value.trim());
      }
    };

    useLayoutEffect(resizeTextarea);
    useWindowResizeListener(resizeTextarea);

    return (
      <FieldContainer error={error} label={label} name={name} message={message}>
        <TextAreaStyled
          data-testid={dataTestId}
          disabled={disabled}
          error={error}
          maxLength={maxLength}
          onChange={change}
          onBlur={blur}
          placeholder={placeholder}
          ref={composedRef}
          name={name}
          {...props}
        />
      </FieldContainer>
    );
  },
);
