import { memo } from 'react';

import { DemandStarSupportEmail } from 'src/utils/texts/common/emails';
import { TollFreeNumber } from 'src/utils/texts/common';

function GettingStart() {
  return (
    <>
      <p>
        DemandStar has amazing customer support available from 6 a.m. to 6 p.m. Pacific Daily (9
        a.m. to 9 p.m. Eastern). If you ever have an issue, give us a call at {TollFreeNumber} and
        we will walk you through the product and help you. You can also email us (
        {DemandStarSupportEmail}) and we will respond to your request in the order it is received.
        Note that almost all DemandStar support tickets are resolved within 4 hours.
      </p>
      <p>
        <b>Phone Support:</b> {TollFreeNumber}
      </p>
      <p>
        <b>Help Desk:</b> {DemandStarSupportEmail}
      </p>
    </>
  );
}

export default memo(GettingStart);
