import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import { NationalProductId, ProductType, SubscriptionProducts } from '../../../types/products';
import { UpsellCardData, UpsellType } from '../../../types/registration';

import { Card } from './Card';
import { ProductsPaths } from 'src/utils/texts/supplier/subscription';
import { selectedProductsState } from '../../../store/recoil/productState';

/**
 * @description a individual upsell card for a product
 * @returns JSX.Element
 * @example <UpsellCard
 * baseProductName='West County'
 * product={productType=ProductType.State, productName='Hawaii', productId: 1, price: 100}
 * totalAgencies={122}
 * upsellType={UpsellType.Parent}
 * />
 */
export const UpsellCard = ({
  baseProductName,
  product,
  totalAgencies,
  upsellType,
}: UpsellCardData) => {
  const { price, productName, productType } = product;

  const [selectedProducts, setSelectedProducts] =
    useRecoilState<SubscriptionProducts>(selectedProductsState);

  const history = useHistory();

  const handleClick = () => {
    switch (productType) {
      case ProductType.County:
        setSelectedProducts({
          ...selectedProducts,
          county: selectedProducts.county?.concat(product),
        });
        break;
      case ProductType.State:
        setSelectedProducts({
          ...selectedProducts,
          state: selectedProducts.state?.concat(product),
        });
        break;
      case ProductType.National:
        setSelectedProducts({
          ...selectedProducts,
          national: NationalProductId.UnitedStates,
        });
        break;
    }

    if (history?.location?.pathname === ProductsPaths.CurrentSubscription) {
      history.push(ProductsPaths.ExploreProducts);
    }
  };

  let header = `${productName} Subscription`;
  let linkText = `Subscribe to ${productName} for $${price}/year`;
  const level = productType;
  let message = '';

  switch (upsellType) {
    case UpsellType.Parent:
      message = `${baseProductName} is in ${productName}. Subscribe and get notifications from ${totalAgencies} agencies.`;
      break;
    case UpsellType.Nearby:
      message = `${baseProductName} is near ${productName}. Subscribe and get notifications from ${totalAgencies} agencies.`;
      break;
    case UpsellType.Popular:
      message = `You might also like ${productName}. Subscribe and get notifications from ${totalAgencies} agencies.`;
      break;
    case UpsellType.National:
      header = 'National Subscription';
      linkText = `Subscribe Nationwide for $${price}/year`;
      message = `Choose a national subscription and get notifications from ${totalAgencies} agencies.`;
      break;
  }

  return (
    <Card
      linkText={linkText}
      header={header}
      level={level}
      message={message}
      handleClick={handleClick}
    />
  );
};
