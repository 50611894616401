import { FieldError } from 'react-hook-form';
import { HTMLProps } from 'react';
import styled from 'styled-components';

import { Label } from '../label/Label';

import { FontFamily, FontWeight, Margin, ParagraphFontSize, TextColor, Width } from '../styles';

export interface FormFieldContainerProps extends HTMLProps<HTMLInputElement> {
  dataTestId?: string;
  error?: FieldError;
  marginBottom?: boolean;
}

export const BaseFieldContainer = styled.div<FormFieldContainerProps>`
  display: inline-block;
  margin-bottom: ${props => (props.marginBottom ? Margin.Base : 0)};
  max-width: ${Width.Full};
  width: ${Width.Full};

  label {
    color: ${props => (props.error ? TextColor.Error : TextColor.Label)};
  }
`;

export interface FormFieldMessageProps extends HTMLProps<HTMLSpanElement> {
  dataTestId?: string;
}
/**
 * This is the span of text that gives any additional message
 * needed for the input.
 */
export const FormFieldMessage = styled.span<FormFieldMessageProps>`
  color: ${TextColor.Helper};
  display: block;
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Small};
  font-weight: ${FontWeight.Base};
  line-height: ${ParagraphFontSize.Small};
  margin-bottom: ${Margin.Less};
  width: ${Width.Full};
`;

/**
 * This is the span of text that tells of any error.
 */
export const FormFieldError = styled(FormFieldMessage)<FormFieldMessageProps>`
  color: ${TextColor.Error};
  font-family: ${FontFamily.Base};
  text-align: right;
`;
