import { formatPhone, isValidPhone } from '../utils';
import { BaseDSLinkProps } from '../link/DSLink';
import { DSLink } from '../link';

export type DSPhoneProps = Omit<BaseDSLinkProps, 'children'> & {
  /** phone number, with only digits */
  children: string;
};

/**
 * Wrap this around a numeric phone number and it will format
 * the phone number and turn it into a phone link (if the phone is valid).
 * Does nothing for invalid phone numbers.
 * @example <DSPhone>4258675309</DSPhone>
 */
export const DSPhone = (props: DSPhoneProps) => {
  const { children, ...linkProps } = props;

  if (isValidPhone(children)) {
    return (
      <DSLink href={`tel:${children}`} {...linkProps}>
        {formatPhone(children)}
      </DSLink>
    );
  }

  return <>{children}</>;
};
