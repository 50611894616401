import { HTMLProps } from 'react';
import styled from 'styled-components';

import {
  BorderRadius,
  BorderThickness,
  FontFamily,
  FontWeight,
  InputFontSize,
  Padding,
  TextColor,
} from '../styles';
import { ButtonColor, ButtonHighlight } from '../styles/colors';
import { DSButtonAppearance } from './';
import { Inactive } from '../types';

type ButtonStyleProps = Pick<
  HTMLProps<HTMLButtonElement>,
  'onClick' | 'name' | 'id' | 'title' | 'type' | 'onKeyDown'
> &
  Partial<CSSStyleDeclaration> &
  Inactive & {
    appearance: DSButtonAppearance;
  };

// Functions passed to styled-component string template literals "...will receive the styled component's props as the first and only argument." (https://styled-components.com/docs/api#taggedtemplateliteral)

function getBgColor({ appearance, inactive }: Pick<ButtonStyleProps, 'appearance' | 'inactive'>) {
  if (inactive) {
    return ButtonColor.Inactive;
  }
  switch (appearance) {
    case 'primary':
      return ButtonColor.Primary;
    case 'secondary':
      return ButtonColor.Secondary;
    case 'tertiary':
      return ButtonColor.Tertiary;
    case 'danger':
    case 'ops':
      return ButtonColor.Danger;
    default:
      return ButtonColor.Primary;
  }
}

function getBgColorHover({ inactive, appearance }: ButtonStyleProps) {
  if (inactive) {
    return ButtonHighlight.Inactive;
  }
  switch (appearance) {
    case 'primary':
      return ButtonHighlight.Primary;
    case 'secondary':
      return ButtonHighlight.Secondary;
    case 'tertiary':
      return ButtonHighlight.Tertiary;
    case 'danger':
      return ButtonHighlight.Danger;
    default:
      return ButtonHighlight.Primary;
  }
}

function getBgColorActive({ inactive, appearance }: ButtonStyleProps) {
  return getBgColor({ inactive, appearance });
}
function getBorderTextColor({
  inactive,
  appearance,
}: Pick<ButtonStyleProps, 'appearance' | 'inactive'>) {
  if (inactive) {
    return TextColor.Inactive;
  }
  switch (appearance) {
    case 'secondary':
      return TextColor.Action;
    default:
      return TextColor.Contrast;
  }
}

function getHoverBorderTextColor({ inactive, appearance }: ButtonStyleProps) {
  if (inactive || appearance !== 'secondary') {
    return getBorderTextColor({ inactive, appearance });
  }
  return TextColor.ActionHover;
}
function getCursor({ inactive }: ButtonStyleProps) {
  return inactive ? 'not-allowed' : 'pointer';
}
function getPointerEvents({ inactive }: ButtonStyleProps) {
  // FIXME: Setting this to none prevents us from getting the 'not-allowed' cursor showing correctly above.
  return inactive ? 'none' : 'all';
}

/**
 * Styled button base component.
 * This is intended to be overridden via styled components for other implementations
 *   using optional props directly available in `ButtonBaseProps`, or
 *   using ```const MyButton = styled(Button)`...`;
 * TODO: implement theming via `styled-components` ThemeProvider HOC or custom impl.
 * @param props: ButtonProps
 */
export const BaseButton: React.FunctionComponent<ButtonStyleProps> = styled.button.attrs<ButtonStyleProps>(
  ({ inactive }: ButtonStyleProps) => ({
    disabled: inactive, // Button won't register onClick events
    'aria-disabled': inactive, // Button is recognized by screen reader
  }),
)`
  background-color: ${getBgColor};
  color: ${getBorderTextColor};
  cursor: ${getCursor};
  pointer-events: ${getPointerEvents};
  font-family: ${FontFamily.Header};
  font-size: ${InputFontSize.Placeholder};
  font-weight: ${FontWeight.Bold};
  padding: ${Padding.Button};
  border: solid ${BorderThickness.Base};
  border-color: ${getBorderTextColor};
  border-radius: ${BorderRadius.Base};
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    background-color: ${getBgColorHover};
    color: ${getHoverBorderTextColor};
    border-color: ${getHoverBorderTextColor};
  }
  &:active {
    background-color: ${getBgColorActive};
  }
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
`;
