import Api, { axiosRequestAuthenticated } from './api';

import {
  GetActivitiesApiResponse,
  GetAllActivitiesParameters,
  GetEBidderActivitiesParameters,
  GetWatchActivitiesParameters,
} from '../../types';
import { constants } from '../../utils/settings';
import { Paths } from '../../utils/constants';

//----- API calls-------//

/**
 * Typed Activities request
 * @param requestParams: GetAllActivitiesParameters
 */
export function getAllActivities(requestParams: GetAllActivitiesParameters) {
  return axiosRequestAuthenticated<GetActivitiesApiResponse>({
    baseURL: constants.api.url,
    data: requestParams,
    method: 'POST',
    url: Paths.activitiesall,
  });
}

/**
 * Typed EBidderActivities request
 * @param requestParams: GetEBidderActivitiesParameters
 */
export function getEBidderActivities(requestParams: GetEBidderActivitiesParameters) {
  const { page, size, ebidproposalStatus } = requestParams.payload;
  const finalRequestParams: GetAllActivitiesParameters = { page, size, type: 'bidder' };
  if (ebidproposalStatus?.value) finalRequestParams.status = ebidproposalStatus?.value;

  return axiosRequestAuthenticated<GetActivitiesApiResponse>({
    baseURL: constants.api.url,
    data: finalRequestParams,
    method: 'POST',
    url: Paths.activitiesall,
  });
}

/**
 * Typed WatchActivities request
 * @param requestParams: GetWatchActivitiesParameters
 */
export function getWatchActivities(requestParams: GetWatchActivitiesParameters) {
  const { page, size, bitWatchStatus } = requestParams.payload;
  const finalRequestParams: GetAllActivitiesParameters = { page, size, type: 'bidder' };
  if (bitWatchStatus?.value) finalRequestParams.status = bitWatchStatus?.value;

  return axiosRequestAuthenticated<GetActivitiesApiResponse>({
    baseURL: constants.api.trackUrl,
    data: requestParams,
    method: 'POST',
    url: Paths.getBidswatchersall,
  });
}

export function getActivitiesAll(data = {}) {
  return Api.postRequestWithAuthentication(Paths.activitiesall, data, {
    baseURL: constants.api.url,
  });
}

export function getActivitiesAllMember(data = {}) {
  return Api.postRequestWithAuthentication(Paths.memberactivitiesall, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getActivitiesWatchs(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getBidswatchersall, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function getBidActivityReport(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getBidActivityReport, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function exportBidActivityReport(data = {}) {
  return Api.downloadFileWithAuthentication(
    Paths.exportBidActivityReport,
    data,
    constants.api.agencyUrl,
  );
}

export function getQuoteActivityReport(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getQuoteActivityReport, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function exportQuoteActivityReport(data = {}) {
  return Api.downloadFileWithAuthentication(
    Paths.exportQuoteActivityReport,
    data,
    constants.api.agencyUrl,
  );
}
//----- API calls-------//
