interface ConfigurationsTypes {
  REACT_APP_API_URL: string;
  REACT_APP_API_AUTHURL: string;
  REACT_APP_API_DOCUMENTURL: string;
  REACT_APP_API_USERURL: string;
  REACT_APP_API_SUBSCRIPTIONURL: string;
  REACT_APP_API_TRACKURL: string;
  REACT_APP_API_AGENCY_URL: string;
  REACT_APP_APP_INSIGHTS_KEY: string;
  REACT_APP_APP_GA: string;
  REACT_APP_APP_GTM: string;
  REACT_APP_REDIRECT_HOME_URL: string;
  REACT_APP_NETWORK_URL: string;
  REACT_APP_PASSWORD_RESET_URL: string;
  REACT_APP_OPS_URL: string;
  REACT_APP_ROUTER_BASE_URL: string;
  REACT_APP_BTKey: string;
  REACT_APP_VERSION?: string;
  GENERATE_SOURCEMAP?: boolean;
  REACT_APP_GOOGLE_RECAPTCHA: string;
  REACT_APP_CONTENT_BLOB_URL: string;
  REACT_APP_IMAGES_CDN: string;
  REACT_APP_BASE_URL: string;
  AMPLITUDE_API_KEY: string;
  COOKIE_DOMAIN: string;
}

let Configurations: ConfigurationsTypes;

switch (window.location.hostname) {
  // Testing
  case 'qa.demandstar.com':
    Configurations = {
      REACT_APP_API_URL: 'https://apiqa.demandstar.com/contents-qa/content/v1',
      REACT_APP_API_AUTHURL: 'https://apiqa.demandstar.com/auth-qa/access/v1',
      REACT_APP_API_DOCUMENTURL: 'https://apiqa.demandstar.com/documents-qa/content/v1',
      REACT_APP_API_USERURL: 'https://apiqa.demandstar.com/users-qa/accounts/v1',
      REACT_APP_API_SUBSCRIPTIONURL: 'https://apiqa.demandstar.com/subscription-qa/account/v1',
      REACT_APP_API_TRACKURL: 'https://apiqa.demandstar.com/tracking-qa/content/v1',
      REACT_APP_API_AGENCY_URL: 'https://apiqa.demandstar.com/contents-qa/',
      REACT_APP_APP_INSIGHTS_KEY: 'c1045fb5-0535-4666-8490-b7d6423bdd04',
      REACT_APP_APP_GA: 'UA-143522261-2',
      REACT_APP_APP_GTM: 'GTM-5GF3XR5',
      REACT_APP_REDIRECT_HOME_URL: '/app/login',
      REACT_APP_NETWORK_URL: 'https://staging12.network.demandstar.com',
      REACT_APP_PASSWORD_RESET_URL: 'https://qa.demandstar.com/app/passwordreset',
      REACT_APP_OPS_URL: 'https://qa.demandstar.com:8081',
      REACT_APP_ROUTER_BASE_URL: '/app',
      REACT_APP_BTKey: 'sandbox_j7rrb2dm_48m7xkkkzs46xghs',
      REACT_APP_VERSION: '026',
      GENERATE_SOURCEMAP: false,
      REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      REACT_APP_CONTENT_BLOB_URL: 'https://networksitecontent.blob.core.windows.net/agecnycontent/',
      REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
      REACT_APP_BASE_URL: 'https://qa.demandstar.com/app',
      AMPLITUDE_API_KEY: '3bc541b379836d9f52b35e05ced1d186',
      COOKIE_DOMAIN: '.demandstar.com',
    };
    break;
  case 'qasqltemp.demandstar.com':
    Configurations = {
      REACT_APP_API_URL: 'https://api.demandstar.com/contents/content/v1',
      REACT_APP_API_AUTHURL: 'https://api.demandstar.com/auth/access/v1',
      REACT_APP_API_DOCUMENTURL: 'https://api.demandstar.com/documents/content/v1',
      REACT_APP_API_USERURL: 'https://api.demandstar.com/users/accounts/v1',
      REACT_APP_API_SUBSCRIPTIONURL: 'https://api.demandstar.com/payments/account/v1',
      REACT_APP_API_TRACKURL: 'https://api.demandstar.com/tracking/content/v1',
      REACT_APP_API_AGENCY_URL: 'https://api.demandstar.com/contents',
      REACT_APP_APP_INSIGHTS_KEY: 'c9493017-35ce-42e0-b806-d0144da2e8a6',
      REACT_APP_APP_GA: 'UA-177609458-1',
      REACT_APP_APP_GTM: 'GTM-5GF3XR5',
      REACT_APP_REDIRECT_HOME_URL: '/app/login',
      REACT_APP_NETWORK_URL: 'https://staging12.network.demandstar.com',
      REACT_APP_PASSWORD_RESET_URL: 'https://qasqltemp.demandstar.com/app/passwordreset',
      REACT_APP_OPS_URL: 'https://qaops.demandstar.com',
      REACT_APP_ROUTER_BASE_URL: '/app',
      REACT_APP_BTKey: 'production_k2kkd7x2_d3fybhcsdg78cdpv',
      REACT_APP_VERSION: '004',
      GENERATE_SOURCEMAP: false,
      REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      REACT_APP_CONTENT_BLOB_URL:
        'https://networkstaticcontent.blob.core.windows.net/agencycontent/',
      REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
      REACT_APP_BASE_URL: 'https://qasqltemp.demandstar.com/app',
      AMPLITUDE_API_KEY: '53320efd760ea246efbbd5d2b922c998',
      COOKIE_DOMAIN: '.demandstar.com',
    };
    break;
  case 'qaazsql.demandstar.com':
    Configurations = {
      REACT_APP_API_URL: 'https://apiqa.demandstar.com/content-azsql/content/v1',
      REACT_APP_API_AUTHURL: 'https://apiqa.demandstar.com/auth-azsql/access/v1',
      REACT_APP_API_DOCUMENTURL: 'https://apiqa.demandstar.com/documents-azsql/content/v1',
      REACT_APP_API_USERURL: 'https://apiqa.demandstar.com/users-azsql/accounts/v1',
      REACT_APP_API_SUBSCRIPTIONURL: 'https://apiqa.demandstar.com/payment-azsql/account/v1',
      REACT_APP_API_TRACKURL: 'https://apiqa.demandstar.com/tracking-azsql/content/v1',
      REACT_APP_API_AGENCY_URL: 'https://apiqa.demandstar.com/content-azsql/',
      REACT_APP_APP_INSIGHTS_KEY: 'c1045fb5-0535-4666-8490-b7d6423bdd04',
      REACT_APP_APP_GA: 'UA-143522261-2',
      REACT_APP_APP_GTM: 'GTM-5GF3XR5',
      REACT_APP_REDIRECT_HOME_URL: '/app/login',
      REACT_APP_NETWORK_URL: 'https://staging12.network.demandstar.com',
      REACT_APP_PASSWORD_RESET_URL: 'https://qaazsql.demandstar.com/app/passwordreset',
      REACT_APP_OPS_URL: 'https://qaazsql.demandstar.com:8081',
      REACT_APP_ROUTER_BASE_URL: '/app',
      REACT_APP_BTKey: 'sandbox_j7rrb2dm_48m7xkkkzs46xghs',
      REACT_APP_VERSION: '026',
      GENERATE_SOURCEMAP: false,
      REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      REACT_APP_CONTENT_BLOB_URL: 'https://networksitecontent.blob.core.windows.net/agecnycontent/',
      REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
      REACT_APP_BASE_URL: 'https://qaazsql.demandstar.com/app',
      AMPLITUDE_API_KEY: '3bc541b379836d9f52b35e05ced1d186',
      COOKIE_DOMAIN: '.demandstar.com',
    };
    break;
  case 'staging.demandstar.com':
    Configurations = {
      REACT_APP_API_URL: 'https://apiqa.demandstar.com/contents-qa/content/v1',
      REACT_APP_API_AUTHURL: 'https://apiqa.demandstar.com/auth-qa/access/v1',
      REACT_APP_API_DOCUMENTURL: 'https://apiqa.demandstar.com/documents-qa/content/v1',
      REACT_APP_API_USERURL: 'https://apiqa.demandstar.com/users-qa/accounts/v1',
      REACT_APP_API_SUBSCRIPTIONURL: 'https://apiqa.demandstar.com/subscription-qa/account/v1',
      REACT_APP_API_TRACKURL: 'https://apiqa.demandstar.com/tracking-qa/content/v1',
      REACT_APP_API_AGENCY_URL: 'https://apiqa.demandstar.com/contents-qa/',
      REACT_APP_APP_INSIGHTS_KEY: 'c1045fb5-0535-4666-8490-b7d6423bdd04',
      REACT_APP_APP_GA: 'UA-143522261-2',
      REACT_APP_APP_GTM: 'GTM-5GF3XR5',
      REACT_APP_REDIRECT_HOME_URL: '/app/login',
      REACT_APP_NETWORK_URL: 'https://staging12.network.demandstar.com',
      REACT_APP_PASSWORD_RESET_URL: `https://${window.location.hostname}/securitycodesignin`,
      REACT_APP_OPS_URL: `https://ops-${window.location.hostname}`,
      REACT_APP_ROUTER_BASE_URL: '/app',
      REACT_APP_BTKey: 'sandbox_j7rrb2dm_48m7xkkkzs46xghs',
      REACT_APP_VERSION: '026',
      GENERATE_SOURCEMAP: false,
      REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      REACT_APP_CONTENT_BLOB_URL: 'https://networksitecontent.blob.core.windows.net/agecnycontent/',
      REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
      REACT_APP_BASE_URL: 'https://staging12.network.demandstar.com/app',
      AMPLITUDE_API_KEY: '3bc541b379836d9f52b35e05ced1d186',
      COOKIE_DOMAIN: '.demandstar.com',
    };
    break;
  // Production
  case 'www.demandstar.com':
    Configurations = {
      REACT_APP_API_URL: 'https://api.demandstar.com/contents/content/v1',
      REACT_APP_API_AUTHURL: 'https://api.demandstar.com/auth/access/v1',
      REACT_APP_API_DOCUMENTURL: 'https://api.demandstar.com/documents/content/v1',
      REACT_APP_API_USERURL: 'https://api.demandstar.com/users/accounts/v1',
      REACT_APP_API_SUBSCRIPTIONURL: 'https://api.demandstar.com/payments/account/v1',
      REACT_APP_API_TRACKURL: 'https://api.demandstar.com/tracking/content/v1',
      REACT_APP_API_AGENCY_URL: 'https://api.demandstar.com/contents',
      REACT_APP_APP_INSIGHTS_KEY: 'c9493017-35ce-42e0-b806-d0144da2e8a6',
      REACT_APP_APP_GA: 'UA-177609458-1',
      REACT_APP_APP_GTM: 'GTM-N8N2WL4',
      REACT_APP_REDIRECT_HOME_URL: '/app/login',
      REACT_APP_NETWORK_URL: 'https://network.demandstar.com',
      REACT_APP_PASSWORD_RESET_URL: 'https://www.demandstar.com/app/passwordreset',
      REACT_APP_OPS_URL: 'https://ops.demandstar.com',
      REACT_APP_ROUTER_BASE_URL: '/app',
      REACT_APP_BTKey: 'production_k2kkd7x2_d3fybhcsdg78cdpv',
      REACT_APP_VERSION: '004',
      GENERATE_SOURCEMAP: false,
      REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      REACT_APP_CONTENT_BLOB_URL:
        'https://networkstaticcontent.blob.core.windows.net/agencycontent/',
      REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
      REACT_APP_BASE_URL: 'https://www.demandstar.com/app',
      AMPLITUDE_API_KEY: '53320efd760ea246efbbd5d2b922c998',
      COOKIE_DOMAIN: '.demandstar.com',
    };
    break;
  case 'qa2.demandstar.com':
    Configurations = {
      REACT_APP_API_URL: 'https://apiqa.demandstar.com/contents-qa/content/v1',
      REACT_APP_API_AUTHURL: 'https://apiqa.demandstar.com/auth-qa/access/v1',
      REACT_APP_API_DOCUMENTURL: 'https://apiqa.demandstar.com/documents-qa/content/v1',
      REACT_APP_API_USERURL: 'https://apiqa.demandstar.com/users-qa/accounts/v1',
      REACT_APP_API_SUBSCRIPTIONURL: 'https://apiqa.demandstar.com/subscription-qa/account/v1',
      REACT_APP_API_TRACKURL: 'https://apiqa.demandstar.com/tracking-qa/content/v1',
      REACT_APP_API_AGENCY_URL: 'https://apiqa.demandstar.com/contents-qa/',
      REACT_APP_APP_INSIGHTS_KEY: 'c1045fb5-0535-4666-8490-b7d6423bdd04',
      REACT_APP_APP_GA: 'UA-143522261-2',
      REACT_APP_APP_GTM: 'GTM-5GF3XR5',
      REACT_APP_REDIRECT_HOME_URL: '/app/login',
      REACT_APP_NETWORK_URL: 'https://staging12.network.demandstar.com',
      REACT_APP_PASSWORD_RESET_URL: 'https://qa2.demandstar.com/app/passwordreset',
      REACT_APP_OPS_URL: 'https://qa2.demandstar.com:8081',
      REACT_APP_ROUTER_BASE_URL: '/app',
      REACT_APP_BTKey: 'sandbox_j7rrb2dm_48m7xkkkzs46xghs',
      REACT_APP_VERSION: '026',
      GENERATE_SOURCEMAP: false,
      REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      REACT_APP_CONTENT_BLOB_URL: 'https://networksitecontent.blob.core.windows.net/agecnycontent/',
      REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
      REACT_APP_BASE_URL: 'https://qa2.demandstar.com/app',
      AMPLITUDE_API_KEY: '3bc541b379836d9f52b35e05ced1d186',
      COOKIE_DOMAIN: '.demandstar.com',
    };
    break;
  case 'localhost':
    Configurations = {
      REACT_APP_API_URL: 'https://apiqa.demandstar.com/contents-qa/content/v1',
      REACT_APP_API_AUTHURL: 'https://apiqa.demandstar.com/auth-qa/access/v1',
      //REACT_APP_API_AUTHURL: 'http://localhost:80/access/v1',
      REACT_APP_API_DOCUMENTURL: 'https://apiqa.demandstar.com/documents-qa/content/v1',
      REACT_APP_API_USERURL: 'https://apiqa.demandstar.com/users-qa/accounts/v1',
      REACT_APP_API_SUBSCRIPTIONURL: 'https://apiqa.demandstar.com/subscription-qa/account/v1',
      REACT_APP_API_TRACKURL: 'https://apiqa.demandstar.com/tracking-qa/content/v1',
      REACT_APP_API_AGENCY_URL: 'https://apiqa.demandstar.com/contents-qa/',
      REACT_APP_PASSWORD_RESET_URL: 'http://localhost:3000/app/passwordreset',
      REACT_APP_OPS_URL: `https://localhost:44317`,
      REACT_APP_APP_INSIGHTS_KEY: 'c1045fb5-0535-4666-8490-b7d6423bdd04',
      REACT_APP_APP_GA: 'UA-143522261-2',
      REACT_APP_APP_GTM: 'GTM-5GF3XR5',
      REACT_APP_REDIRECT_HOME_URL: '/app/login',
      REACT_APP_NETWORK_URL: 'https://staging12.network.demandstar.com',
      REACT_APP_ROUTER_BASE_URL: '/app',
      REACT_APP_BTKey: 'sandbox_j7rrb2dm_48m7xkkkzs46xghs',
      REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      REACT_APP_CONTENT_BLOB_URL: 'https://networksitecontent.blob.core.windows.net/agecnycontent/',
      REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
      REACT_APP_BASE_URL: 'http://localhost:3000/app',
      AMPLITUDE_API_KEY: '3bc541b379836d9f52b35e05ced1d186',
      COOKIE_DOMAIN: 'localhost',
    };
    break;
  // Fallback (Development)
  default:
    Configurations = {
      REACT_APP_API_URL: 'https://apiqa.demandstar.com/contents-qa/content/v1',
      REACT_APP_API_AUTHURL: 'https://apiqa.demandstar.com/auth-qa/access/v1',
      REACT_APP_API_DOCUMENTURL: 'https://apiqa.demandstar.com/documents-qa/content/v1',
      REACT_APP_API_USERURL: 'https://apiqa.demandstar.com/users-qa/accounts/v1',
      REACT_APP_API_SUBSCRIPTIONURL: 'https://apiqa.demandstar.com/subscription-qa/account/v1',
      REACT_APP_API_TRACKURL: 'https://apiqa.demandstar.com/tracking-qa/content/v1',
      REACT_APP_API_AGENCY_URL: 'https://apiqa.demandstar.com/contents-qa/',
      REACT_APP_APP_INSIGHTS_KEY: 'c1045fb5-0535-4666-8490-b7d6423bdd04',
      REACT_APP_APP_GA: 'UA-143522261-2',
      REACT_APP_APP_GTM: 'GTM-5GF3XR5',
      REACT_APP_REDIRECT_HOME_URL: '/app/login',
      REACT_APP_NETWORK_URL: 'https://staging12.network.demandstar.com',
      REACT_APP_PASSWORD_RESET_URL: `https://${window.location.hostname}/app/passwordreset`,
      REACT_APP_OPS_URL: `https://${window.location.hostname}:8081`,
      REACT_APP_ROUTER_BASE_URL: '/app',
      REACT_APP_BTKey: 'sandbox_j7rrb2dm_48m7xkkkzs46xghs',
      REACT_APP_VERSION: '026',
      GENERATE_SOURCEMAP: false,
      REACT_APP_GOOGLE_RECAPTCHA: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      REACT_APP_CONTENT_BLOB_URL: 'https://networksitecontent.blob.core.windows.net/agecnycontent/',
      REACT_APP_IMAGES_CDN: 'https://webappcontent.blob.core.windows.net/appfiles/images',
      REACT_APP_BASE_URL: `https://${window.location.hostname}/app`,
      AMPLITUDE_API_KEY: '3bc541b379836d9f52b35e05ced1d186',
      COOKIE_DOMAIN: '.demandstar.com',
    };
    break;
}

export default Configurations;
