/* eslint-disable max-lines */
import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { BidExternalStatusType, memberTypes, userEvent } from '../../utils/constants';
import { formatPhoneNumber, quietLogOutFn, toastFn } from '../../utils/helpers';
import history from '../../utils/history';
import { saveBidName } from '../../utils/storage';
import * as actionTypes from '../actionTypes';
import * as AddBidApi from '../services/addbid';
import * as bidApi from '../services/bids';
import * as sharedApi from '../services/shared';
import {
  bidspayloadTypes,
  BidSummaryResult,
  bidsummaryTypes,
  planTypes,
  reminderTypes,
  supplierdetailsTypes,
  supplierTypes,
} from '../../types/bidssummary';

import { BidSummaryState } from '../reducers/bidssummary';
import { Awardee, Planholder, PlanholderDetailType } from '../../types/biddetail';
import { AddBidState } from '../reducers/addbid';
import { SetAddBidDetailsPayload } from '../actions';
import { NewSupplier } from '../../types/awardBid';
import { WizardPage, WizardStatus } from '../../types/wizard';

const bidsummarytoastID = '91a3f025-1afb-4660-afaa-f89dbd72d2c6';

function* watchBidSummaryAsync() {
  yield takeLatest(actionTypes.LOAD_BID_SUMMARY.TRIGGER, bidSummaryAsync);
}

function* bidSummaryAsync(action: {
  payload: { otherapis: boolean; checkfor: string; bidId: number };
}): any {
  yield put({ type: actionTypes.LOAD_BID_SUMMARY.REQUEST, meta: action.payload });
  try {
    const { otherapis = false, checkfor = '', bidId } = action.payload || {};
    const response = yield call(bidApi.getBidsSummary, action.payload);

    if (response.data.result) {
      let payload = {};
      if (checkfor === 'ebid') {
        const bidssummary = yield select(state => state.bidssummary);
        const { results = {} } = bidssummary;
        payload = { summary: { ...results, ...response.data.result } };
      } else {
        payload = { summary: { ...response.data.result, commodities: [] } };
      }
      yield put({ type: actionTypes.LOAD_BID_SUMMARY.SUCCESS, payload });

      if (checkfor === 'ebid') {
        if (
          ['Submit', 'ViewEdit'].includes(response.data.result.eBidding) &&
          response.data.result.bidExternalStatusType === BidExternalStatusType.Active
        ) {
          const wizard: WizardPage[] = [
            { name: 'Contact Information', status: WizardStatus.Current, id: 1 },
            { name: 'Documents Upload', status: WizardStatus.Unavailable, id: 2 },
            { name: 'Review Bid', status: WizardStatus.Unavailable, id: 3 },
          ];
          yield put({ type: actionTypes.SET_EBIDS_DETAILS.TRIGGER, payload: { wizard } });
          history.push(`/suppliers/bids/${bidId}/response`);
        } else {
          toastFn('error', 'Bid has been closed. Please contact the agency', bidId);
        }
      }

      if (otherapis) {
        yield call(getOtherBidDetails, action);
      }
    } else {
      yield put({ type: actionTypes.LOAD_BID_SUMMARY.FAILURE });
    }
    if (response.data.result.eBidding === 'Submit') {
      saveBidName({ bidName: response.data.result.bidName, bidID: response.data.result.bidID });
    } else {
      localStorage.removeItem('bidName');
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_SUMMARY.FAILURE,
      payload: { payload: action.payload, error },
    });
    history.replace('/');
  }
}

function* watchgetBidsWatchStatus() {
  yield takeLatest(actionTypes.GET_BID_SUMMARY_WATCH_STATUS.TRIGGER, getBidsWatchStatus);
}

function* getBidsWatchStatus(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.GET_BID_SUMMARY_WATCH_STATUS.REQUEST, meta: action.payload });

  try {
    const response = yield call(bidApi.getBidsWatchStatus, action.payload);
    yield put({
      type: actionTypes.GET_BID_SUMMARY_WATCH_STATUS.SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_BID_SUMMARY_WATCH_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAwardees() {
  yield takeLatest(actionTypes.GET_BID_AWARDEES.TRIGGER, getAwardees);
}

function* getAwardees(action: { payload: { bidId: number } }): any {
  yield put({ type: actionTypes.GET_BID_AWARDEES.REQUEST, meta: action.payload });

  try {
    const awardeeResponse = yield call(AddBidApi.getAwardee, action.payload);
    const awardees = awardeeResponse.data.result || '';

    if (awardees) {
      yield put({ type: actionTypes.GET_BID_AWARDEES.SUCCESS, payload: { awardees } });
    } else {
      yield put({ type: actionTypes.GET_BID_AWARDEES.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_BID_AWARDEES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetBidView() {
  yield takeLatest(actionTypes.GET_BID_VIEW.TRIGGER, getBidView);
}

function* getBidView(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.GET_BID_VIEW.REQUEST, meta: action.payload });

  try {
    const bidviewResponse = yield call(bidApi.getbBidView, action.payload);
    if (bidviewResponse.data.result.visitLimitExceeded) {
      yield delay(1500);
      quietLogOutFn();
      yield put({ type: actionTypes.CLEAR_APP_STATE.TRIGGER });
      history.replace('/bidlimitexceeded');
      document.body.className = '';
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_BID_VIEW.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidWriterInfo() {
  yield takeLatest(actionTypes.GET_WRITER_INFO.TRIGGER, bidWriterInfo);
}

function* bidWriterInfo(action: {
  payload: { writerAccountID: string | number; memberID: number };
}): any {
  yield put({ type: actionTypes.GET_WRITER_INFO.REQUEST, meta: action.payload });

  try {
    const bidWriterResponse = yield call(bidApi.getbidWriter, action.payload.writerAccountID);

    const memberinfo = yield select(state => state && state.memberinfo);
    let memberInfo = memberinfo;

    if (!memberinfo || (memberInfo && memberInfo.mi !== action.payload.memberID)) {
      const memberInfoResult = yield call(bidApi.getMemberInfo, {
        memberId: action.payload.memberID,
        isCurrentMember: false,
      });
      memberInfo = memberInfoResult.data.result;
    }

    const writerinfo = {
      writerInfo: bidWriterResponse.data.result,
      memberInfo: memberInfo,
    };

    yield put({ type: actionTypes.GET_WRITER_INFO.SUCCESS, payload: writerinfo });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  } catch (error) {
    yield put({
      type: actionTypes.GET_WRITER_INFO.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchBidAuditTrial() {
  yield takeLatest(actionTypes.BIDS_AUDIT_TRIAL.TRIGGER, bidAuditTrial);
}

function* bidAuditTrial(action: bidspayloadTypes) {
  yield put({ type: actionTypes.BIDS_AUDIT_TRIAL.REQUEST, meta: action.payload });

  try {
    let response = { data: [] };
    response = yield call(bidApi.getbidAuditTrial, action.payload);

    yield put({ type: actionTypes.BIDS_AUDIT_TRIAL.SUCCESS, payload: response.data });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  } catch (error) {
    yield put({
      type: actionTypes.BIDS_AUDIT_TRIAL.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchGetBidCommodityCodes() {
  yield takeLatest(actionTypes.GET_BID_COMMODITYCODES.TRIGGER, getBidCommodityCodes);
}

function* getBidCommodityCodes(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.GET_BID_COMMODITYCODES.REQUEST, meta: action.payload });

  try {
    const bidSummary: BidSummaryState = yield select(state => state.bidssummary || '');
    const { results = {} } = bidSummary;
    const response = yield call(bidApi.getBidCommodityByType, action.payload);
    const summaryData = { ...(results as BidSummaryResult), commodities: response.data.result };
    yield put({
      type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
      payload: { results: summaryData },
    });
    yield put({ type: actionTypes.GET_BID_COMMODITYCODES.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_BID_COMMODITYCODES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* getOtherBidDetails(action: { payload: { bidId: number } }): any {
  const auth = yield select(state => state.auth);
  const { mt: memberType } = auth;

  if (memberType === memberTypes.agencyBuyer) {
    yield put({ type: actionTypes.LOAD_BID_STATS.TRIGGER, payload: action.payload });
    //yield put({ type: actionTypes.LOAD_BID_WATCHERS.TRIGGER, payload: action.payload })
  } else {
    yield put({ type: actionTypes.LOAD_BID_PURCHASEINFO.TRIGGER, payload: action.payload });
    //yield put({ type: actionTypes.LOAD_BID_SIMILARBIDS.TRIGGER, payload: {} })
    yield put({ type: actionTypes.LOAD_BID_PLANHOLDERS.TRIGGER, payload: action.payload });
  }
  yield put({ type: actionTypes.LOAD_BID_DOCUMENTS.TRIGGER, payload: action.payload });
  yield put({
    type: actionTypes.GET_BID_COMMODITYCODES.TRIGGER,
    payload: { bidId: action.payload.bidId, type: 'bid' },
  });
  // yield put({ type: actionTypes.LOAD_BID_BIDDERS.TRIGGER, payload: action.payload })
  //yield put({ type: actionTypes.LOAD_BID_LEGALAD.TRIGGER, payload: action.payload });
  /* yield put({ type: actionTypes.LOAD_BID_AUDITTRAIL.TRIGGER, payload: action.payload }) */
}

//Bid Statistics
function* watchBidsStats() {
  yield takeLatest(actionTypes.LOAD_BID_STATS.TRIGGER, bidStatsAsync);
}

function* bidStatsAsync(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.LOAD_BID_STATS.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getBidsStats, action.payload);
    yield put({
      type: actionTypes.LOAD_BID_STATS.SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_STATS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidsDocuments() {
  yield takeLatest(actionTypes.LOAD_BID_DOCUMENTS.TRIGGER, bidDocumentsAsync);
}

function* bidDocumentsAsync(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.LOAD_BID_DOCUMENTS.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getBidsDocuments, action.payload);
    yield put({
      type: actionTypes.LOAD_BID_DOCUMENTS.SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_STATS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

// Bid Watchers
function* watchBidsWatchers() {
  yield takeLatest(actionTypes.LOAD_BID_WATCHERS.TRIGGER, bidWatchersAsync);
}

function* bidWatchersAsync(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.LOAD_BID_WATCHERS.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getBidsWatchers, action.payload);
    if (response) {
      const result = response.data.result.sort(
        (a: { supplierName: string }, b: { supplierName: string }) =>
          a.supplierName.localeCompare(b.supplierName),
      );
      const total = response.data.total;
      yield put({ type: actionTypes.LOAD_BID_WATCHERS.SUCCESS, payload: { result, total } });
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { internalLoader: false },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_WATCHERS.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchBidsPlanHolders() {
  yield takeLatest(actionTypes.LOAD_BID_PLANHOLDERS.TRIGGER, bidPlanHoldersAsync);
}

function* bidPlanHoldersAsync(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.LOAD_BID_PLANHOLDERS.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getBidsPlanHolders, action.payload);
    response.data.ids = response.data.result.map((item: { mi: number }) => item.mi);
    const planholdersList = response.data.result.map((item: any) => {
      return {
        ...item,
        supplierName: item.supplierName ? item.supplierName : '',
      };
    });
    planholdersList.sort((a: { supplierName: string }, b: { supplierName: string }) =>
      a.supplierName.localeCompare(b.supplierName),
    );

    let planholdersdetail: any = [];
    let currentsupplier: any = '';
    if (planholdersList.length > 0) {
      const planholdersDetailResponse = yield call(bidApi.getPlanholdersDetail, {
        memberIds: response.data.ids.join(','),
      });
      planholdersdetail = planholdersDetailResponse.data.result;
      const initialMemberId = planholdersList[0].mi;
      currentsupplier = planholdersdetail.find(
        (item: PlanholderDetailType) => item.memberId === initialMemberId,
      );
      currentsupplier = {
        ...currentsupplier,
        ...planholdersList[0],
      };
    }

    yield put({
      type: actionTypes.LOAD_BID_PLANHOLDERS.SUCCESS,
      payload: { ...response.data, result: planholdersList, planholdersdetail, currentsupplier },
    });

    if (action.payload.pagefor !== 'supplier' && currentsupplier) {
      yield put({
        type: actionTypes.GET_PLANHOLDERS_OTHER_DETAILS.TRIGGER,
        payload: { memberId: currentsupplier.mi, bidId: action.payload.bidId },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_PLANHOLDERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidsPostbidViewers() {
  yield takeLatest(actionTypes.LOAD_BID_POSTBIDVIEWERS.TRIGGER, bidsPostbidViewers);
}

function* bidsPostbidViewers(action: { payload: { bidId: number } }): any {
  yield put({ type: actionTypes.LOAD_BID_POSTBIDVIEWERS.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getBidsPostbidViewers, action.payload);
    response.data.ids = response.data.result.map((item: { mi: number }) => item.mi);
    response.data.result.sort((a: { supplierName: string }, b: { supplierName: string }) =>
      a.supplierName.localeCompare(b.supplierName),
    );
    if (response.data.result && response.data.result.length > 0) {
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { postbid_internalLoader: true },
      });
      const value = response.data.result[0];
      yield put({
        type: actionTypes.SET_POST_BID_SUPPLIER_DETAILS.TRIGGER,
        payload: { currentpostviewer: value, bidId: action.payload.bidId },
      });
    } else {
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { postbid_internalLoader: false },
      });
    }
    yield put({ type: actionTypes.LOAD_BID_POSTBIDVIEWERS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_POSTBIDVIEWERS.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { postbid_internalLoader: false },
    });
  }
}

function* watchBidsBidders() {
  yield takeLatest(actionTypes.LOAD_BID_BIDDERS.TRIGGER, bidBiddersAsync);
}
function* bidBiddersAsync(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.LOAD_BID_BIDDERS.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getBidsBidders, action.payload);
    yield put({
      type: actionTypes.LOAD_BID_BIDDERS.SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_BIDDERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidsSimilarBids() {
  yield takeLatest(actionTypes.LOAD_BID_SIMILARBIDS.TRIGGER, bidsSimilarBidsAsync);
}

function* bidsSimilarBidsAsync(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.LOAD_BID_SIMILARBIDS.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getBidsSimilarBids, action.payload);
    yield put({ type: actionTypes.LOAD_BID_SIMILARBIDS.SUCCESS, payload: response.data.result });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_SIMILARBIDS.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchBidsBidWatch() {
  yield takeLatest(actionTypes.LOAD_BID_BIDWATCH.TRIGGER, bidsBidWatchAsync);
}

function* bidsBidWatchAsync(action: { payload: { bidId: number; watchOn: boolean } }): any {
  yield put({ type: actionTypes.LOAD_BID_BIDWATCH.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.watchBid, action.payload);
    if (response.data.status) {
      const bids = yield select(state => state.bids || '');
      const { results = [] } = bids;
      if (results.length > 0) {
        const index = results.findIndex(
          (item: { bidId: number }) => item.bidId === action.payload.bidId,
        );
        results[index].watches = action.payload.watchOn
          ? results[index].watches + 1
          : results[index].watches - 1;
        results[index].watchStatus = action.payload.watchOn ? true : false;
        results[index].watchOn = action.payload.watchOn ? true : false;
        yield put({ type: actionTypes.SET_BID_DETAILS.TRIGGER, payload: { results } });
      }
      yield put({ type: actionTypes.LOAD_BID_BIDWATCH.SUCCESS, payload: action.payload.watchOn });
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_BIDWATCH.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidsLegalAd() {
  yield takeLatest(actionTypes.LOAD_BID_LEGALAD.TRIGGER, bidsLegalAdAsync);
}

function* bidsLegalAdAsync(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.LOAD_BID_LEGALAD.REQUEST, meta: action.payload });
  try {
    yield put({
      type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
      payload: { legalAddLoading: true },
    });
    const response = yield call(bidApi.getBidsLegalAd, action.payload);
    yield put({
      type: actionTypes.LOAD_BID_LEGALAD.SUCCESS,
      payload: { ...response.data.result, bidId: action.payload.bidId },
    });
    yield put({
      type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
      payload: { legalAddLoading: false },
    });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_LEGALAD.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidsEbids() {
  yield takeLatest(actionTypes.LOAD_BID_EBIDS.TRIGGER, bidsEbidsAsync);
}
function* bidsEbidsAsync(action: { payload: { bidId: number } }): any {
  yield put({ type: actionTypes.LOAD_BID_EBIDS.REQUEST, meta: action.payload });
  try {
    const bidssummary = yield select(state => state.bidssummary);
    const response = yield call(bidApi.getBidsEbids, { bidId: action.payload.bidId });
    const { requiredDocuments: rDocuments = [], supplementalDocuments: sDocuments = [] } =
      response.data.result || '';
    let requiredDocuments = [];
    if (rDocuments && rDocuments.length > 0) {
      requiredDocuments = rDocuments.map((item: { icon: string; statusName: string }) => ({
        ...item,
        ...{ icon: item.icon || 'None', statusName: item.statusName || 'None' },
      }));
    }

    let supplementalDocuments = [];
    if (sDocuments && sDocuments.length > 0) {
      supplementalDocuments = sDocuments.map((item: { icon: string; statusName: string }) => ({
        ...item,
        ...{ icon: item.icon || 'None', statusName: item.statusName || 'None' },
      }));
    }

    const contactInfo = yield call(bidApi.getbidresponderinfo, {
      bidResponderAddressID: response.data.result.responderAddressId || 0,
      // memberId: action.payload.memberId,
      supplierId: response.data.result.supplierId,
    });

    if (!response.data.result.address1)
      response.data.result.address1 = contactInfo.data.result.address1;
    if (!response.data.result.address2)
      response.data.result.address2 = contactInfo.data.result.address2;
    if (!response.data.result.city) response.data.result.city = contactInfo.data.result.city;
    if (!response.data.result.state) response.data.result.state = contactInfo.data.result.state;
    if (!response.data.result.postalCode)
      response.data.result.postalCode = contactInfo.data.result.postalCode;
    // if (!response.data.result.phoneNumber) response.data.result.phoneNumber = contactInfo.data.result.phone
    if (!response.data.result.countryName)
      response.data.result.countryName = contactInfo.data.result.country;

    response.data.result = { ...response.data.result, requiredDocuments, supplementalDocuments };
    if (!response.data.result.agencyState) {
      let memberInfo: any = '';
      memberInfo = yield call(bidApi.getMemberInfo, {
        memberId:
          (bidssummary.results && bidssummary.results.memberID) || contactInfo.data.result.memberId,
        isCurrentMember: false,
      });
      if (memberInfo) response.data.result.agencyState = memberInfo.data.result.st;
    }

    yield put({ type: actionTypes.LOAD_BID_EBIDS.SUCCESS, payload: response.data.result });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_EBIDS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidsPurchaseInfo() {
  yield takeLatest(actionTypes.LOAD_BID_PURCHASEINFO.TRIGGER, bidsPurchaseInfoAsync);
}
function* bidsPurchaseInfoAsync(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.LOAD_BID_PURCHASEINFO.REQUEST, meta: action.payload });
  try {
    //const { bidId, type = '' } = action.payload
    const response = yield call(bidApi.getBidsPurchaseInfo, action.payload);
    yield put({
      type: actionTypes.LOAD_BID_PURCHASEINFO.SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_PURCHASEINFO.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidsDownloadDocument() {
  yield takeLatest(actionTypes.DOWNLOAD_DOCUMENT.TRIGGER, bidsDownloadDocument);
}
function* bidsDownloadDocument(action: bidspayloadTypes) {
  yield put({ type: actionTypes.DOWNLOAD_DOCUMENT.REQUEST, meta: action.payload });
  try {
    yield call(bidApi.getBidsDocument, action.payload);
    yield put({ type: actionTypes.GET_AMPLITUDE_USER_PROPERTIES.TRIGGER });
    yield put({ type: actionTypes.DOWNLOAD_DOCUMENT.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.DOWNLOAD_DOCUMENT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidDownloadAllDocument() {
  yield takeLatest(actionTypes.DOWNLOAD_ALL_DOCUMENT.TRIGGER, bidDownloadAllDcouments);
}
function* bidDownloadAllDcouments(action: bidspayloadTypes) {
  yield put({ type: actionTypes.DOWNLOAD_ALL_DOCUMENT.REQUEST, meta: action.payload });
  try {
    yield call(bidApi.getBidsAllDocuments, action.payload);
    yield put({ type: actionTypes.GET_AMPLITUDE_USER_PROPERTIES.TRIGGER });
    yield put({ type: actionTypes.DOWNLOAD_ALL_DOCUMENT.SUCCESS });
    const payloadEvent = {
      eventId: userEvent.DownloadPackage,
      parentId: action.payload.id,
      value: 'Downloaded',
    };
    yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
  } catch (error) {
    yield put({
      type: actionTypes.DOWNLOAD_DOCUMENT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidsResponseDocumentDownlad() {
  yield takeLatest(actionTypes.LOAD_BID_RESPONSE_DOCUMENTS.TRIGGER, bidsResponseDocument);
}

function* bidsResponseDocument(action: bidspayloadTypes) {
  yield put({ type: actionTypes.LOAD_BID_RESPONSE_DOCUMENTS.REQUEST, meta: action.payload });
  try {
    yield call(bidApi.getBidsResponseDocument, action.payload);
    yield put({ type: actionTypes.LOAD_BID_RESPONSE_DOCUMENTS.SUCCESS });
    const { id, type, docId } = action.payload;
    if (id && type && docId) {
      const payloadEvent = {
        eventId: type === 'Bid' ? userEvent.DownloadBidDocument : userEvent.DownloadQuoteDocument,
        parentId: id,
        value: docId,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_RESPONSE_DOCUMENTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidsSimilarSuppliers() {
  yield takeLatest(actionTypes.LOAD_BIDS_SIMILAR_SUPPLIERS.TRIGGER, bidsSimilarSuppliersAsync);
}

function* bidsSimilarSuppliersAsync(action: bidspayloadTypes): any {
  try {
    yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: { spLoader: true } });
    const response = yield call(bidApi.getBidsSimilarSellers, action.payload);
    const similarSuppliers = response.data.result || [];
    yield put({
      type: actionTypes.LOAD_BIDS_SIMILAR_SUPPLIERS.SUCCESS,
      payload: { similarSuppliers },
    });
    yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: { spLoader: false } });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BIDS_SIMILAR_SUPPLIERS.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: { spLoader: false } });
  }
}

function* watchBidsPlanholderDocuments() {
  yield takeLatest(actionTypes.LOAD_BID_PLANHOLDER_DOCUMENTS.TRIGGER, bidsPlanholderDocumentsAsync);
}

function* bidsPlanholderDocumentsAsync(action: bidspayloadTypes): any {
  try {
    const planholderDocuments = yield call(bidApi.getBidsPlanholdersDocuments, action.payload);

    yield put({
      type: actionTypes.LOAD_BID_PLANHOLDER_DOCUMENTS.SUCCESS,
      payload: {
        planholderDocuments: planholderDocuments.data.result || [],
      },
    });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_PLANHOLDER_DOCUMENTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsendBidSelectedSupplier() {
  yield takeLatest(actionTypes.SEND_BID_SELECTED_SUPPLIER.TRIGGER, sendBidSelectedSupplier);
}

function* sendBidSelectedSupplier(action: {
  payload: { similarSuppliers: any[]; bidId: number; supplierId: number };
}): any {
  yield put({ type: actionTypes.SEND_BID_SELECTED_SUPPLIER.REQUEST, meta: action.payload });
  try {
    const { similarSuppliers, bidId, supplierId } = action.payload;
    const finalvalue = {
      bidId,
      supplierId,
      members: similarSuppliers
        .filter((item: { selected: boolean }) => item.selected)
        .map((item: { mi: number }) => item.mi)
        .join(','),
    };
    const response = yield call(bidApi.sendBidSelectedSupplier, finalvalue);
    const data = response.data.status || '';
    if (data) {
      const newlist = similarSuppliers.filter(item => !item.selected);
      yield delay(50);
      yield put({ type: actionTypes.SEND_BID_SELECTED_SUPPLIER.SUCCESS, payload: newlist });
      toastFn('success', 'Send', bidsummarytoastID);
    } else {
      yield put({ type: actionTypes.SEND_BID_SELECTED_SUPPLIER.FAILURE });
      toastFn('error', 'Failed', bidsummarytoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SEND_BID_SELECTED_SUPPLIER.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', bidsummarytoastID);
  }
}

function* watchbidsAddNewSupplier() {
  yield takeLatest(actionTypes.ADD_NEW_SUPPLIER.TRIGGER, bidsAddNewSupplier);
}

export interface AddNewSupplierPayload {
  bidId?: number;
  formData?: any;
  newSupplier?: NewSupplier;
  searchResult?: Planholder[];
  type: 1 | 2;
  addToAwardeeList?: boolean;
}

interface BidPlanholder {
  accountId: number;
  address1: string;
  address2: string;
  bidId: number;
  city?: string;
  contact: string;
  countryId: number;
  declaredAttributes: string;
  email: string;
  faxNumber: string;
  memberId: number;
  name: string;
  phoneNumber?: string;
  planholderId: number;
  postalCode: string;
  stateId: number;
}

function* bidsAddNewSupplier(action: { payload: AddNewSupplierPayload }): any {
  yield put({ type: actionTypes.ADD_NEW_SUPPLIER.REQUEST, meta: action.payload });
  try {
    const bidsummary: BidSummaryState = yield select(state => state.bidssummary);
    const bidId = action.payload.bidId || bidsummary.stats.bidID;
    const values = { ...action.payload.formData };
    const isExist = bidsummary.planHolders.result.filter(
      planholder =>
        (!values.planholderId && planholder.email === values.email) ||
        (values.isEdit === false && planholder.mi === values.planholderId) ||
        (values.isEdit === true &&
          planholder.mi !== values.editedMemberId &&
          planholder.email === values.email),
    ).length;
    if (isExist === 0) {
      const keys = values.selfdecAttr ? Object.keys(values.selfdecAttr) : [];
      const declaredAttr =
        values.selfdecAttr && keys
          ? keys.filter((item, index) => values.selfdecAttr[item] === true) || []
          : [];
      let fields: BidPlanholder | null = null;
      if (action.payload.newSupplier) {
        const newSupplier = action.payload.newSupplier;
        fields = {
          name: newSupplier.name,
          city: newSupplier.address?.city || '',
          phoneNumber: newSupplier.phoneNumber || '',
          email: newSupplier.email.trim(),
          planholderId: 0,
          address1: newSupplier.address?.address1.trim() || '',
          address2: newSupplier.address?.address2.trim() || '',
          stateId: newSupplier.address?.state?.id || 0,
          countryId: newSupplier.address?.country?.value || 0,
          postalCode: '',
          faxNumber: '',
          contact: '',
          declaredAttributes: '',
          bidId,
          memberId: 0,
          accountId: 0,
        };
      } else {
        fields = {
          name: values.companyname ? values.companyname.trim() : '',
          city: values.city ? values.city.trim() : '',
          phoneNumber: values.phoneNumber ? values.phoneNumber : '',
          email: values.email ? values.email.trim() : '',
          planholderId: values.planholderId,
          address1: values.address1 ? values.address1.trim() : '',
          address2: values.address2 ? values.address2.trim() : '',
          stateId: values.state.id,
          countryId: values.country.value,
          postalCode: values.postalCode,
          faxNumber: values.faxNumber,
          contact: values.contact ? values.contact.trim() : '',
          declaredAttributes: declaredAttr.join(','),
          bidId,
          memberId: 0,
          accountId: 0,
        };
      }

      yield put({ type: actionTypes.LOAD_BID_PLANHOLDERS.REQUEST, meta: action.payload });

      const addPlanholderResponse = yield call(bidApi.addBidPlanholder, fields);
      yield put({
        type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
        payload: { selectedSuppliers: [], currentsupplier: '' },
      });

      yield delay(1000);
      const response: {
        data: {
          result: Planholder[];
          ids?: number[];
        };
      } = yield call(bidApi.getBidsPlanHolders, { bidId });

      const planholderids = response.data.result.map(item => item.mi);

      response.data.ids = planholderids;

      action.payload.searchResult = [];

      if (
        Number(action.payload.type) === 2 &&
        values.planholderId &&
        bidsummary.searchPlanholder.length > 0
      ) {
        action.payload.searchResult = bidsummary.searchPlanholder.filter(
          item => planholderids.indexOf(item.mi) === -1,
        );
      }

      let planholdersdetail: any = [];
      let currentsupplier: any = '';
      if (response.data.result.length > 0) {
        const planholdersDetailResponse = yield call(bidApi.getPlanholdersDetail, {
          memberIds: response.data.ids.join(','),
        });
        planholdersdetail = planholdersDetailResponse.data.result;
        const initialMemberId = response.data.result[0].mi;

        const planholderDocResponse = yield call(bidApi.getBidsPlanholdersDocuments, {
          supplierId: initialMemberId,
          bidId,
        });

        currentsupplier = planholdersdetail.find(
          (item: PlanholderDetailType) => item.memberId === initialMemberId,
        );
        currentsupplier = {
          ...currentsupplier,
          planholderDocuments: planholderDocResponse.data.result,
          ...response.data.result[0],
        };
      }

      yield put({
        type: actionTypes.LOAD_BID_PLANHOLDERS.SUCCESS,
        payload: {
          ...response.data,
          result: response.data.result,
          planholdersdetail,
          currentsupplier,
        },
      });

      if (action.payload.addToAwardeeList) {
        const addBid: AddBidState = yield select(state => state.addbid);
        const awardeeIds = addBid.awardeeIds || [];
        const awardeeList = addBid.awardeelist;

        const newAwardeeEmail = fields.email;

        const newPlanholder = response.data.result.find(planholder => {
          return planholder.email === newAwardeeEmail;
        });

        if (newPlanholder) {
          const memberId = newPlanholder.mi;
          const newAwardee: Awardee = {
            awardedToMemberId: memberId,
            supplierName: newPlanholder.supplierName,
            amount: 0,
          };

          yield put({
            type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
            payload: {
              awardeeIds: [...awardeeIds, memberId],
              awardeelist: [...awardeeList, newAwardee],
            } as SetAddBidDetailsPayload,
          });
        }
      }

      if (bidsummary.modalType === 'add') {
        if (
          !values.planholderId &&
          addPlanholderResponse.data.result === true &&
          !addPlanholderResponse.data.isNewUser
        ) {
          toastFn(
            'success',
            'Added already existing supplier associated with the email',
            bidsummarytoastID,
          );
        } else {
          toastFn('success', 'Added', bidsummarytoastID);
        }
      } else toastFn('success', 'Updated', bidsummarytoastID);

      yield put({ type: actionTypes.ADD_NEW_SUPPLIER.SUCCESS, payload: action.payload });
    } else {
      toastFn(
        'error',
        'Planholder already exists, please search for them instead.',
        bidsummarytoastID,
      );
      yield put({ type: actionTypes.ADD_NEW_SUPPLIER.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_NEW_SUPPLIER.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchremoveBidPlanholderSupplier() {
  yield takeLatest(actionTypes.REMOVE_BID_PLANHOLDER_SUPPLIER.TRIGGER, removeBidPlanholderSupplier);
}

function* removeBidPlanholderSupplier(action: {
  payload: { bidId: number; supplierId: number };
}): any {
  yield put({ type: actionTypes.REMOVE_BID_PLANHOLDER_SUPPLIER.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.removeBidPlanholderSupplier, action.payload);
    if (response.data.result) {
      const { bidId } = action.payload;
      const list = yield select(state => state.bidssummary.planHolders.result) || [];
      const result =
        (list && list.filter((item: { mi: number }) => item.mi !== action.payload.supplierId)) ||
        [];
      const currentsupplier = (result && result[0]) || [];
      if (currentsupplier && currentsupplier.mi) {
        yield put({
          type: actionTypes.LOAD_BIDS_SIMILAR_SUPPLIERS.TRIGGER,
          payload: { supplierId: currentsupplier.mi, bidId },
        });

        yield put({
          type: actionTypes.GET_PLANHOLDERS_OTHER_DETAILS.TRIGGER,
          payload: { memberId: currentsupplier.mi, bidId },
        });
      }

      const planholderids = result.map((item: { mi: number }) => item.mi);

      yield put({
        type: actionTypes.REMOVE_BID_PLANHOLDER_SUPPLIER.SUCCESS,
        payload: { currentsupplier, result, planholderids },
      });
      toastFn('success', 'Deleted', bidsummarytoastID);
    } else {
      yield put({ type: actionTypes.REMOVE_BID_PLANHOLDER_SUPPLIER.FAILURE });
      toastFn('error', 'Failed', bidsummarytoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.REMOVE_BID_PLANHOLDER_SUPPLIER.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', bidsummarytoastID);
  }
}

function* watchBidsSearchPlanholder() {
  yield takeLatest(actionTypes.GET_BIDS_SEARCH_PLANDER.TRIGGER, BidsSearchPlanholder);
}

function* BidsSearchPlanholder(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_BIDS_SEARCH_PLANDER.REQUEST, meta: action.payload });
  try {
    const bidsummary = yield select(state => state.bidssummary);
    const { name = '', city = '', phoneNumber = '', email = '' } = action.payload;

    const finalvalues = { ...action.payload, phoneNumber: formatPhoneNumber(phoneNumber) };

    Object.keys(finalvalues).map(k => (finalvalues[k] = finalvalues[k].trim()));

    if (name !== '' || city !== '' || phoneNumber !== '' || email !== '') {
      const searchPlanholder = yield call(bidApi.getBidsSearchPlanholders, finalvalues);

      const filteredResult = searchPlanholder.data.result.filter(
        (item: { memberId: number }) => bidsummary.planHolders.ids.indexOf(item.memberId) === -1,
      );

      /*let searchArray = []
      if (finalvalues.name) searchArray.push('Supplier Name :' + finalvalues.name)
      if (finalvalues.city) searchArray.push('City :' + finalvalues.city)
      if (finalvalues.phoneNumber) searchArray.push('Phone Number :' + finalvalues.phoneNumber)
      if (finalvalues.email) searchArray.push('Email :' + finalvalues.email)*/

      yield put({
        type: actionTypes.GET_BIDS_SEARCH_PLANDER.SUCCESS,
        payload: { searchPlanholder: filteredResult || [], searchFields: finalvalues },
      });

      if (filteredResult.length === 0) toastFn('error', 'No Results Found', bidsummarytoastID);
    } else {
      yield put({ type: actionTypes.GET_BIDS_SEARCH_PLANDER.FAILURE });
      toastFn('error', 'Please Fill Any One Of Fields', bidsummarytoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_BIDS_SEARCH_PLANDER.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', bidsummarytoastID);
  }
}

function* watchgetBidBroadcastsHistory() {
  yield takeLatest(actionTypes.GET_BIDS_BROADCAST_HISTORY.TRIGGER, getBidBroadcastHistory);
}

function* getBidBroadcastHistory(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.GET_BIDS_BROADCAST_HISTORY.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getbidBroadcastHistory, action.payload);
    if (response.data.result) {
      const broadcast = response.data.result;
      const broadcasthistory =
        (broadcast &&
          broadcast.broadCasts &&
          broadcast.broadCasts.length > 0 &&
          broadcast.broadCasts.sort((a: { date: string }, b: { date: string }) =>
            b.date.localeCompare(a.date),
          )) ||
        [];
      const recipients = (broadcast && broadcast.recipients) || [];
      yield put({
        type: actionTypes.GET_BIDS_BROADCAST_HISTORY.SUCCESS,
        payload: { broadcasthistory, recipients },
      });
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { internalLoader: false },
      });
    } else {
      yield put({ type: actionTypes.GET_BIDS_BROADCAST_HISTORY.FAILURE });
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { internalLoader: false },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_BIDS_BROADCAST_HISTORY.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchBidsLoadPlanHolderData() {
  yield takeLatest(actionTypes.LOAD_BID_PLANDER_DATA.TRIGGER, bidsLoadPlanHolderData);
}

function* bidsLoadPlanHolderData(action: planTypes): any {
  yield put({ type: actionTypes.LOAD_BID_PLANDER_DATA.REQUEST, meta: action.payload });
  try {
    const shared = yield select(state => state.shared);
    const stateresponse = yield call(sharedApi.getStatesList);
    const ntatelist = (stateresponse.data && stateresponse.data.result) || [];
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: {
        stateslist: ntatelist.map((items: { name: string; title: string; id: number }) => ({
          ...items,
          label: items.name || items.title,
          value: items.id,
        })),
      },
    });

    const newstatelist = ntatelist.map((items: { name: string; title: string; id: number }) => ({
      ...items,
      label: items.name || items.title,
      value: items.id,
    }));

    if (action.payload.stateId === 0) action.payload.stateId = 2;
    if (action.payload.countryId === 0) action.payload.countryId = 1;

    const state =
      newstatelist.find((items: { id: number }) => items.id === action.payload.stateId) || [];
    const selfdecAttr: any = {};

    if (action.payload.memberType === 'PL') {
      const response = yield call(bidApi.getSupplierSelfDeclarations, {
        memberId: action.payload.memberId,
        isCurrentMember: false,
      });

      response.data.result.map(
        (item: { attributeType: string; selected: boolean }) =>
          (selfdecAttr[`${item.attributeType}`] = item.selected),
      );
    }

    const contact =
      action.payload.firstName + (action.payload.lastName ? ' ' + action.payload.lastName : null);

    const supplierData = {
      companyname: action.payload.supplierName,
      address1: action.payload.address1,
      address2: action.payload.address2,
      city: action.payload.city,
      country:
        (shared &&
          shared.countrieslist.find(
            (items: { value: number }) => items.value === action.payload.countryId,
          )) ||
        '',
      state: state || '',
      postalCode: action.payload.postalCode,
      phoneNumber: action.payload.phone,
      faxNumber: action.payload.fax,
      email: action.payload.email,
      contact: contact,
      memberId: 0,
      selfdecAttr: selfdecAttr,
      memberType: action.payload.memberType,
      planholderId: action.payload.memberId,
    };
    yield put({ type: actionTypes.LOAD_BID_PLANDER_DATA.SUCCESS, payload: { supplierData } });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_PURCHASEINFO.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchSetBidSummaryDetails() {
  yield takeLatest(actionTypes.SET_SUPPLIER_SELF_DECLARATION.TRIGGER, setBidSummaryDetails);
}

function* setBidSummaryDetails(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.SET_SUPPLIER_SELF_DECLARATION.REQUEST, meta: action.payload });

  const response = yield call(bidApi.getSupplierSelfDeclarations, action.payload);

  const selfDeclr =
    response.data.result
      .filter((item: { selected: boolean }) => item.selected === true)
      .map((item: { attributeName: string }) => item.attributeName) || [];
  const selfDeclaration = selfDeclr.join(',');

  yield put({ type: actionTypes.SET_SUPPLIER_SELF_DECLARATION.SUCCESS, payload: selfDeclaration });
}

function* watchEditBidPlanholder() {
  yield takeLatest(actionTypes.EDIT_BID_PLANHOLDER.TRIGGER, EditBidPlanholder);
}

function* EditBidPlanholder(action: { payload: any }): any {
  yield put({ type: actionTypes.LOAD_BID_PLANDER_DATA.REQUEST, meta: action.payload });

  try {
    const shared = yield select(state => state.shared);
    const stateresponse = yield call(sharedApi.getStatesList);
    const ntatelist = (stateresponse.data && stateresponse.data.result) || [];
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: {
        stateslist: ntatelist.map((items: { name: string; title: string; id: number }) => ({
          ...items,
          label: items.name || items.title,
          value: items.id,
        })),
      },
    });

    const newstatelist = ntatelist.map((items: { name: string; title: string; id: number }) => ({
      ...items,
      label: items.name || items.title,
      value: items.id,
    }));

    const state =
      newstatelist.find(
        (items: { id: number }) => action.payload && items.id === action.payload.stateId,
      ) || [];

    const response = yield call(bidApi.getSupplierSelfDeclarations, {
      memberId: action.payload.mi,
      isCurrentMember: false,
    });

    const selfdecAttr: any = {};
    response.data.result.map(
      (item: { attributeType: string; selected: number }) =>
        (selfdecAttr[`${item.attributeType}`] = item.selected),
    );

    const contact =
      action.payload.fn && action.payload.ln
        ? action.payload.fn + ' ' + action.payload.ln
        : action.payload.fn
        ? action.payload.fn
        : action.payload.ln
        ? action.payload.ln
        : ' ';

    const supplierData = {
      companyname: action.payload.supplierName,
      address1: action.payload.address1,
      address2: action.payload.address2,
      city: action.payload.city,
      country:
        (shared &&
          shared.countrieslist.find(
            (items: { value: number }) => items.value === action.payload.countryId,
          )) ||
        '',
      state: state || '',
      postalCode: action.payload.postalCode,
      phoneNumber: action.payload.phone,
      faxNumber: action.payload.fax,
      email: action.payload.email,
      contact: contact,
      memberId: 0,
      selfdecAttr: selfdecAttr,
      memberType: action.payload.mt,
      planholderId: action.payload.mi,
      isEdit: action.payload.isEdit || false,
      editedMemberId: action.payload.editedMemberId || '',
    };
    yield put({ type: actionTypes.LOAD_BID_PLANDER_DATA.SUCCESS, payload: { supplierData } });
  } catch (error) {
    yield put({
      type: actionTypes.EDIT_BID_PLANHOLDER.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchOpenAddSupplierModal() {
  yield takeLatest(actionTypes.SET_MODAL_OPEN.TRIGGER, OpenAddSupplierModal);
}

function* OpenAddSupplierModal(action: supplierTypes): any {
  yield put({ type: actionTypes.SET_MODAL_OPEN.REQUEST, meta: action.payload });

  const shared = yield select(state => state.shared);
  const bidsummary = yield select(state => state.bidssummary);

  if (!bidsummary.selectedCountry && !bidsummary.selectedState) {
    action.payload.selectedCountry = shared.countrieslist[0];
    const stateresponse = yield call(sharedApi.getStatesList);
    let ntatelist = (stateresponse.data && stateresponse.data.result) || [];
    if (ntatelist.length) {
      ntatelist = ntatelist.map((items: { name: string; title: string; id: number }) => ({
        ...items,
        label: items.name || items.title,
        value: items.id,
      }));
    }
    yield put({ type: actionTypes.GET_STATES_LIST.SUCCESS, payload: ntatelist });
    action.payload.selectedStatelist = ntatelist.filter(
      (item: { countryId: number }) =>
        item.countryId === (action.payload.selectedCountry && action.payload.selectedCountry.value),
    );
    action.payload.selectedState = action.payload.selectedStatelist[0];
  } else {
    action.payload.selectedCountry = bidsummary.selectedCountry;
    action.payload.selectedState = bidsummary.selectedState;
    action.payload.selectedStatelist = bidsummary.selectedStatelist;
  }

  yield put({ type: actionTypes.SET_MODAL_OPEN.SUCCESS, payload: action.payload });
}

function* watchSelectCountryState() {
  yield takeLatest(actionTypes.SELECT_COUNTRY_STATE.TRIGGER, selectCountryState);
}

function* selectCountryState(action: { payload: { value: number } }): any {
  yield put({ type: actionTypes.SELECT_COUNTRY_STATE.REQUEST, meta: action.payload });
  const shared = yield select(state => state.shared);
  const selectedState = shared.stateslist.filter(
    (item: { countryId: number }) => item.countryId === action.payload.value,
  );

  yield put({
    type: actionTypes.SELECT_COUNTRY_STATE.SUCCESS,
    payload: { selectedCountry: action.payload, selectedState: selectedState[0] },
  });
}

function* watchgetBidsReminders() {
  yield takeLatest(actionTypes.GET_BIDS_BIDS_REMINDERS.TRIGGER, getBidsReminders);
}

function* getBidsReminders(action: supplierTypes): any {
  yield put({ type: actionTypes.GET_BIDS_BIDS_REMINDERS.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getbidsReminders, action.payload);
    if (response.data.result) {
      const reminders =
        response.data.result.sort((a: { reminderDate: any }, b: { reminderDate: string }) =>
          b.reminderDate.localeCompare(a.reminderDate),
        ) || [];
      const currentremindersrecipients =
        (reminders.length > 0 &&
          reminders[0].recipients.split(';').sort((a: string, b: string) => a.localeCompare(b))) ||
        [];
      const currentreminders = reminders.length > 0 && reminders[0];
      yield put({
        type: actionTypes.GET_BIDS_BIDS_REMINDERS.SUCCESS,
        payload: { reminders, currentremindersrecipients, currentreminders },
      });
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { internalLoader: false },
      });
    } else {
      yield put({ type: actionTypes.GET_BIDS_BIDS_REMINDERS.FAILURE });
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { internalLoader: false },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_BIDS_BIDS_REMINDERS.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchsubmitBidsUpdateReminder() {
  yield takeLatest(actionTypes.SUBMIT_BIDS_UPDATE_REMINDER.TRIGGER, submitBidsUpdateReminder);
}

function* submitBidsUpdateReminder(action: { payload: reminderTypes }): any {
  yield put({ type: actionTypes.SUBMIT_BIDS_UPDATE_REMINDER.REQUEST, meta: action.payload });
  try {
    const { bidID = '', isDeleted, data: values = {} } = action.payload;
    const { modalAddReminder, currentreminders, reminders } = yield select(
      state => state.bidssummary,
    );
    const { us } = yield select(state => state.auth);
    const toastmessage =
      modalAddReminder && modalAddReminder.modalfor === 'edit'
        ? 'Updated'
        : modalAddReminder && modalAddReminder.modalfor === 'add'
        ? 'Saved'
        : 'Deleted';
    let finaldata: any = action.payload;
    if (!isDeleted) {
      const { emailsarray, email, reminderDate, subject, content } = values || {};
      const recipientsArr = [{ email }, ...emailsarray].map(item => item.email);
      finaldata = {
        id: 0,
        bidId: bidID,
        accountId: us,
        reminderDate,
        subject: subject.trim(),
        content: content.trim(),
        recipients: [...Array.from(new Set(recipientsArr))].join(';'),
      };
      if (modalAddReminder.modalfor === 'edit') {
        finaldata.id = currentreminders && currentreminders.id;
      }
    }
    const response = yield call(bidApi.submitBidsUpdateReminder, finaldata);
    if (response.data.result) {
      let prevlist = reminders || [];
      if ((modalAddReminder && modalAddReminder.modalfor === 'edit') || isDeleted) {
        prevlist = reminders.filter((item: { id: number }) => item.id !== currentreminders.id);
      }
      if (!isDeleted && modalAddReminder && modalAddReminder.modalfor) {
        prevlist = [response.data.result, ...prevlist];
      }
      const list =
        prevlist.sort((a: { reminderDate: any }, b: { reminderDate: string }) =>
          b.reminderDate.localeCompare(a.reminderDate),
        ) || [];
      const newrecipients =
        (list.length > 0 &&
          list[0].recipients.split(';').sort((a: string, b: string) => a.localeCompare(b))) ||
        [];
      const newcurrentreminders = (list.length > 0 && list[0]) || {};
      yield put({
        type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
        payload: {
          modalAddReminder: { open: false, modalfor: '' },
          reminders: list,
          currentremindersrecipients: newrecipients,
          currentreminders: newcurrentreminders,
        },
      });
      yield put({ type: actionTypes.SUBMIT_BIDS_UPDATE_REMINDER.SUCCESS, payload: response });
      toastFn('success', toastmessage, bidsummarytoastID);
    } else {
      yield put({ type: actionTypes.SUBMIT_BIDS_UPDATE_REMINDER.FAILURE });
      toastFn('error', 'Failed', bidsummarytoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_BIDS_UPDATE_REMINDER.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Error', bidsummarytoastID);
  }
}

function* watchGetBidSummary() {
  yield takeLatest(actionTypes.GET_PLANHOLDERS_DETAILS.TRIGGER, getBidsSummary);
}

function* getBidsSummary(action: bidsummaryTypes): any {
  yield put({ type: actionTypes.GET_PLANHOLDERS_DETAILS.REQUEST, meta: action.payload });

  yield put({
    type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
    payload: { PlanholderDetail_internalLoader: true },
  });

  try {
    const memberInfo = yield call(bidApi.getMemberInfo, {
      memberId: action.payload.currentsupplier.mi,
      isCurrentMember: false,
    });

    const bidsummary = yield select(state => state && state.bidssummary);
    const { currentsupplier = {} } = bidsummary;

    const updatedCurrentSupplier = {
      ...action.payload.currentsupplier,
      ...currentsupplier,
      address1: memberInfo.data.result.a1,
      address2: memberInfo.data.result.a2,
      city: memberInfo.data.result.ct,
      state: memberInfo.data.result.st,
      country: memberInfo.data.result.ca,
      postalCode: memberInfo.data.result.pc,
      phone: memberInfo.data.result.p,
      email: memberInfo.data.result.eml,
    };

    yield put({
      type: actionTypes.GET_PLANHOLDERS_DETAILS.SUCCESS,
      payload: { currentsupplier: updatedCurrentSupplier },
    });
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { PlanholderDetail_internalLoader: false },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_PLANHOLDERS_DETAILS.SUCCESS,
      payload: { currentsupplier: action.payload.currentsupplier },
    });
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { PlanholderDetail_internalLoader: false },
    });
  }
}

function* watchGetPlanholderOtherDetails() {
  yield takeLatest(actionTypes.GET_PLANHOLDERS_OTHER_DETAILS.TRIGGER, getPlanholderOtherDetails);
}

function* getPlanholderOtherDetails(action: { payload: { memberId: number; bidId: number } }): any {
  yield put({ type: actionTypes.GET_PLANHOLDERS_OTHER_DETAILS.REQUEST, meta: action.payload });

  try {
    let planholderDocuments: any = [];

    const planholderDocResponse = yield call(bidApi.getBidsPlanholdersDocuments, {
      supplierId: action.payload.memberId,
      bidId: action.payload.bidId,
    });
    planholderDocuments = planholderDocResponse.data.result;

    const bidssummary = yield select(state => state && state.bidssummary);
    const { currentsupplier = {}, planholdersdetail = [] } = bidssummary;

    const updatedSupplierDetails = {
      ...currentsupplier,
      planholderDocuments: planholderDocuments,
    };

    const updatedPlanholderDetails = planholdersdetail.map((item: any) => {
      if (item.memberId === action.payload.memberId) {
        return {
          ...item,
          planholderDocuments: planholderDocuments,
        };
      }
      return item;
    });

    yield put({
      type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
      payload: {
        currentsupplier: updatedSupplierDetails,
        planholdersdetail: updatedPlanholderDetails,
      },
    });
    yield put({ type: actionTypes.GET_PLANHOLDERS_OTHER_DETAILS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_PLANHOLDERS_OTHER_DETAILS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetPostbidSupplierDetails() {
  yield takeLatest(actionTypes.SET_POST_BID_SUPPLIER_DETAILS.TRIGGER, getPostbidSupplierDetails);
}

function* getPostbidSupplierDetails(action: { payload: supplierdetailsTypes }): any {
  yield put({ type: actionTypes.SET_POST_BID_SUPPLIER_DETAILS.REQUEST, meta: action.payload });
  yield put({
    type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
    payload: { postbid_internalLoader: true },
  });
  if (action.payload.currentpostviewer) {
    const planholderDocuments = yield call(bidApi.getBidsPlanholdersDocuments, {
      supplierId: action.payload.currentpostviewer.mi,
      bidId: action.payload.bidId,
    });

    const response = yield call(bidApi.getSupplierSelfDeclarations, {
      memberId: action.payload.currentpostviewer.mi,
      isCurrentMember: false,
    });

    const selfDeclr =
      response.data.result
        .filter((item: { selected: boolean }) => item.selected === true)
        .map((item: { attributeName: string }) => item.attributeName) || [];
    const selfDeclaration = selfDeclr.join(',');

    action.payload.currentpostviewer.declaredAttributes = selfDeclaration;
    action.payload.currentpostviewer.planholderDocuments = planholderDocuments.data.result;
  }

  try {
    const memberInfo = yield call(bidApi.getMemberInfo, {
      memberId: action.payload.currentpostviewer.mi,
      isCurrentMember: false,
    });

    action.payload.currentpostviewer.address1 = memberInfo.data.result.a1;
    action.payload.currentpostviewer.address2 = memberInfo.data.result.a2;
    action.payload.currentpostviewer.city = memberInfo.data.result.ct;
    action.payload.currentpostviewer.state = memberInfo.data.result.st;
    action.payload.currentpostviewer.country = memberInfo.data.result.ca;
    action.payload.currentpostviewer.postalCode = memberInfo.data.result.pc;
    action.payload.currentpostviewer.phone = memberInfo.data.result.p;
    action.payload.currentpostviewer.email = memberInfo.data.result.eml;
    yield put({
      type: actionTypes.SET_POST_BID_SUPPLIER_DETAILS.SUCCESS,
      payload: { currentpostviewer: action.payload.currentpostviewer },
    });
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { postbid_internalLoader: false },
    });
  } catch (error) {
    yield put({
      type: actionTypes.SET_POST_BID_SUPPLIER_DETAILS.SUCCESS,
      payload: { currentpostviewer: action.payload.currentpostviewer },
    });
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { postbid_internalLoader: false },
    });
  }
}

function* watchgetCanUpgrade() {
  yield takeLatest(actionTypes.SUPPLIER_CAN_UPGRADE.TRIGGER, getCanUpgrade);
}

function* getCanUpgrade(action: { payload: any }): any {
  yield put({ type: actionTypes.SUPPLIER_CAN_UPGRADE.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getCanUpgrade);
    yield put({
      type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
      payload: { canSupplierUpgrade: response.data.result.canUpgrade || false },
    });
    yield put({ type: actionTypes.SUPPLIER_CAN_UPGRADE.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.SUPPLIER_CAN_UPGRADE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetResponseEvents() {
  yield takeLatest(actionTypes.GET_RESPONSE_EVENTS.TRIGGER, getResponseEvents);
}

function* getResponseEvents(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.GET_RESPONSE_EVENTS.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getResponseEvents, action.payload);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_RESPONSE_EVENTS.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_RESPONSE_EVENTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchAddBidSupplierTabulation() {
  yield takeLatest(actionTypes.ADD_BID_SUPPLIER_TABULATION.TRIGGER, addBidSupplierTabulation);
}

function* addBidSupplierTabulation(action: { payload: { isExport: any } }): any {
  if (action.payload.isExport) {
    yield call(bidApi.addBidSupplierTabulation, action.payload);
  } else {
    try {
      yield put({ type: actionTypes.ADD_BID_SUPPLIER_TABULATION.REQUEST, meta: action.payload });
      const response = yield call(bidApi.addBidSupplierTabulation, action.payload);
      const payload = response.data.result || [];
      yield put({ type: actionTypes.ADD_BID_SUPPLIER_TABULATION.SUCCESS, payload });
      toastFn(
        'success',
        'Supplier Bid Tabulation Document will be available in a few mins.',
        bidsummarytoastID,
      );
    } catch (error) {
      yield put({
        type: actionTypes.ADD_BID_SUPPLIER_TABULATION.FAILURE,
        payload: { payload: action.payload, error },
      });
      toastFn('error', 'Failed', bidsummarytoastID);
    }
  }
}

function* watchExportPlanholders() {
  yield takeLatest(actionTypes.EXPORT_PLANHOLDERS_DETAILS.TRIGGER, exportPlanholders);
}

function* exportPlanholders(action: bidspayloadTypes) {
  yield put({ type: actionTypes.EXPORT_PLANHOLDERS_DETAILS.REQUEST, meta: action.payload });
  try {
    yield call(bidApi.exportPlanholders, action.payload);
    yield put({ type: actionTypes.EXPORT_PLANHOLDERS_DETAILS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.EXPORT_PLANHOLDERS_DETAILS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidTabulation() {
  yield takeLatest(actionTypes.GET_BID_TABULATION.TRIGGER, bidTabulationAsync);
}

function* bidTabulationAsync(action: bidspayloadTypes): any {
  yield put({ type: actionTypes.GET_BID_TABULATION.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getBidTabulation, action.payload);
    yield put({
      type: actionTypes.GET_BID_TABULATION.SUCCESS,
      payload: response.data.result,
    });
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { tabulation_internalLoader: false },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_BID_TABULATION.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchExportBroadcastList() {
  yield takeLatest(actionTypes.EXPORT_BROADCAST_LIST.TRIGGER, exportBroadcastList);
}

function* exportBroadcastList(action: bidspayloadTypes) {
  yield put({ type: actionTypes.EXPORT_BROADCAST_LIST.REQUEST, meta: action.payload });
  try {
    yield call(bidApi.exportBroadcastList, action.payload);
    yield put({ type: actionTypes.EXPORT_BROADCAST_LIST.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.EXPORT_BROADCAST_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchExportSupplementalSuppliers() {
  yield takeLatest(actionTypes.EXPORT_SUPPLEMENTAL_SUPPLIERS.TRIGGER, exportSupplementalSuppliers);
}

function* exportSupplementalSuppliers(action: bidspayloadTypes) {
  yield put({ type: actionTypes.EXPORT_SUPPLEMENTAL_SUPPLIERS.REQUEST, meta: action.payload });
  try {
    yield call(bidApi.exportSupplementalSuppliers, action.payload);
    yield put({ type: actionTypes.EXPORT_SUPPLEMENTAL_SUPPLIERS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.EXPORT_SUPPLEMENTAL_SUPPLIERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchExportLegalAd() {
  yield takeLatest(actionTypes.EXPORT_LEGAL_AD.TRIGGER, exportLegalAd);
}

function* exportLegalAd(action: bidspayloadTypes) {
  yield put({ type: actionTypes.EXPORT_LEGAL_AD.REQUEST, meta: action.payload });
  try {
    yield call(bidApi.exportLegalAd, action.payload);
    yield put({ type: actionTypes.EXPORT_LEGAL_AD.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.EXPORT_LEGAL_AD.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchUpdateBidStatus() {
  yield takeLatest(actionTypes.UPDATE_BID_STATUS.TRIGGER, updateBidStatus);
}

function* updateBidStatus(action: bidspayloadTypes) {
  yield put({ type: actionTypes.UPDATE_BID_STATUS.REQUEST, meta: action.payload });
  try {
    yield call(bidApi.updateBidStatus, action.payload);

    yield put({ type: actionTypes.UPDATE_BID_STATUS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_BID_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* bidsSummarySaga() {
  yield fork(watchBidSummaryAsync);
  yield fork(watchgetBidsWatchStatus);
  yield fork(watchBidsStats);
  yield fork(watchBidsDocuments);
  yield fork(watchBidsWatchers);
  yield fork(watchBidsPlanHolders);
  yield fork(watchBidsBidders);
  yield fork(watchBidsSimilarBids);
  yield fork(watchBidsBidWatch);
  yield fork(watchBidsLegalAd);
  yield fork(watchBidsEbids);
  yield fork(watchBidsPurchaseInfo);
  //yield fork(watchBidsAuditTrail)
  yield fork(watchBidsDownloadDocument);
  yield fork(watchBidsResponseDocumentDownlad);
  yield fork(watchBidsSimilarSuppliers);
  yield fork(watchsendBidSelectedSupplier);
  yield fork(watchbidsAddNewSupplier);
  yield fork(watchremoveBidPlanholderSupplier);
  yield fork(watchBidsPlanholderDocuments);
  yield fork(watchBidsSearchPlanholder);
  yield fork(watchBidAuditTrial);
  yield fork(watchgetBidBroadcastsHistory);
  yield fork(watchBidsLoadPlanHolderData);
  yield fork(watchGetPlanholderOtherDetails);
  yield fork(watchSetBidSummaryDetails);
  yield fork(watchEditBidPlanholder);
  yield fork(watchOpenAddSupplierModal);
  yield fork(watchSelectCountryState);
  yield fork(watchsubmitBidsUpdateReminder);
  yield fork(watchgetBidsReminders);
  yield fork(watchBidWriterInfo);
  yield fork(watchGetBidSummary);
  yield fork(watchgetCanUpgrade);
  yield fork(watchBidsPostbidViewers);
  yield fork(watchgetPostbidSupplierDetails);
  yield fork(watchgetBidView);
  yield fork(watchgetAwardees);
  yield fork(watchGetBidCommodityCodes);
  yield fork(watchgetResponseEvents);
  yield fork(watchAddBidSupplierTabulation);
  yield fork(watchExportPlanholders);
  yield fork(watchBidTabulation);
  yield fork(watchExportBroadcastList);
  yield fork(watchExportSupplementalSuppliers);
  yield fork(watchExportLegalAd);
  yield fork(watchBidDownloadAllDocument);
  yield fork(watchUpdateBidStatus);
}
