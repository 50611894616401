import { RecoilState } from 'recoil';

import { isValidEmail, isValidPhone } from '@demandstar/components/utils';
import { DSEmail } from '@demandstar/components/email/DSEmail';
import { DSPhone } from '@demandstar/components/phone/DSPhone';

import { CustomMessageType, EmailInfo } from '../../../../types/awardBid';
import { EmailInput } from '../../../forms/EmailInput';
import { NotificationRow } from './NotificationRow';
import { notifyAwardeeTexts } from '../../../../utils/texts/agency/awardBid';
import { PhoneInput } from '../../../forms/PhoneInput';
import { Section } from './../styled/AwardBidStyled';
import { TextArea } from '../../../forms/TextArea';
import { TextInput } from '../../../forms/TextInput';
import { ToggleEditDisplay } from '../../../forms/ToggleEditDisplay';
import { useLocalRecoilState } from '../../../../shared/hooks/useLocalRecoilState';

interface EditEmailInfoProps {
  emailInfoState: RecoilState<EmailInfo>;
  includeContactInfo?: boolean;
  customMessageType: CustomMessageType;
}

export const EditEmailInfo = (props: EditEmailInfoProps) => {
  const { emailInfoState, includeContactInfo, customMessageType } = props;

  const [emailInfo, setEmailInfo] = useLocalRecoilState(emailInfoState);

  function addressee() {
    switch (customMessageType) {
      case CustomMessageType.Awardees:
        return notifyAwardeeTexts.copy.whatShouldRecipientDo.awardee;
      case CustomMessageType.NonAwardees:
        return notifyAwardeeTexts.copy.whatShouldRecipientDo.nonAwardee;
      case CustomMessageType.Colleagues:
        return notifyAwardeeTexts.copy.whatShouldRecipientDo.colleague;
      default:
        return notifyAwardeeTexts.copy.whatShouldRecipientDo.default;
    }
  }

  function setEmailInfoField(field: keyof EmailInfo, value: string) {
    setEmailInfo({ ...emailInfo, [field]: value });
  }

  function whatShouldRecipientDo() {
    if (customMessageType === CustomMessageType.NonAwardees) {
      return; // no message for Unselected Bidders
    }
    return `${notifyAwardeeTexts.copy.whatShouldRecipientDo[0]} ${addressee()} ${
      notifyAwardeeTexts.copy.whatShouldRecipientDo[1]
    }`;
    // What should the awardee do?
    // What should your colleague do?
  }

  return (
    <>
      <Section>
        <h4>{notifyAwardeeTexts.headers.message}</h4>
        <div>
          <NotificationRow required label={notifyAwardeeTexts.labels.subjectLine}>
            <ToggleEditDisplay value={emailInfo.subject}>
              <TextInput
                required
                name='subjectLine'
                value={emailInfo.subject}
                onChange={value => setEmailInfoField('subject', value)}
              />
            </ToggleEditDisplay>
          </NotificationRow>
          <NotificationRow
            required
            label={notifyAwardeeTexts.labels.customMessage}
            subLabel={whatShouldRecipientDo()}
          >
            <ToggleEditDisplay value={emailInfo.customMessage}>
              <TextArea
                required
                name='customMessage'
                value={emailInfo.customMessage}
                onChange={value => setEmailInfoField('customMessage', value)}
              />
            </ToggleEditDisplay>
          </NotificationRow>
        </div>
      </Section>
      {includeContactInfo && (
        <Section>
          <h4>{notifyAwardeeTexts.headers.contactInfo}</h4>
          <div>
            <NotificationRow required label={notifyAwardeeTexts.labels.contactName}>
              <ToggleEditDisplay value={emailInfo.contactName}>
                <TextInput
                  required
                  name='contactName'
                  value={emailInfo.contactName}
                  onChange={value => setEmailInfoField('contactName', value)}
                />
              </ToggleEditDisplay>
            </NotificationRow>
            <NotificationRow required label={notifyAwardeeTexts.labels.contactEmail}>
              <ToggleEditDisplay
                value={emailInfo.contactEmail}
                isValid={isValidEmail}
                display={<DSEmail>{emailInfo.contactEmail}</DSEmail>}
              >
                <EmailInput
                  name='contactEmail'
                  value={emailInfo.contactEmail}
                  onChange={value => setEmailInfoField('contactEmail', value)}
                />
              </ToggleEditDisplay>
            </NotificationRow>
            <NotificationRow required label={notifyAwardeeTexts.labels.phoneNumber}>
              <ToggleEditDisplay
                value={emailInfo.contactPhone}
                display={<DSPhone>{emailInfo.contactPhone}</DSPhone>}
                isValid={isValidPhone}
              >
                <PhoneInput
                  required
                  name='phoneNumber'
                  value={emailInfo.contactPhone}
                  onChange={value => setEmailInfoField('contactPhone', value)}
                />
              </ToggleEditDisplay>
            </NotificationRow>
            <NotificationRow required label={notifyAwardeeTexts.labels.jobTitle}>
              <ToggleEditDisplay value={emailInfo.contactTitle}>
                <TextInput
                  required
                  name='jobTitle'
                  value={emailInfo.contactTitle}
                  maxLength={60} // Maximum length in database
                  onChange={value => setEmailInfoField('contactTitle', value)}
                />
              </ToggleEditDisplay>
            </NotificationRow>
          </div>
        </Section>
      )}
    </>
  );
};
