import { connect, ConnectedProps } from 'react-redux';
import { Field, Formik } from 'formik';
import { memo, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../../store/actions';
import { Buttons } from '../../customcontrols';
import { commonEmail } from 'src/utils/texts/common';
import { countrieslist } from '../../../store/reducers/shared';
import { DSEmail } from '@demandstar/components/email';
import { limitedbiddetailsTypes } from '../../../types/limited';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { renderDeprecatedTextField } from '../../common/controls/inputs/text';
import { setSharedDetails } from '../../../store/actions';
import { sharedTypes } from '../../../types/shared';
import { toastFn } from '../../../utils/helpers';

import LimitedFooter from '../footer';
import LimitedHeader from '../header';
import renderSelectField from '../../common/controls/inputs/selectbox';
import Validations from './validations';

interface propsTypes extends PropsFromRedux {
  limited: limitedbiddetailsTypes;
  shared: sharedTypes;
  getLimitedStatesList: any;
  getLimitedCountiesList: any;
  getLimitedMetroList: any;
  submitLimitedAddMemberAccount: any;
  resetLimitedDetails: any;
  getLimitedWappagencies: any;
  setLimitedDetails: any;
  populateMetrodetails: any;
  getStateList: any;
}

const WappRegistration = (props: propsTypes) => {
  const {
    limited,
    shared,
    getLimitedStatesList,
    getLimitedCountiesList,
    populateMetrodetails,
    submitLimitedAddMemberAccount,
    getLimitedWappagencies,
    setLimitedDetails,
    resetLimitedDetails,
    getStateList,
  } = props;
  const { stateslist = [], countieslist, sharedFormInitialValues, formPage } = shared;
  const { wappsuccessmodal, wappagencylist, wappagencyexists } = limited;
  const { /* isBlackList,  */ companyExists, accountExists } = wappagencyexists;
  const initialValues = {
    // username: '',
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    companyname: '',
    address1: '',
    address2: '',
    city: '',
    postalcode: '',
    phonenumber: '',
    country: {
      title: 'United States of America',
      label: 'United States of America',
      value: 1,
      key: 'usa',
    } as any,
    state: '' as any,
    county: '',
    website: '',
    agency: 'Wisconsin Association of Public Procurement (WAPP)',
  };

  const [wappInitialValues, setWapInitialValues] = useState(initialValues);

  useEffect(() => {
    getStateList();
  }, [getStateList]);

  useEffect(() => {
    getLimitedWappagencies();
  }, [getLimitedWappagencies]);

  const setValidationFn = () => {
    if (companyExists || accountExists) {
      setLimitedDetails({
        wappagencyexists: { isBlackList: false, companyExists: false, accountExists: false },
      });
    }
  };

  useEffect(() => {
    if (formPage && formPage === 'wappregistration') {
      setWapInitialValues(sharedFormInitialValues);
      setSharedDetails({ formPage: '' });
    }
  }, [formPage, sharedFormInitialValues]);

  const onSubmitFn = (values: any, form: any) => {
    if (values && form) {
      submitLimitedAddMemberAccount({ values, form });
    }
  };

  useEffect(() => {
    return () => {
      resetLimitedDetails();
    };
  }, [resetLimitedDetails]);

  return (
    <>
      <LimitedHeader {...props} />
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col'>
              <div className='colWrapper'>
                <h1 className='arrowWrapper'>WAPP Registration</h1>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <h5>Subscriber General Information</h5>
                    <h6>WAPP Notification Sign-up</h6>
                    <p>
                      By signing up for WAPP (Wisconsin Association for Public Procurement), you
                      will receive notification from the following DemandStar agencies within
                      Wisconsin:
                    </p>
                    <ul className='listStyleCustom arrow'>
                      {wappagencylist.length > 0 && (
                        <>
                          {wappagencylist.map((item, index) => (
                            <li key={index}>{item.productName}</li>
                          ))}
                        </>
                      )}
                    </ul>
                    <p>
                      Please complete the following fields. Once you have submitted the information
                      below your account will be set-up within 48 hours.
                    </p>
                    <p>
                      For technical support or questions regarding this process or about the
                      services we provide please send an email to{' '}
                      <DSEmail>{commonEmail.supplierServices}</DSEmail>.
                    </p>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <Formik
                      enableReinitialize
                      initialValues={wappInitialValues}
                      validate={Validations}
                      onSubmit={onSubmitFn}
                    >
                      {formikProps => {
                        const {
                          values,
                          // errors,
                          // touched,
                          // handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          // setFieldTouched,
                          // setSubmitting,
                          // resetForm,
                          isValidating,
                          // setFieldError,
                          validateForm,
                          /* and other goodies */
                        } = formikProps;
                        return (
                          <form onSubmit={handleSubmit}>
                            {/* <Field
															label='Username (E-Mail)'
															name='username'
															placeholder='Username'
															component={renderDeprecatedTextField}
															onChange={(name: string, value: string) => {
																setValidationFn()
																setFieldValue(name, value)
															}}
															maxLength={80}
														/> */}
                            <div className='clearfix'>
                              <Field
                                label='First Name'
                                name='firstname'
                                placeholder='First Name'
                                component={renderDeprecatedTextField}
                                onChange={setFieldValue}
                                parentClass='w-48 float-left'
                                maxLength={25}
                              />
                              <Field
                                label='Last Name'
                                name='lastname'
                                placeholder='Last Name'
                                component={renderDeprecatedTextField}
                                onChange={setFieldValue}
                                parentClass='w-48 float-right'
                                maxLength={25}
                              />
                            </div>
                            <div className='clearfix'>
                              <Field
                                label='Email'
                                name='email'
                                placeholder='Email'
                                component={renderDeprecatedTextField}
                                onChange={(name: string, value: string) => {
                                  setValidationFn();
                                  setFieldValue(name, value);
                                }}
                                parentClass='w-48 float-left'
                                maxLength={80}
                              />
                              <Field
                                label='Phone'
                                name='phone'
                                placeholder='Phone'
                                component={renderDeprecatedTextField}
                                onChange={setFieldValue}
                                parentClass='w-48 float-right'
                                maxLength={17}
                              />
                            </div>
                            <Field
                              label='Company Name'
                              name='companyname'
                              placeholder='Company Name'
                              component={renderDeprecatedTextField}
                              onChange={(name: string, value: string) => {
                                setValidationFn();
                                setFieldValue(name, value);
                              }}
                              maxLength={150}
                            />
                            <div className='clearfix'>
                              <Field
                                label='Address 1'
                                name='address1'
                                placeholder='Address 1'
                                component={renderDeprecatedTextField}
                                onChange={setFieldValue}
                                parentClass='w-48 float-left'
                                maxLength={50}
                              />
                              <Field
                                label='Address 2'
                                name='address2'
                                placeholder='Address 2'
                                component={renderDeprecatedTextField}
                                onChange={setFieldValue}
                                optional='optional'
                                parentClass='w-48 float-right'
                                maxLength={50}
                              />
                            </div>
                            <div className='clearfix'>
                              <Field
                                label='Country'
                                name='country'
                                options={countrieslist}
                                component={renderSelectField}
                                onChange={(name: string, value: any) => {
                                  if (value) {
                                    getStateList(value.value);
                                  }
                                  if (values?.country?.value !== value?.value) {
                                    setFieldValue('state', '');
                                    setFieldValue('county', '');
                                  }
                                  setFieldValue(name, value);
                                }}
                                onBlur={handleBlur}
                                parentClass='w-48 float-left'
                              />
                              <Field
                                label='Zip/Postal Code'
                                name='postalcode'
                                placeholder='Zip/Postal Code'
                                component={renderDeprecatedTextField}
                                onChange={(name: string, value: any) => {
                                  if (values?.postalcode !== value && value.length >= 5) {
                                    populateMetrodetails({
                                      zip: value,
                                      formData: { ...formikProps.values, postalcode: value },
                                      formPage: 'wappregistration',
                                    });
                                  }
                                  setFieldValue(name, value);
                                }}
                                parentClass='w-48 float-right'
                                maxLength={30}
                              />
                            </div>
                            <div className='clearfix'>
                              <Field
                                label='State/Province'
                                name='state'
                                options={
                                  stateslist &&
                                  stateslist.filter(
                                    (item: any) => item.countryId === values?.country?.value,
                                  )
                                }
                                component={renderSelectField}
                                onChange={(name: string, value: any) => {
                                  if (values?.state?.value !== value?.value) {
                                    getLimitedCountiesList(value?.value);
                                    setFieldValue('county', '');
                                  }
                                  setFieldValue(name, value);
                                }}
                                onBlur={handleBlur}
                                parentClass='w-48 float-left'
                              />
                              {values?.country?.value === 1 && (
                                <Field
                                  label='County'
                                  name='county'
                                  options={countieslist}
                                  component={renderSelectField}
                                  onChange={setFieldValue}
                                  onBlur={handleBlur}
                                  optional='optional'
                                  parentClass='w-48 float-right'
                                />
                              )}
                            </div>
                            <div className='clearfix'>
                              <Field
                                label='City'
                                name='city'
                                placeholder='City'
                                component={renderDeprecatedTextField}
                                onChange={setFieldValue}
                                parentClass='w-48 float-left'
                                maxLength={50}
                              />
                              <Field
                                label='Phone Number'
                                name='phonenumber'
                                placeholder='Phone Number'
                                component={renderDeprecatedTextField}
                                onChange={setFieldValue}
                                parentClass='w-48 float-right'
                                maxLength={17}
                              />
                            </div>
                            <Field
                              label='Website'
                              name='website'
                              placeholder='Website'
                              component={renderDeprecatedTextField}
                              onChange={setFieldValue}
                              optional='optional'
                              maxLength={100}
                            />
                            <div className='d-flex justify-content-between mt-4'>
                              <Buttons
                                type='button'
                                text='Cancel'
                                title='Cancel'
                                classNames='bttn-secondary'
                                onClick={() => (window.location.href = '/')}
                              />
                              <Buttons
                                type='submit'
                                text='Submit'
                                title='Submit'
                                onClick={() => {
                                  validateForm().then(errors => {
                                    if (Object.keys(errors).length > 0) {
                                      toastFn(
                                        'error',
                                        'Please enter required fields',
                                        'wapp registartion form info input',
                                      );
                                    }
                                  });
                                }}
                                classNames='bttn-primary'
                                disable={isSubmitting || isValidating}
                              />
                            </div>
                            <ModalPopUp
                              title='Account Confirmation'
                              closeModal={false}
                              isOpen={wappsuccessmodal}
                              size='md'
                            >
                              <div className='text-center'>
                                <h6>
                                  A Confirmation has been sent to your mail. Please use the link and
                                  temporary password we send you to log in and take advantage of
                                  your membership.
                                </h6>
                                <Buttons
                                  classNames='bttn-primary mt-3'
                                  text='Log In'
                                  title='Log In'
                                  type='button'
                                  onClick={() => (window.location.href = '/')}
                                />
                              </div>
                            </ModalPopUp>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LimitedFooter />
    </>
  );
};

const connector = connect(
  (state: any) => ({
    limited: state.limited,
    shared: state.shared,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(WappRegistration));
