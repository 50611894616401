import styled from 'styled-components';

import {
  BorderColor,
  BorderThickness,
  FontFamily,
  FontWeight,
  LineHeight,
  Padding,
  ParagraphFontSize,
  TableBackground,
  TextColor,
} from '../styles';

const cellStyles = `
  line-height: ${LineHeight.Subheader};
  color: ${TextColor.Base};
  font-family: ${FontFamily.Header};
  font-size: ${ParagraphFontSize.Base};
  font-weight: ${FontWeight.Base};
  padding-top: ${Padding.Less};
  padding-bottom: ${Padding.Less};
  padding-right: ${Padding.Base};
  cursor: default;
`;

export const BaseTable = styled.table`
  border-spacing: 0;
  width: 100%;
`;

export const BaseTableHead = styled.thead``;

export const BaseTableHeader = styled.th`
  ${cellStyles}
  font-weight: ${FontWeight.Header};
`;

export const BaseTableBody = styled.tbody``;

export const BaseTableRow = styled.tr`
  padding: ${Padding.Base} ${Padding.None};
  background: ${TableBackground.Base};
  border-bottom: ${BorderThickness.Base} solid ${BorderColor.Table};
`;

export const BaseTableCell = styled.td`
  ${cellStyles}
`;

export const BaseTableFooter = styled.tfoot``;

export const BaseTableFooterRow = styled(BaseTableRow)`
  background: ${TableBackground.Footer};
  border: ${BorderThickness.None};
`;

export const BaseTableFooterCell = styled(BaseTableHeader)``;
