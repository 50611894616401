import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { DSButton } from '@demandstar/components/button';
import { DSTextArea } from '@demandstar/components/textarea';

import { OpsActionsCopy, ProductsPaths } from 'src/utils/texts/supplier/subscription';

import { commonLabels } from 'src/shared/constants';
import { ModalPopUp } from '../../common/modals';
import { SpacedButtonWrapper } from '../agency-selection/styles';
import { useCart } from 'src/components/products/cart/useCart';
import { useSubscription } from 'src/shared/hooks/useSubscription';

interface ConfirmComplimentaryOrderProps {
  /** called on `Cancel` to close the modal or go back */
  close: () => void;
}

interface SalesNote {
  salesNote: string;
}

/**
 * @description the content of the Complimentary Order Modal, allows OPS user to
 * enter a sales note to document the reason for the comp order
 * @returns JSX.Element
 *
 * @example <ConfirmComplimentaryOrder />
 */
const ConfirmComplimentaryOrder = ({ close }: ConfirmComplimentaryOrderProps) => {
  const { cartItems, cartTotal, unchangedSubscribedProducts } = useCart();
  const { createSubscription } = useSubscription();
  const history = useHistory();

  const [processing, setProcessing] = useState(false);

  const { errors, handleSubmit, register } = useForm({
    reValidateMode: 'onChange',
  });

  const placeComplimentaryOrder = useCallback(
    async (formData: SalesNote) => {
      setProcessing(true);

      const products = unchangedSubscribedProducts.concat(cartItems);

      const result = await createSubscription({
        isComplimentary: true,
        isUpgrade: true,
        products,
        expectedTotal: cartTotal,
        salesNotes: formData.salesNote,
      });

      if (result.status) {
        close();
        history.push(ProductsPaths.CurrentSubscription);
      }

      setProcessing(false);
    },
    [cartItems, cartTotal, close, createSubscription, history, unchangedSubscribedProducts],
  );

  return (
    <form onSubmit={handleSubmit<SalesNote>(placeComplimentaryOrder)}>
      <DSTextArea
        dataTestId='test-complimentary-sales-note'
        error={errors.salesNote}
        label={'Sales Note'}
        message={OpsActionsCopy.ComplimentaryOrderHeader}
        name={'salesNote'}
        required={true}
        ref={register}
      />
      <SpacedButtonWrapper justifyContent='space-between'>
        <DSButton buttonType='secondary' onClick={close} inactive={processing}>
          {commonLabels.cancel}
        </DSButton>
        <DSButton buttonType='submit' inactive={processing}>
          {processing ? 'Processing...' : commonLabels.confirm}
        </DSButton>
      </SpacedButtonWrapper>
    </form>
  );
};

/**
 * @description a self-contained OPS-styled button that opens a modal that allows for a complimentary
 * order to be placed for a member
 * @returns JSX.Element
 *
 * @example <ComplimentaryOrder />
 */
export const ComplimentaryOrder = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <DSButton buttonType={'ops'} onClick={() => setModalOpen(!modalOpen)}>
        {OpsActionsCopy.PlaceComplimentaryOrder}
      </DSButton>
      <ModalPopUp
        title={OpsActionsCopy.PlaceComplimentaryOrder}
        size='lg'
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        <ConfirmComplimentaryOrder close={() => setModalOpen(false)} />
      </ModalPopUp>
    </>
  );
};
