// TOREFACTOR: Use library components.

import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilStateLoadable } from 'recoil';

import { DSSelectField } from '@demandstar/components/fields/select-field';
import { Helper } from '@demandstar/components/styles';

import {
  AddressLookupCounties,
  getAddressCounties,
  getAddressStates,
  zipCodeRegex,
} from './helpers';
import { AllStates, allStatesList } from '../../../../store/recoil/addressState';
import { countrieslist } from '../../../../utils/constants';
import { isValidRequiredTrimmingSpace } from 'src/utils/helpers';
import LogoDSLoader from 'src/assets/images/loader';
import { postalCodeLookup } from '../../../../utils/helpers/postalcodeLookup';
import { StateDropdownItem } from '../../../products/select-products/SelectState';

export interface AddressInfo {
  address1: string;
  address2: string;
  addressType: string;
  city: string;
  county: { countryId: number; id: number; label: string; name: string; value: number };
  countyId: number;
  countyName: {
    countryId: number;
    id: number;
    label: string;
    name: string;
    value: number;
  };
  country: {
    key: string;
    label: string;
    title: string;
    value: number;
  };
  countryId: number;
  state: { label: string };
  stateId: number;
  stateName: {
    id: number;
    name: string;
    countryId: number;
    abbr: string;
    label: string;
    value: number;
  };
  postalCode: string;
}

/* This is a react-hook-form reusable component
 * and requires a parent <FormProvider> from react-hook-form
 * See `components/products/CompleteProfile.tsx` as an example
 */
const AddressLookup = (): React.ReactElement => {
  const [allStates] = useRecoilStateLoadable(allStatesList);

  const [allStatesLocal, setAllStatesLocal] = useState<AllStates[]>([]);
  const [stateCounties, setStateCounties] = useState<AddressLookupCounties[]>([]);
  const [asyncLoading, setAsyncLoading] = useState<boolean>(false);

  const {
    formState: { errors },
    register,
    setValue,
    trigger,
  } = useFormContext();

  const changeSelectedState = useCallback(
    async (value: StateDropdownItem) => {
      const stateName = allStatesLocal.find(state => state.name === value.label);

      if (stateName) {
        setValue('addressInfo.county', undefined);
        setValue('addressInfo.countyId', undefined);
        setValue('addressInfo.countyName', undefined);
        setValue('addressInfo.state', { label: value.label }, { shouldValidate: true });
        setValue('addressInfo.stateName', {
          id: stateName.id,
          name: stateName.name,
          countryId: stateName.countryId,
          abbr: stateName.abbr,
          label: stateName.name,
          value: stateName.id,
        });

        const counties = await getAddressCounties(value.value);
        setStateCounties(counties);
      }
    },
    [allStatesLocal, setValue],
  );

  const refreshStateCountyOptions = useCallback(async (stateId: string) => {
    setAsyncLoading(true);
    const counties = await getAddressCounties(stateId);
    setAsyncLoading(false);
    setStateCounties(counties);
  }, []);

  const postalCodeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.persist();

    if (event.target.value && event.target.value.length >= 5) {
      // Call to prepopulate State and County fields
      setAsyncLoading(true);
      const postalCodeResult = await postalCodeLookup(event.target.value);
      setAsyncLoading(false);

      // Set geo data in ProfileInfo
      if (postalCodeResult) {
        setValue('addressInfo.city', postalCodeResult.selectedCity);
        setValue('addressInfo.state', { label: postalCodeResult.selectedState.name });
        setValue('addressInfo.county', {
          countryId: postalCodeResult.selectedCounty.countryId,
          id: postalCodeResult.selectedCounty.id,
          label: postalCodeResult.selectedCounty.name,
          name: postalCodeResult.selectedCounty.name,
          value: postalCodeResult.selectedCounty.value,
        });
        setValue('addressInfo.countyId', postalCodeResult.selectedCounty.id);
        setValue('addressInfo.countyName', postalCodeResult.selectedCounty);
        setValue('addressInfo.country', postalCodeResult.selectedCountry);
        setValue('addressInfo.countryId', postalCodeResult.selectedCountry.value);
        setValue('addressInfo.stateId', postalCodeResult.selectedState.id);
        setValue('addressInfo.stateName', {
          id: postalCodeResult.selectedState.id,
          name: postalCodeResult.selectedState.name,
          countryId: postalCodeResult.selectedState.countryId,
          abbr: postalCodeResult.selectedState.abbr,
          label: postalCodeResult.selectedState.label,
          value: postalCodeResult.selectedState.id,
        });
        setValue('addressInfo.postalCode', event.target.value);

        refreshStateCountyOptions(postalCodeResult.selectedState.id);
        trigger();
      }
    }
  };

  const trimInputValue = (value: string) => value.trim();

  useEffect(() => {
    if (allStates.state === 'hasValue') {
      setAllStatesLocal(allStates.contents);
    }
  }, [allStates]);

  useEffect(() => {
    register('addressInfo.countyId');
    register('addressInfo.countryId');
    register('addressInfo.countyName');
    register('addressInfo.stateId');
    register('addressInfo.stateName');
  }, [register]);

  return (
    <>
      <div className={`inputWrapper clearfix ${errors.addressInfo?.address1 && 'errorWrapper'}`}>
        <div>
          <label htmlFor='address1' aria-labelledby='address1'>
            Address
          </label>
          <input
            id='address1'
            maxLength={50}
            name='addressInfo.address1'
            placeholder='Address'
            ref={register({
              required: true,
              setValueAs: trimInputValue,
              validate: isValidRequiredTrimmingSpace,
            })}
            type='text'
          />
        </div>
        <div className='errorMsg'>
          <span>{errors.addressInfo?.address1 && 'Address is required'}</span>
        </div>
      </div>

      <div className={`inputWrapper clearfix ${errors.addressInfo?.address2 && 'errorWrapper'}`}>
        <div>
          <label htmlFor='address2' aria-labelledby='address2'>
            Suite, Floor, etc. <Helper>(Optional)</Helper>
          </label>
          <input
            id='address2'
            maxLength={50}
            name='addressInfo.address2'
            placeholder='Suite, Floor, etc.'
            ref={register({
              setValueAs: trimInputValue,
            })}
            type='text'
            data-testid='suite-floor'
          />
        </div>
        <div className='errorMsg'>
          <span>{errors.addressInfo?.address2 && 'Formatted improperly'}</span>
        </div>
      </div>

      <div className={`inputWrapper clearfix ${errors.addressInfo?.postalCode && 'errorWrapper'}`}>
        <div>
          <label htmlFor='postalCode' aria-labelledby='postalCode'>
            ZIP Code
          </label>
          <input
            disabled={asyncLoading}
            id='postalCode'
            maxLength={5}
            name='addressInfo.postalCode'
            onChange={postalCodeChange}
            placeholder='ZIP Code'
            ref={register({
              required: true,
              setValueAs: trimInputValue,
              pattern: {
                message: 'Invalid ZIP Code',
                value: zipCodeRegex,
              },
            })}
            type='text'
          />
        </div>
        <div className='errorMsg'>
          <span>{errors.addressInfo?.postalCode && 'ZIP Code is required'}</span>
        </div>
      </div>

      <div className={`inputWrapper clearfix ${errors.addressInfo?.city && 'errorWrapper'}`}>
        <div>
          <label htmlFor='city' aria-labelledby='city'>
            City
          </label>
          <input
            id='city'
            disabled={asyncLoading}
            maxLength={50}
            name='addressInfo.city'
            placeholder='City'
            ref={register({
              required: true,
              setValueAs: trimInputValue,
              validate: isValidRequiredTrimmingSpace,
            })}
            type='text'
          />
        </div>
        <div className='errorMsg'>
          <span>{errors.addressInfo?.city && 'City is required'}</span>
        </div>
      </div>

      <DSSelectField
        closeMenuOnSelect={true}
        defaultValue=''
        isDisabled={asyncLoading}
        label='State'
        name={'addressInfo.state'}
        onSelect={(e: StateDropdownItem) => changeSelectedState(e)}
        options={getAddressStates(allStatesLocal)}
      />

      <DSSelectField
        closeMenuOnSelect={true}
        defaultValue=''
        isDisabled={asyncLoading}
        label='County'
        name='addressInfo.county'
        options={stateCounties}
      />

      <DSSelectField
        closeMenuOnSelect={true}
        defaultValue=''
        isDisabled={asyncLoading}
        label='Country'
        name='addressInfo.country'
        options={countrieslist}
      />

      {asyncLoading && (
        <div className='overlay'>
          <div className='progressbox'>
            <div>
              <LogoDSLoader alt='Fetching zip code data.' />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AddressLookup;
