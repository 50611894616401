import React from 'react';

import { DSPillContainer, DSPillIcon, getStatusColorMap } from './DSPillStyles';
import { DataTestId } from '../types';
import { Status } from '../constants';

type DSPillProps = {
  icon?: string;
  iconLabel?: string;
  label?: string;
  handleIconClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  showIcon?: boolean;
  showIconRight?: boolean;
  status?: Status;
} & DataTestId;

/**
 * @description Shows a pill for status with an optional icon & action
 * @example <DSPill label='Test DSPill' showIcon={true} icon='mdi-close' iconLabel='clear' onIconClick={() => {}} />
 * @default{ icon = '', iconLabel = '', label = '', showIcon = false, showIconRight = false }
 * @param {string | undefined}  icon - class name for pill icon
 * @param {string | undefined}  iconLabel - title/aria-title for pill icon
 * @param {string}  label - text that will show on the pill
 * @param {function} onIconClick - function to be called if the pill icon is clicked
 * @param {boolean?} showIcon - whether to show the pill icon or not
 * @param {boolean?} showIconRight - whether to show the pill icon on the right, false = left
 * @param {string} status - 'success' | 'warning' | 'error' determines pill color, leave undefined for base styling
 */
export const DSPill: React.FunctionComponent<DSPillProps> = ({
  icon = '',
  iconLabel = '',
  label = '',
  handleIconClick,
  showIcon = false,
  showIconRight = false,
  status,
  dataTestId,
}: DSPillProps) => {
  const iconOrder = showIconRight ? 2 : 0;

  return (
    <DSPillContainer {...getStatusColorMap(status)} data-testid={`${dataTestId}`}>
      {showIcon && (
        <DSPillIcon
          className={`mdi ${icon}`}
          title={iconLabel}
          aria-label={iconLabel}
          onClick={handleIconClick}
          order={iconOrder}
          data-testid={`${dataTestId}.icon`}
        />
      )}
      <span title={label}>{label}</span>
    </DSPillContainer>
  );
};
