import { StrictMode, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';

import amplitude from 'amplitude-js';
import App from './App';

import { AmplitudeProvider } from '../components/amplitude';
import { constants } from '../utils/settings';
import rootSaga from '../store/sagas';

import { loadState } from 'src/utils/storage';
import { store } from '../store/configureStore';
import { useAuth } from 'src/shared/hooks/useAuth';
import { getTokenData } from 'src/utils/token';
import { AuthState } from 'src/store/reducers/auth';

store.runSaga(rootSaga);

const amplitudeInstance = amplitude.getInstance();

const Root = () => {
  const { setAuth } = useAuth();

  const storedState = useMemo(() => {
    const state = loadState()?.auth as AuthState | undefined;

    return state?.tk ? getTokenData(state.tk) : null;
  }, []);

  // Preload auth for Recoil from localStorage.
  useEffect(() => {
    if (storedState) {
      setAuth(storedState);
    }
  }, [setAuth, storedState]);

  if (process.env.NODE_ENV === 'production') {
    return (
      <Provider store={store as any}>
        <>
          <AmplitudeProvider
            amplitudeInstance={amplitudeInstance}
            apiKey={constants.amplitudeToken}
          >
            <StrictMode>
              <App />
            </StrictMode>
          </AmplitudeProvider>
        </>
      </Provider>
    );
  } else {
    return (
      <Provider store={store as any}>
        <AmplitudeProvider amplitudeInstance={amplitudeInstance} apiKey={constants.amplitudeToken}>
          <App />
        </AmplitudeProvider>
      </Provider>
    );
  }
};

export default Root;
