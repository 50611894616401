// TOREFACTOR: Use library components + FormProvider.

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';

import { DSTextField } from '@demandstar/components/fields';
import { emailVal } from '@demandstar/components/utils';

import { isValidRequiredTrimmingSpace, toastFn } from '../../../../utils/helpers';
import { Buttons } from '../../../customcontrols';
import { incompleteRegistrationState } from '../../../../store/recoil/registrationState';
import { supplierRegistrationTypes } from '../../../../types/supplierregistration';
import { useAmplitude } from '../../../amplitude';
import { useRegistration } from 'src/shared/hooks/useRegistration';

interface CreateAccountFormProps {
  registration: supplierRegistrationTypes;
  checkAccountExists?: any;
  setRegistrationData?: any;
  match?: any;
}

const CreateAccountForm = (props: CreateAccountFormProps) => {
  const { errors, handleSubmit, register, setValue } = useForm();
  const { logEvent, setUserProperties } = useAmplitude();

  const { checkAccountExists, setRegistrationData, registration } = props;
  const { registrationData } = registration;
  const { emailAddress = '', companyName = '', acceptTerms = false } = registrationData;

  const memberId = props.match && props.match.params.memberId ? props.match.params.memberId : '';

  const setRecoilRegistrationData = useSetRecoilState(incompleteRegistrationState);
  const { resetRegistrationState } = useRegistration();

  useEffect(() => {
    if (emailAddress && companyName) {
      setValue('emailAddress', emailAddress);
      setValue('companyName', companyName);
    }
  }, [emailAddress, companyName, setValue]);

  useEffect(() => {
    resetRegistrationState();
  }, [resetRegistrationState]);

  const trimInputValue = (value: string) => value.trim();

  const onSubmitFn = (values: { emailAddress: string; companyName: string }) => {
    if (acceptTerms) {
      setRecoilRegistrationData({
        ...registrationData,
        emailAddress: values.emailAddress,
        companyName: values.companyName,
      });
      checkAccountExists({ ...values, memberId });
      setUserProperties({
        Email: values.emailAddress,
        'Organization Name': values.companyName,
      });
      logEvent('registration (supplier) - added email', {
        Description: 'Add email and company name',
      });
    } else toastFn('error', 'Please accept DemandStar Terms of Use and Privacy Policy');
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFn)}>
      <div className='d-flex align-items-end flex-column h-100'>
        <DSTextField
          dataTestId='registration-input-email'
          error={errors.emailAddress}
          label='Email Address'
          message='This will be your username for logging in to DemandStar'
          name='emailAddress'
          ref={register({
            required: true,
            setValueAs: trimInputValue,
            pattern: {
              value: emailVal,
              message: 'Invalid email format',
            },
            validate: isValidRequiredTrimmingSpace,
          })}
        />
        <DSTextField
          dataTestId='registration-input-company-name'
          error={errors.companyName}
          label='Company Name'
          name='companyName'
          ref={register({
            maxLength: 150,
            required: true,
            setValueAs: trimInputValue,
            validate: isValidRequiredTrimmingSpace,
          })}
        />
        <div className='clearfix w-100 text-center'>
          <Buttons
            type='submit'
            classNames='bttn-accent mt-4 mb-3'
            text='Create your DemandStar account'
            title='Next'
            onClick={() => {
              setRegistrationData({
                registrationData: { ...registrationData, acceptTerms: true },
              });
            }}
          />
          <small>
            By creating an account, you agree to DemandStar's{' '}
            <a href='https://network.demandstar.com/terms-of-use/'>Terms of Use</a> and{' '}
            <a href='https://network.demandstar.com/privacy-policy/'>Privacy Policy</a>.
          </small>
        </div>
      </div>
    </form>
  );
};

export default CreateAccountForm;
