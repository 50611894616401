import Select, { Props as ReactSelectProps } from 'react-select';

import { DSSelectStyled, IconOption, styleOverrides } from './DSSelectStyled';
import { FieldContainer } from '../../field-container/FieldContainer';

// Reference the react-select docs before adding props to this list https://react-select.com/props
// If anything listed needs to be exposed, do it in the function, not the interface.
// onChange prop is reserved by React Hook Form's Controller. For consistency with DSSelectField, use onSelect instead.
export type DSSelectProps = Omit<ReactSelectProps, 'onChange'> & {
  /** Test ID for testing-library */
  dataTestId?: string;
  /** Field label displayed above the textarea */
  label?: string;
  /** Message displayed below the label */
  message?: string;
  /** Custom behavior for the Select action; onChange should not be used */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (value: any) => void;
  /** keys for mapping label/value pair to data object */
  optionKeys?: { label: string; value: string };
  /** Array of options for the dropdown */
  optionValues?: Record<string, unknown>[];
};

/**
 * @summary       DSSelect is a dropdown select used in forms.
 * @description   Wraps react-select so the props and behaviors of that component are available
 *                Visit https://react-select.com/props for details.
 *                Allows use of traditional `options` prop with {label, value} or the combination of optionKeys
 *                and optionValues to declare label and value for data that does not have a label and/or value
 *
 * @prop {string}   dataTestId - value for data-testid
 * @prop {string}   label - Select's label, highly recommended
 * @prop {string}   message - A message, usually helper text, displayed above the dropdown
 * @prop {string}   name - highly recommended, name for the <input/>; default is an empty string
 * @prop {(unknown) => void}   onSelect - function to trigger onChange. React-select defines the parameter as unknown,
 *                                        so it has to be typed within the function.
 * @prop {object[]} options - array of options with 'label' and 'value' attributes
 * @prop {object}   optionKeys - {label: string; value: string}
 *                               configure the label and value of optionValues to conform to react-select format
 * @prop {object[]} optionValues - array of options that do not have label and value attributes
 *                                 must be used with optionKeys
 *
 * @example
 *    const optionsUnique = [
 *      { color: 'black', hex: '111', extra: true, number: 5 },
 *      { color: 'purple', hex: '593e87', extra: true, number: 15 },
 *      { color: 'red', hex: 'c7362d', extra: false, number: 24 },
 *      { color: 'teal', hex: '3395a4', extra: true, number: 1 },
 *      { color: 'white', hex: 'fff', extra: false, number: 5 },
 *    ];
 *    const optionKeys = { label: 'color', value: 'hex' };
 *
 *    <DSSelect
 *      label='Select color'
 *      name='color'
 *      onSelect={(value) => console.log('hello', value.label)}
 *      optionKeys={optionKeys}
 *      optionValues={optionsUnique}
 *    />
 */
export const DSSelect = (props: DSSelectProps) => {
  const {
    dataTestId,
    label,
    message,
    name = '',
    onSelect,
    options,
    optionKeys,
    optionValues,
  } = props;

  const selectProps = { dataTestId, ...props };

  let formattedOptions: Record<string, unknown>[];
  const optionLabelKey = optionKeys ? optionKeys.label.toString() : 'label';
  const optionValueKey = optionKeys ? optionKeys.value.toString() : 'value';

  if (optionValues && (optionLabelKey !== 'label' || optionValueKey !== 'value')) {
    formattedOptions = optionValues.map((option: any) => {
      return {
        ...option,
        label: option[optionLabelKey],
        value: option[optionValueKey],
      };
    });
  } else {
    formattedOptions = options as Record<string, unknown>[];
  }

  return (
    <FieldContainer dataTestId={dataTestId} label={label} message={message} name={name}>
      <DSSelectStyled>
        <Select
          components={{ Option: IconOption }}
          inputId={name}
          options={formattedOptions}
          onChange={onSelect}
          styles={{ ...styleOverrides, ...props.styles }}
          {...selectProps}
        />
      </DSSelectStyled>
    </FieldContainer>
  );
};
