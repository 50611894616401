import styled from 'styled-components';

import {
  FontFamily,
  FontWeight,
  HeaderFontSize,
  LineHeight,
  Margin,
  ParagraphFontSize,
} from '@demandstar/components/styles';

export const StatusRow = styled.div`
  margin: ${Margin.Less} ${Margin.None};
  line-height: ${LineHeight.Base};
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Large};
  width: 100%;

  & > div {
    display: inline-flex;
    font-family: ${FontFamily.Header};
    font-size: ${HeaderFontSize.H6};
    &:first-of-type {
      font-weight: ${FontWeight.Bold};
      margin-right: ${Margin.Base};
      min-width: 8rem;
      width: 33.33%;
    }
  }
`;
