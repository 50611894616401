import { connect, ConnectedProps } from 'react-redux';
import React, { memo } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../store/actions';
import { getCookie, validateCookie } from '../utils/cookie';
import InitialData from './HOC';
import { isExpiredToken } from '../utils/helpers';
import { Loader } from '../components/customcontrols/loader';
import Routes from './Routes';
import { toastAutoCloseDelay } from '../utils/constants';
import { tokenCookieName } from '../utils/constants';
import { TourComp } from '../components/common/tourcomp';

import '../assets/scss/agencylanding.scss';
import '../assets/scss/anonymousagency.scss';
import '../assets/scss/layouts/breadcrumb.scss';
import '../assets/scss/layouts/footer.scss';
import '../assets/scss/layouts/forms.scss';
import '../assets/scss/layouts/header.scss';
import '../assets/scss/layouts/paging.scss';
import '../assets/scss/main.scss';
import '../assets/scss/theme/themes.scss';
import 'antd/lib/date-picker/style/css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

type propsTypes = PropsFromRedux;
declare const window: any;
const App = (props: propsTypes) => {
  const { tours, auth } = props;
  const { activateTour } = tours;
  const { mi: memberId, mt: memberType, tk: token } = auth;
  const isIE = window.MSCompatibleInfo != null ? true : false;
  return (
    <main>
      {isIE ? (
        <div className='opsAlertWrapper bg-red200'>
          As Microsoft no longer intends to support Internet Explorer 11, please use Chrome,
          Firefox, or Edge when using DemandStar.
        </div>
      ) : null}
      <ToastContainer
        autoClose={toastAutoCloseDelay}
        pauseOnHover={false}
        transition={Zoom}
        position='top-right'
        closeOnClick={true}
        draggable={true}
        hideProgressBar={true}
      />
      {getCookie(tokenCookieName) &&
      validateCookie(tokenCookieName) &&
      memberId &&
      memberType &&
      !isExpiredToken(token) ? (
        <InitialData />
      ) : null}
      {activateTour ? <TourComp /> : null}
      <Routes {...props} />
      <Loader />
    </main>
  );
};

const connector = connect(
  (state: any) => ({
    tours: state.tours,
    auth: state.auth,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(memo(App));
