import styled from 'styled-components';

import {
  BackgroundColor,
  BorderRadius,
  DeprecatedColors,
  FontFamily,
  FontWeight,
  HeaderFontSize,
  LineHeight,
  Margin,
  Padding,
  TextColor,
} from '../styles';

export const BreadcrumbContainer = styled.div`
  background: ${BackgroundColor.BaseTeal};
  display: flex;
  flex-flow: row wrap;
  font-family: ${FontFamily.Base};
  font-size: ${HeaderFontSize.H6};
  justify-content: space-between;
  margin-top: -0.25rem;
  position: sticky;
  width: 100%;

  @media (min-width: 992px) {
    margin-top: ${Margin.None};
  }
`;

export const BreadcrumbList = styled.ul`
  list-style-type: none;
  margin: ${Margin.None};
  padding: ${Padding.Less} ${Padding.Base};

  & li {
    color: ${TextColor.ContrastAction};
    display: inline-flex;
    font-weight: ${FontWeight.Header};
    line-height: ${LineHeight.Base};
    margin: ${Margin.None} 0.25rem;
    width: auto;

    &:not(:last-child) {
      max-width: 8rem;
      text-decoration-line: underline;

      & i {
        font-size: ${HeaderFontSize.H5};
      }

      & span {
        cursor: pointer;
        margin-right: 0.25rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }

    &:last-child {
      color: ${TextColor.Contrast};
    }
  }
`;

export const BreadcrumbComponent = styled.ul`
  list-style-type: none;
  margin: ${Margin.None};
  padding: ${Padding.Less} ${Padding.Base};

  & li {
    background: ${DeprecatedColors.BlueGreenMedium};
    border-radius: ${BorderRadius.Eased};
    color: ${TextColor.Contrast};
    display: inline-flex;
    font-weight: ${FontWeight.Header};
    padding: ${Padding.None} ${Padding.Less};

    &:not(:last-child) {
      margin-right: ${Margin.Less};
    }

    & span.staticLink {
      color: ${TextColor.Contrast} !important;
    }
  }
`;
