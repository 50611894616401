import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useHistory } from 'react-router';

import { DSSelect, DSTextInput } from '@demandstar/components/inputs';
import { DSButton } from '@demandstar/components/button';
import { DSCheckbox } from '@demandstar/components/checkbox/DSCheckbox';
import { DSLink } from '@demandstar/components/link';
import { DSPaginate } from '@demandstar/components/paginate';
import { DSRadio } from '@demandstar/components/inputs';
// TODO: Eliminate the need for these style wrappers
import { LibraryStyleWrapper } from '@demandstar/components/inputs/text-input';

import { AgencySelectionList, SpacedButtonWrapper } from './styles';
import { AgencySelectionProps, SelectAgencyFilters } from './types';
import { allProductsState, selectedProductsState } from '../../../store/recoil/productState';
import { normalizeFreeAgencies, searchAgencies, selectFreeAgency } from './helpers';
import { ProductApiResponse, ProductType, SubscriptionProducts } from '../../../types/products';

import { agencySelectionText } from './texts';
import { commonLabels } from 'src/shared/constants';
import { compareObjectsByKey } from '../../../utils';
import { MultipleAgencyConfirmationModal } from './MultipleAgencyConfirmationModal';
import { NoResult } from '../../customcontrols/index';
import { Product } from 'src/types/subscriptions';
import { ProductsPaths } from 'src/utils/texts/supplier/subscription';
import { registrationComponent } from '../../../utils/constants';
import { scrollToTop } from '../../../utils/helpers';
import { Selectable } from 'src/types/common';
import { useAccountInfo } from 'src/shared/hooks/useAccountInfo';
import { useAmplitude } from '../../amplitude';
import { useSubscription } from '../../../shared/hooks/useSubscription';

/**
 * @description Component that allows for the filtering and selecting a free agency product
 * @returns A filterable, selectable list of agencies with optional buttons for navigating
 * @example
 * <AgencySelection
      products={[{
        productId: 1,
        productName: 'Sample Free Agency',
        productType: ProductType.FreeAgency,
      }]}
      toggleAgencySelection={() => {}}
      pageFor='registration'
      movetoNextPage={movetoNextPage}
  />
* @example
  <AgencySelection
      products={products}
      toggleAgencySelection={() => toggleAgencySelection}
      isOpsUser={true}
  />
 */
export const AgencySelection = ({
  movetoNextPage,
  pageFor = 'subscription',
  products = [],
  toggleAgencySelection,
  isOpsUser = false,
}: AgencySelectionProps) => {
  const history = useHistory();
  const { updateSupplierFreeAgency } = useSubscription();
  const { refreshAccountInfo } = useAccountInfo();
  const { logEvent } = useAmplitude();
  const allProducts = useRecoilValueLoadable(allProductsState);

  const [selectedProducts, setSelectedProducts] =
    useRecoilState<SubscriptionProducts>(selectedProductsState);
  const [agencyFilter, setAgencyFilter] = useState<SelectAgencyFilters>({ autoSuggestText: '' });

  const [freeAgencies, setFreeAgencies] = useState<ProductApiResponse[]>([]);
  const [agencyCounties, setAgencyCounties] = useState<Selectable<number>[]>([]);
  const [agencyStates, setAgencyStates] = useState<Selectable<number>[]>([]);

  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [newAgenciesExist, setNewAgenciesExist] = useState(false);

  /**
   * @description converts products:(Product | number)[] prop into ProductApiResponse[]
   * @returns ProductApiResponse[]
   */
  const normalizedProducts = useMemo(() => {
    return normalizeFreeAgencies(allProducts, products, pageFor === 'registration');
  }, [allProducts, pageFor, products]);

  const [selectedFreeAgencies, setSelectedFreeAgencies] = useState(normalizedProducts);
  const [freeAgencyName, setSelectedFreeAgencyname] = useState('');
  const [selectedAgencyInfo, setSelectedAgencyInfo] = useState({
    agencyName: '',
    agencyState: '',
    agencyCounty: '',
  });

  useEffect(() => {
    if (normalizedProducts.length > 0 && !freeAgencyName) {
      const selectedProduct = normalizedProducts[0];
      setSelectedFreeAgencyname(selectedProduct.productName);
      setSelectedFreeAgencies(normalizedProducts);
    }
  }, [freeAgencyName, normalizedProducts]);

  useEffect(() => {
    if (allProducts.state === 'hasValue') {
      const agencies = searchAgencies(allProducts.contents);
      setFreeAgencies(agencies);

      const statesList = allProducts.contents
        .filter(item => item.productType === ProductType.State)
        .map(itemData => {
          return { value: itemData.productId, label: itemData.productName };
        })
        .sort(compareObjectsByKey('label'));

      setAgencyStates(statesList);
    }
  }, [allProducts.contents, allProducts.state]);

  /**
   * @description sets filtered free agency products state based on set filters
   * @param filters SelectAgencyFilters filters to apply
   * @returns void
   *
   * @example getFilteredAgencies({
   *  state: { value: 12345 },
   *  county: { value: 12345 },
   *  autoSuggestText: '',
   * })
   */
  const getFilteredAgencies = useCallback(
    (filters: SelectAgencyFilters) => {
      const state = filters.state && filters.state.value ? filters.state.value : 0;
      const county = filters.county && filters.county.value ? filters.county.value : 0;

      if (allProducts.state === 'hasValue') {
        const agencies = searchAgencies(
          allProducts.contents,
          state,
          county,
          filters.autoSuggestText,
        );

        setFreeAgencies(agencies);
      }
    },
    [allProducts.contents, allProducts.state],
  );

  /**
   * @description handles the change of one the filter fields & updates state
   * @param name ('state' | 'county' | 'autoSuggestText') the field being updated
   * @param value (Selectable | string) the value to which the field is being set
   * @return void
   */
  const onInputChange = useCallback(
    (name: 'state' | 'county' | 'autoSuggestText', value: Selectable<number> | string) => {
      let filters = {
        ...agencyFilter,
        [name]: value,
      } as SelectAgencyFilters;
      setAgencyFilter({ ...agencyFilter, ...filters });

      if (name === 'state' && typeof value !== 'string' && allProducts.state === 'hasValue') {
        filters = {
          ...agencyFilter,
          state: value,
          county: undefined,
        };
        setAgencyFilter({ ...agencyFilter, ...filters, county: undefined });
        const selectedState = allProducts.contents.find(item => item.productId === value.value);
        if (selectedState) {
          const selectedStateCounties = allProducts.contents
            .filter(item => item.parentId === selectedState.productId)
            .map(item => {
              return { label: item.productName, value: item.productId };
            });
          setAgencyCounties(selectedStateCounties.sort(compareObjectsByKey('label')));
          setAgencyFilter({ ...agencyFilter, ...agencyFilter, state: value, county: undefined });
        }
      }

      getFilteredAgencies(filters);
    },
    [agencyFilter, allProducts.contents, allProducts.state, getFilteredAgencies],
  );

  /**
   * @description resets filter state
   * @returns void
   *
   * @example resetFilter()
   */
  const resetFilter = useCallback(() => {
    setAgencyFilter({ autoSuggestText: '', state: undefined, county: undefined });
    setSelectedFreeAgencies([]);

    if (allProducts.state === 'hasValue') {
      const agencies = searchAgencies(allProducts.contents);
      setFreeAgencies(agencies);
      setSelectedProducts({ ...selectedProducts, agency: undefined });
    }
  }, [allProducts.contents, allProducts.state, selectedProducts, setSelectedProducts]);

  /**
   * @description handles a click on a checkbox or radio button to add a free agency to selection
   * @param agencyId (number) the product id of the agency to select
   * @returns void
   *
   * @example selectAgency(1)
   */
  const selectAgency = useCallback(
    (agencyId: number) => {
      const result = selectFreeAgency(
        allProducts,
        selectedFreeAgencies,
        normalizedProducts,
        agencyId,
        isOpsUser,
      );

      if (!result) return;

      const { agency, agencyInfo, newAgenciesExist, selectedAgencies } = result;

      setNewAgenciesExist(newAgenciesExist);
      setSelectedFreeAgencies(selectedAgencies);
      setSelectedFreeAgencyname(agency?.productName);
      setSelectedAgencyInfo(agencyInfo);

      setSelectedProducts({ ...selectedProducts, agency });
    },
    [
      allProducts,
      isOpsUser,
      normalizedProducts,
      selectedFreeAgencies,
      selectedProducts,
      setSelectedProducts,
    ],
  );

  const isOptionSelected = useCallback(
    (freeAgency: ProductApiResponse) => {
      return selectedFreeAgencies.find(fa => freeAgency.productId === fa.productId) ? true : false;
    },
    [selectedFreeAgencies],
  );

  /**
   * @description hits endpoint to update supplier free agency, resets filters, and redirects to another page
   *    only used when pageFor === 'subscription'
   * @returns void
   *
   * @example updateFreeAgencies()
   */
  const updateFreeAgencies = useCallback(async () => {
    await updateSupplierFreeAgency(selectedFreeAgencies, pageFor === 'registration');

    if (isOpsUser) await refreshAccountInfo();

    setAgencyFilter({ ...agencyFilter, autoSuggestText: '', state: undefined, county: undefined });

    toggleAgencySelection();

    const redirectPath =
      pageFor === 'subscription' ? ProductsPaths.CurrentSubscription : '/suppliers/dashboard';

    history.push(redirectPath);
  }, [
    agencyFilter,
    history,
    isOpsUser,
    pageFor,
    refreshAccountInfo,
    selectedFreeAgencies,
    toggleAgencySelection,
    updateSupplierFreeAgency,
  ]);

  /**
   * @description checks if more or less than one agency is selected & displays a warning modal if so
   * @returns void
   *
   * @example checkAgencyCount()
   */
  const checkAgencyCount = useCallback(async () => {
    if (selectedFreeAgencies.length === 1) {
      await updateFreeAgencies();
    } else {
      setConfirmationPopup(true);
    }
  }, [selectedFreeAgencies, updateFreeAgencies]);

  /**
   * @description logs an event pagination change
   * @param page ({selected: number}) a pagination parameter to handle when page is changed
   * @returns void
   *
   * @example handlePageChange({selected: 3})
   */
  const handlePageChange = useCallback(
    (page: { selected: number }) => {
      if (page) {
        logEvent('registration (supplier) - click agency pagination', {
          Description:
            "User clicks any of the numbers or < >'s in the pagination component to see more agencies",
          Page: page.selected + 1, // Increases index from 0 to 1
        });
      }
    },
    [logEvent],
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  function toggleConfirmationPopup() {
    setConfirmationPopup(!confirmationPopup);
  }

  return (
    <div data-testid='agency-selection'>
      {pageFor === 'registration' ? (
        <p className='reg-intro no-bottom-margin'>{agencySelectionText.registrationHeader}</p>
      ) : (
        <p className='reg-intro no-bottom-margin'>{agencySelectionText.standardHeader}</p>
      )}
      {selectedFreeAgencies.length > 0 && !isOpsUser && (
        <p className='reg-intro no-top-padding'>
          {agencySelectionText.selectedAgencyLabel} <strong>{freeAgencyName}</strong>
        </p>
      )}

      {/** TOREFACTOR: When no DeprecatedTextInputs remain in registration process, eliminate LibraryStyleWrapper */}
      <LibraryStyleWrapper>
        <DSTextInput
          dataTestId='agency-selection-name-filter'
          onChange={value => {
            onInputChange('autoSuggestText', value);
          }}
          label='Search by Agency Name'
          name='autoSuggestText'
          value={agencyFilter.autoSuggestText ?? ''}
          type='text'
        />
        <DSSelect
          dataTestId='agency-selection-state-filter'
          onSelect={value => {
            onInputChange('state', value);
          }}
          label='State'
          name='state'
          options={agencyStates}
          value={agencyFilter.state ?? ''}
        />
        <DSSelect
          dataTestId='agency-selection-county-filter'
          onSelect={value => {
            onInputChange('county', value);
          }}
          label='County'
          name='county'
          options={agencyCounties}
          value={agencyFilter.county ?? ''}
        />
      </LibraryStyleWrapper>

      <div className='clear-both text-right w-100 mb-4'>
        <DSLink dataTestId='agency-selection-reset-filter' onClick={resetFilter}>
          Reset Search
        </DSLink>
      </div>

      <AgencySelectionList>
        <DSPaginate
          data={freeAgencies}
          data-testid='agency-selection-paginate'
          onPageChange={handlePageChange}
        >
          {data =>
            data.map((freeAgency: ProductApiResponse, index: number) => {
              const isSelected = isOptionSelected(freeAgency);
              return !isOpsUser ? (
                <DSRadio
                  data-testid={`agency-selection-select-item-${freeAgency.productId}`}
                  checked={isSelected}
                  onSelect={() => selectAgency(freeAgency.productId)}
                  key={freeAgency.productId}
                  name={`freeagency-${freeAgency.productName}`}
                  label={freeAgency.productName}
                />
              ) : (
                <DSCheckbox
                  checked={isSelected}
                  onClick={() => selectAgency(freeAgency.productId)}
                  key={freeAgency.productId}
                  name={`freeagency-${freeAgency.productName}`}
                  label={freeAgency.productName}
                />
              );
            })
          }
        </DSPaginate>
        {(agencyFilter.autoSuggestText || agencyFilter.state || agencyFilter.county) &&
          freeAgencies.length === 0 && <NoResult message='No Agencies Available' />}
      </AgencySelectionList>
      {pageFor !== 'registration' ? (
        <SpacedButtonWrapper justifyContent='space-between'>
          <DSButton buttonType='secondary' onClick={toggleAgencySelection}>
            {commonLabels.cancel}
          </DSButton>

          <DSButton
            buttonType='primary'
            inactive={!newAgenciesExist || selectedFreeAgencies.length === 0}
            onClick={checkAgencyCount}
          >
            {commonLabels.save}
          </DSButton>
        </SpacedButtonWrapper>
      ) : (
        <SpacedButtonWrapper justifyContent='end'>
          <DSLink
            onClick={() => {
              setSelectedProducts({ ...selectedProducts, agency: undefined });
              setSelectedFreeAgencies([]);
              setAgencyFilter({
                ...agencyFilter,
                autoSuggestText: '',
                state: undefined,
                county: undefined,
              });
              if (movetoNextPage) movetoNextPage(registrationComponent.ChooseSubscription, []);
            }}
          >
            Skip Agency Selection
          </DSLink>
          <DSButton
            buttonType='primary'
            onClick={() => {
              if (movetoNextPage)
                movetoNextPage(
                  registrationComponent.ChooseSubscription,
                  selectedFreeAgencies,
                  true,
                  freeAgencyName,
                  selectedAgencyInfo,
                );
            }}
            inactive={selectedFreeAgencies.length === 0}
          >
            Next
          </DSButton>
        </SpacedButtonWrapper>
      )}
      <MultipleAgencyConfirmationModal
        isOpen={confirmationPopup}
        onCancel={toggleConfirmationPopup}
        onConfirm={() => {
          toggleConfirmationPopup();
          updateFreeAgencies();
        }}
      />
    </div>
  );
};
