// TOREFACTOR
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { memo, Suspense, useEffect } from 'react';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../../store/actions';

import { clearStorageLoginFn, clearTokenCookie } from 'src/utils/helpers';
import {
  getStateList,
  getSubscriptionMemberDetails,
  loadPreInCompleteRegistration,
  resetAuth,
  setRegistrationData,
} from '../../../store/actions';
import {
  useInitializeSelectedProductsFromRegistration,
  useRefreshIncompleteRegistration,
  useRefreshRegistrationSubscriptions,
  useRegistration,
} from 'src/shared/hooks/useRegistration';

import AccountConfirmation from './accountconfirmation';
import CompanyAddressInfo from './companyaddressinfo';
import CreateAccount from './createaccount';
import EmailAccountDetect from './emailaccouuntdetect';
import LimitedFooter from '../footer';
import LimitedHeader from '../header';
import PrintReceipt from '../../subscription/printreceipt';

import { CompleteProfile } from '../../products/complete-profile/CompleteProfile';
import { DSState } from '../../../store/reducers';
import { initialRegistrationData } from '../../../store/reducers/registration';
import { limitedbiddetailsTypes } from '../../../types/limited';
import { registrationComponent } from '../../../utils/constants';
import { ReviewRegistration } from '../../products/review-order';
import { SelectFreeAgency } from '../../products/select-free-agency/SelectFreeAgency';
import { SelectProducts } from '../../products/select-products';
import { SubscriptionDetails } from '../../../types/subscriptions';
import { supplierRegistrationTypes } from '../../../types/supplierregistration';

interface RegistrationProps extends PropsFromRedux {
  limited: limitedbiddetailsTypes;
  registration: supplierRegistrationTypes;
  match: any;
  location: Location;
  subscriptions: SubscriptionDetails;
  getAllProducts: any;
}
const Registration = (props: RegistrationProps) => {
  const dispatch = useDispatch();

  const { registration, getDynamicProductPrices, getAllProducts, subscriptions } = props;
  const { currentComponent } = registration;
  const { products = [] } = subscriptions;
  const memberId = props.match.params.memberId;

  const { populateRegistrationEmail } = useRegistration();

  useRefreshIncompleteRegistration();
  useInitializeSelectedProductsFromRegistration();
  useRefreshRegistrationSubscriptions();

  useEffect(() => {
    dispatch(resetAuth());
    clearStorageLoginFn();
    clearTokenCookie();
  });

  useEffect(() => {
    dispatch(getStateList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDynamicProductPrices());
  }, [dispatch, getDynamicProductPrices]);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const email = query.get('email');
    if (email) {
      dispatch(loadPreInCompleteRegistration({ email }));
    }
  }, [dispatch, props.location.search]);

  useEffect(() => {
    if (memberId) {
      dispatch(getSubscriptionMemberDetails({ memberId, pagefor: 'registration' }));
    }
  }, [dispatch, memberId]);

  useEffect(() => {
    return () => {
      dispatch(
        setRegistrationData({
          memberId: '',
          currentComponent: registrationComponent.CreateAccount,
          registrationData: initialRegistrationData,
          isRegistrationCompleted: false,
        }),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (products.length === 0) {
      getAllProducts();
    }
  }, [getAllProducts, products.length]);

  useEffect(() => {
    populateRegistrationEmail();
  }, [populateRegistrationEmail]);

  return (
    <>
      <LimitedHeader {...props} pagefor='accountrecovery' />

      {currentComponent === registrationComponent.CreateAccount ? (
        <CreateAccount {...props} />
      ) : currentComponent === registrationComponent.EmailAccount ? (
        <EmailAccountDetect {...props} />
      ) : currentComponent === registrationComponent.ChooseAgency ? (
        <SelectFreeAgency registration={props.registration} />
      ) : currentComponent === registrationComponent.ChooseSubscription ||
        currentComponent === registrationComponent.MainContactInfo ? (
        <div className='unAuthWrapper'>
          <div className='container pt-5'>
            <div className='row d-flex justify-content-center'>
              <SelectProducts setRegistrationData={props.setRegistrationData} />
            </div>
          </div>
        </div>
      ) : currentComponent === registrationComponent.Company ||
        currentComponent === registrationComponent.AboutCompany ? (
        <CompanyAddressInfo />
      ) : currentComponent === registrationComponent.ReviewOrder ? (
        <div className='unAuthWrapper'>
          <div className='container pt-5'>
            <div className='row d-flex justify-content-center'>
              <Suspense fallback={<div>Review Order Loading..</div>}>
                <ReviewRegistration setRegistrationData={props.setRegistrationData} />
              </Suspense>
            </div>
          </div>
        </div>
      ) : currentComponent === registrationComponent.CompleteProfile ? (
        <div className='unAuthWrapper'>
          <div className='container pt-5'>
            <div className='row d-flex justify-content-center'>
              <CompleteProfile setRegistrationData={props.setRegistrationData} />
            </div>
          </div>
        </div>
      ) : currentComponent === registrationComponent.PrintReceipt ? (
        <div className='unAuthWrapper'>
          <div className='container pt-5'>
            <div className='row d-flex justify-content-center'>
              <PrintReceipt {...props} pagefor='registration' />
            </div>
          </div>
        </div>
      ) : currentComponent === registrationComponent.AccountConfirmation ? (
        <AccountConfirmation />
      ) : null}
      <LimitedFooter pagefor='registration' currentComponent={currentComponent} />
    </>
  );
};

const connector = connect(
  (state: DSState) => ({
    limited: state.limited,
    registration: state.registration,
    shared: state.shared,
    accountinfo: state.accountinfo,
    subscriptions: state.subscriptions,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Registration));
