import { Awardee } from '../../../../../types/biddetail';
import { BidExternalStatusType } from '../../../../../utils/constants';
import React from 'react';
import { Table } from 'reactstrap';
import { formatPrice } from '../../../../../utils/helpers';

const tdWidth = {
  width: '50%',
};

interface AwardeesProps {
  awardees: Awardee[];
  bidExternalStatusType: BidExternalStatusType;
}
const Awardees = (props: AwardeesProps) => {
  const { awardees, bidExternalStatusType } = props;

  function displayAmount(amount?: number) {
    if (amount) {
      return '$' + formatPrice(amount);
    } else if (amount === 0) {
      return '$0.00';
    } else {
      return 'No Award Amount Given';
    }
  }

  return (
    <>
      {awardees &&
      awardees.length > 0 &&
      bidExternalStatusType === BidExternalStatusType.Awarded ? (
        <>
          <h4>Awarded To</h4>
          <div className='innerColIndent'>
            <Table className='tableata' borderless>
              <thead>
                <tr data-testid='limited.biddetails.awards.head'>
                  <th style={tdWidth}>Supplier</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {awardees.map(awardee => (
                  <tr key={awardee.awardedToMemberId} data-testid='limited.biddetails.awards.body'>
                    <td>{awardee.supplierName}</td>
                    <td>{displayAmount(awardee.amount)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Awardees;
