/* TOREFACTOR
  In fact, I think this we should replace this file from the ground up. It's very convoluted
  and behaves differently depending on where it's put. An address form is useful, but should be
  about addresses and nothing else.
*/
import React, { memo, useEffect } from 'react';
import { Field, Formik } from 'formik';
import { renderDeprecatedTextField } from '../../common/controls/inputs/text';
import renderSelect from '../../common/controls/inputs/selectbox';
import renderTextarea from '../../common/controls/inputs/textarea';
import renderCheckbox from '../../common/controls/inputs/checkbox';
import renderDateField from '../../common/controls/inputs/datepicker';
import TimePicker from '../../common/controls/inputs/timepicker';
import { BuyerEbidStatus, memberTypes, usaDateFormat } from '../../../utils/constants';
import MemberInfoControl from '../breadcrumb/accountinfo/memberinfo';
import BillingEditControl from '../breadcrumb/supplier/billibgeditControl';
import { Buttons } from '../../customcontrols';
import { validate } from './validations';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { getCountiesList } from '../../../store/actions/shared';
import { setBreadcrumb } from '../../../store/actions';

interface AddressFormProps {
  handleSubmit?: any;
  change?: any;
  pristine?: boolean;
  valid?: Record<string, unknown>;
  submitting?: boolean;
  onSubmit?: any;
  onSubmitNext?: any;
  countrieslist: Array<Record<string, unknown>>;
  countieslist: Array<Record<string, unknown>>;
  getStateList?: any;
  pagevaluescancel?: () => void;
  pagevaluescancelname?: string;
  pagevaluessavename?: string;
  pagevaluessaveandtextname?: string;
  pagevaluespage?: string;
  pagevaluestitle?: string;
  pagevaluesaltname?: string;
  pagevaluesname?: string;
  pagevaluespagefor?: string;
  bidssummary?: {
    supplierFields?: { planholderId?: number; memberType?: string };
    modalType: string;
  };
  selectCountryState?: any;
  breadcrumbs?: boolean;
  auth?: { opi: number; mt: string };
  shared?: {
    selfdeclaredattributes?: Array<{
      attributeName: string;
      attributeType: string;
      selected: boolean;
    }>;
    stateslist: Array<{
      abbr: string;
      countryId: number;
      id: number;
      label: string;
      name: string;
      value: number;
    }>;
    countieslist: Array<{
      countryId: number;
      id: number;
      label: string;
      name: string;
      value: string;
    }>;
  };
  synchronousError?: any;
  initialValues?: any;
  setFormData?: any;
  populateMetrodetails?: any;
}

const AddressFrom = (props: AddressFormProps) => {
  const dispatch = useDispatch();
  const {
    valid,
    pristine,
    submitting,
    onSubmit,
    onSubmitNext,
    countrieslist,
    getStateList,
    pagevaluescancel = () => {},
    pagevaluescancelname = '',
    pagevaluessavename = '',
    pagevaluessaveandtextname = '',
    pagevaluespage = '',
    pagevaluestitle = '',
    pagevaluesaltname = '',
    pagevaluesname = '',
    pagevaluespagefor = '',
    bidssummary,
    breadcrumbs = true,
    auth,
    shared,
    initialValues = {},
    populateMetrodetails,
  } = props;

  const pagefor = pagevaluespagefor;

  const { opi = 0, mt = '' } = auth || {};

  const { supplierFields, modalType } = bidssummary || {};
  const {
    selfdeclaredattributes = [],
    stateslist: allStatesList = [],
    countieslist: statecountieslist = [],
  } = shared || {};

  useEffect(() => {
    if (breadcrumbs) {
      let BreadcrumbControls = '' as any;
      if (pagefor === 'actioninfo-payments') {
        BreadcrumbControls = (
          <BillingEditControl
            submit={handleBreadcrumbSubmit}
            cancel={pagevaluescancel}
            valid={valid}
            pristine={pristine}
            submitting={submitting}
          />
        );
      } else if (pagefor === 'actioninfo-memberinfo') {
        BreadcrumbControls = (
          <MemberInfoControl
            submit={handleBreadcrumbSubmit}
            cancel={pagevaluescancel}
            valid={valid}
            pristine={pristine}
            submitting={submitting}
            opi={opi}
          />
        );
      }
      dispatch(
        setBreadcrumb({
          component: BreadcrumbControls,
          page: pagevaluespage,
          title: pagevaluestitle,
          altname: pagevaluesaltname,
          name: pagevaluesname,
        }),
      );
    }
  }, [
    pagevaluesaltname,
    pagevaluesname,
    pagevaluespage,
    pagefor,
    pagevaluestitle,
    pristine,
    dispatch,
    submitting,
    valid,
    breadcrumbs,
    pagevaluescancel,
    opi,
  ]);

  const handleBreadcrumbSubmit = () => {
    const submitbtn = document.getElementById('submitform');
    submitbtn?.click();
  };

  const onSubmitFn = (values: any) => {
    if (
      pagevaluespagefor === 'planholders-addsupplier' &&
      values.action &&
      values.action === pagevaluessaveandtextname
    ) {
      onSubmitNext(values);
    } else {
      onSubmit(values);
    }
  };

  const prePopulatePostalCodeData = (postalCode: string, formData: any) => {
    if (postalCode.length > 4) {
      populateMetrodetails({ zip: postalCode, formData, formPage: 'addressreduxform' });
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmitFn}
      >
        {formikProps => {
          const { values, handleSubmit, setFieldValue } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              {pagefor === 'orderpackage-billingaddress' ? null : (
                <>
                  {pagefor === 'planholders-addsupplier' &&
                  supplierFields &&
                  supplierFields.planholderId ? (
                    <Field
                      label='Company Name'
                      type='text'
                      classNames='class'
                      placeholder='Company Name'
                      name='companyname'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      disabled={true}
                      maxLength={150}
                    />
                  ) : (
                    <Field
                      label='Company Name'
                      type='text'
                      classNames='class'
                      placeholder='Company Name'
                      name='companyname'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      disabled={
                        !(mt !== memberTypes.agencyBuyer && opi) &&
                        (pagefor === 'ebids-response' ||
                          pagefor === 'actioninfo-memberinfo' ||
                          pagefor === 'actioninfo-payments')
                          ? true
                          : false
                      }
                      maxLength={150}
                    />
                  )}
                </>
              )}
              <Field
                label='Address 1'
                type='text'
                classNames='class'
                placeholder='Address 1'
                name='address1'
                component={renderDeprecatedTextField}
                onChange={setFieldValue}
                optional={pagefor === 'planholders-addsupplier' ? true : false}
                maxLength={50}
              />
              <Field
                label='Address 2'
                type='text'
                classNames='class'
                parentClass='mb-3'
                placeholder='Address 2'
                name='address2'
                component={renderDeprecatedTextField}
                onChange={setFieldValue}
                optional={true}
                maxLength={50}
              />
              <Field
                label='Postal Code'
                type='text'
                classNames='class'
                placeholder='Postal Code'
                parentClass='mb-3'
                name='postalCode'
                component={renderDeprecatedTextField}
                onChange={(name: string, value: string) => {
                  setFieldValue('postalCode', value);
                  if (value)
                    prePopulatePostalCodeData(value, { ...formikProps.values, postalCode: value });
                }}
                optional={pagefor === 'planholders-addsupplier' ? true : false}
                maxLength={30}
              />

              <div className='clearfix'>
                <Field
                  label='City'
                  type='text'
                  classNames='class'
                  placeholder='City'
                  parentClass='w-48 d-inline-block'
                  name='city'
                  component={renderDeprecatedTextField}
                  onChange={setFieldValue}
                  optional={pagefor === 'planholders-addsupplier' ? true : false}
                  maxLength={50}
                />
                <Field
                  name='state'
                  label='State/Province'
                  type='select'
                  component={renderSelect}
                  parentClass='w-48 float-right'
                  options={
                    formikProps.values.country
                      ? allStatesList.filter(
                          (item: any) => item.countryId === formikProps.values.country.value,
                        )
                      : []
                  }
                  onChange={(name: string, value: any) => {
                    if (value) {
                      setFieldValue(name, value);
                      if (value && value.id) {
                        if (
                          (value.countryId === 1 && pagefor !== 'planholders-addsupplier') ||
                          (value && value.value)
                        ) {
                          dispatch(getCountiesList(value.value));
                          setFieldValue('county', '');
                        }
                      }
                    }
                  }}
                  optional={pagefor === 'planholders-addsupplier' ? true : false}
                />
              </div>
              {pagefor !== 'planholders-addsupplier' ? (
                <div className='clearfix'>
                  <Field
                    name='county'
                    label='County'
                    type='select'
                    component={renderSelect}
                    parentClass='w-48 d-inline-block'
                    options={statecountieslist}
                    onChange={setFieldValue}
                    optional={true}
                    disabled={values && values.country && values.country.value === 5 ? true : false}
                  />
                  <Field
                    name='country'
                    label='Country'
                    type='select'
                    parentClass='w-48  float-right'
                    component={renderSelect}
                    onChange={(name: string, value: any) => {
                      if (value) {
                        setFieldValue(name, value);
                        setFieldValue('state', '');
                        setFieldValue('postalCode', '');
                        setFieldValue('county', '');
                        if (pagefor !== 'orderpackage-billingaddress') {
                          dispatch(getCountiesList(''));
                        }
                        if (pagefor === 'planholders-addsupplier') {
                          getStateList(value);
                        }
                      }
                    }}
                    options={countrieslist}
                    optional={pagefor === 'planholders-addsupplier' ? true : false}
                  />
                </div>
              ) : (
                <div className='clearfix mb-2'>
                  <Field
                    name='country'
                    label='Country'
                    type='select'
                    parentClass='w-48  d-inline-block'
                    component={renderSelect}
                    onChange={(name: string, value: any) => {
                      if (value) {
                        setFieldValue(name, value);
                        setFieldValue('state', '');
                        setFieldValue('postalCode', '');
                        setFieldValue('county', '');
                        if (pagefor === 'planholders-addsupplier') {
                          getStateList(value);
                        }
                      }
                    }}
                    options={countrieslist}
                    optional={pagefor === 'planholders-addsupplier' ? true : false}
                  />
                  <Field
                    label='Phone Number'
                    type='text'
                    classNames='class'
                    placeholder='Phone Number'
                    parentClass='w-48 float-right'
                    name='phoneNumber'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    optional={pagefor === 'planholders-addsupplier' ? true : false}
                    maxLength={17}
                  />
                </div>
              )}

              {pagefor === 'orderpackage-billingaddress' ||
              pagefor === 'planholders-addsupplier' ? null : (
                <>
                  <div className='clearfix mb-2'>
                    <Field
                      label='Phone Number'
                      type='text'
                      classNames='class'
                      placeholder='Phone Number'
                      parentClass='w-48 d-inline-block'
                      name='phoneNumber'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      maxLength={17}
                    />
                    <Field
                      label='Extension'
                      type='text'
                      classNames='class'
                      placeholder='Extension'
                      parentClass='w-48 float-right'
                      name='phoneExtension'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      optional='optional'
                      maxLength={10}
                    />
                  </div>
                </>
              )}
              {pagefor === 'ebids-response' ? (
                <>
                  <div className='clearfix mb-2'>
                    <Field
                      label='Bid Amount'
                      type='text'
                      classNames='class'
                      placeholder='Bid Amount'
                      parentClass='w-48 d-inline-block'
                      name='totalPrice'
                      maxLength={14}
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                    />
                    <Field
                      label='Alternate Bid Amount'
                      type='text'
                      classNames='class'
                      placeholder='Alternate Bid Amount'
                      parentClass='w-48 float-right'
                      name='altTotalPrice'
                      maxLength={14}
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      optional='optional'
                    />
                  </div>
                  <Field
                    label='Notes'
                    type='textarea'
                    maxLength={240}
                    classNames='class'
                    placeholder='Enter your notes here. (the space expands as required)'
                    name='notes'
                    component={renderTextarea}
                    onChange={setFieldValue}
                    optional='optional'
                  />
                  {mt === memberTypes.agencyBuyer ? (
                    <>
                      <Field
                        label='Response Status'
                        type='text'
                        classNames='class'
                        placeholder='Response Status'
                        name='buyerresponsestatus'
                        options={BuyerEbidStatus}
                        component={renderSelect}
                        onChange={setFieldValue}
                      />
                      <div className='clearfix'>
                        <Field
                          label='Response Received'
                          classNames='class'
                          name='buyerresponsedate'
                          parentClass='my-1 w-48 d-inline-block'
                          component={renderDateField}
                          optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                          setFieldValue={setFieldValue}
                          minDate=''
                        />
                        <TimePicker
                          component={renderSelect}
                          timeFieldName='buyerdueTime'
                          zoneFieldName='buyerdueTimeZone'
                          parentClass='my-1 w-48 float-right'
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
              {pagefor === 'actioninfo-memberinfo' ? (
                <div className='clearfix mb-2'>
                  <Field
                    label='Website'
                    type='text'
                    classNames='class'
                    placeholder='Website'
                    name='website'
                    maxLength={100}
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    optional={true}
                  />
                </div>
              ) : null}
              {pagefor === 'planholders-addsupplier' ? (
                <>
                  <div className='clearfix mb-2'>
                    <Field
                      label='Email'
                      type='text'
                      classNames='class'
                      placeholder='Email'
                      name='email'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      maxLength={100}
                    />
                    <Field
                      label='Contact Name'
                      type='text'
                      classNames='class'
                      placeholder='Contact Name'
                      name='contact'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      optional={true}
                      maxLength={80}
                    />
                  </div>
                  {supplierFields &&
                  (!supplierFields.planholderId || supplierFields.memberType === 'PL') ? (
                    <div className='clearfix mb-2'>
                      <h5>Self Declared Attributes</h5>
                      {selfdeclaredattributes.map((item, index) => {
                        return (
                          <Field
                            key={index}
                            name={`selfdecAttr[${item.attributeType}]`}
                            component={renderCheckbox}
                            title={item.attributeName}
                            classNames='class'
                            optional='optional'
                            handleChange={setFieldValue}
                          />
                        );
                      })}
                    </div>
                  ) : null}
                </>
              ) : null}

              {pagefor === 'planholders-addsupplier' ? (
                <div className='d-flex flex-column flex-lg-row justify-content-between'>
                  {pagevaluescancelname &&
                  supplierFields &&
                  supplierFields.planholderId &&
                  modalType === 'add' ? (
                    <Buttons
                      classNames='bttn-secondary mt-4 mb-3 float-left'
                      text={pagevaluescancelname}
                      title={pagevaluescancelname}
                      onClick={pagevaluescancel}
                      type='button'
                    />
                  ) : null}
                  <>
                    {modalType === 'add' ? (
                      <Buttons
                        classNames='bttn-primary mt-4 mb-3 float-left'
                        text={pagevaluessaveandtextname}
                        title={pagevaluessaveandtextname}
                        type='submit'
                        onClick={() => setFieldValue('action', pagevaluessaveandtextname)}
                      />
                    ) : null}

                    <Buttons
                      classNames='bttn-primary mt-4 mb-3 float-right'
                      text={pagevaluessavename}
                      title={pagevaluessavename}
                      id='submitform'
                      type='submit'
                      onClick={() => setFieldValue('action', pagevaluessavename)}
                    />
                  </>
                </div>
              ) : (
                <div className='d-block clearfix'>
                  {pagevaluescancelname ? (
                    <Buttons
                      classNames='bttn-primary mt-4 mb-3 float-left'
                      text={pagevaluescancelname}
                      title={pagevaluescancelname}
                      onClick={pagevaluescancel}
                      type='button'
                    />
                  ) : null}
                  {pagefor === 'actioninfo-payments' ? (
                    <Buttons
                      classNames='bttn-primary mt-4 mb-3 float-right'
                      text={pagevaluessavename}
                      title={pagevaluessavename}
                      id='submitform'
                      type='submit'
                    />
                  ) : (
                    <Buttons
                      classNames='bttn-primary mt-4 mb-3 float-right'
                      text={pagevaluessavename}
                      title={pagevaluessavename}
                      id='submitform'
                      type='submit'
                    />
                  )}
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default memo(AddressFrom);
