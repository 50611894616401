import jwt from 'jsonwebtoken';

import { Authorization, authTypes } from 'src/types';

export function getTokenData(token: string): Authorization {
  const tokenData: authTypes | any = jwt.decode(token);
  const fullName = tokenData?.fln || tokenData?.fn + ' ' + tokenData?.ln;
  const {
    us,
    lkd,
    lm,
    prms,
    eml,
    fn,
    opi = 0,
    ln,
    mbl,
    mi,
    ms,
    mt,
    pmid,
    ut,
    un,
    mc,
    her,
    ml,
    mgrtd,
    tm = '',
    mcd = '',
    acd = '',
  } = tokenData;

  return {
    accountId: Number(us),
    accountLocked: Boolean(lkd === 'True'),
    accountLockedMinutes: Number(lm),
    accountPermissions: prms,
    email: eml,
    firstName: fn,
    fullName,
    hasError: her,
    lastName: ln,
    mainContact: Boolean(mc === 'True'),
    memberBlackListed: Boolean(mbl === 'True'),
    memberId: Number(mi),
    membershipLevels: ml,
    memberStatus: ms,
    memberType: mt,
    migrated: Boolean(mgrtd === 'True'),
    opsId: Number(opi),
    primaryMemberId: Number(pmid),
    themetokenName: tm,
    token,
    userName: un,
    userType: ut,
    validatedResult: { status: true, firstName: fn, lastName: ln },
  };
}
