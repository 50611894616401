import styled from 'styled-components';

import {
  FontFamily,
  FontWeight,
  LineHeight,
  Margin,
  Padding,
  ParagraphFontSize,
  TextColor,
} from '../styles';

export const DSJumpToLink = styled.a`
  color: ${TextColor.Action};
  text-decoration-line: underline;

  &:hover {
    text-decoration-line: underline;
  }
`;

export const DSJumpToList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const DSJumpToListItem = styled.li<any>`
  margin-bottom: ${props => (props?.lastInSection ? Margin.Extra : Margin.Less)};
`;

// TODO: TS4 - Additional Styled props need to be typed otherwise they are an implicit any
export const DSJumpToSection = styled.div<any>`
  display: inline-flex;
  flex-direction: column;
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Large};
  font-weight: ${FontWeight.Base};
  height: 100%;
  line-height: ${LineHeight.Base};
  padding: ${Padding.Less} ${Padding.Extra};
  position: sticky;
  top: ${props => props.top};
`;

// TODO: This whole bit could be a shared Title style
export const DSJumpToTitle = styled.div`
  font-family: ${FontFamily.Header};
  font-weight: ${FontWeight.Header};
  margin-bottom: ${Margin.Less};
`;
