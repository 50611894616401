import styled from 'styled-components';

import { BackgroundColor, BorderColor } from './colors';
import { Margin } from './spacing';

export const transitions = {
  base: '0.25s',
};

export const FullWidth = styled.div`
  width: 100%;
`;

export const TextOverflowEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: break-word;
`;

export const ContentRowWrapping = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InnerColumnNarrow = styled.div`
  display: flex;
  flex-basis: 33%;
  flex-direction: column;
  flex-grow: 1;
  width: 33%;
`;

export const InnerColumnWide = styled.div`
  display: flex;
  flex-basis: 67%;
  flex-direction: column;
  flex-grow: 2;
  width: 67%;
`;

export const HighlightedDetailsWrapper = styled.div`
  background-color: ${BackgroundColor.BaseWhite};
  border: solid 0.1875rem ${BorderColor.Highlight};
  border-radius: 0.125rem;
  height: 100%;
  margin-left: -3rem;
  margin-right: -1rem;
  padding: 0.75rem 1rem 0.75rem 3rem;
`;

export const HighlightedListWrapper = styled.div`
  background-color: ${BackgroundColor.BaseWhite};
  height: 100%;
  margin-left: -1.5rem;
  padding: 0.75rem 1.5rem 0 1.5rem;
`;

export interface FlexContainerProps {
  /** establishes the main-axis, thus defining the direction flex items are placed in the flex container.
   * @default 'row' */
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  /** defines whether the flex items are forced in a single line or can be flowed into multiple lines
   * @default 'nowrap' */
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  /** defines the alignment along the main axis
   * @default 'flex-start' */
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right';
}

/** Simple flex container for <Flex> components
 * @example <FlexContainer>
 *  <Flex>Foo</Flex>
 *  <Flex>Bar</Flex>
 * </FlexContainer>
 */
export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  column-gap: ${Margin.Extra};
  row-gap: ${Margin.Base};
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  align-items: baseline;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
`;

/**
 * Helper component for column layout
 *
 */
export const FlexContainerColumn = styled(FlexContainer).attrs((props: FlexContainerProps) => ({
  direction: 'column',
  wrap: 'nowrap',
}))``;

interface FlexProps {
  /** sets the flex grow factor of a flex item's main size */
  grow?: number;
}

/** Flex div, fits inside <FlexContainer> to space automatically.
 * Good for simple multi-column needs.
 * @example <Flex grow={2}>Foo</Flex>
 * <Flex>Bar</Flex>
 */
export const Flex = styled.div<FlexProps>`
  flex: ${({ grow }) => (grow === undefined ? 1 : grow)} 1;
`;
