// NOTE: Do not add new constants to this file

import { commonEmail } from '../texts/common/emails';

// Instead, create a page specific file and import it
export * from './amplitude';
export * from './bid';
export * from './dateTime';
export * from './document';
export * from '../../shared/constants/forms';
export * from './keys';
export * from './member';
export * from './menu';
export * from './paths';
export * from './quote';
export * from './registration';
export * from './styles';
export * from './tokens';
export * from './validation';

export const chargeType = {
  agencySubscription: 'AG',
  bidPurchase: 'DF',
  countySubscription: 'CN',
  generalSubscription: 'GS',
  internationalSubscription: 'IN',
  metroAreaSubscription: 'MA',
  nationalSubscription: 'NA',
  regionalSubscription: 'RG',
  stateSubscription: 'ST',
};

export const fixedPercentage = 99;

export const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const idleTimeOut = 30 * 60000;

export const InternalLoaderNames = {
  OtherAccountInfo: 'OtherAccountInfo',
};

export const landingPageAlertMsg = 'You will lose any unsaved changes. Do you want to continue?';

export const notificationExpandCount = 2;
export const notificationInitialCount = 4;

export const processPercentage = 10;

export const reportOptions = [
  { label: 'Bids', value: 'bids' },
  { label: 'Quotes', value: 'quotes' },
];

export const shortProcessPercentage = 50;

export const supplimentalSupplierColumns = [
  { label: 'Company Name', value: 'company' },
  { label: 'Contact Name', value: 'contact' },
  { label: 'Phone Number', value: 'phone' },
  { label: 'City', value: 'city' },
  { label: 'State', value: 'state' },
];

export const timeOut = 300000; /*ms, *0 - no timeout*/

export enum TourId {
  bidSearch = 2,
  bulkUpload = 3,
  awardBidButton = 7,
}

/** The values in userEvent should not be alphabetized so new fields can incremement easily */
export const userEvent = {
  AccountStatus: 1,
  AddCertificate: 2, //TODO
  AddPublication: 3, //TODO
  ApplyCertificate: 4,
  ApproveCertificate: 5,
  AutoRenewal: 6,
  BannerChange: 7,
  CancelSubscription: 8,
  CommodityCodeChange: 9,
  DeleteCertificate: 10,
  DeletePublication: 11,
  EditCertificate: 12,
  ExtendSubscription: 13,
  MainContactChange: 14,
  MemberStatusChange: 15,
  MemberTypeChange: 16, //TODO
  PasswordReset: 17, //TODO
  ReceiveNotification: 18,
  RemoveCardProfile: 19,
  SubscriptionRenewal: 20, //TODO
  SubscriptionUpgrade: 21, //TODO
  UpdateAccount: 22,
  UpdateAddress: 23,
  UpdateBidType: 24,
  UpdateBillingAddress: 25,
  UpdateCard: 26,
  UpdateDocumentApproveStatus: 27,
  UpdateMemberLegalAd: 28,
  UpdateMemberResponseDocument: 29,
  UpdateSelfDeclaration: 30,
  UsernameChange: 31,
  ApproveDocument: 32, //TODO
  AwardBid: 33,
  DownloadBidDocument: 34,
  DownloadPackage: 35,
  FinishBid: 36,
  SubmitResponse: 37, //TODO
  UpdateBidInfo: 38,
  UpdateBroadcastDate: 39,
  UpdateBidBroadcastInfo: 40,
  UpdateBidDocument: 41, //TODO
  UpdateDueDate: 42,
  UpdateBidLegalAd: 43,
  UpdatePBC: 44,
  UpdatePlans: 45, //TODO
  UpdatePublications: 46,
  UpdateBidResponse: 47, //TODO
  UpdateBidResponseDocument: 48, //TODO
  UpdateStatus: 49,
  UpdateBidSupplementalSupplier: 50,
  DownloadQuoteDocument: 51,
  UpdateQuoteBroadcastInfo: 52,
  UpdateQuoteDocument: 53,
  UpdateQuoteResponse: 54,
  UpdateQuoteInfo: 55,
  UpdateQuoteWire: 56,
  UpdateQuoteSupplementalSupplier: 57,
  UpdateSavedList: 58,
  DeleteSavedList: 59,
};

export const showMaintananceBanner = true;
export const MaintananceBannerText = `DemandStar will be inaccessible due to infrastructure upgrades between 11:00 PM EST July 2nd and 9:00 AM EST July 3rd. Contact ${commonEmail.support} with questions.`;
