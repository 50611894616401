import { atom, selector } from 'recoil';

import { Authorization, Permission } from 'src/types';

import { defaultNumericId } from 'src/shared/constants';

const defaultAuthState: Authorization = {
  accountId: defaultNumericId,
  accountLocked: false,
  accountLockedMinutes: defaultNumericId,
  accountPermissions: '',
  email: '',
  firstName: '',
  fullName: '',
  hasError: '',
  lastName: '',
  mainContact: false,
  memberBlackListed: false,
  memberId: defaultNumericId,
  membershipLevels: '',
  memberStatus: '',
  memberType: '',
  migrated: false,
  opsId: defaultNumericId,
  primaryMemberId: defaultNumericId,
  themetokenName: '',
  token: '',
  userName: '',
  userType: '',
  validatedResult: { status: true, firstName: '', lastName: '' },
};

export const authState = atom<Authorization>({
  key: 'authState',
  default: defaultAuthState,
});

export const authPermissionsState = selector<Permission[]>({
  key: 'authPermissionsState',
  get: ({ get }) => {
    const { accountPermissions = '' } = get(authState);
    return accountPermissions.replace(/ /g, '').split(',') as Permission[];
  },
});

export const authErrorState = atom<string>({ key: 'authErrorState', default: '' });
