import React from 'react';

import { buildRouteLink } from '../../../../utils/route';
import { Cell } from 'react-table';
import { NoOpFunction } from '../../../../types/shared';
import { RouteLink } from '../../controls/text/links/RouteLink';

/**
 * This type assumes a URL will be constructed as follows:
 * `${baseUrl}/${id}/${subUrl}?{queryString}`
 *   e.g. "suppliers/bids/6523465/details?actionId=87665"
 */
export type LinkTextCellProps<T> = {
  /** URL  */
  baseUrl?: string;
  /** URL sub path  */
  subUrl?: string;
  /** object field to render the unique id  */
  idField: string;
  /** object field to render the label  */
  labelField: string;
  /** optional additional click-handler */
  handleClick?: NoOpFunction;
  /** optional getter to construct itemId */
  getItemId?: (item: T) => string;
  /** optional non-link label text */
  renderLabelPrefix?: NoOpFunction;
};

/**
 * This cell renders a `react-roouter-dom` `<Link>` element
 * with the `to` (href) constructed from the baseUrl and id
 * and the text and title from the label.
 * The curried function allows custom params to be sent along with
 * the field values from `react-table`.
 * @param initialProps
 */
export function LinkTextCell<T = void>(initialProps: LinkTextCellProps<T>) {
  // `props` are typed as `any` from `react-table`
  return (props: Cell<any>) => {
    const {
      baseUrl = '',
      subUrl = '',
      handleClick,
      idField,
      labelField,
      getItemId,
      renderLabelPrefix,
    } = initialProps;
    const { row } = props;
    const { original } = row;
    const idValue = original[idField];
    const itemId = getItemId ? getItemId(original) : 'noItemId...';

    const route = buildRouteLink({ baseUrl, id: idValue, subUrl, itemId });

    const labelPrefix = renderLabelPrefix ? renderLabelPrefix(original) : '';
    const label = original[labelField] || '';
    const labelDisplay = labelPrefix + label;

    /**
     * Optional click handler for functionality beyond routing,
     * e.g. setting app state.
     * Passes the `original` row data object back to the callback.
     */
    const handleLinkClick = () => {
      if (handleClick) {
        handleClick(original);
      }
    };

    return (
      <RouteLink
        to={`${route}`}
        onClick={handleLinkClick}
        title={`${labelDisplay}`}
      >{`${labelDisplay}`}</RouteLink>
    );
  };
}
