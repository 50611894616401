import { BaseDSLinkProps } from '../link/DSLink';
import { DSLink } from '../link';
import { isValidEmail } from '../utils/validation';

export type DSEmailProps = Omit<BaseDSLinkProps, 'children'> & {
  /** display text */
  children: string;
  /** optional address, if the the display text does not match the email*/
  address?: string;
};

/** Wrap this around an email and it will turn it into an email link (if the email is valid).
 * Does nothing for invalid emails.
 * @example <DSEmail>foo@example.com</DSEmail>
 * @example <DSEmail address='foo@example.com'>Foo</DSEmail>
 */
export const DSEmail = (props: DSEmailProps) => {
  const { children, address, ...linkProps } = props;

  if (isValidEmail(address || children)) {
    return (
      <DSLink href={`mailto:${address || children}`} {...linkProps}>
        {children}
      </DSLink>
    );
  }

  return <>{children}</>;
};
