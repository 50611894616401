export type BuildRouteLinkParams = {
  /** URL  */
  baseUrl?: string;
  /** unique id  */
  id?: string | number;
  /** optional unique id for the sub path value  */
  itemId?: string;
  /** URL sub path  */
  subUrl?: string;
};

/**
 * Construct a URL with the included base and sub path params
 * @param {BuildRouteLinkParams} params
 * @returns {string} route
 */
export function buildRouteLink(params: BuildRouteLinkParams) {
  const { baseUrl, id, subUrl, itemId } = params;
  let route = `${baseUrl}`;
  if (id) {
    route = `${route}/${id}`;
  }
  if (subUrl) {
    route = `${route}/${subUrl}`;
  }
  if (itemId) {
    route = `${route}/${itemId}`;
  }
  return route;
}
