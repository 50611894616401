import styled from 'styled-components';

import { Colors, FontWeight } from '../../../shared/styles';
import { DeprecatedLink } from '../../../components/common/controls/text/BaseLink';
import { FontFamily } from '@demandstar/components/styles';

/**
 * Table cell link text
 */

const linkTextAttributes = `
 flex: 1 1;
 font-size: 1.125rem;
 line-height: 1.5rem;
`;

export const NoLinkText = styled.span`
  ${linkTextAttributes}
  padding-top: 0.4rem;
  font-family: ${FontFamily.Base};
  font-weight: ${FontWeight.bold};
  color: ${Colors.grayMediumDark};
`;

export const LinkText = styled(DeprecatedLink)`
  ${linkTextAttributes}
`;

export const HeaderIconWrapper = styled.span`
  display: inline-block;
  padding-left: 0.5rem;
  color: ${Colors.action};
`;
