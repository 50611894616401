import { BaseCheckbox } from './DSCheckboxStyles';
import { ChoiceLabel } from '../assets/ChoiceStyles';
import { Inactive } from '../types';
import { InvisibleInput } from '../assets/CommonStyles';
import { onEnter } from '../utils';

// TODO: Make more accessible.
// TODO: Create DSCheckboxGroup to be used in react-hook-form forms.

export interface DSCheckboxProps extends Inactive {
  checked: boolean;
  indeterminate?: boolean;
  label: string;
  onClick: () => void;
  id?: string;
  name: string;
}

/**
 * @prop {boolean} checked - the checkbox is true
 * @prop {string} label - the description of the checkbox
 * @prop {() => void} onClick - updates the value
 * @prop {boolean=} inactive - prevents onClick from firing
 * @prop {boolean=} indeterminate - displays a - to indicate neither true nor false
 */
export const DSCheckbox = (props: DSCheckboxProps): JSX.Element => {
  const { checked, inactive, id, name, onClick, label, indeterminate } = props;

  function click() {
    if (!inactive) {
      onClick();
    }
  }

  return (
    <ChoiceLabel inactive={inactive}>
      {label}
      <InvisibleInput
        readOnly={true}
        type='checkbox'
        onClick={click}
        id={id}
        name={name}
        checked={checked}
        onKeyDown={onEnter(click)}
      />
      <BaseCheckbox checked={checked} inactive={inactive} indeterminate={indeterminate} />
    </ChoiceLabel>
  );
};
