import React, { ReactNode } from 'react';

import { DSPill } from '../pill';
import { Status } from '../constants';

import { DataList } from './DSRecordItemStyles';

export enum DSRecordItemStatus {
  Active = 'Active',
  Awarded = 'Awarded',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Completed = 'Completed',
  Draft = 'Draft',
  Overdue = 'Overdue',
  RecommendationOfAward = 'Recommendation of Award',
  UnderEvaluation = 'Under Evaluation',
}

interface DSRecordItemProps {
  data: { title: string; value: string | number | boolean }[];
  status: DSRecordItemStatus;
  subtitle?: string;
  title: ReactNode;
  watch?: ReactNode;
}

/**
 * @description Styles a single record data, like a bid or contract.
 * @example <DSRecordItem data={[]} title='' status=''/>
 * @param {array}     data - Array of data in title/value pairs.
 * @param {enum}      status - Active | Awarded | Cancelled | Closed | Completed | Draft |
 *                             Overdue | RecommendationOfAward | UnderEvaluation
 * @param {string}    subtitle - Optional value for more information
 * @param {ReactNode} title - Name of the Record, can be a Link
 * @param {ReactNode} watch - optional JSX.Element to show icon
 */

export const DSRecordItem = (props: DSRecordItemProps) => {
  const { data, status, subtitle, title, watch } = props;

  let pillStatus: Status | undefined;

  switch (status.toLowerCase()) {
    case 'active':
    case 'awarded':
      pillStatus = Status.Success;
      break;
    case 'cancelled':
      pillStatus = Status.Error;
      break;
    case 'draft':
      pillStatus = Status.Warning;
      break;
  }

  return (
    <div>
      <div>
        <h5>{title}</h5>
        <DSPill label={status} showIcon={false} status={pillStatus} />
        {watch && <span data-testid='rc-watch'>{watch}</span>}
      </div>
      <br />
      {subtitle && <div data-testid='rc-subtitle'>{subtitle}</div>}
      <DataList>
        {data.map((item, index) => (
          <li key={index}>
            <strong>{item.title}:</strong> {item.value}
          </li>
        ))}
      </DataList>
    </div>
  );
};
