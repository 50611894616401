import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { memo } from 'react';

import * as actionCreators from '../../store/actions';
import { DemandStarSupportEmail } from 'src/utils/texts/common/emails';
import { limitedbiddetailsTypes } from '../../types/limited';
import LimitedFooter from '../limited/footer';
import LimitedHeader from '../limited/header';

interface BidsDetailsProps extends PropsFromRedux {
  limited: limitedbiddetailsTypes;
  history: any;
  match: any;
  bidssummary: any;
  getLimitedAgencySummary: any;
  getLimitedAgencyDocuments: any;
  getLimitedAgencyLegal: any;
  getLimitedAgencyAwards: any;
  getLimitedAgencyPlanholders: any;
  getLimitedAgencyCommodities: any;
  getLimitedAgencySelfDeclarations: any;
  setLimitedDetails: any;
}

//TODO: Fix spelling of `maintenance` throughout.

function MaintananceSplash(props: BidsDetailsProps) {
  return (
    <>
      <LimitedHeader {...props} pagefor='maintanance' />
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-11'>
              <div className='colWrapper'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='maintananceText'>
                      DemandStar will be inaccessible due to infrastructure upgrades between{' '}
                      <span className='text-red200'>11:00 PM EST July 2nd</span> and{' '}
                      <span className='text-red200'>9:00 AM EST July 3rd</span>. Contact
                      {DemandStarSupportEmail} with questions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LimitedFooter />
    </>
  );
}

const connector = connect(
  (state: any) => ({
    limited: state.limited,
    shared: state.shared,
    auth: state.auth,
    bidssummary: state.bidssummary,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(MaintananceSplash));
