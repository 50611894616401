import dayjs from 'dayjs';

import { DSPill } from '@demandstar/components/pill';
import { H3 } from '@demandstar/components/styles';
import { Status } from '@demandstar/components/constants';

import {
  SubscriptionStatusCopy,
  SubscriptionStatusLabels,
} from '../../../utils/texts/supplier/subscription';
import { AutoRenewal } from './autorenewal/AutoRenewal';
import { DateTimeUnits } from '../../../shared/constants';
import { displayDate } from '../../../utils/helpers';
import { ProfileInfoState } from '../../../store/recoil/accountInfoState';
import { StatusRow } from './styles';
import { TollFreeNumber } from '../../../utils/texts/common';
import { UserType } from 'src/types/accountinfo';

/**
 * @description shows the subscription status in the form of a pill, used by SubscriptionStatus component
 * @param expiryDate - dayjs.Dayjs
 * @returns JSX.Element, the status pill
 *
 * @example <ExpiryPill expiryDate={dayjs()} />
 */
const ExpiryPill = ({ expiryDate }: { expiryDate: dayjs.Dayjs }) => {
  const currentDateTimestamp = dayjs();
  const dueDateTimestamp = expiryDate;

  const timeRemaining = dueDateTimestamp.diff(currentDateTimestamp);

  let status = Status.Success;
  let message: string = SubscriptionStatusLabels.Active;

  if (timeRemaining <= 0) {
    status = Status.Error;
    message = SubscriptionStatusLabels.Expired;
  } else if (timeRemaining <= DateTimeUnits.month) {
    status = Status.Warning;
    message = `${SubscriptionStatusLabels.Expiring} ${displayDate(dueDateTimestamp)}`;
  }

  return <DSPill label={message} status={status} />;
};

interface SubscriptionStatusProps {
  /** contains card-on-file details */
  profileInfo: ProfileInfoState;
  /** date subscription is set to expire */
  expiryDate: dayjs.Dayjs;
  /** the user type */
  userType: UserType;
  /** whether logged in account is the member's main contact */
  isMainContact: boolean;
}

/**
 * @description displays the subscription status details
 * @returns JSX.Element
 *
 * @example <SubscriptionStatus
 *  profileInfo={{
 *    card:{
 *      cardType:'Visa',
 *      cardLastFour: 1111,
 *      isAutoRenewalOn:{true},
 *    },
 *    result: 'SUCCESS',
 *  }}
 *  expiryDate={new Date()}
 *  userType={UserType.PaidSupplier}
 *  isMainContact={true}
 * />
 */
export const SubscriptionStatus = ({
  profileInfo,
  expiryDate,
  userType,
  isMainContact,
}: SubscriptionStatusProps) => {
  const { card, result = '' } = profileInfo || {};
  const { isAutoRenewalOn } = card || {};

  const hasProfile = result === 'SUCCESS';

  return (
    <>
      <H3>Subscription Status</H3>
      <div>
        {expiryDate && (
          <>
            <StatusRow>
              <div>{SubscriptionStatusCopy.Subscription}</div>
              <ExpiryPill expiryDate={expiryDate}></ExpiryPill>
            </StatusRow>
            <StatusRow>
              <div>{SubscriptionStatusCopy.ExpirationDate}</div>
              <div>{displayDate(expiryDate)}</div>
            </StatusRow>
          </>
        )}
        {userType === UserType.PaidSupplier && hasProfile && isMainContact && (
          <StatusRow>
            <div>{SubscriptionStatusCopy.Autorenew}</div>
            <AutoRenewal isAutoRenewalOn={isAutoRenewalOn} />
          </StatusRow>
        )}
        <StatusRow>
          {SubscriptionStatusCopy.ModifyOrCancel}
          {TollFreeNumber}
        </StatusRow>
      </div>
    </>
  );
};
