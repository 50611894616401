import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilStateLoadable, useSetRecoilState } from 'recoil';

import { DSCheckbox, DSPaginate, FlexContainer } from '@demandstar/components/index';
import { DSButton } from '@demandstar/components/button';
import { DSLink } from '@demandstar/components/link';
import { DSTextInput } from '@demandstar/components/inputs';
// TODO: Eliminate the need for these style wrappers
import { LibraryStyleWrapper } from '@demandstar/components/inputs/text-input';

import {
  allProductsState,
  initialCountyProduct,
  initialNationalProduct,
  selectedProductsState,
} from '../../../store/recoil/productState';
import { getAgencyCounts, isSubscribed } from '../helpers';
import { ProductApiResponse, ProductType, SubscriptionProducts } from '../../../types/products';

import { AddStateButtonWrapper } from '../styles';
import { agenciesByProductState } from '../../../store/recoil/agencyState';
import { compareObjectsByKey } from '../../../utils/sort';

import { pluralize } from '../../../utils/helpers';
import { ProductList } from './styles';
import { useAccountInfo } from '../../../shared/hooks/useAccountInfo';
import { useCart } from '../cart';

interface SelectCountyProps {
  /** if the selected state has bundles */
  hasBundle: boolean;
  /** currently selected state */
  parentState: ProductApiResponse;
}

/**
 * @description a list of checkboxes allowing selection of county products
 * @returns JSX.Element
 *
 * @example <SelectCounty hasBundle={true} parentState={{
 *  productId: 10,
 *  productName: 'Florida',
 *  productType: ProductType.State,
 *  price: 1050,
 * }} />
 */
export const SelectCounty = (props: SelectCountyProps) => {
  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const { hasBundle, parentState } = props;
  const { removeProduct } = useCart();

  const setAgenciesByProduct = useSetRecoilState<ProductApiResponse[]>(agenciesByProductState);

  const [selectedProducts, setSelectedProducts] =
    useRecoilState<SubscriptionProducts>(selectedProductsState);

  const {
    accountInfo: { products: subscribedProducts },
  } = useAccountInfo();

  const [allProductsLoaded, setAllProductsLoaded] = useState([initialNationalProduct]);
  const [countiesList, setCountiesList] = useState([initialCountyProduct]);
  const [countySearch, setCountySearch] = useState('');
  const [filteredCounties, setFilteredCounties] = useState(countiesList);
  const [parentPrice, setParentPrice] = useState(0);
  const [selectedCounties, setSelectedCounties] = useState([0]);
  const [selectedStates, setSelectedStates] = useState([0]);

  const isProductSubscribed = useCallback(
    (product: ProductApiResponse): boolean => {
      return isSubscribed(product, subscribedProducts);
    },
    [subscribedProducts],
  );

  const getAgenciesForModal = (productId: number) => {
    const agenciesByProduct = allProductsLoaded.filter(
      product => product.productType === ProductType.FreeAgency && product.parentId === productId,
    );
    setAgenciesByProduct(agenciesByProduct.sort(compareObjectsByKey('productName')));
  };

  const handleChecked = (name: string, value: boolean) => {
    const checkedCounties: ProductApiResponse[] = selectedProducts.county || [];
    const checkingCounty: ProductApiResponse[] = countiesList.filter(
      county => county.productName === name,
    );
    // add county to the array
    if (value) {
      setSelectedProducts({
        ...selectedProducts,
        county: checkingCounty.concat(checkedCounties),
      });
    }
    // remove county from the array
    else {
      removeProduct(checkingCounty[0]);
    }
  };

  const handleSelectStateClick = () => {
    setSelectedProducts({
      ...selectedProducts,
      state: selectedProducts.state?.concat(parentState),
    });
  };

  const searchCounty = (text: string) => {
    setCountySearch(text);
    const filteredCountiesList = countiesList.filter((county: ProductApiResponse) =>
      county.productName.toLowerCase().includes(text.toLowerCase()),
    );
    setFilteredCounties(filteredCountiesList);
  };

  const subscribeToStateButtonText = `Subscribe to ${parentState.productName} for $${parentPrice} / year`;

  useEffect(() => {
    let needCounties = true;
    function getCountiesByState() {
      /* istanbul ignore else */
      if (allProducts.state === 'hasValue') {
        setAllProductsLoaded(allProducts.contents);
        const countiesByState: ProductApiResponse[] = allProducts.contents.filter(
          product =>
            product.productType === ProductType.County &&
            product.parentId === parentState.productId,
        );
        countiesByState.sort(compareObjectsByKey('productName'));

        setParentPrice(countiesByState[0].parentPrice || 0);
        setCountiesList(countiesByState);
        setFilteredCounties(countiesByState);
        setCountySearch('');
      }
    }

    /* istanbul ignore else */
    if (
      needCounties &&
      allProducts.state === 'hasValue' &&
      countiesList[0].parentName !== parentState.productName
    ) {
      getCountiesByState();
    }

    function getSelectedProducts(products: ProductApiResponse[], type: ProductType) {
      const temp = products.map(item => item.productId);
      switch (type) {
        case ProductType.State:
          setSelectedStates(temp);
          break;
        case ProductType.County:
          if (temp.length !== selectedCounties.length || selectedCounties[0] === 0)
            setSelectedCounties(temp);
          break;
      }
    }

    /* istanbul ignore else */
    if (needCounties && selectedProducts.county)
      getSelectedProducts(selectedProducts.county, ProductType.County);
    /* istanbul ignore else */
    if (needCounties && selectedProducts.state)
      getSelectedProducts(selectedProducts.state, ProductType.State);

    return () => {
      needCounties = false;
    };
  }, [allProducts, countiesList, parentState, selectedProducts, selectedCounties]);

  return (
    <div>
      {hasBundle && !isProductSubscribed(parentState) ? (
        <>
          <h5>Build your own Package</h5>
          <p className='no-top-padding'>
            If none of our packages match your needs, build your own package below by selecting the
            counties where you'd like to do business.
          </p>
        </>
      ) : (
        <>
          <h5>Choose your counties in {parentState.productName}</h5>
          {countiesList.length > 1 ? (
            <p className='no-top-padding'>
              There are currently {countiesList.length} {parentState.productName} counties with
              agencies publishing bids on DemandStar. Please choose which counties you'd like to
              subscribed to. You may also subscribe to all counties in {parentState.productName} and
              save!
            </p>
          ) : (
            <p className='no-top-padding'>
              There is currently {countiesList.length} {parentState.productName} county with
              agencies publishing bids on DemandStar. Please select the county to subscribe.
            </p>
          )}

          {!selectedProducts.state?.includes(parentState) && !isProductSubscribed(parentState) && (
            <AddStateButtonWrapper justifyContent='center'>
              <DSButton icon={'mdi-plus'} onClick={handleSelectStateClick}>
                {subscribeToStateButtonText}
              </DSButton>
              <br />
            </AddStateButtonWrapper>
          )}
        </>
      )}
      {/**
       * TOREFACTOR: When no DeprecatedTextInputs remain in registration process,
       * eliminate LibraryStyleWrapper & get rid of ".regWrapper input" SCSS
       */}
      <LibraryStyleWrapper>
        <DSTextInput
          dataTestId='county.search'
          onChange={searchCounty}
          label='Search for Counties'
          name='Search'
          placeholder='Search for Counties'
          type='text'
          value={countySearch}
        />
      </LibraryStyleWrapper>
      <ProductList>
        <DSPaginate data={filteredCounties}>
          {data =>
            data.map((county: ProductApiResponse) => {
              const isChecked =
                (selectedProducts?.national ?? 0) > 0 ||
                selectedCounties.includes(county.productId) ||
                (county.parentId && selectedStates.includes(county.parentId)) ||
                isProductSubscribed(county);
              return (
                <li key={county.productId}>
                  <FlexContainer justifyContent='space-between'>
                    <div>
                      <DSCheckbox
                        checked={isChecked}
                        id={county.productName}
                        onClick={() => {
                          handleChecked(county.productName, !isChecked);
                        }}
                        name={county.productName}
                        label={county.productName}
                        inactive={isProductSubscribed(county)}
                      />
                    </div>
                    <div>
                      <DSLink onClick={() => getAgenciesForModal(county.productId)}>
                        {`View ${getAgencyCounts(
                          allProductsLoaded,
                          county.productId,
                          ProductType.County,
                        )}
                        ${pluralize(
                          getAgencyCounts(allProductsLoaded, county.productId, ProductType.County),
                          'Agency',
                          'Agencies',
                        )}
                        `}
                      </DSLink>
                    </div>
                    <div>{`$${county.price}/year`}</div>
                  </FlexContainer>
                </li>
              );
            })
          }
        </DSPaginate>
      </ProductList>
    </div>
  );
};
