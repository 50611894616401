export * from '@demandstar/components/utils/validation';

/** Phone
 * TODO: Do we want to do no validation for what a user enters,
 * but have validation for the stripped numbers.
 * Must be 7+ digits and only digits?
 * */
export const phoneVal = /^([(]?[+]?1[)]?)?[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;

/** Integer -
 * must be either 0 or an integer that starts with 1-9
 * */
export const integerVal = /^(0|[1-9]\d*)$/;
