import { Loadable } from 'recoil';

import { ProductApiResponse, ProductType } from 'src/types/products';
import { compareObjectsByKey } from 'src/utils';
import { Product } from 'src/types/subscriptions';
import { removeDuplicates } from 'src/utils/helpers';

/**
 * @description turns a provided array of assorted types into ProductApiResponse[]
 * @param allProducts Loadable<ProductApiResponse[]> - the list of all available products
 * @param products (Product | number)[] - an array of Products or product ids
 * @param isRegistration boolean - if this is for registration
 * @returns ProductApiResponse[] representing objects provided in `products` param
 */
export function normalizeFreeAgencies(
  allProducts: Loadable<ProductApiResponse[]>,
  products: (Product | number)[],
  isRegistration = false,
) {
  if (allProducts.state === 'hasValue') {
    const normalized = products.map(p => {
      if (typeof p === 'number') {
        return allProducts.contents.filter(ap => ap.productId === p)[0];
      } else {
        return allProducts.contents.filter(ap => ap.productId === p.productId)[0];
      }
    });

    return normalized.filter(p => isRegistration || p.productType === ProductType.FreeAgency);
  }

  return [];
}

/**
 * @description handles a click on a checkbox or radio button to add a free agency to selection
 * @param allProducts (Loadable<ProductApiResponse[]>) all available products
 * @param selectedFreeAgencies ProductApiResponse[] - free agencies already selected
 * @param existingFreeAgencies ProductApiResponse[] - free agencies already subscribed
 * @param agencyId (number) the product id of the agency to select
 * @param allowMultiple - boolean - whether the user can select multiple free agencies
 * @returns {
 *  agency: ProductApiResponse,
 *  agencyInfo: agencyInfo,
 *  newAgenciesExist: distinctAgencies.length + removedDistinctAgencies.length > 0,
 *  selectedAgencies: localfreeAgencies,
 * }
 *
 * @example selectFreeAgency(
 *  true,
 *  1,
 *  'agency',
 *  {
 *    productId: 1,
 *    productName: 'Sample Free Agency',
 *    productType: ProductType.FreeAgency,
 *  },
 *  'radio',
 * )
 */
export function selectFreeAgency(
  allProducts: Loadable<ProductApiResponse[]>,
  selectedFreeAgencies: ProductApiResponse[],
  existingFreeAgencies: ProductApiResponse[],
  agencyId: number,
  allowMultiple = false,
) {
  if (allProducts.state === 'hasValue') {
    let localfreeAgencies: ProductApiResponse[];
    const status = selectedFreeAgencies.find(ag => ag.productId === agencyId) ? true : false;

    const agency = allProducts.contents.filter(p => p.productId === agencyId)[0];
    if (allowMultiple) {
      localfreeAgencies = status
        ? [...selectedFreeAgencies.filter(fa => fa.productId !== agencyId)]
        : [...selectedFreeAgencies, agency];
    } else {
      localfreeAgencies = [agency];
    }

    const agencyCounty = allProducts.contents.find(
      product => product.productId === agency.parentId,
    );
    if (agencyCounty) {
      let agencyState = allProducts.contents.find(
        product => product.productId === agencyCounty?.parentId,
      );
      if (agencyCounty && agencyCounty.parentId === 0) {
        agencyState = { ...agencyCounty };
      }

      const agencyInfo = {
        agencyName: agency.productName,
        agencyState: agencyState ? agencyState.productName : '',
        agencyCounty: agencyCounty ? agencyCounty.productName : '',
      };

      const distinctAgencies = localfreeAgencies.filter(product =>
        existingFreeAgencies.find(x => x.productId === product.productId) ? false : true,
      );
      const removedDistinctAgencies = existingFreeAgencies.filter(product =>
        localfreeAgencies.find(x => x.productId === product.productId) ? false : true,
      );

      return {
        agency: status ? agency : localfreeAgencies[0],
        agencyInfo: agencyInfo,
        newAgenciesExist: distinctAgencies?.length > 0 || removedDistinctAgencies?.length > 0,
        selectedAgencies: localfreeAgencies,
      };
    }
  }
}

// TOREFACTOR: This seems fairly inefficient, iterates over the entire list of products potentially over 10 times
/**
 * @description searches provided products array based on provided filters
 * @param products all products to search
 * @param stateId the ID of the selected state product, or 0
 * @param countyId the ID of the selected county product, or 0
 * @param search a freeform text search for product name
 * @returns ProductApiResponse[] containing free agency products that match filters
 */
export const searchAgencies = (
  products: ProductApiResponse[],
  stateId = 0,
  countyId = 0,
  search = '',
) => {
  let agenciesList = products.filter(product => product.productType === ProductType.FreeAgency);

  if (stateId) {
    const stateProduct = products.find(product => product.productId === stateId);

    const countyIds = products
      .filter(
        product =>
          product.productType === ProductType.County &&
          product.parentId === stateId &&
          product.productGroupId === stateProduct?.productGroupId,
      )
      .map(product => product.productId);

    const countyGroupIds = products
      .filter(
        product =>
          product.productType === ProductType.County &&
          product.parentId === stateId &&
          product.productGroupId === stateProduct?.productGroupId,
      )
      .map(product => product.productGroupId);

    agenciesList = products.filter(
      agency =>
        agency.productType === ProductType.FreeAgency &&
        ((agency.parentType === ProductType.State &&
          agency.productGroupId === stateProduct?.productGroupId) ||
          (agency.parentType === ProductType.County &&
            agency?.parentId &&
            countyIds.includes(agency.parentId) &&
            countyGroupIds.includes(agency.productGroupId))),
    );

    if (countyId) {
      const countyProduct = products.find(product => product.productId === countyId);
      agenciesList = agenciesList.filter(
        agency =>
          agency.productType === ProductType.FreeAgency &&
          agency.parentType === ProductType.County &&
          agency.parentId === countyId &&
          agency.productGroupId === countyProduct?.productGroupId,
      );
    }
  }

  agenciesList = removeDuplicates(agenciesList, 'productId');

  if (search) {
    agenciesList = agenciesList.filter(product =>
      product.productName.toLowerCase().includes(search.toLowerCase()),
    );
  }

  return agenciesList.sort(compareObjectsByKey('productName'));
};
