import { memo, useEffect } from 'react';
import { useRecoilStateLoadable } from 'recoil';

import CardCount from '../../common/dashboard/cardcount';
import navHistory from '../../../utils/history';
import ReactGA from 'react-ga';
import UpsellCard from './UpsellCard';

import { formatNumber, pluralize } from '../../../utils/helpers';

import { allProductsState } from '../../../store/recoil/productState';
import { authTypes } from '../../../types/auth';
import { memberTypes } from '../../../utils/constants';
import { ProductType } from 'src/types/products';
import { sharedTypes } from '../../../types/shared';
import { UpsellBasic } from './UpsellBasic';

interface Product {
  productId: number;
  productType: string;
  productName: string;
  shortName: string;
}

interface PropsTypes {
  shared: sharedTypes;
  auth: authTypes;
  memberinfo: any;
  accountinfo: {
    accountinfo: { products: Product[] };
    membersinfo: {
      county: { name: string };
      state: { name: string };
    };
    mtn: string;
  };
  trackAmplitudeUserActions?: any;
  setSubscriptionDetails?: any;
}

interface CardData {
  title: string;
  count: number | string;
  classNames: string;
  count0Text: string;
}

function cardClass(memberType: string, cardIndex: number): string {
  let commonClasses = ['pr-lg-1', 'col-sm-6'];
  const isBasicSupplier = memberType === memberTypes.basicSupplier;
  if (isBasicSupplier && (cardIndex === 0 || cardIndex === 1)) {
    commonClasses = commonClasses.concat(['col-12', 'mb-4', 'col-lg-3', 'mb-lg-0']);
  } else if (cardIndex === 0 || cardIndex === 1) {
    commonClasses = commonClasses.concat(['ncol-12', 'mb-4', 'col-md-6']);
  } else if (isBasicSupplier && cardIndex === 2) {
    commonClasses = commonClasses.concat(['mb-sm-0', 'col-12', 'mb-4', 'col-lg-3', 'mb-lg-0']);
  } else if (cardIndex === 2) {
    commonClasses = commonClasses.concat(['mb-sm-0', 'col-12', 'mb-4', 'mb-lg-0', 'col-md-6']);
  } else if (isBasicSupplier && cardIndex === 3) {
    commonClasses = commonClasses.concat(['col-12', 'col-lg-3']);
  } else {
    commonClasses = commonClasses.concat(['col-12', 'mb-4', 'mb-lg-0', 'col-md-6']);
  }
  return commonClasses.join(' ');
}

function RightsideBottom(props: PropsTypes) {
  const fallbackAccountInfo: { products: any[] } = { products: [] };
  const {
    shared,
    auth,
    trackAmplitudeUserActions,
    memberinfo,
    accountinfo: { accountinfo: realAccountInfo = fallbackAccountInfo },
    setSubscriptionDetails,
  } = props;
  const {
    dashboardnetworkData,
    siblingProducts = [],
    relatedBids = [],
    sibilingParentCounty = '',
  } = shared;
  const { mtn: memberTypeName } = memberinfo;
  const { mt: memberType, mi: memberId } = auth;
  const { products: existingProducts = [] } = realAccountInfo;
  const {
    nearByGovernments = 0,
    totalBids = 0,
    governments = 0,
    suppliers = 0,
  } = dashboardnetworkData;
  const isFreeAgency = memberTypeName === 'Free Agency';
  const isBasicSupplier = memberType === memberTypes.basicSupplier;

  const upsellCountyProducts: Product[] = siblingProducts.filter(
    prod =>
      !existingProducts.map(ep => ep.productId).includes(prod.productId) &&
      prod.productType !== ProductType.State,
  );

  const upSellStateProducts: Product[] = siblingProducts.filter(
    prod =>
      !existingProducts.map(ep => ep.productId).includes(prod.productId) &&
      prod.productType === ProductType.State,
  );

  const identifier =
    relatedBids.length > 4
      ? 'upsell-bids-button'
      : upsellCountyProducts.length >= 2
      ? 'upsell-county-notifications-button'
      : 'upsell-state-notifications-button';

  const cardData: CardData[] = [
    {
      title: 'New Governments Past 12 Months',
      count: formatNumber(governments),
      classNames: 'default',
      count0Text: 'No New Governments Past 12 Months',
    },
    {
      title: 'New Governments Near Me',
      count: formatNumber(nearByGovernments),
      classNames: 'default',
      count0Text: 'No New Governments Near Me',
    },
    {
      title: 'Total Active Bids',
      count: formatNumber(totalBids),
      classNames: 'default',
      count0Text: '',
    },
    {
      title: 'New Suppliers Past 30 Days',
      count: formatNumber(suppliers),
      classNames: 'default',
      count0Text: 'No New Suppliers Past 30 Days',
    },
  ];

  const bidMessage = (
    <div>
      <h6>You missed out on</h6>
      <span className='upsell-big'>{relatedBids.length}</span>
      <h6>
        bid {pluralize(relatedBids.length, 'notification', 'notifications')} in the last 12 months
      </h6>
    </div>
  );

  const agencyCountyMessage = (
    <div>
      <h6>You could be getting notifications from</h6>
      <span className='upsell-big'>{upsellCountyProducts.length}</span>
      <h6>other {pluralize(upsellCountyProducts.length, 'agency', 'agencies')} in your county</h6>
    </div>
  );

  const agencyStateMessage = (
    <div>
      <h6>You could be getting notifications from</h6>
      <span className='upsell-big'>{upSellStateProducts.length}</span>
      <h6>other {pluralize(upSellStateProducts.length, 'agency', 'agencies')} in your state</h6>
    </div>
  );

  const upsellCardData: {
    message: JSX.Element;
    linkMessage: string;
    isVisible: boolean;
    identifier: string;
    amplitudeMessage: string;
    productRecommended: string;
    productIds?: string;
    productLength: number;
  }[] = [
    {
      message: agencyCountyMessage,
      linkMessage: 'Get more notifications',
      isVisible: upsellCountyProducts.length >= 2,
      identifier: 'upsell-county-notifications-button',
      amplitudeMessage: 'Bid notifications in their county, Other agencies in their county',
      productRecommended: upsellCountyProducts
        .map((product: Product) => product.shortName)
        .join(', '),
      productIds: upsellCountyProducts.map((product: Product) => product.productId).join(', '),
      productLength: upsellCountyProducts.length,
    },
    {
      message: bidMessage,
      linkMessage: 'Get more bid opportunities',
      isVisible: relatedBids.length > 4,
      identifier: 'upsell-bids-button',
      amplitudeMessage: 'Bid notifications in their county, Other agencies in their county',
      productRecommended: relatedBids.map((item: any) => item.bidName).join(', '),
      productIds: upsellCountyProducts.map((product: Product) => product.productId).join(', '),
      productLength: relatedBids.length,
    },
    {
      message: agencyStateMessage,
      linkMessage: 'Get more notifications',
      isVisible: upSellStateProducts.length > 0 && upsellCountyProducts.length < 2,
      identifier: 'upsell-state-notifications-button',
      amplitudeMessage: 'Bid notifications in their state, Other agencies in their state',
      productRecommended: upSellStateProducts
        .map((product: Product) => product.shortName)
        .join(', '),
      productIds: upSellStateProducts.map((product: Product) => product.productId).join(', '),
      productLength: upSellStateProducts.length,
    },
  ]
    .filter(c => c.isVisible)
    .filter(c => c.identifier === identifier);

  const card = !!upsellCardData.find(c => c.identifier === identifier);

  useEffect(() => {
    // don't send this message if there isn't a visible card
    if (!(memberId && card)) return;

    ReactGA.event({
      category: 'Upsell',
      action: `Viewed Dashboard Message ${identifier}`,
      dimension1: `${memberId}`,
      dimension2: identifier,
    });
  }, [memberId, identifier, card]);

  const cards = (
    <div className='row'>
      {cardData.map((data, idx) => {
        const { title, count, classNames, count0Text } = data;
        return (
          <div className={cardClass(memberType, idx)} key={idx}>
            <CardCount
              title={title}
              count={count}
              classNames={classNames}
              count0Text={count0Text}
            />
          </div>
        );
      })}
    </div>
  );

  const UpsellCards = () => {
    const [allProducts] = useRecoilStateLoadable(allProductsState);

    const setAgencyPrepopulateData = () => {
      if (allProducts.state === 'hasValue') {
        const agency = allProducts?.contents?.find(
          product => product.productId === props.accountinfo.accountinfo.products[0].productId,
        );
        const county = allProducts?.contents?.find(
          product => product.productId === agency?.parentId,
        );
        const stateId = county?.parentId || 0;
        if (county && (upsellCountyProducts.length >= 2 || relatedBids.length > 4)) {
          const countyGroup = `${county.productGroupId}_${county.parentId}_${county.productId}`;
          setSubscriptionDetails({
            subscribedCounties: [county.productId],
            subscribedCountiesGroup: [countyGroup],
          });
          /* setPrepopulatedCounties({
            state: stateId,
            counties: [county.productId],
          }); */
        } else {
          setSubscriptionDetails({ subscribedStates: [stateId] });
        }
      }
    };

    const handleClick = (amplitudeMessage: string, productLength: number, productIds?: string) => {
      ReactGA.event({
        category: 'Upsell',
        action: `Clicked Dashboard Message ${identifier}`,
        dimension1: `${memberId}`,
        dimension2: identifier,
      });
      if (isFreeAgency) {
        setAgencyPrepopulateData();
      }
      trackAmplitudeUserActions({
        title: 'upgrade - click fomobox',
        desc: 'Click on FOMObox on the dashboard',
        data: {
          Message: amplitudeMessage,
          'Product Recommendation': sibilingParentCounty,
          'FOMObox Value': productLength,
        },

        isStore: true,
      });
      navHistory.push(`/subscription/renewal${productIds ? `?pid=${productIds}` : ''}`);
    };

    return (
      <div id='upsell-card'>
        {upsellCardData.map(
          ({ message, linkMessage, amplitudeMessage, productLength, productIds }, idx) => {
            return (
              <UpsellCard
                key={idx}
                message={message}
                linkMessage={linkMessage}
                handleClick={() => handleClick(amplitudeMessage, productLength, productIds)}
                isLast={idx === upsellCardData.length - 1}
              />
            );
          },
        )}
      </div>
    );
  };

  const shouldRender =
    memberTypeName &&
    memberId &&
    ((isFreeAgency && upsellCardData.length) || !isFreeAgency || isBasicSupplier);
  return shouldRender ? (
    <div className='row'>
      <div className='col-md-12'>
        <h3 className='mt-5'>DemandStar Network</h3>
        {isFreeAgency ? (
          <UpsellCards />
        ) : isBasicSupplier ? (
          <UpsellBasic accountInfo={props.accountinfo} />
        ) : (
          cards
        )}
      </div>
    </div>
  ) : null;
}

export default memo(RightsideBottom);
