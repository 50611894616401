import { useRecoilState } from 'recoil';

import { DSAlert } from '@demandstar/components/alert';
import { Status } from '@demandstar/components/constants';

import { initialStateProduct, selectedProductsState } from '../../../store/recoil/productState';
import {
  NationalProductId,
  ProductApiResponse,
  SubscriptionProducts,
} from '../../../types/products';

import { CartAlertType } from 'src/types/registration';
import { compareObjectsByKey } from '../../../utils/sort';

interface RegistrationInfoAlertProps {
  /** total number of agencies already selected */
  agenciesInCart?: number;
  /** total number of agencies if upgrade is performed */
  agenciesWithUpgrade?: number;
  /** the product being upsold, if applicable */
  product?: ProductApiResponse;
  /** the type of alert to display */
  type: CartAlertType;
}

/**
 * @description displays an alert for registration
 * @returns JSX.Element
 *
 * @example <RegistrationInfoAlert
 * agenciesInCart={3}
 * agenciesWithUpgrade={12}
 * product={[{
 *  productId: 2,
 *  productType: ProductType.State,
 *  productName: 'Upsell Product',
 *  price: 100,
 * }}
 * type={CartAlertType.StateUpgrade}
 * />
 */
export const RegistrationInfoAlert = (props: RegistrationInfoAlertProps) => {
  const { agenciesInCart, agenciesWithUpgrade, product = initialStateProduct, type } = props;

  const [selectedProducts, setSelectedProducts] =
    useRecoilState<SubscriptionProducts>(selectedProductsState);

  let handleClick: (() => void) | undefined = undefined;
  let header = '';
  let linkText = '';
  let message = '';
  let moreAgencies = 0;

  if (agenciesWithUpgrade && agenciesInCart) {
    moreAgencies = agenciesWithUpgrade - agenciesInCart;
  }

  switch (type) {
    case CartAlertType.County:
      header = 'Where did the county subscriptions go?';
      message =
        'Because you added a state subscription, we’ve hidden the counties in that state. They’re included in your state subscription.';
      break;
    case CartAlertType.National:
      header = 'Where did the other subscriptions go?';
      message =
        'Because you added a national subscription, we’ve hidden all the others. They’re included in your national subscription.';
      break;
    case CartAlertType.NationalUpgrade:
      handleClick = () => {
        setSelectedProducts({ ...selectedProducts, national: NationalProductId.UnitedStates });
      };
      let messageEnding = `get notifications from ${moreAgencies} more agencies.`;
      switch (moreAgencies) {
        case 0:
          messageEnding = 'save';
          break;
        case 1:
          messageEnding = `get notifications from ${moreAgencies} more agency.`;
          break;
      }
      header = 'Upgrade to a national subscription';
      linkText = `Subscribe to nationwide for $${product.price} / year`;
      message = `Upgrade to a national subscription for $${product.price} a year, and ${messageEnding}`;
      break;
    case CartAlertType.StateUpgrade:
      // "...an Utah subscription" is not correct
      const stateStartsWithVowel = ['A', 'E', 'I', 'O'].includes(product.productName[0]);
      handleClick = () => {
        setSelectedProducts({
          ...selectedProducts,
          state: selectedProducts.state?.concat(product).sort(compareObjectsByKey('productName')),
        });
      };
      header = `Upgrade to ${stateStartsWithVowel ? 'an' : 'a'} ${
        product.productName
      } subscription`;
      linkText = `Subscribe to ${product.productName} for $${product.price} / year`;
      message = `Get all notifications from all governments in ${product.productName} and save by upgrading to a state subscription.`;
      break;
  }

  return (
    <DSAlert
      handleClick={handleClick}
      header={header}
      linkText={linkText}
      message={message}
      type={Status.Warning}
    />
  );
};
