import React, { memo } from 'react';

import imgsCdn from '../../../assets/images/imagescdn';

import { authTypes } from '../../../types';

const dsResearchLogo = imgsCdn.otherImg.dsLogoResearch;

export function ContractConfirm(props: { auth: authTypes }) {
  const { auth } = props;

  return (
    <>
      <h5 className='lineHR'>{'A letter from the DemandStar Product Development Team'}</h5>
      <div className='innerColIndent d-flex'>
        <div className='mr-4'>
          <img src={dsResearchLogo} width='100px' alt='Logo' />
        </div>
        <div className='flex-grow-1'>
          <p>Thank you for your willingness to talk with us!</p>
          <p>
            {"We've recorded your name as"} <b>{auth.fln}</b> and your email address as{' '}
            <b>{auth.eml}</b>.
          </p>
          <p>If this is incorrect, please contact DemandStar Support team.</p>
          <p>
            A member of the DemandStar Product Development team will be reaching out soon to
            schedule a time to talk.
          </p>
        </div>
      </div>
    </>
  );
}
