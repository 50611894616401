import styled from 'styled-components';

import { BackgroundColor, Margin, Padding } from '../styles';

export const DSPageLayoutStyled = styled.div`
  background-color: ${BackgroundColor.BaseWhite};
  margin: ${Margin.None} ${Margin.Base} ${Margin.Base} ${Margin.Base};
  padding: 8.75rem ${Padding.Extra} 4rem ${Padding.Extra};

  @media (max-width: 33rem) {
    padding-top: 10.3125rem;
  }

  @media (max-width: 47.9rem) {
    padding-top: 9.375rem;
  }

  h2 {
    margin-top: ${Margin.Base};
  }
`;
