import { DSLink } from '@demandstar/components/link';

import {
  WizardContent,
  WizardCurrentDot,
  WizardItem,
  WizardLine,
  WizardNavItem,
  WizardNavWrapper,
  WizardStatusCircle,
} from './WizardStyles';
import { WizardPage, WizardStatus } from '../../../types/wizard';

export type WizardNavProps = {
  allowForwardNav?: boolean;
  navigateTo?: (id: number) => void;
  wizard: WizardPage[];
  disableNav?: boolean;
};
/**
 * @prop {boolean}      allowForwardNav - true if you want all pages to allow navigation
 * @prop {function}     navigateTo - function that changes the current page
 * @prop {WizardPage[]} wizard - the entire array of wizard pages.
 */
export const WizardNav = (props: WizardNavProps): JSX.Element => {
  const { allowForwardNav, wizard, navigateTo, disableNav } = props;

  function isAvailable(status: WizardStatus) {
    return (
      status === WizardStatus.Available ||
      status === WizardStatus.Completed ||
      status === WizardStatus.Current
    );
  }

  function isNextAvailable(index: number) {
    const nextWizard = wizard[index + 1];
    if (nextWizard) {
      return isAvailable(nextWizard.status);
    }
    return false;
  }

  /**
   * @desc gets navName or name from WizardPage object
   * @param {WizardPage} page - a WizardPage object
   * @returns string - name
   */
  function name(page: WizardPage) {
    return page.navName || page.name;
  }

  return (
    <WizardNavWrapper className='wizardWrapper'>
      {wizard.map((page, index) => {
        return (
          <WizardItem key={page.id}>
            <WizardLine className='wizard-line' nextAvailable={isNextAvailable(index)} />
            <WizardStatusCircle status={page.status}>
              {page.status === WizardStatus.Current ? <WizardCurrentDot /> : <></>}
            </WizardStatusCircle>
            <WizardContent>
              {navigateTo &&
              !disableNav &&
              (page.status === WizardStatus.Completed ||
                page.status === WizardStatus.Available ||
                allowForwardNav) &&
              page.status !== WizardStatus.Current ? (
                <DSLink onClick={() => navigateTo(page.id)} large dataTestId={'wizardLink'}>
                  {name(page)}
                </DSLink>
              ) : (
                <WizardNavItem current={page.status === WizardStatus.Current}>
                  {name(page)}
                </WizardNavItem>
              )}
            </WizardContent>
          </WizardItem>
        );
      })}
    </WizardNavWrapper>
  );
};
