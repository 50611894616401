export enum Margin {
  None = '0',
  Less = '0.5rem',
  Base = '1rem',
  Extra = '1.5rem',
}

export enum Padding {
  Base = '1rem',
  Button = '0.5rem 1rem',
  Extra = '1.5rem',
  Less = '0.5rem',
  None = '0',
  Small = '0.25rem',
}

export enum Width {
  Input = '22.5rem',
  Full = '100%',
}
