import { MouseEvent, useCallback } from 'react';

import { BaseButton, DSButtonType } from './';
import { DataTestId, Id, Inactive, OnClick, SVGIcon, Title } from '../types';
import { iconSuffix } from './constants';
import { onEnter } from '../utils';

export type DSButtonProps = DataTestId &
  Inactive &
  OnClick &
  SVGIcon &
  Title &
  Id & {
    name?: string;
    buttonType?: DSButtonType;
    children: string;
  };

export function DSButton(props: DSButtonProps): JSX.Element {
  const {
    buttonType = 'primary',
    children,
    dataTestId,
    icon,
    id,
    inactive,
    name,
    onClick,
    title = '',
  } = props;
  // Optional svg icon or style string for legacy impl.
  // TODO: assumed left abstract icon layout position.
  const iconClassName = typeof icon === 'string' ? `mr-2 mdi ${icon}` : '';
  const IconComponent = typeof icon === 'string' ? 'i' : icon;

  const htmlType = buttonType === 'submit' || buttonType === 'reset' ? buttonType : 'button';
  const appearance =
    buttonType === 'submit' ? 'primary' : buttonType === 'reset' ? 'secondary' : buttonType;

  const handleClick = useCallback(
    (event?: unknown) => {
      if (onClick) {
        onClick(event as MouseEvent);
      }
    },
    [onClick],
  );

  // Build a testId value for the icon by appending the `'-icon'` suffix to the button's dataTestId value.
  const iconDataTestId = `${dataTestId}-${iconSuffix}`;
  const iconTitle = `${title} (${iconSuffix})`;

  return (
    <BaseButton
      appearance={appearance}
      data-testid={dataTestId}
      id={id}
      inactive={inactive}
      name={name}
      onClick={handleClick}
      title={title ?? children}
      type={htmlType}
      onKeyDown={onEnter(handleClick)}
    >
      {IconComponent && (
        <IconComponent className={iconClassName} title={iconTitle} dataTestId={iconDataTestId} />
      )}
      {children}
    </BaseButton>
  );
}
