import { useRecoilCallback } from 'recoil';

import { addBidPlanholder, getBidPlanholderList } from '../../../store/services';
import { bidPlanholderListState, selectedBidIdState } from '../../../store/recoil/bidState';
import {
  defaultEmailInfo,
  notifyAwardeesState,
  notifyUnselectedState,
} from '../../../store/recoil/award-bid';

import { Assert } from '../../../utils/helpers';
import { authState } from 'src/store/recoil/authState';
import { Awardee } from '../../../types/biddetail';
import { bidAwardeeListState } from '../../../store/recoil/award-bid';
import { CustomMessageType } from '../../../types/awardBid';
import { getCustomAwardNotification } from '../../../store/services/awardBid';
import { memberInfoState } from '../../../store/recoil/memberState';
import { notifyAwardeeTexts } from '../../../utils/texts/agency/awardBid';

export * from './useRequiredDocumentResponses';
export * from './useRequiredDocuments';
export * from './useAwardBidWizard';

interface SavePlanholderPayload {
  name: string;
  email: string;
}

export function useSavePlanholder() {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (planholder: SavePlanholderPayload, awardPlanholder?: boolean) => {
        const name = planholder.name.trim();
        const email = planholder.email.trim(); // these are passed in.
        const bidId = await snapshot.getPromise(selectedBidIdState); // this is from recoil state.
        await addBidPlanholder({
          // this is an endpoint.
          name,
          city: '',
          phoneNumber: '',
          email,
          planholderId: 0,
          address1: '',
          address2: '',
          stateId: 0,
          countryId: 0,
          postalCode: '',
          faxNumber: '',
          contact: '',
          declaredAttributes: '',
          bidId,
          memberId: 0,
          accountId: 0,
        });
        const updatedPlanholders = await getBidPlanholderList(bidId); // once we add the planholder, we get ALL planholders
        set(bidPlanholderListState, updatedPlanholders); // and then update the planholder recoil state.
        if (awardPlanholder) {
          // do we want to also make the planholder an awardee??
          const awardees = await snapshot.getPromise(bidAwardeeListState); // grab the awardee recoil snapshot
          const newPlanholder = updatedPlanholders.find(planholder => {
            return planholder.email === email;
          });

          if (newPlanholder) {
            const memberId = newPlanholder.mi;
            const newAwardee: Awardee = {
              awardedToMemberId: memberId,
              supplierName: newPlanholder.supplierName,
              amount: 0,
            };
            set(bidAwardeeListState, [...awardees, newAwardee]); //... and set the awardee recoil state with the new planholder!
          }
        }
      },
    [],
  );
}

export function useUpdateAwardeeAndNonAwardeeEmailInfo() {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const bidId = await snapshot.getPromise(selectedBidIdState);
        const memberInfo = await snapshot.getPromise(memberInfoState);
        const auth = await snapshot.getPromise(authState);
        Assert(
          !!memberInfo,
          'We should have memberInfo here',
          'useUpdateAwardeeAndNonAwardeeEmailInfo',
        );
        const awardeeEmailInfo = await getCustomAwardNotification({
          bidId,
          customMessageType: CustomMessageType.Awardees,
        });
        set(
          notifyAwardeesState,
          defaultEmailInfo(
            awardeeEmailInfo,
            memberInfo,
            auth.fullName,
            notifyAwardeeTexts.notificationDefaults.subject.awardees,
            notifyAwardeeTexts.notificationDefaults.customMessage.awardees,
          ),
        );
        const unselectedEmailInfo = await getCustomAwardNotification({
          bidId,
          customMessageType: CustomMessageType.NonAwardees,
        });
        set(
          notifyUnselectedState,
          defaultEmailInfo(
            unselectedEmailInfo,
            memberInfo,
            auth.fullName,
            notifyAwardeeTexts.notificationDefaults.subject.unselected,
            notifyAwardeeTexts.notificationDefaults.customMessage.unselected,
          ),
        );
      },
    [],
  );
}
