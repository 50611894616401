import styled from 'styled-components';

import { FontFamily, LineHeight, ParagraphFontSize } from '@demandstar/components/styles';
import { DSLink } from '@demandstar/components/link';

import { FreeAgencyCopy } from '../../../../utils/texts/supplier/subscription';
import { Product } from '../../../../types/subscriptions';
import { ProductType } from 'src/types/products';
import { TollFreeNumber } from '../../../../utils/texts/common';

interface FreeAgencyProps {
  /** the selected free agency, if any */
  freeAgency?: Product;
}

const FreeAgencyStyles = styled.p`
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Base};
  line-height: ${LineHeight.Base};
`;

/**
 * @description displays an alert to users with instructions for changing a free agency, if applicable
 * @returns JSX.Element
 *
 * <FreeAgencyFooter freeAgency={{
 *  productId: 1,
 *  productType: ProductType.FreeAgency,
 *  productName: 'Subscribed Agency',
 *  price: 0,
 * }} />
 */
export const FreeAgencyFooter = ({ freeAgency }: FreeAgencyProps) => {
  return (
    <FreeAgencyStyles>
      {!freeAgency || freeAgency.productType !== ProductType.FreeAgency ? (
        <>
          {FreeAgencyCopy.NoFreeAgency}
          <DSLink href={FreeAgencyCopy.UpgradeLink}>{FreeAgencyCopy.ChooseFreeAgency}</DSLink>
        </>
      ) : (
        <>
          {FreeAgencyCopy.ToMakeChanges}
          {TollFreeNumber}
        </>
      )}
    </FreeAgencyStyles>
  );
};
