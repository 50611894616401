import { ForwardedRef, HTMLProps } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';

import { BackgroundColor, BorderColor, FontFamily, ParagraphFontSize, TextColor } from '../styles';

interface TextAreaStyledProps extends HTMLProps<HTMLTextAreaElement> {
  error?: FieldError;
  inputHeight?: string;
  inputWidth?: string;
  ref?: ForwardedRef<HTMLTextAreaElement>;
}

export const TextAreaStyled = styled.textarea<TextAreaStyledProps>`
  background: ${BackgroundColor.Input};
  border-radius: 0.25rem;
  border: 0.0625rem solid ${props => (props.error?.message ? BorderColor.Error : BorderColor.Base)};
  color: ${TextColor.Base};
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Base};
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  padding: 0.25rem 0.5rem;
  resize: none;
  text-overflow: ellipsis;
  width: 100%;
  word-spacing: normal;
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${TextColor.Base};
    background: ${BackgroundColor.Inactive};
  }
  ${({ inputHeight }) => (inputHeight ? `height: ${inputHeight};` : '')}
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth};` : '')}
`;
