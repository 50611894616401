import React, { memo, useCallback, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import PublishTabulationSheet from './publishtabulationsheet';
import { Buttons, NoResult } from '../../../../customcontrols/index';
import { deprecatedGetDateTimeZoneConverted, formatPrice } from '../../../../../utils/helpers';
import { loadingMsg } from '../../../../../utils/texts';
import { bidssummaryParamType } from '../../../../../types/biddetail';
import { sharedTypes } from '../../../../../types/shared';
import { BidExternalStatusType } from '../../../../../utils/constants';
import { MemberInfoState } from '../../../../../store/reducers/memberinfo';

interface PropsTypes {
  bidssummary?: bidssummaryParamType;
  addBidSupplierTabulation?: any;
  getBidTabulation?: any;
  bidsResponseDocumentDownload?: any;
  setSharedDetails?: any;
  shared?: sharedTypes;
  match?: any;
  memberinfo?: MemberInfoState;
}

function Tabulation(props: PropsTypes) {
  const {
    bidssummary,
    addBidSupplierTabulation,
    getBidTabulation,
    bidsResponseDocumentDownload,
    setSharedDetails,
    shared,
    memberinfo,
  } = props;

  const bidId = parseInt(props?.match?.params?.bidId);
  const { bidTabulation, results: summary } = bidssummary || {};
  const { tabulation_internalLoader } = shared || {};
  const { bidExternalStatusType } = summary || {};
  const { tzfn, tzn } = memberinfo || {};

  useEffect(() => {
    setSharedDetails({ tabulation_internalLoader: true });
    getBidTabulation({ bidId });
  }, [bidId, getBidTabulation, setSharedDetails]);

  const [modalOpen, setModelOpen] = useState(false);
  const [isExport, setisExport] = useState(false);
  const [modalPublishOpen, setPublishModelOpen] = useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    setisExport(false);
    setModelOpen(!modalOpen);
  };

  const handleExportClick = (e: any) => {
    e.preventDefault();
    setisExport(true);
    setModelOpen(!modalOpen);
  };

  const handlePublishClick = (e: any) => {
    e.preventDefault();
    setisExport(false);
    setPublishModelOpen(!modalPublishOpen);
  };

  const handleNextPublishModal = (e: any) => {
    e.preventDefault();
    setModelOpen(true);
    setisExport(false);
    setPublishModelOpen(!modalPublishOpen);
  };

  const handlePDFClick = useCallback(
    (includeNonCompliant: any, fileType: any, isExport: any) => {
      addBidSupplierTabulation({ bidId, includeNonCompliant, fileType, isExport });
      setModelOpen(!modalOpen);
    },
    [bidId, modalOpen, addBidSupplierTabulation, setModelOpen],
  );

  const downloadClick = (e: any, docId: number) => {
    e.preventDefault();
    if (docId && docId > 0) {
      bidsResponseDocumentDownload({ docId, id: bidId, type: 'Bid' });
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-12' data-testid='biddetails.tabulation.buttons'>
          <h3 className='mb-3 arrowWrapper' data-testid='biddetails.tabulation.head'>
            Tabulation
          </h3>
          {bidTabulation &&
            bidTabulation.length > 0 &&
            bidExternalStatusType &&
            bidExternalStatusType !== BidExternalStatusType.Active && (
              <>
                <Buttons
                  text='Publish'
                  classNames='bttn bttn-secondary float-right small ml-3'
                  onClick={handlePublishClick}
                />
                <Buttons
                  text='Download'
                  classNames='bttn bttn-secondary float-right small'
                  onClick={handleExportClick}
                />
              </>
            )}
        </div>
        <div className='col-12'>
          <ul className='list-unstyled float-right'>
            <li className='d-inline-block mr-3'>
              <span className='successTick' />
              Online
            </li>
            <li className='d-inline-block mr-3'>
              <span className='manual' />
              Offline
            </li>
            <li className='d-inline-block mr-3'>
              <span className='noneDot' />
              Not Submitted
            </li>
            <li className='d-inline-block mr-3'>
              <span className='notSubmit' />
              Not Received
            </li>
          </ul>
          <Table responsive className='tableHData'>
            <thead>
              <tr data-testid='biddetails.tabulation.listhead'>
                <th>Company</th>
                <th>Responded</th>
                <th>Address</th>
                <th>Bid Amt</th>
                <th>Alt Bid Amt</th>
                <th>Attributes</th>
                <th>Documents</th>
                <th>Send</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody data-testid='biddetails.tabulation.listbody'>
              {bidTabulation && bidTabulation.length > 0 ? (
                bidTabulation.map(response => (
                  <tr key={response.bidResponseId}>
                    <td>{response.supplierName}</td>
                    <td>
                      {deprecatedGetDateTimeZoneConverted(response.responseDateTime, tzfn)} {tzn}
                    </td>
                    <td>
                      {(response.address1 ? response.address1 : '') +
                        (response.address2 ? `, ${response.address2}` : '') +
                        (response.city ? `, ${response.city}` : '') +
                        (response.state ? `, ${response.state}` : '') +
                        (response.zipCode ? `, ${response.zipCode}` : '')}
                    </td>
                    <td>{response.totalPrice && '$' + formatPrice(response.totalPrice)}</td>
                    <td>{response.altTotalPrice && '$' + formatPrice(response.altTotalPrice)}</td>
                    <td>{response?.declaredAttributes}</td>
                    <td colSpan={2}>
                      <ul className='list-unstyled mb-0'>
                        {response.responseDoc &&
                          response.responseDoc.length > 0 &&
                          response.responseDoc.map((doc, index) => (
                            <li
                              key={index}
                              className={doc.docId && doc.docId > 0 ? 'staticLink' : ''}
                              onClick={e => downloadClick(e, doc.docId)}
                            >
                             <span className='float-right'>
                            {doc.documentStatus === 'EL' && <span className='successTick' />}
                            {doc.documentStatus === 'MN' && <span className='manual' />}
                            {(doc.documentStatus === 'None' || !doc.documentStatus) && (
                              <span className='noneDot' />
                            )}
                            {doc.documentStatus === 'NS' && <span className='notSubmit' />}
                            </span>
                            <span className='document-tabulation'>{doc.documentName}</span>
                            </li>
                          ))}
                      </ul>
                    </td>
                  
                    <td>{response.responseStatus}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>
                    <NoResult
                      message={tabulation_internalLoader ? loadingMsg : 'No responses Available'}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <ModalPopUp
        title={(isExport ? 'Export' : 'Publish') + ' Tabulation Sheet'}
        size='md'
        isOpen={modalOpen}
        closeModal={handleClick}
      >
        <PublishTabulationSheet {...props} isExport={isExport} handleOnClick={handlePDFClick} />
      </ModalPopUp>
      <ModalPopUp
        title={'Publish Tabulation Sheet'}
        size='md'
        isOpen={modalPublishOpen}
        closeModal={handlePublishClick}
      >
        <p>
          This will generate a PDF of your tabulation sheet, upload it to the Documents section for
          this bid, then alert suppliers the Tabulation Sheet is available.
        </p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Cancel'
          title='Cancel'
          type='button'
          onClick={handlePublishClick}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Publish'
          title='Publish'
          type='button'
          onClick={handleNextPublishModal}
        />
      </ModalPopUp>
    </>
  );
}

export default memo(Tabulation);
