import styled from 'styled-components';

import { HeaderFontSize, Padding, TextColor } from '../styles';

export const ProgressText = styled.div`
  position: absolute;
  font-size: ${HeaderFontSize.H6};
  line-height: ${HeaderFontSize.H6};
  color: ${TextColor.Action};
  padding-left: ${Padding.Small};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const AnimatedStarWrapper = styled.div`
  height: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes toggle {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .star-ray-1,
  .star-ray-2,
  .star-ray-3,
  .star-ray-4,
  .star-ray-5,
  .star-ray-6,
  .star-ray-7,
  .star-ray-8 {
    animation: toggle 4s infinite;
    color: ${props => props?.color || TextColor.Action};
  }
  .star-ray-1 {
    animation-delay: 0.25s;
  }
  .star-ray-5 {
    animation-delay: 0.5s;
  }
  .star-ray-8 {
    animation-delay: 0.75s;
  }
  .star-ray-7 {
    animation-delay: 1s;
  }
  .star-ray-6 {
    animation-delay: 1.25s;
  }
  .star-ray-2 {
    animation-delay: 1.5s;
  }
  .star-ray-4 {
    animation-delay: 2s;
  }
  .star-ray-3 {
    animation-delay: 1.75s;
  }
`;
