import { useRecoilCallback, useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { useEffect } from 'react';

import { addMemberAccount, getAcctInfo } from '../../store/services';

import { accountInfoState } from '../../store/recoil/accountInfoState';
import { AddressInfo } from 'src/components/common/formcomponents/AddressLookup';
import { ProductType } from 'src/types/products';
import { recoilRegistrationDataState } from 'src/store/recoil/registrationState';
import { RegistrationData } from 'src/types/supplierregistration';
import { track } from '../../utils/telemetry';
import { UserType } from 'src/types/accountinfo';

/**
 * @description wraps accountInfoState & related functionality
 * @example const { accountInfo } = useAccountInfo();
 */
export function useAccountInfo() {
  const accountInfo = useRecoilValue(accountInfoState);

  /**
   * @description pulls the account information from the service layer & refreshes state
   * @returns void
   * @example refreshAccountInfo();
   */
  const refreshAccountInfo = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const acctInfo = await getAcctInfo();

          if (acctInfo) {
            let userType = acctInfo.userType;
            if (!userType) {
              userType = acctInfo?.products?.filter(p => p.productType !== ProductType.FreeAgency)
                ?.length
                ? UserType.PaidSupplier
                : acctInfo?.products?.filter(p => p.productType === ProductType.FreeAgency)?.length
                ? UserType.FreeAgencySupplier
                : UserType.BasicSupplier;
            }

            set(accountInfoState, {
              ...acctInfo,
              expiryDate: dayjs(acctInfo.expiryDate),
              newProductIds: [...accountInfo.newProductIds],
              userType,
            });
          }
        } catch (error: any) {
          track('refreshAcctInfo -> getAcctInfo() ERROR:', {
            error,
            method: 'GET',
            errorMessage: error.message,
          });

          // eslint-disable-next-line no-console
          console.error(`acctInfoAtom -> getAcctInfo() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.

          throw new Error(error.message);
        }
      },
    [],
  );

  /**
   * @description creates a new account based on the data in recoilRegistrationDataState
   * @param billingAddress - AddressInfo - optional billing address to include in the new account
   * @returns Promise<AxiosResponse<any>>
   * @example createMemberAccount();
   */
  const createMemberAccount = useRecoilCallback(
    ({ snapshot }) =>
      async (billingAddress?: AddressInfo) => {
        const recoilRegistrationData = await snapshot.getPromise<RegistrationData>(
          recoilRegistrationDataState,
        );
        return await addMemberAccount({
          account: {
            ...recoilRegistrationData.account,
            userName:
              recoilRegistrationData?.account?.userName || recoilRegistrationData?.emailAddress,
          },
          billingAddress: billingAddress
            ? {
                ...billingAddress,
              }
            : undefined,
          member: recoilRegistrationData.member,
          memberAddress: recoilRegistrationData.memberAddress,
          memberContact: {
            ...recoilRegistrationData.memberContact,
            email:
              recoilRegistrationData?.memberContact?.email || recoilRegistrationData?.emailAddress,
          },
          memberContactPhone: recoilRegistrationData.memberContactPhone,
          memberPhones: recoilRegistrationData.memberPhones,
        });
      },
  );

  return {
    accountInfo,
    createMemberAccount,
    refreshAccountInfo,
  };
}

/**
 * @description re-loads the account state on component's first render
 * @returns void
 * @example useRefreshAccountInfo();
 */
export function useRefreshAccountInfo(): void {
  const { refreshAccountInfo } = useAccountInfo();

  useEffect(() => {
    refreshAccountInfo();
  }, [refreshAccountInfo]);
}
