import { GetAuthTokenRequest, GetAuthTokenResponse } from 'src/types';

import { axiosPostRequest } from './api';
import { constants } from 'src/utils/settings';
import { Paths } from 'src/utils/constants';

/**
 * Authorization token request
 * @param {GetAuthTokenRequest} requestPayload
 * @returns {GetAuthTokenResponse} tokenresponse
 */
export function getAuthToken(requestPayload: GetAuthTokenRequest) {
  const { password: Password, userName: UserName } = requestPayload;
  const tokenresponse = axiosPostRequest<GetAuthTokenResponse>({
    baseURL: constants.api.authUrl,
    data: {
      Expiration: 24,
      IsAnonymous: false,
      Password,
      UserName,
    },
    // method: 'POST',
    url: Paths.auth.authorization,
  });

  return tokenresponse;
}

// export function getRefreshToken(data = {}) {
//   return postRequestWithAuthentication(paths.auth.RefreshToken, data, {
//     baseURL: appConfig.api.authUrl,
//   });
// }

// export function validateEmail(data: any = {}) {
//   return getRequest(`${paths.member.validateEmail}?id=${data.accountId}`, {
//     baseURL: appConfig.api.userUrl,
//   });
// }
