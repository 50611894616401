// TOREFACTOR - fix casing
import * as H from 'history';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BidDocument, DocStatus, DocTypeValue } from '../../../types/addbid';
import { WizardPage } from '../../../types/wizard';

import { agencyTexts, approveConfirmText, processConfirmText } from '../../../utils/texts';
import { useForceUpdate } from '../../../utils/helperHooks';
import { setWizardStatus } from '../../../utils/helpers';
import { Buttons } from '../../customcontrols';
import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import { ModalPopUp } from '../modals/ModalPopUp';
import AddBidWizardButtons from './AddBidWizardButtons';
import { ConfirmModal } from '../modals/ConfirmModal';
import { Prompt, useHistory } from 'react-router-dom';
import { continueebidconfirmmsgText } from '../../../utils/texts';
import { DocumentUploadTable } from '../DocumentUploadTable';
import { useDSSelector } from '../../../store/reducers';
import {
  getAddBidCanComplete,
  getaddBidGetAwardees,
  getAddBidIsPermissions,
  getDocAutoApprovalStatus,
  setAddBidDetails,
  setBreadcrumb,
} from '../../../store/actions';

interface DocumentUploadProps {
  valid?: Record<string, unknown>;
  pristine?: Record<string, unknown>;
  submitting?: Record<string, unknown>;
  currentwizard: WizardPage;
  bidId: number;
  currentBidName: string;
  SkipNext: (typefor?: string) => void;
}

function DocumentUpload(props: DocumentUploadProps) {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const history = useHistory();

  const { bidId, currentBidName, currentwizard, pristine, submitting, valid } = props;

  const addbidwizard = useDSSelector(state => state.addbid.addbidwizard);
  const broadcastModal = useDSSelector(state => state.addbid.broadcastModal);
  const EbidAlertModal = useDSSelector(state => state.addbid.EbidAlertModal);
  const finishbiderrormodal = useDSSelector(state => state.addbid.finishbiderrormodal);
  const [nextLocation, setNextLocation] = useState<H.Location>();
  const [blockNav, setBlockNav] = useState(true);

  const [processingModal, setProcessingModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [awardModal, setAwardModal] = useState(false);

  function toggleProcessingModal() {
    setProcessingModal(!processingModal);
  }
  function toggleApprovalModal() {
    setApprovalModal(!approvalModal);
  }
  function toggleAwardModal() {
    setAwardModal(!awardModal);
  }

  // this sets halfwayCancel and processInterrupt when this component is destroyed
  useEffect(() => {
    return () => {
      dispatch(setAddBidDetails({ halfWayCancel: false, processInterrupt: true }));
    };
  }, [dispatch]);

  // this sets accountinfo.autoapproval.autoApprovalStatus, but I don't think we use it here. - DC
  useEffect(() => {
    dispatch(getDocAutoApprovalStatus());
  }, [dispatch]);

  // this sets addbid.canFinishBid, but I don't know where that factors in. - DC
  useEffect(() => {
    if (bidId) {
      dispatch(getAddBidCanComplete({ bidId }));
    }
  }, [bidId, dispatch]);

  // this presumably gets our awardees. We may not need this down the line
  // if we make the only way to add Awardees through our Award Bid process. - DC
  useEffect(() => {
    if (bidId) {
      dispatch(getaddBidGetAwardees({ bidId }));
    }
  }, [bidId, dispatch]);

  const setBroadcastModal = useCallback(() => {
    dispatch(setAddBidDetails({ broadcastModal: !broadcastModal }));
  }, [dispatch, broadcastModal]);

  // this sets our breadcrumb trail
  useEffect(() => {
    dispatch(
      setBreadcrumb({
        component: (
          <AddBidControl valid={valid} pristine={pristine} submitting={submitting} bidId={bidId} />
        ),
        page: currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation',
        title: currentBidName
          ? `Update Bid - ${currentwizard && currentwizard.name}`
          : `Bid Creation - ${currentwizard && currentwizard.name}`,
        altname: currentBidName
          ? `Update Bid - ${currentwizard && currentwizard.name}`
          : `Bid Creation - ${currentwizard && currentwizard.name}`,
        name: currentBidName ? currentBidName : 'Bid Creation',
        id: bidId,
        menuactive: 'Bid Creations',
      }),
    );
  }, [dispatch, bidId, currentwizard, pristine, submitting, valid, currentBidName]);

  const SetFinishbidError = () => {
    dispatch(
      setAddBidDetails({
        finishbiderrormodal: !finishbiderrormodal,
        finishBidErrorMsg: [],
        broadcastModal: false,
      }),
    );
  };

  const FinishBidError = () => {
    const finishBidErrorMsg = useDSSelector(state => state.addbid.finishBidErrorMsg);
    useEffect(() => {
      return () => {
        dispatch(
          setAddBidDetails({
            finishbiderrormodal: false,
            broadcastModal: false,
            finishBidErrorMsg: [],
          }),
        );
      };
    }, []);

    return (
      <>
        {finishBidErrorMsg &&
          finishBidErrorMsg.length > 0 &&
          finishBidErrorMsg.map((item: string, index: number) => <p key={index}>{item}</p>)}
        <Buttons
          classNames='bttn-primary w-100 mt-2'
          text='Ok' // TODO: Create 'Ok' Constant.
          title='Ok'
          type='button'
          onClick={() => {
            dispatch(getAddBidIsPermissions({ bidId }));
            SetFinishbidError();
          }}
        />
      </>
    );
  };

  const setEBidAlertModal = () => {
    dispatch(setAddBidDetails({ EbidAlertModal: !EbidAlertModal }));
  };

  // DOCUMENTS
  const documents: BidDocument[] = useDSSelector(state => state.addbid.documentslist);

  function handleBlockedNavigation(nextLocation: H.Location) {
    // TODO: Define these as a constant.
    const yetToProcessDocs = !!documents.filter(doc =>
      [DocStatus.PdfProcessPending, DocStatus.ProcessPending].includes(doc.statusType),
    ).length;

    const yetToApproveDocs = !!documents.filter(
      doc =>
        doc.statusType === DocStatus.ApprovalPending && doc.docType !== DocTypeValue.AwardDocument,
    ).length;
    const yetToAwardDocs = !!documents.filter(
      doc =>
        doc.statusType === DocStatus.ApprovalPending && doc.docType === DocTypeValue.AwardDocument,
    ).length;

    if (
      (yetToProcessDocs || yetToApproveDocs || yetToAwardDocs) &&
      blockNav &&
      !processingModal &&
      !approvalModal &&
      !awardModal
    ) {
      setNextLocation(nextLocation);
      if (yetToProcessDocs) {
        setProcessingModal(true);
        return false;
      }
      if (yetToApproveDocs) {
        setApprovalModal(true);
        return false;
      }
      if (yetToAwardDocs) {
        setAwardModal(true);
        return false;
      }
      return false;
    } else {
      return true;
    }
  }

  function continueNav() {
    setBlockNav(false);
    forceUpdate();
    history.push((nextLocation as H.Location).pathname);
  }

  function navToAward() {
    setBlockNav(false);
    history.push(`/buyers/bids/${bidId}/award`);
  }

  return (
    <>
      <Prompt when={blockNav} message={handleBlockedNavigation} />

      <DocumentUploadTable bidId={bidId} type='Bid' />
      <AddBidWizardButtons {...props} />

      <ModalPopUp
        size='md'
        title='Error Message'
        closeModal={SetFinishbidError}
        isOpen={finishbiderrormodal}
      >
        <FinishBidError />
      </ModalPopUp>

      <ModalPopUp
        title='eBidding Confirmation'
        size='md'
        isOpen={!!EbidAlertModal}
        closeModal={setEBidAlertModal}
      >
        <p data-testid='addbid.documentupload.ebid.confirmation.modal'>
          {continueebidconfirmmsgText}
        </p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Change'
          title='Change'
          type='button'
          onClick={() => {
            setEBidAlertModal();
            dispatch(setAddBidDetails({ addbidwizard: setWizardStatus(addbidwizard, 1) }));
          }}
          data-testid='addbid.documentupload.ebid.confirmation.modal.changebtn'
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Continue'
          title='Continue'
          type='button'
          onClick={() => {
            setEBidAlertModal();
            setBroadcastModal();
          }}
          data-testid='addbid.documentupload.ebid.confirmation.modal.continuebtn'
        />
      </ModalPopUp>

      <ConfirmModal
        isOpen={processingModal}
        confirmMessage={processConfirmText}
        approveAction={toggleProcessingModal}
        cancelAction={continueNav}
        approveText='Wait on this page for documents to process'
        cancelText='Leave this page and approve these documents later'
        title='Confirmation'
        size='lg'
        closeModal={toggleProcessingModal}
      />
      <ConfirmModal
        isOpen={approvalModal}
        confirmMessage={approveConfirmText}
        approveAction={toggleApprovalModal}
        cancelAction={continueNav}
        approveText={agencyTexts.documents.approveDocsButton}
        cancelText='Leave this page and approve these documents later'
        title='Confirmation'
        size='lg'
        closeModal={toggleApprovalModal}
      />
      <ConfirmModal
        isOpen={awardModal}
        confirmMessage={
          'DemandStar has created a new process for awarding bids! Would you like to go there to award this bid?'
        }
        approveAction={navToAward}
        cancelAction={continueNav}
        approveText={'Start Award Process'}
        cancelText='No Thanks'
        title='Confirmation'
        size='md'
        closeModal={toggleAwardModal}
      />
    </>
  );
}

export default memo(DocumentUpload);
