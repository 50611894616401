import { AnimatedStarWrapper, ProgressText } from './styles';

import { defaultNumericId } from '../constants/common';
import { DSProgressIndicatorProps } from './types';
import { DSSVGIcon } from '../icon';
import { TextColor } from '../styles';

export const DSLogoStar = ({
  color = TextColor.Action,
  dataTestId,
  height = 120,
  onClick,
  progress = defaultNumericId,
  viewBox = '0 0 48 48',
  width = 120,
}: DSProgressIndicatorProps) => {
  const textTestId = `${dataTestId}-text`;

  const progressText = progress >= 0 ? `${progress}%` : '';

  return (
    <AnimatedStarWrapper data-testid={dataTestId}>
      <DSSVGIcon
        color={`${color}`}
        height={height}
        onClick={onClick}
        viewBox={viewBox}
        width={width}
        dataTestId={`${dataTestId}-icon`}
        title='DemandStar Logo'
      >
        <path
          className='star-ray-3'
          data-name='Path 110'
          d='M 17.307 23.991 C 17.37 25.338 16.282 26.458 14.934 26.434 C 14.434 26.434 10.783 26.3 7.07 25.925 C 0.732 25.281 0 24.513 0 23.991 C 0 23.469 0.732 22.706 7.069 22.068 C 10.769 21.688 14.428 21.554 14.933 21.554 C 16.28 21.528 17.368 22.645 17.306 23.991 Z'
        ></path>
        <path
          className='star-ray-2'
          data-name='Path 112'
          d='M 19.93 30.575 C 19.924 31.199 19.664 31.793 19.21 32.22 C 18.592 32.846 13.934 36.758 12.139 36.758 C 11.942 36.77 11.749 36.703 11.602 36.571 C 11.441 36.411 11.354 36.192 11.363 35.965 C 11.363 34.339 14.453 30.084 15.763 28.778 C 16.189 28.322 16.784 28.061 17.408 28.055 C 17.717 28.059 18.022 28.121 18.308 28.239 C 19.263 28.628 19.899 29.544 19.93 30.575 Z'
        ></path>
        <path
          className='star-ray-1'
          data-name='Path 116'
          d='M 26.438 14.934 C 26.372 16.81 24.3 17.912 22.708 16.917 C 22.02 16.487 21.591 15.744 21.563 14.934 C 21.563 14.42 21.694 10.734 22.068 7.067 C 22.713 0.73 23.479 0 24 0 C 24.521 0 25.285 0.73 25.926 7.067 C 26.306 10.768 26.438 14.427 26.438 14.934 Z'
        ></path>
        <path
          className='star-ray-4'
          data-name='Path 109'
          d='M 19.747 18.302 C 19.357 19.257 18.442 19.892 17.411 19.924 C 16.788 19.918 16.193 19.658 15.766 19.204 C 14.537 17.925 10.32 12.772 11.412 11.594 C 11.574 11.435 11.794 11.348 12.021 11.354 C 13.638 11.354 17.9 14.443 19.211 15.754 C 19.911 16.404 20.126 17.425 19.747 18.302 Z'
        ></path>
        <path
          className='star-ray-5'
          data-name='Path 111'
          d='M 36.64 12.073 C 36.64 13.69 33.547 17.95 32.24 19.255 C 31.814 19.708 31.221 19.97 30.599 19.979 C 30.29 19.975 29.984 19.911 29.699 19.79 C 28.745 19.4 28.11 18.484 28.079 17.454 C 28.084 16.832 28.344 16.239 28.799 15.815 C 29.416 15.193 34.068 11.277 35.864 11.272 C 36.064 11.262 36.26 11.33 36.411 11.462 C 36.572 11.624 36.658 11.846 36.649 12.074 Z'
        ></path>
        <path
          className='star-ray-6'
          data-name='Path 113'
          d='M 26.438 33.062 C 26.438 33.562 26.305 37.222 25.927 40.929 C 25.287 47.263 24.518 47.999 24 47.999 C 23.482 47.999 22.715 47.262 22.069 40.928 C 21.694 37.258 21.564 33.575 21.564 33.061 C 21.63 31.185 23.702 30.083 25.294 31.078 C 25.982 31.508 26.411 32.251 26.439 33.061 Z'
        ></path>
        <path
          className='star-ray-7'
          data-name='Path 114'
          d='M 36.547 36.396 C 36.384 36.555 36.164 36.641 35.936 36.633 C 34.313 36.633 30.057 33.542 28.748 32.233 C 28.047 31.586 27.833 30.566 28.215 29.692 C 28.603 28.736 29.519 28.099 30.55 28.068 C 31.172 28.074 31.766 28.331 32.195 28.782 C 33.466 30.119 37.628 35.228 36.547 36.396 Z'
        ></path>
        <path
          className='star-ray-8'
          data-name='Path 115'
          d='M 47.997 24.02 C 47.997 24.532 47.259 25.292 40.923 25.929 C 37.23 26.305 33.57 26.437 33.065 26.437 C 31.186 26.399 30.053 24.341 31.026 22.733 C 31.459 22.017 32.228 21.573 33.065 21.556 C 35.796 21.556 46.536 22.278 47.787 23.54 C 47.926 23.661 48.003 23.837 47.997 24.021 Z'
        ></path>
      </DSSVGIcon>
      <ProgressText data-testid={textTestId}>{progressText}</ProgressText>
    </AnimatedStarWrapper>
  );
};
