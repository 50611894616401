import React, { useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import {
  BID_LABEL_PLURAL,
  BID_RESPONSES_ERROR_MESSAGE,
  BID_RESPONSES_LOADING_MESSAGE,
  BID_RESPONSES_NOT_FOUND_MESSAGE,
} from '../../../../shared/constants/bids';
import {
  BidResponseListPanelView,
  BidResponseListPanelViewProps,
} from './BidResponseListPanelView';
import { ErrorPanel, LoadingPanel } from '../../../../components/common/loading';
import {
  responseListState,
  SELECTED_RESPONSE_ID_DEFAULT,
  selectedResponseIdState,
} from '../../../../store/recoil/bidResponseState';
import { SelectOption, SortProps } from '../../../../shared/hooks/useSort';

import { defaultNumericId } from '../../../../shared/constants';
import { EBidResponse } from '../../../../types/suppliers';
import { PanelLabel } from '../../../../shared/styles';
import { SortControlGroup } from '../../../../components/common/sort/SortControlGroup';
import { sortDirections } from '../../../../utils/constants';
import { useSelectedBidId } from '../../../../shared/hooks/useSelectedBidId';

// SortControlGroup options.
const sortOptions: SelectOption<EBidResponse>[] = [
  { label: 'Bid Name', value: 'name' },
  {
    label: 'Response Date',
    value: 'responseDate',
    selected: true,
    initialSortOrder: sortDirections.DESC,
  },
  { label: 'Status', value: 'status' },
];

/**
 * Container for the Panel displaying Required Actions.
 * @description This container component holds typing, application state
 * and sorting logic to be passed to the presentation component(s).
 * @returns Panel containing the `BidResponseListPanelView` presentation component
 * @todo Abstract this logic to share and synchronize with the Required Actions List view.
 */
export function BidResponseListPanel() {
  const [selectedResponseId, setSelectedResponseId] = useRecoilState(selectedResponseIdState);
  const { selectedBidId, setSelectedBidId } = useSelectedBidId();

  // Get the list of `responses` from the Recoil store.
  const responsesListLoadable = useRecoilValueLoadable(responseListState);

  // Local state - This should be instantiated by the API response stored in Recoil app state,
  // then managed locally, with callbacks from the `SortControlGroup` and the
  // `Paginate` controls, to accomplish sorting and pagination.
  const [responseList, setResponseList] = useState<EBidResponse[]>([]);

  /**
   * Update the App state values of the `selectedBidId` and `selectedResponseIdState`
   * @description This will change the details loaded in the right-hand pane.
   * @param bidId: string
   * @param responseId: string
   */
  const handleItemSelection = (bidId: number, responseId: number) => {
    setSelectedBidId(bidId);
    setSelectedResponseId(responseId);
  };

  /**
   * Callback for the `useSort` hook to update state and restart component rendering.
   * @param data: ListItem[]
   */

  function handleSortChange(data: EBidResponse[]) {
    setResponseList(data);
  }

  // Set return content.
  let returnContent: JSX.Element = <span />;

  // Handle Recoil State Loadable states:
  // TODO: Standardize styled components for 'hasError' and/or 'loading' states.
  if (responsesListLoadable.state === 'hasError') {
    returnContent = <ErrorPanel message={BID_RESPONSES_ERROR_MESSAGE} />;
  }

  if (responsesListLoadable.state === 'loading') {
    returnContent = <LoadingPanel message={BID_RESPONSES_LOADING_MESSAGE} />;
  }

  if (responsesListLoadable.state === 'hasValue') {
    const responses = responsesListLoadable?.contents || [];

    // Set local state on the initial load only.
    if (responses.length && !responseList.length) {
      setResponseList([...responses]);
    }

    // Set the selected bidId as the first result by default, unless another has been selected already.
    const currentSelectedBidId =
      selectedBidId > 0
        ? selectedBidId
        : responseList.length
        ? responseList[0]?.bidId
        : defaultNumericId;

    const currentSelectedResponseId = responseList.length
      ? responseList.find((item: EBidResponse) => item.bidId === Number(currentSelectedBidId))
          ?.responseId
      : SELECTED_RESPONSE_ID_DEFAULT;

    setSelectedBidId(Number(currentSelectedBidId));
    setSelectedResponseId(currentSelectedResponseId || -1);

    if (!responseList.length) {
      returnContent = <LoadingPanel message={BID_RESPONSES_NOT_FOUND_MESSAGE} />;
    } else {
      const panelLabel = `${BID_LABEL_PLURAL}`;

      const viewProps: BidResponseListPanelViewProps = {
        responses: responseList,
        label: panelLabel,
        handleItemClick: handleItemSelection,
        selectedBidId,
      };

      const sortProps: SortProps<EBidResponse> = {
        data: responseList,
        sortOptions,
        onSortChange: handleSortChange,
      };

      returnContent = (
        <>
          <PanelLabel>{panelLabel}</PanelLabel>
          <SortControlGroup<EBidResponse> {...sortProps} />
          <BidResponseListPanelView {...viewProps} />
        </>
      );
    }
  }

  return returnContent;
}
