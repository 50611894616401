import React, { memo, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions';
import { Table } from 'reactstrap';
import CommonDataTable from './commondatatable';
import {
  bidTypeFields,
  eBidDocumentFields,
  publicationFields /* , productFields */,
} from '../../../utils/constants';
import LegalAd from '../../buyer/account/templates/legalad';
import history from '../../../utils/history';
import NoResult from '../../customcontrols/noresult';
import CopyBox from '../../customcontrols/copybox';
import { constants } from '../../../utils/settings';
import { agencyRegistrationParamType } from '../../../types/agencyregistration';
import { sharedParamType } from '../../../types/commoditycodes';
import { formattedPhoneNumber, getWebsiteFn } from '../../../utils/helpers';
import Configurations from '../../../settings';

const tdWidth = {
  width: '320px',
};

interface propsTypes {
  agencyregistration?: agencyRegistrationParamType;
  shared?: sharedParamType;
  getAddAgencyLegal?: any;
  getAddAgencyPublications?: any;
  getGeneralInfoIntialValues?: any;
  getTimezoneList?: any;
  setBreadcrumb?: any;
  getAgencySettings?: any;
  match?: any;
  form?: any;
}

function AgencySummary(props: propsTypes) {
  const {
    agencyregistration,
    getAddAgencyLegal,
    getAddAgencyPublications,
    getGeneralInfoIntialValues,
    getTimezoneList,
    setBreadcrumb,
    getAgencySettings,
  } = props;

  const {
    generalInfoFormInitialValues,
    agencypublications = [],
    agencybidTypes = [],
    agencyEbidDocuments = [],
    legalAds = {},
    agencyProducts = [],
    escapedagencyname = '',
    state = '',
    guid = '',
  } = agencyregistration || {};
  const memberId = props.match.params.memberId || '';

  useEffect(() => {
    getTimezoneList({});
  }, [getTimezoneList]);

  useEffect(() => {
    if (memberId) getGeneralInfoIntialValues({ buyerId: memberId, pagefor: 'summary' });
  }, [getGeneralInfoIntialValues, memberId]);

  useEffect(() => {
    if (memberId) getAddAgencyLegal({ memberId });
  }, [getAddAgencyLegal, memberId]);

  useEffect(() => {
    if (memberId) getAddAgencyPublications({ buyerId: memberId });
  }, [getAddAgencyPublications, memberId]);

  useEffect(() => {
    if (memberId) getAgencySettings({ agencyId: memberId });
  }, [getAgencySettings, memberId]);

  const addNewAgency = () => {
    history.replace('/ops/agency/registration');
  };

  useEffect(() => {
    const control = (
      <li title='Edit Legal Ad'>
        <span
          className='staticLink addBid'
          onClick={event => {
            event.preventDefault();
            addNewAgency();
          }}
        >
          <i className='mdi mdi-plus mr-1' /> Add New Agency
        </span>
      </li>
    );
    setBreadcrumb({
      component: control,
      page: 'Agency Creation',
      title: 'Agency Creation - Summary',
      altname: 'Agency Creation - Summary',
      name: 'Agency Creation',
      id: 'AgCR',
      menuactive: 'Agency',
    });
  }, [setBreadcrumb]);

  let address = '';
  const addressArray = [];
  if (generalInfoFormInitialValues && generalInfoFormInitialValues.address1)
    addressArray.push(generalInfoFormInitialValues.address1);
  if (generalInfoFormInitialValues && generalInfoFormInitialValues.address2)
    addressArray.push(generalInfoFormInitialValues.address2);
  if (generalInfoFormInitialValues && generalInfoFormInitialValues.city)
    addressArray.push(generalInfoFormInitialValues.city);
  if (generalInfoFormInitialValues && generalInfoFormInitialValues.county)
    addressArray.push(generalInfoFormInitialValues.county.label);
  if (generalInfoFormInitialValues && generalInfoFormInitialValues.state)
    addressArray.push(
      `${generalInfoFormInitialValues.state.name} - ${generalInfoFormInitialValues.zipcode}`,
    );
  if (generalInfoFormInitialValues && generalInfoFormInitialValues.country)
    addressArray.push(`${generalInfoFormInitialValues.country.label}`);

  address = addressArray.join(', ');

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='colWrapper clearfix '>
              <div className='row'>
                <div className='col-lg-9'>
                  <h1 className='arrowWrapper'>
                    {generalInfoFormInitialValues && generalInfoFormInitialValues.agencyname}
                  </h1>
                </div>
                <div className='col-12'>
                  <h4>General Information</h4>
                  <div className='innerColIndent'>
                    <Table borderless className='tableData'>
                      <tbody>
                        <tr>
                          <th style={tdWidth}>User Name</th>
                          <td>
                            {generalInfoFormInitialValues &&
                              generalInfoFormInitialValues.loginusername}
                          </td>
                        </tr>
                        <tr>
                          <th style={tdWidth}>Contact Name</th>
                          <td>{`${
                            generalInfoFormInitialValues && generalInfoFormInitialValues.firstname
                          } ${
                            generalInfoFormInitialValues && generalInfoFormInitialValues.lastname
                          }`}</td>
                        </tr>
                        <tr>
                          <th style={tdWidth}>Contact Address</th>
                          <td>{address}</td>
                        </tr>
                        <tr>
                          <th style={tdWidth}>Contact Email</th>
                          <td>
                            {generalInfoFormInitialValues &&
                              generalInfoFormInitialValues.contactEmail}
                          </td>
                        </tr>
                        <tr>
                          <th style={tdWidth}>Contact Phone</th>
                          <td>
                            {generalInfoFormInitialValues &&
                              formattedPhoneNumber(generalInfoFormInitialValues.contactPhone)}
                          </td>
                        </tr>
                        <tr>
                          <th style={tdWidth}>Timezone</th>
                          <td>
                            {generalInfoFormInitialValues &&
                              generalInfoFormInitialValues.timeZone &&
                              generalInfoFormInitialValues.timeZone.label}
                          </td>
                        </tr>
                        <tr>
                          <th style={tdWidth}>Status</th>
                          <td>
                            {generalInfoFormInitialValues &&
                            generalInfoFormInitialValues.memberStatus === 'AC'
                              ? 'Active'
                              : 'Preliminary Agency'}
                          </td>
                        </tr>
                        <tr>
                          <th style={tdWidth}>Free Document Download</th>
                          <td>
                            {generalInfoFormInitialValues &&
                            generalInfoFormInitialValues.isFreeDocDownload
                              ? 'Yes'
                              : 'No'}
                          </td>
                        </tr>
                        <tr>
                          <th style={tdWidth}>Agency Phone number</th>
                          <td>
                            {generalInfoFormInitialValues &&
                              formattedPhoneNumber(generalInfoFormInitialValues.buyerPhone)}
                          </td>
                        </tr>
                        <tr>
                          <th style={tdWidth}>Agency Website</th>
                          <td>
                            {generalInfoFormInitialValues ? (
                              <a
                                href={getWebsiteFn(generalInfoFormInitialValues.website)}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                {generalInfoFormInitialValues.website}
                              </a>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <h4>Publications</h4>
                  <div className='innerColIndent'>
                    {agencypublications.length > 0 ? (
                      <CommonDataTable
                        {...props}
                        data={agencypublications}
                        pagefor='summary'
                        fields={publicationFields}
                      />
                    ) : (
                      <NoResult message={'No Publications'} />
                    )}
                  </div>
                  <h4>Bid Types</h4>
                  <div className='innerColIndent'>
                    <CommonDataTable
                      {...props}
                      data={agencybidTypes}
                      pagefor='summary'
                      fields={bidTypeFields}
                    />
                  </div>
                  <h4>eBidding Documents</h4>
                  <div className='innerColIndent'>
                    {agencyEbidDocuments.length > 0 ? (
                      <CommonDataTable
                        {...props}
                        data={agencyEbidDocuments}
                        pagefor='summary'
                        fields={eBidDocumentFields}
                      />
                    ) : (
                      <NoResult message={'No eBidding Documents'} />
                    )}
                  </div>
                  <h4>Product Participation</h4>
                  <div className='innerColIndent'>
                    <ul className='list-unstyled productsList'>
                      {agencyProducts.map((item, index) => (
                        <li key={index}>{item.productName}</li>
                      ))}
                    </ul>
                  </div>
                  <h4>Legal Ads</h4>
                  <LegalAd {...props} pagefor='summary' legalData={legalAds} />
                  <h4>Agency Landing Page</h4>
                  <div className='innerColIndent'>
                    <ul className='list-unstyled productsList'>
                      <p>
                        <CopyBox
                          content={`${Configurations.REACT_APP_BASE_URL}/agencies/${state}/${escapedagencyname}/procurement-opportunities/${guid}/`}
                        />
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(
  connect(
    (state: any) => ({
      agencyregistration: state.agencyregistration,
      shared: state.shared,
      memberinfo: state.memberinfo,
    }),
    dispatch => bindActionCreators({ ...actionCreators }, dispatch),
  )(memo(AgencySummary)),
);
