import React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { Assert, hasValue, isValidEmailInfo } from '../../../../../utils/helpers';
import { AwardWizardId, notifyUnselectedState } from '../../../../../store/recoil/award-bid';
import { AddColleagues } from './AddColleagues';
import { AwardNotificationBidDetails } from '../../notify-pages/AwardNotificationBidDetails';
import { BidEmailPreview } from '../BidEmailPreview';
import { CustomMessageType } from '../../../../../types/awardBid';
import { EditEmailInfo } from '../../notify-pages/EditEmailInfo';
import { LoadableWrapper } from '../../../../common/loading/LoadableWrapper';
import { MessagePreview } from '../MessagePreview';
import { notifyColleaguesState } from '../../../../../store/recoil/award-bid';
import { useAwardBidWizard } from '../../../../../shared/hooks';
import { WizardButtons } from '../../../../customcontrols/wizard/WizardButtons';

export const NotifyColleagues = () => {
  // Hooks
  const { saveAwardBidWizard, returnToBidSummary, messagePreview } = useAwardBidWizard();

  // Recoil
  const emailInfo = useRecoilValueLoadable(notifyColleaguesState);
  const hasColleagues = !!(hasValue(emailInfo) && emailInfo.contents.recipients.length);
  const unselectedEmailInfo = useRecoilValueLoadable(notifyUnselectedState);
  const hasUnselected = !!(
    hasValue(unselectedEmailInfo) && unselectedEmailInfo.contents.recipients.length
  );

  function onPrevious() {
    if (messagePreview) {
      saveAwardBidWizard(AwardWizardId.NotifyColleagues);
    } else {
      saveAwardBidWizard(AwardWizardId.NotifyUnselected, hasUnselected);
    }
  }

  function onNext() {
    if (messagePreview || !hasColleagues) {
      saveAwardBidWizard(AwardWizardId.FinalizeAward);
    } else {
      saveAwardBidWizard(AwardWizardId.NotifyColleagues, true);
    }
  }

  function singleContactName() {
    Assert(hasValue(emailInfo), 'We should only call this when we have emailInfo loaded');
    Assert(
      !!emailInfo.contents.recipients[0].contactName,
      'This recipient must have a contact name.',
    );
    return emailInfo.contents.recipients[0].contactName;
  }

  return (
    <div>
      {messagePreview ? (
        <LoadableWrapper loadable={emailInfo}>
          {hasValue(emailInfo) && (
            <MessagePreview to={emailInfo.contents.recipients} subject={emailInfo.contents.subject}>
              <BidEmailPreview
                emailInfo={emailInfo.contents}
                addressee={
                  emailInfo.contents.recipients.length === 1
                    ? singleContactName()
                    : '{{CONTACT_NAME}}'
                }
              />
            </MessagePreview>
          )}
        </LoadableWrapper>
      ) : (
        <>
          <LoadableWrapper loadable={emailInfo}>
            <AddColleagues />
            <EditEmailInfo
              emailInfoState={notifyColleaguesState}
              customMessageType={CustomMessageType.Colleagues}
            />
          </LoadableWrapper>
          <AwardNotificationBidDetails />
        </>
      )}
      <WizardButtons
        onPrevious={onPrevious}
        onNext={onNext}
        altNext={messagePreview || !hasColleagues ? undefined : 'Preview'}
        inactiveAll={!hasValue(emailInfo) || !isValidEmailInfo(emailInfo.contents)}
        save={returnToBidSummary}
      ></WizardButtons>
    </div>
  );
};
