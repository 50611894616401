import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actionTypes';
import * as registrationApi from '../services/registration';
import * as sharedApi from '../services/shared';
import * as subscriptionApi from '../services/subscriptions';

import { formatPhoneNumber, toastFn } from '../../utils/helpers';

import { ProductType } from 'src/types/products';
import { registrationComponent } from '../../utils/constants';

function* watchLoadPreIncompleteRegistration() {
  yield takeLatest(
    actionTypes.LOAD_PRE_INCOMPLETE_REGISTRATION.TRIGGER,
    loadPreIncompleteRegistration,
  );
}

function* loadPreIncompleteRegistration(action: { payload: any }) {
  yield put({ type: actionTypes.LOAD_PRE_INCOMPLETE_REGISTRATION.REQUEST, meta: action.payload });
  try {
    yield put({
      type: actionTypes.CHECK_ACCOUNT_EXISTS.TRIGGER,
      payload: { companyName: '', emailAddress: action.payload.email },
    });
    yield put({ type: actionTypes.LOAD_PRE_INCOMPLETE_REGISTRATION.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_PRE_INCOMPLETE_REGISTRATION.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchCheckAccountExists() {
  yield takeLatest(actionTypes.CHECK_ACCOUNT_EXISTS.TRIGGER, checkAccountExists);
}

function* checkAccountExists(action: { payload: any }): any {
  yield put({ type: actionTypes.CHECK_ACCOUNT_EXISTS.REQUEST, meta: action.payload });
  try {
    const { emailAddress, companyName, memberId = '' } = action.payload;
    const payload = { email: emailAddress, company: companyName };
    const response = yield call(registrationApi.checkAccountExist, payload);
    if (
      response.data.result.isBlackList === false &&
      (response.data.result.planholderExist === false || memberId) &&
      response.data.result.companyExists === false &&
      response.data.result.accountExists === false
    ) {
      const isIncompleteResponse = yield call(registrationApi.getIncompleteRegistration, {
        email: emailAddress,
      });
      const responseData = isIncompleteResponse.data.result
        ? JSON.parse(isIncompleteResponse.data.result)
        : {};
      const currentComponent = responseData.regData
        ? responseData.regData.currentComponent
        : registrationComponent.ChooseAgency;
      let payload = { currentComponent } as any;

      const registration = yield select(state => state && state.registration);
      const { registrationData } = registration;

      if (responseData.regData) {
        /* responseData.regData = {...responseData.regData, registrationData:{...registrationData, ...responseData.regData.registrationData}};
                let Regpayload = {...responseData.regData};
                if(responseData.regData.currentComponent === registrationComponent.CreateAccount){
                    Regpayload = {...responseData.regData, currentComponent:registrationComponent.ChooseAgency};
                } */
        let Regpayload = {} as any;
        if (responseData.regData.registrationData) {
          responseData.regData = {
            ...responseData.regData,
            registrationData: { ...registrationData, ...responseData.regData.registrationData },
          };
          Regpayload = { ...responseData.regData };
          if (responseData.regData.currentComponent === registrationComponent.CreateAccount) {
            Regpayload = {
              ...responseData.regData,
              currentComponent: registrationComponent.ChooseAgency,
            };
          }
        } else {
          const registrationInfo = { ...registrationData };
          registrationInfo.emailAddress = responseData.regData.emailId;
          registrationInfo.companyName = responseData.regData.companyName;
          registrationInfo.acceptTerms = responseData.regData.isAcceptTerms;
          registrationInfo.freeAgency = parseInt(responseData.regData.selectedAgencyId);
          registrationInfo.selectedAgencyName = responseData.regData.selectedAgencyName
            ? responseData.regData.selectedAgencyName
            : '';
          if (responseData.regData.memberAddress && responseData.regData.memberAddress.city) {
            yield put({
              type: actionTypes.GET_COUNTIES_LIST.TRIGGER,
              payload: responseData.regData.memberAddress.stateId,
            });
            yield delay(1000);
            const shared = yield select(state => state && state.shared);
            let { stateslist = [] } = shared || {};
            const { countrieslist = [], countieslist = [] } = shared || {};

            if (stateslist.length === 0) {
              const response = yield call(sharedApi.getStatesList, action.payload);
              const Statepayload = yield (response.data && response.data.result) || [];
              if (Statepayload.length) {
                stateslist = Statepayload.map(
                  (items: { name: string; title: string; id: number }) => ({
                    ...items,
                    label: items.name || items.title,
                    value: items.id,
                  }),
                );
                yield put({
                  type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
                  payload: { stateslist },
                });
              }
            }

            const selectedState = stateslist.find(
              (item: any) => item.value === responseData.regData.memberAddress.stateId,
            );
            const selectedCountry = countrieslist.find((item: any) => item.value === 1);
            const selectedCounty = countieslist.find(
              (item: any) => item.value === responseData.regData.memberAddress.countyId,
            );

            registrationInfo.companyAddress = {
              address1: responseData.regData.memberAddress.address1,
              address2: responseData.regData.memberAddress.address2,
              city: responseData.regData.memberAddress.city,
              stateId: responseData.regData.memberAddress.stateId,
              stateName: selectedState,
              countyName: selectedCounty,
              countyId: responseData.regData.memberAddress.countyId,
              countryId: 1,
              country: selectedCountry,
              postalCode: responseData.regData.memberAddress.postalCode,
            };
          }

          if (responseData.regData.companyName) {
            registrationInfo.companyInfo.contactName = responseData.regData.companyName;
          }
          if (responseData.regData.memberPhone) {
            registrationInfo.companyInfo.contactPhone = responseData.regData.memberPhone;
          }
          if (responseData.regData.memberfax) {
            registrationInfo.companyInfo.fax = responseData.regData.memberfax;
          }
          if (responseData.regData.member.website) {
            registrationInfo.companyInfo.website = responseData.regData.member.website;
          }
          registrationInfo.subscriptions = {};
          if (responseData.subData.cartData && responseData.subData.cartData.length > 0) {
            const subscribedStates =
              responseData.subData.cartData
                .filter((item: any) => item.productType === ProductType.State)
                .map((item: any) => item.productId) || [];
            const subscribedCounties =
              responseData.subData.cartData
                .filter((item: any) => item.productType === ProductType.County)
                .map((item: any) => item.productId) || [];
            const subscribedNational =
              responseData.subData.cartData
                .filter((item: any) => item.productType === ProductType.National)
                .map((item: any) => item.productId) || [];
            registrationInfo.subscriptions = {
              subscriptionSelected: true,
              subscribedStates,
              subscribedCounties,
              subscribedNational,
            };
          }
          Regpayload = {
            currentComponent: responseData.regData.currentComponent,
            registrationData: { ...registrationInfo },
          };
        }

        yield put({ type: actionTypes.SET_REGISTRATION_DATA.TRIGGER, payload: Regpayload });
        if (
          Regpayload.registrationData &&
          Regpayload.registrationData.subscriptions &&
          Regpayload.registrationData.subscriptions.subscriptionSelected
        ) {
          const subscribedCountiesGroup = [] as Array<string>;
          if (Regpayload.registrationData.subscriptions.subscribedCounties) {
            const response = yield call(subscriptionApi.getAllProducts);
            const payload = response.data.result || [];
            Regpayload.registrationData.subscriptions.subscribedCounties.map((item: number) => {
              const filteredProduct = payload.find((Pitem: any) => Pitem.productId === item);
              if (filteredProduct) {
                subscribedCountiesGroup.push(
                  `${filteredProduct.productGroupId}_${filteredProduct.parentId}_${filteredProduct.productId}`,
                );
              }
            });
          }

          yield put({
            type: actionTypes.SET_SUBSCRIPTIONS_DETAILS.TRIGGER,
            payload: { ...Regpayload.registrationData.subscriptions, subscribedCountiesGroup },
          });
        }
      } else {
        payload = {
          ...payload,
          registrationData: {
            ...registrationData,
            emailAddress,
            companyName,
            acceptTerms: true,
            isNew: true,
          },
        };
        const inCompletePayload = {
          email: emailAddress,
          JsonData: {
            regData: {
              currentComponent: registrationComponent.ChooseAgency,
              registrationData: {
                emailAddress: emailAddress,
                companyName: companyName,
                acceptTerms: true,
              },
            },
          },
        };
        yield put({
          type: actionTypes.ADD_INCOMPLETE_REGISTRATION_DATA.TRIGGER,
          payload: inCompletePayload,
        });
      }
      yield put({
        type: actionTypes.SET_REGISTRATION_DATA.TRIGGER,
        payload: { ...payload, claimSent: false },
      });
    } else {
      yield put({
        type: actionTypes.SET_REGISTRATION_DATA.TRIGGER,
        payload: {
          currentComponent: registrationComponent.EmailAccount,
          errorData: response.data.result,
          planholderEmail: emailAddress,
          claimSent: false,
        },
      });
    }

    yield put({ type: actionTypes.CHECK_ACCOUNT_EXISTS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.CHECK_ACCOUNT_EXISTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchAddIncompleteRegistrationData() {
  yield takeLatest(
    actionTypes.ADD_INCOMPLETE_REGISTRATION_DATA.TRIGGER,
    addInCompleteRegistrationData,
  );
}

function* addInCompleteRegistrationData(action: { payload: any }): any {
  yield put({ type: actionTypes.ADD_INCOMPLETE_REGISTRATION_DATA.REQUEST, meta: action.payload });
  try {
    const response = yield call(registrationApi.addInCompleteRegistrationData, action.payload);
    yield put({ type: actionTypes.ADD_INCOMPLETE_REGISTRATION_DATA.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.ADD_INCOMPLETE_REGISTRATION_DATA.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchAddMemberAccount() {
  yield takeLatest(actionTypes.ADD_MEMBER_ACCOUNT.TRIGGER, addMemberAccount);
}

function* addMemberAccount(action: { payload: any }): any {
  yield put({ type: actionTypes.ADD_MEMBER_ACCOUNT.REQUEST, meta: action.payload });
  try {
    yield delay(1000);
    const registration = yield select(state => state && state.registration);
    const subscriptions = yield select(state => state && state.subscriptions);
    const { registrationData = {}, memberId = '' } = registration;
    const {
      subscribedCounties = [],
      subscribedStates = [],
      subscribedNational = [],
    } = subscriptions;
    const requestPayload = {
      account: {},
      member: {},
      memberAddress: {},
      memberContact: {},
      memberContactPhone: {},
      memberPhones: [],
    } as any;
    requestPayload.account = {
      userName: registrationData.emailAddress,
      firstName: registrationData.mainContact.firstName,
      lastName: registrationData.mainContact.lastName,
      initials: `${registrationData.mainContact.firstName[0]}${registrationData.mainContact.lastName[0]}`,
    };

    let userPayment = false;
    if (
      subscribedCounties.length > 0 ||
      subscribedStates.length > 0 ||
      subscribedNational.length > 0
    ) {
      userPayment = true;
    }

    if (
      subscribedCounties.length > 0 ||
      subscribedStates.length > 0 ||
      subscribedNational.length > 0 ||
      registrationData.freeAgency
    ) {
      requestPayload.member.memberType = 'SS';
      requestPayload.member.memberStatus = 'SW';
    } else {
      requestPayload.member.memberType = 'SB';
      requestPayload.member.memberStatus = 'AC';
    }
    requestPayload.member.memberId = memberId;
    requestPayload.member.productIds = registrationData.freeAgency
      ? [registrationData.freeAgency]
      : [];
    requestPayload.member.scraperDomain = '';
    requestPayload.member.shortName = registrationData.companyName;
    requestPayload.member.website = registrationData.companyInfo.website;
    requestPayload.memberAddress = {
      ...registrationData.companyAddress,
      stateId: registrationData.companyAddress.stateName.value,
      countyId: registrationData.companyAddress.countyName.value,
    };
    requestPayload.memberContact = { email: registrationData.mainContact.email, mainContact: true };
    requestPayload.memberContactPhone = {
      phoneType: 'MN',
      phoneNumber: formatPhoneNumber(registrationData.mainContact.phone),
    };
    requestPayload.memberPhones.push({
      phoneType: 'MN',
      phoneNumber: formatPhoneNumber(registrationData.companyInfo.contactPhone),
    });
    if (registrationData.companyInfo.fax) {
      requestPayload.memberPhones.push({
        phoneType: 'FX',
        phoneNumber: formatPhoneNumber(registrationData.companyInfo.fax),
      });
    }

    const response = yield call(registrationApi.addMemberAccount, requestPayload);

    if (response.data.result.memberId) {
      let nextComponent = registrationComponent.AccountConfirmation;
      if (userPayment) {
        nextComponent = registrationComponent.ReviewOrder;
      }
      yield put({
        type: actionTypes.SET_REGISTRATION_DATA.TRIGGER,
        payload: {
          memberId: response.data.result.memberId,
          isRegistrationCompleted: true,
          currentComponent: nextComponent,
        },
      });
      yield put({ type: actionTypes.ADD_MEMBER_ACCOUNT.SUCCESS });
    } else {
      toastFn('error', 'Failed', 'Supplier registration - Add Member Account');
      yield put({ type: actionTypes.ADD_MEMBER_ACCOUNT.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_MEMBER_ACCOUNT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchClaimPlanholderAccount() {
  yield takeLatest(actionTypes.CLAIM_PLANHOLDER_ACCOUNT.TRIGGER, claimPlanholderAccount);
}

function* claimPlanholderAccount(action: { payload: any }): any {
  yield put({ type: actionTypes.CLAIM_PLANHOLDER_ACCOUNT.REQUEST, meta: action.payload });
  try {
    const response = yield call(registrationApi.claimPlanholderAccount, action.payload);
    if (response.data.result.status) {
      yield put({ type: actionTypes.SET_REGISTRATION_DATA.TRIGGER, payload: { claimSent: true } });
    }
    yield put({ type: actionTypes.CLAIM_PLANHOLDER_ACCOUNT.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.CLAIM_PLANHOLDER_ACCOUNT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* registrationSaga() {
  yield fork(watchCheckAccountExists);
  yield fork(watchAddIncompleteRegistrationData);
  yield fork(watchAddMemberAccount);
  yield fork(watchLoadPreIncompleteRegistration);
  yield fork(watchClaimPlanholderAccount);
}
