import { Cell, Column } from 'react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { DSButton } from '@demandstar/components/button';
import { DSTable } from '@demandstar/components/table';
import { DSTextField } from '@demandstar/components/fields';

import {
  NewRequiredDocType,
  RequiredDocType,
  useRequiredDocumentTypes,
} from '../../../../shared/hooks/award-bid/useRequiredDocumentTypes';
import { PanelLabel, PanelRow, PanelRowFlexSpaceBetween } from '../../../../shared/styles';
import { Assert } from '../../../../utils/helpers';
import { commonLabels } from '../../../../shared/constants';
import { DeleteIconCell } from '../../../common/table/renderers/DeleteIconCell';
import { ErrorPanel } from '../../../common/loading';
import { Id } from '../../../../types/shared';
import { requiredDocLabels } from './constants';
import { SubmitButton } from '../../../common/controls/buttons/SubmitButton';
import { TextInput } from '../../../forms';
import { WidgetSet } from '../../../common/form';

interface EditDocTypeForm {
  docTypes: RequiredDocType[];
}
interface AddCustomDocumentTypeFormProps {
  closeModal: () => void;
}

export function CustomDocumentTypeAddForm({ closeModal }: AddCustomDocumentTypeFormProps) {
  // Hooks
  const { addRequiredDocTypes, requiredDocTypes, saveRequiredDocTypes } =
    useRequiredDocumentTypes();

  const {
    register,
    formState: { errors, isValid },
    getValues,
    reset,
  } = useForm<EditDocTypeForm>({
    mode: 'onTouched',
    defaultValues: { docTypes: requiredDocTypes },
  });

  // Local state
  const [newCustomDocType, setNewCustomDocType] = useState<NewRequiredDocType>({
    description: '',
    title: '',
    sortOrder: 0,
    isDelete: false,
  });

  const [deletedTypes, setDeletedTypes] = useState<RequiredDocType[]>([]);

  /** saves the form values, and calls endpoint while updating recoil
   * @param {number} deleteId - optional id to delete.
   * @returns any deleted documents for easy display
   */
  const saveDocTypes = useCallback(
    async (deleteId?: number) => {
      const updatedRequiredDocTypes = requiredDocTypes.map((docType, index) => {
        const docTypeFormValues = getValues().docTypes[index];
        const title = docTypeFormValues.title;
        const description = docTypeFormValues.description;
        const isDelete = !title || docType.memberAwardRequiredDocResponseItemId === deleteId;
        return {
          ...docType,
          title,
          description,
          isDelete,
        };
      });
      return await saveRequiredDocTypes(updatedRequiredDocTypes);
    },
    [getValues, requiredDocTypes, saveRequiredDocTypes],
  );

  const deleteRow = useCallback(
    async ({ id }: Id<number>) => {
      Assert(!!id, 'We must pass an id to delete row');
      const deletedDocs = await saveDocTypes(id);
      setDeletedTypes([...deletedTypes, ...deletedDocs]);
    },
    [deletedTypes, saveDocTypes],
  );

  const getIndexFromId = useCallback(
    (id: string) => {
      return requiredDocTypes.findIndex(docType => {
        return docType.memberAwardRequiredDocResponseItemId === Number(id);
      });
    },
    [requiredDocTypes],
  );

  // Form hook functions
  const customDocTableConfig: Column<RequiredDocType>[] = useMemo(() => {
    return [
      {
        accessor: 'title',
        Header: 'Title',
        minWidth: 300,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: Cell<RequiredDocType>) => {
          const rowIndex = getIndexFromId(cellData.row.id);
          const rowErrors = errors.docTypes ? errors.docTypes[rowIndex] : undefined;
          const titleError = rowErrors ? rowErrors.title : undefined;
          return (
            <DSTextField
              name={`docTypes.${rowIndex}.title`}
              ref={register()}
              error={titleError}
              optional
            />
          );
        },
      },
      {
        accessor: 'description',
        Header: 'Description',
        minWidth: 300,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: Cell<RequiredDocType>) => {
          const rowIndex = getIndexFromId(cellData.row.id);
          const rowErrors = errors.docTypes ? errors.docTypes[rowIndex] : undefined;
          const descriptionError = rowErrors ? rowErrors.description : undefined;
          return (
            <DSTextField
              name={`docTypes.${rowIndex}.description`}
              ref={register()}
              error={descriptionError}
              optional
            />
          );
        },
      },
      {
        accessor: 'isDelete',
        Header: '',
        Cell: DeleteIconCell<number, RequiredDocType>({
          idField: 'memberAwardRequiredDocResponseItemId',
          labelField: 'title',
          handleClick: deleteRow,
        }),
        maxWidth: 30,
        disableSortBy: true,
      },
    ] as Column<RequiredDocType>[];
  }, [deleteRow, errors.docTypes, getIndexFromId, register]);

  useEffect(() => {
    // When requiredDocTypes changes, we update all our form fields accordingly.
    if (requiredDocTypes) {
      reset({ docTypes: requiredDocTypes });
    }
  }, [requiredDocTypes, reset]);

  function handleTitleChange(title: string) {
    setNewCustomDocType({ ...newCustomDocType, title });
  }
  function handleDescriptionChange(description: string) {
    setNewCustomDocType({ ...newCustomDocType, description });
  }

  async function submitForm() {
    if (newCustomDocType.title) {
      addRequiredDocTypes([newCustomDocType]);
    }
    saveDocTypes();
    closeModal();
  }

  return (
    <>
      <PanelLabel>{requiredDocLabels.addNewDocumentType}</PanelLabel>
      <WidgetSet>
        <TextInput
          name={'documentTypeName'}
          label={requiredDocLabels.documentTypeTitle}
          onChange={handleTitleChange}
          value={newCustomDocType.title}
          required
        ></TextInput>
        <TextInput
          name={'documentTypeDescription'}
          label={requiredDocLabels.documentTypeDescription}
          onChange={handleDescriptionChange}
          value={newCustomDocType.description}
          disabled={!newCustomDocType.title}
        ></TextInput>
      </WidgetSet>
      <br></br>
      <PanelLabel>{requiredDocLabels.editDocumentTypes}</PanelLabel>

      <DSTable
        columns={customDocTableConfig}
        data={requiredDocTypes}
        emptyMessage={commonLabels.notFound}
        sort='title'
        rowKey='memberAwardRequiredDocResponseItemId'
      />
      {deletedTypes.map(docType => {
        return (
          <ErrorPanel
            key={docType.memberAwardRequiredDocResponseItemId}
            message={`${docType.title} was deleted.`}
          />
        );
      })}
      <PanelRow paddingVertical={'0.5rem'} />

      <PanelRowFlexSpaceBetween>
        <DSButton buttonType='secondary' onClick={closeModal}>
          {commonLabels.cancel}
        </DSButton>
        <SubmitButton onClick={submitForm} inactive={!isValid}>
          {commonLabels.save}
        </SubmitButton>
      </PanelRowFlexSpaceBetween>
    </>
  );
}
