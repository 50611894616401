import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';
import { activitiesTypes } from '../../types';
import {
  bidReportsActivityPaginationsOptions,
  quoteReportsActivityPaginationsOptions,
  reportbidStatus,
  reportOptions,
} from '../../utils/constants';
import moment from 'moment-timezone';

export const activitiesNotificationsInitialValue = {
  page: 1,
  result: [],
  accounts: [],
  size: 0,
  total: 0,
  bidsCount: 0,
  accountCount: 0,
};

export const activitiesInitialValue = {
  page: 1,
  result: [],
  size: 0,
  total: 0,
};

export const bidreports_quotesInitialValue = {
  bidActivity: [],
  upcomingBids: 0,
  activeBids: 0,
  underEvaluationBids: 0,
  awardedBids: 0,
  formalBidsYTD: 0,
  totalBids: 0,
};

export const quotereports_quotesInitialValue = {
  quoteActivity: [],
  cancelledQuotes: 0,
  closedQuotes: 0,
  incompleteQuotes: 0,
  awardedQuotes: 0,
  formalQuotesYTD: 0,
  openQuotes: 0,
  totalQuotes: 0,
};

export const initialStateActivities: activitiesTypes = {
  activities_notifications: activitiesNotificationsInitialValue,
  activities_notifications_member: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_watchs: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_planholders: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_ebidders: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_quotes: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_notifications_search: {
    page: 1,
    result: [],
    accounts: [],
    size: 0,
    total: 0,
    bidsCount: 0,
    accountCount: 0,
  },
  activities_watchs_search: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_planholders_search: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_ebidders_search: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_quotes_search: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  notifications_internalLoader: true,
  notifications_member_internalLoader: true,
  watchs_internalLoader: true,
  planholders_internalLoader: true,
  ebidders_internalLoader: true,
  quotes_internalLoader: true,
  currentnotificationcategory: 'Bids',
  search: '',
  activitiesSearch: false,
  searchSelect: { label: 'Title', value: 'title' },
  search_internalLoader: true,
  notificationStatus: { label: 'All', value: '' },
  bitWatchStatus: { label: 'All', value: '' },
  planholderStatus: { label: 'All', value: '' },
  ebidproposalStatus: { label: 'All', value: '' },
  quotesStatus: { label: 'All', value: '' },
  activities_bidreports: bidreports_quotesInitialValue,
  activities_bidreports_search: false,
  activities_bidreports_search_result: bidreports_quotesInitialValue,
  bidreports_status: reportbidStatus[0],
  bidreports_startDate: moment().subtract(1, 'months'),
  bidreports_endDate: moment(),
  bidreport_internalLoader: false,
  bidReportsActivityPaginations: bidReportsActivityPaginationsOptions[0],
  //selectedReport: reportOptions[0],
  activities_quotereports: quotereports_quotesInitialValue,
  activities_quotereports_search: false,
  activities_quotereports_search_result: quotereports_quotesInitialValue,
  quotereports_status: reportbidStatus[0],
  quotereports_startDate: moment().subtract(1, 'months'),
  quotereports_endDate: moment(),
  quotereport_internalLoader: false,
  quoteReportsActivityPaginations: quoteReportsActivityPaginationsOptions[0],
};

export const reducer = (state = initialStateActivities, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.SET_ACTIVITIES_DETAILS.TRIGGER:
      state = { ...state, ...payload };
      break;
    case actionTypes.RESET_ACTIVITIES_DETAILS.TRIGGER:
      state = initialStateActivities;
      break;
    case actionTypes.GET_ACTIVITIES_NOTIFICATIONS.SUCCESS:
      state = {
        ...state,
        activities_notifications: payload,
        notifications_internalLoader: false,
      };
      break;
    case actionTypes.GET_ACTIVITIES_NOTIFICATIONS.FAILURE:
      state = { ...state, notifications_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_NOTIFICATIONS_MEMBER.SUCCESS:
      state = {
        ...state,
        activities_notifications_member: payload,
        notifications_member_internalLoader: false,
      };
      break;
    case actionTypes.GET_ACTIVITIES_NOTIFICATIONS_MEMBER.FAILURE:
      state = { ...state, notifications_member_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_WATCHS.SUCCESS:
      state = { ...state, activities_watchs: payload, watchs_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_WATCHS.FAILURE:
      state = { ...state, watchs_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_PLANHOLDERS.SUCCESS:
      state = {
        ...state,
        activities_planholders: payload,
        planholders_internalLoader: false,
      };
      break;
    case actionTypes.GET_ACTIVITIES_PLANHOLDERS.FAILURE:
      state = { ...state, planholders_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_EBIDDERS.SUCCESS:
      state = {
        ...state,
        activities_ebidders: payload,
        ebidders_internalLoader: false,
      };
      break;
    case actionTypes.GET_ACTIVITIES_EBIDDERS.FAILURE:
      state = { ...state, ebidders_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_QUOTES.SUCCESS:
      state = { ...state, activities_quotes: payload, quotes_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_QUOTES.FAILURE:
      state = { ...state, quotes_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_SEARCH.SUCCESS:
      state = { ...state, search_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_SEARCH.FAILURE:
      state = { ...state, search_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_BIDREPORT.SUCCESS:
      state = {
        ...state,
        activities_bidreports: payload?.search ? state?.activities_bidreports : payload?.data,
        activities_bidreports_search_result: payload?.search
          ? payload?.data
          : state?.activities_bidreports_search_result,
        bidreport_internalLoader: false,
      };
      break;
    case actionTypes.GET_ACTIVITIES_BIDREPORT.FAILURE:
      state = { ...state, bidreport_internalLoader: false };
      break;
    case actionTypes.GET_ACTIVITIES_QUOTEREPORT.SUCCESS:
      state = {
        ...state,
        activities_quotereports: payload?.search ? state?.activities_quotereports : payload?.data,
        activities_quotereports_search_result: payload?.search
          ? payload?.data
          : state?.activities_quotereports_search_result,
        quotereport_internalLoader: false,
      };
      break;
    case actionTypes.GET_ACTIVITIES_QUOTEREPORT.FAILURE:
      state = { ...state, quotereport_internalLoader: false };
      break;
    default:
      break;
  }
  return state;
};
