/**TOREFACTOR: Rewrite this to use Controller */
import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import { AllowedTextInputType, BaseTextInput } from '../../inputs';
import {
  FieldContainer,
  FieldContainerProps,
  getFieldContainerProps,
} from './../../field-container';
import { Inactive } from 'src/types/shared';
import { RelativeContainer } from './../../assets/CommonStyles';

export type DSTextFieldProps = Omit<FieldContainerProps, 'children'> &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'autoFocus' | 'placeholder'> &
  Inactive & {
    /** required ref */
    ref: ForwardedRef<HTMLInputElement>;
    /** optional isNumeric */
    isNumeric?: boolean;
    /** paired down HTML type */
    type?: AllowedTextInputType;
  };

/** DSTextField - text field exclusively used with react-hook-form
 * @example <DSTextField
              name='supplierName'
              label='Supplier Name'
              ref={register({ required: true })}
              message={"This is the name of the business that won the contract."}
              error={errors.supplierName}
            />
 * */
export const DSTextField = forwardRef(
  (props: DSTextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      autoFocus,
      dataTestId,
      inactive,
      isNumeric,
      name,
      error,
      placeholder,
      type = 'text',
    } = props;

    return (
      <FieldContainer {...getFieldContainerProps(props)}>
        <RelativeContainer>
          <BaseTextInput
            autoFocus={autoFocus}
            data-testid={dataTestId}
            error={error}
            id={name}
            isNumeric={isNumeric || type === 'number'}
            name={name}
            placeholder={placeholder}
            readOnly={inactive}
            ref={ref}
            type={type}
          />
        </RelativeContainer>
      </FieldContainer>
    );
  },
);
