import { FormProvider, useForm } from 'react-hook-form';

import { DSEmailField, DSTextField } from '@demandstar/components/fields';

import {
  AwardBidEventName,
  useAwardBidEvents,
} from '../../../../shared/hooks/amplitude/awardBid.events';
import { SubmitButton } from '../../../common/controls/buttons/SubmitButton';
import { useSavePlanholder } from '../../../../shared/hooks';

interface AddNewAwardeeProps {
  closeModal: () => void;
}

interface AddAwardeeForm {
  supplierName: string;
  email: string;
}

/**
 * A pop-up which allows the user to add a planholder and automatically
 * assign that planholder as an awardee.
 */
export const AddNewAwardee = (props: AddNewAwardeeProps) => {
  const { closeModal } = props;

  // Hooks
  const { logEvent } = useAwardBidEvents();
  const methods = useForm<AddAwardeeForm>({ mode: 'onTouched' });
  const {
    register,
    formState: { errors, isValid },
    getValues,
  } = methods;

  const savePlanholder = useSavePlanholder();

  async function saveNewSupplier() {
    logAddSupplierAmplitude();
    await savePlanholder({ name: getValues().supplierName, email: getValues().email }, true);
  }

  async function saveAwardeeAndClose() {
    await saveNewSupplier();
    closeModal();
  }

  function logAddSupplierAmplitude() {
    logEvent({
      eventName: AwardBidEventName.NonDemandStarSupplier,
      eventProps: {
        'Awardee Name': getValues().supplierName,
        'Email Address': getValues().email,
      },
    });
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-12 col-md-12 col-lg-10'>
        <div>
          <FormProvider {...methods}>
            <DSTextField
              name='supplierName'
              label='Supplier Name'
              ref={register({ required: true })}
              error={errors.supplierName}
            />
            <DSEmailField name='email' label='Email' />
          </FormProvider>
        </div>
        <SubmitButton inactive={!isValid} onClick={saveAwardeeAndClose}>
          Save Supplier
        </SubmitButton>
      </div>
    </div>
  );
};
