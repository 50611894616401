import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';
import Api from './api';

export function getLimitedAgencySummary(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencysummary, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function getLimitedAgencyDocuments(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencydocuments, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function getLimitedAgencyLegal(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencylegal, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function getLimitedAgencyPlanholders(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencyplanholders, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function getLimitedAgencyCommodities(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencycommodityByType, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function getLimitedAgencyAwards(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencyAwards, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function getLimitedAgencySelfDeclarations(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencyselfDeclarations, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function getLimitedStatesList(data = {}) {
  return Api.getRequest(Paths.geoStates, { baseURL: constants.api.userUrl });
}

export function getLimitedCountiesList(data = {}) {
  return Api.getRequestWithAuthentication(`${Paths.geoCounties}?stateId=${data}`, {
    baseURL: constants.api.userUrl,
  });
}

export function getLimitedTimezoneList(data = {}) {
  return Api.getRequest(Paths.timezones, { baseURL: constants.api.userUrl });
}

export function getLimitedMetroList(data = {}) {
  return Api.postRequest(Paths.metro, data, { baseURL: constants.api.userUrl });
}

export function submitLimitedAddMemberAccount(data = {}) {
  return Api.postRequest(Paths.AddMemberAccount, data, { baseURL: constants.api.userUrl });
}

export function submitLimitedIsCompanyOrAccountExists(data = {}) {
  return Api.postRequest(Paths.IsCompanyOrAccountExists, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getLimitedWappagencies(data = {}) {
  return Api.postRequest(Paths.Wappagencies, data, { baseURL: constants.api.userUrl });
}

export function submitLimitedForgotPassword(data = {}) {
  return Api.postRequest(Paths.forgotPassword, data, { baseURL: constants.api.userUrl });
}

export function submitLimitedForgotEmail(data = {}) {
  return Api.postRequest(Paths.ForgotLogin, data, { baseURL: constants.api.userUrl });
}

export function submitLimitedResetPassword(data = {}) {
  return Api.postRequest(Paths.ResetPassword, data, { baseURL: constants.api.authUrl });
}

export function getLimitedVerifySecurityCode(data = {}) {
  return Api.getRequestWithAuthentication(`${Paths.VerifySecurityCode}?securityCode=${data}`, {
    baseURL: constants.api.userUrl,
  });
}

export function getLimitedAgencyLanding(id = '') {
  return Api.getRequest(`${Paths.agencyLanding}?id=${id}`, { baseURL: constants.api.agencyUrl });
}

export function getLimitedAgencyDetails(data: { guid: string; memberId: number | string }) {
  let param = '';
  if (data.guid) param = `id=${data.guid}`;
  if (data.memberId) param = `memberId=${data.memberId}`;
  return Api.getRequest(`${Paths.getAgencyDetails}?${param}`, { baseURL: constants.api.agencyUrl });
}
