import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import { formatPhoneNumber, toastFn } from '../../utils/helpers';
import * as agencyApi from '../services/agencyregistration';
import * as sharedApi from '../services/shared';
import history from '../../utils/history';
import moment from 'moment-timezone';
import { agencyPageTheme, imagePositions, userEvent } from '../../utils/constants';
import { constants } from '../../utils/settings';
import { RegistrationPayload } from '../../types/registration';

export function* watchCreateBuyerSaga() {
  yield takeLatest(actionTypes.CREATE_BUYER.TRIGGER, createBuyer);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
export function* createBuyer(action: { payload: Record<string, unknown> }): any {
  yield put({ type: actionTypes.CREATE_BUYER.REQUEST, meta: action.payload });
  try {
    const agencyregistration = yield select(state => state && state.agencyregistration);
    const auth = yield select(state => state.auth);
    const {
      generalInfoFormInitialValues: values = {},
      agBidTypes = [],
      legalAds = {},
      agencypublications = [],
      agencyEbidDocuments = [],
      pageSettingFields,
    } = agencyregistration;
    const { us = '' } = auth;

    const fieldValues: any = {
      userName: values.loginusername,
      firstName: values.firstname,
      lastName: values.lastname,
      contactPhone: formatPhoneNumber(values.contactPhone),
      contactEmail: values.contactEmail,
      buyerName: values.agencyname,
      memberStatus: values.memberStatus,
      postalCode: values.zipcode,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      buyerPhone: formatPhoneNumber(values.buyerPhone),
      planholderDisplayType: values.planholderDisplayType && values.planholderDisplayType.value,
      website: values.website,
      countryId: values.country && values.country.value,
      timeZoneId: values.timeZone && values.timeZone.value,
      countyId: values.county && values.county.value,
      metroId: values.metro && values.metro.value,
      metro: values.metro && values.metro.label,
      stateId: values.state ? values.state.value : values.country ? values.county.parentId : null,
      createdBy: us,
      initialDate: values.initialDate,
    };

    if (
      !fieldValues.countyId ||
      !fieldValues.postalCode ||
      !fieldValues.metroId ||
      !fieldValues.stateId
    ) {
      yield put({
        type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER,
        payload: { wizardcurrentpage: 1 },
      });
      yield put({
        type: actionTypes.CREATE_BUYER.FAILURE,
        payload: { payload: action.payload, error: 'missing required Data' },
      });
      toastFn('error', 'Missing Address Data', 'Add agency');
    } else {
      const response = yield call(agencyApi.createBuyer, fieldValues);

      if (response.data.result.memberId) {
        if (agBidTypes.length > 0) {
          const bidTypes = agBidTypes.map((item: { bidType: string; bidTypeDesc: string }) => ({
            type: item.bidType,
            description: item.bidTypeDesc,
          }));

          const bidTypePayload = { bidTypes, memberId: response.data.result.memberId };
          yield call(agencyApi.addAgencyBidType, bidTypePayload);
        }

        const legalAdPayload = {
          memberId: response.data.result.memberId,
          quoteIntro: legalAds.quoteIntro,
          quoteClose: legalAds.quoteClose,
          quoteInsurance: legalAds.quoteInsurance,
          legalIntro: legalAds.legalAdIntro,
          legalCostInfo: legalAds.legalAdCostInfo,
          legalDueDateTime: legalAds.legalAdDueDateTime,
          legalAdditional: legalAds.legalAdAdditional,
          legalClosing: legalAds.legalAdClosing,
        };

        yield call(agencyApi.agencyAddLegalAd, legalAdPayload);

        if (agencypublications.length > 0) {
          const publicationPayload = {
            buyerId: response.data.result.memberId,
            buyerAccountId: response.data.result.accountId,
            publications: agencypublications,
          };

          yield call(agencyApi.agencyAddPublications, publicationPayload);
        }

        if (agencyEbidDocuments.length > 0) {
          const eBidDocumentPayload = {
            buyerId: response.data.result.memberId,
            buyerAccountId: response.data.result.accountId,
            documents: agencyEbidDocuments,
          };

          yield call(agencyApi.agencyAddEbidDocuments, eBidDocumentPayload);
        }

        const finalvalue = {
          ...pageSettingFields,
          logo: pageSettingFields?.logo?.name ? pageSettingFields.logo : '',
          banner: pageSettingFields?.banner?.name ? pageSettingFields.banner : '',
          theme: pageSettingFields.theme.value,
          memberId: response.data.result.memberId,
        };
        yield call(agencyApi.updateAgencySettings, finalvalue);

        yield call(agencyApi.agencyFinishRegistration, {
          memberId: response.data.result.memberId,
          createdBy: auth.us,
        });

        history.push(`/ops/agency/registration/summary/${response.data.result.memberId}`);
        toastFn('success', 'Agency Created', 'Agency Page Settings');
        yield put({ type: actionTypes.CREATE_BUYER.SUCCESS });
      } else {
        let errormsg = '';
        if (response.data.result.accountExists) {
          errormsg = `${errormsg}${response.data.result.accountExistsAlert}`;
        }
        if (response.data.result.buyerExists) {
          errormsg = `${errormsg}\n${response.data.result.buyerExistsAlert}`;
        }
        toastFn('error', errormsg, 'Add agency');
        yield put({
          type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER,
          payload: { wizardcurrentpage: 1 },
        });
        yield put({ type: actionTypes.CREATE_BUYER.FAILURE });
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_BUYER.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', 'Add agency');
  }
}
export function* watchAddAgencyBidTypes() {
  yield takeLatest(actionTypes.ADD_AGENCY_BID_TYPES.TRIGGER, AddAgencyBidTypes);
}
function* AddAgencyBidTypes(action: { payload: { bidType: string; description: string } }): any {
  yield put({ type: actionTypes.ADD_AGENCY_BID_TYPES.REQUEST, meta: action.payload });

  try {
    const response = yield call(agencyApi.addAgencyBidType, action.payload);
    if (response.data.result.status === true) {
      const agencyregistration = yield select(state => state.agencyregistration);
      const { agBidTypes = [] } = agencyregistration;
      const newBidTypes = [
        { bidType: action.payload.bidType, bidTypeDesc: action.payload.description },
      ];
      agBidTypes.forEach((item: { bidType: string; bidTypeDesc: string }, index: any) => {
        newBidTypes.push(item);
      });

      yield put({
        type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER,
        payload: { agBidTypes: newBidTypes },
      });
      yield put({ type: actionTypes.ADD_AGENCY_BID_TYPES.SUCCESS });
      toastFn('success', 'Added', 'AgencyAddbidType');
    } else {
      yield put({ type: actionTypes.ADD_AGENCY_BID_TYPES.FAILURE });
      toastFn('error', 'Failed', 'AgencyAddbidType');
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_AGENCY_BID_TYPES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchGetAddagencyLegalads() {
  yield takeLatest(actionTypes.GET_ADD_AGENCY_LEGAL_AD.TRIGGER, getAddagencyLegalads);
}
function* getAddagencyLegalads(action: RegistrationPayload): any {
  yield put({ type: actionTypes.GET_ADD_AGENCY_LEGAL_AD.REQUEST, meta: action.payload });

  try {
    const response = yield call(agencyApi.getAgencyLegalAd, action.payload);

    const legalAdData = { ...response.data.result };
    if (response.data.result.eQuoteClose) legalAdData.quoteClose = response.data.result.eQuoteClose;
    if (response.data.result.eQuoteInsurance)
      legalAdData.quoteInsurance = response.data.result.eQuoteInsurance;
    if (response.data.result.eQuoteIntro) legalAdData.quoteIntro = response.data.result.eQuoteIntro;

    const payload = { legalAds: { ...legalAdData } };
    yield put({ type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER, payload });
    yield put({ type: actionTypes.ADD_AGENCY_BID_TYPES.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_AGENCY_LEGAL_AD.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchagencyAddLegalAd() {
  yield takeLatest(actionTypes.SUBMIT_AGENCY_LEGAL_AD.TRIGGER, agencyAddLegalAd);
}
function* agencyAddLegalAd(action: { payload: { memberId: number; data: any } }): any {
  yield put({ type: actionTypes.SUBMIT_AGENCY_LEGAL_AD.REQUEST, meta: action.payload });

  try {
    const { data: values = {} } = action.payload;

    const fieldvalues = {
      ...action.payload,
      memberId: action.payload.memberId,
      quoteIntro: values.quoteIntro,
      quoteClose: values.quoteClose,
      quoteInsurance: values.quoteInsurance,
      legalIntro: values.legalAdIntro,
      legalCostInfo: values.legalAdCostInfo,
      legalDueDateTime: values.legalAdDueDateTime,
      legalAdditional: values.legalAdAdditional,
      legalClosing: values.legalAdClosing,
    };
    const response = yield call(agencyApi.agencyAddLegalAd, fieldvalues);
    if (response.data.result.status === true) {
      const auth = yield select(state => state.auth);
      const agencyregistration = yield select(state => state.agencyregistration);
      const { wizardcurrentpage } = agencyregistration;
      yield call(agencyApi.agencyFinishRegistration, {
        memberId: action.payload.memberId,
        createdBy: auth.us,
      });
      toastFn('success', 'Saved', 'Add agency legalad');
      yield put({ type: actionTypes.SUBMIT_AGENCY_LEGAL_AD.SUCCESS });
      const payload = { wizardcurrentpage: wizardcurrentpage + 1 };
      yield put({ type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER, payload });
      yield put({
        type: actionTypes.GET_ADD_AGENCY_LEGAL_AD.TRIGGER,
        payload: { memberId: action.payload.memberId },
      });
    } else {
      toastFn('error', 'Failed', 'Add agency legalad');
      yield put({ type: actionTypes.SUBMIT_AGENCY_LEGAL_AD.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', 'Add agency legalad');
    yield put({
      type: actionTypes.SUBMIT_AGENCY_LEGAL_AD.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchAgencyAddPublications() {
  yield takeLatest(actionTypes.AGENCY_ADD_PUBLICATIONS.TRIGGER, agencyAddPublications);
}
function* agencyAddPublications(action: { payload: { buyerId: number } }): any {
  yield put({ type: actionTypes.AGENCY_ADD_PUBLICATIONS.REQUEST, meta: action.payload });

  try {
    const response = yield call(agencyApi.agencyAddPublications, action.payload);
    if (response.data.result.status === true) {
      yield put({
        type: actionTypes.AGENCY_GET_PUBLICATIONS_LIST.TRIGGER,
        payload: { buyerId: action.payload.buyerId },
      });
      toastFn('success', 'Saved', 'Add agency publications');
      yield put({ type: actionTypes.AGENCY_ADD_PUBLICATIONS.SUCCESS });
    } else {
      toastFn('error', 'Failed', 'Add agency publications');
      yield put({ type: actionTypes.AGENCY_ADD_PUBLICATIONS.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', 'Add agency publications');
    yield put({
      type: actionTypes.AGENCY_ADD_PUBLICATIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchAgencyAddEbidDocuments() {
  yield takeLatest(actionTypes.AGENCY_ADD_EBID_DOCUMENTS.TRIGGER, agencyAddEbidDocuments);
}
function* agencyAddEbidDocuments(action: { payload: { buyerId: number } }): any {
  yield put({ type: actionTypes.AGENCY_ADD_EBID_DOCUMENTS.REQUEST, meta: action.payload });

  try {
    const response = yield call(agencyApi.agencyAddEbidDocuments, action.payload);
    if (response.data.result.status === true) {
      yield put({
        type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER,
        payload: { addDocumentModal: false, ebiddocumentreorder: false, ebiddocumentPopupdata: {} },
      });
      yield put({
        type: actionTypes.AGENCY_GET_REQUIRED_DOCS.TRIGGER,
        payload: { buyerId: action.payload.buyerId },
      });
      toastFn('success', 'Saved', 'Add agency publications');
      yield put({ type: actionTypes.AGENCY_ADD_EBID_DOCUMENTS.SUCCESS });
    } else {
      toastFn('error', 'Failed', 'Add agency publications');
      yield put({ type: actionTypes.AGENCY_ADD_EBID_DOCUMENTS.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', 'Add agency publications');
    yield put({
      type: actionTypes.AGENCY_ADD_EBID_DOCUMENTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchAgencyGetPublications() {
  yield takeLatest(actionTypes.AGENCY_GET_PUBLICATIONS_LIST.TRIGGER, agencyGetPublications);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* agencyGetPublications(action: { payload: Record<string, unknown> | undefined }): any {
  yield put({ type: actionTypes.AGENCY_GET_PUBLICATIONS_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(agencyApi.agencyGetPublications, action.payload);
    const payload = { agencypublications: response.data.result };
    yield put({ type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER, payload });
    yield put({ type: actionTypes.AGENCY_GET_PUBLICATIONS_LIST.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.AGENCY_GET_PUBLICATIONS_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchAgencyGetRequiredDocs() {
  yield takeLatest(actionTypes.AGENCY_GET_REQUIRED_DOCS.TRIGGER, agencyGetRequiredDocs);
}
function* agencyGetRequiredDocs(action: RegistrationPayload): any {
  yield put({ type: actionTypes.AGENCY_GET_REQUIRED_DOCS.REQUEST, meta: action.payload });

  try {
    const response = yield call(agencyApi.agencyGetRequiredDocs, action.payload);
    const payload = { agencyEbidDocuments: response.data.result.requiredDocuments };
    yield put({ type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER, payload });
    yield put({ type: actionTypes.AGENCY_GET_REQUIRED_DOCS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.AGENCY_GET_REQUIRED_DOCS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchAgencyGetBidTypes() {
  yield takeLatest(actionTypes.AGENCY_GET_BIDTYPES.TRIGGER, agencyGetBidTypes);
}
function* agencyGetBidTypes(action: { payload: any }): any {
  yield put({ type: actionTypes.AGENCY_GET_BIDTYPES.REQUEST, meta: action.payload });

  try {
    const response = yield call(agencyApi.agencyGetBidTypes, action.payload);
    yield put({
      type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER,
      payload: { agencybidTypes: response.data.result, agBidTypes: response.data.result },
    });
    yield put({ type: actionTypes.AGENCY_GET_BIDTYPES.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.AGENCY_GET_BIDTYPES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchAgencyBuyerInformation() {
  yield takeLatest(actionTypes.AGENCY_GENERAL_INFO_INITIAL_VALUES.TRIGGER, agencyBuyerInformation);
}
function* agencyBuyerInformation(action: {
  payload: { buyerId: number; pagefor: string | undefined };
}): any {
  yield put({ type: actionTypes.AGENCY_GENERAL_INFO_INITIAL_VALUES.REQUEST, meta: action.payload });

  try {
    const { pagefor = '' } = action.payload;
    const response = yield call(agencyApi.agencyBuyerInformation, action.payload);
    const agencyData = response.data.result;

    const shared = yield select(state => state.shared);
    let { timeZoneList = [] } = shared;

    if (timeZoneList.length === 0) {
      const sharedResponse = yield call(sharedApi.getTimezoneList, action.payload);
      const sharedPayload = sharedResponse.data.result.map(
        (item: { timeZoneName: string; timeZoneId: number }) => ({
          ...item,
          label: item.timeZoneName,
          value: item.timeZoneId,
        }),
      );
      timeZoneList = sharedPayload;
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { timeZoneList: sharedPayload },
      });
    }

    const { stateslist = [], countrieslist = {}, countieslist = [] } = shared;
    let selectedState = {};

    if (stateslist.length === 0) {
      const stateResponse = yield call(sharedApi.getStatesList, {});
      selectedState = stateResponse.data.result.find(
        (item: { id: number }) => item.id === agencyData.memberInfo[0].stateId,
      );
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { stateslist: stateResponse.data.result },
      });
    } else {
      selectedState = stateslist.find(
        (item: { value: number }) => item.value === agencyData.memberInfo[0].stateId,
      );
    }

    let selectedCounty = {};

    let countyResponse = yield call(sharedApi.getCountiesList, agencyData.memberInfo[0].stateId);
    if (countyResponse.data.result.length) {
      countyResponse = countyResponse.data.result.map(
        (items: { name: string; title: string; id: number }) => ({
          ...items,
          label: items.name || items.title,
          value: items.id,
        }),
      );
    }
    selectedCounty = countyResponse.find(
      (item: { id: number }) => item.id === agencyData.memberInfo[0].countyId,
    );

    const generalInfoInitialValues = {
      loginusername: agencyData.accounts[0].username,
      firstname: agencyData.contacts[0].firstName,
      lastname: agencyData.contacts[0].lastName,
      contactPhone: agencyData.contacts[0].phoneNumber,
      contactEmail: agencyData.contacts[0].email,
      agencyname: agencyData.memberInfo[0].shortName,
      timeZone: timeZoneList.filter(
        (item: { value: any }) => item.value === agencyData.memberInfo[0].timeZoneId,
      )[0],
      address1: agencyData.memberInfo[0].address1,
      address2: agencyData.memberInfo[0].address2,
      city: agencyData.memberInfo[0].city,
      stateId: agencyData.memberInfo[0].stateId,
      state: selectedState,
      county: selectedCounty,
      countyId: agencyData.memberInfo[0].countyId,
      countryId: agencyData.memberInfo[0].countryId,
      country: countrieslist.find((item: any) => item.value === agencyData.memberInfo[0].countryId),
      zipcode: agencyData.memberInfo[0].postalCode,
      buyerPhone: agencyData.memberInfo[0].phoneNumber,
      website: agencyData.memberInfo[0].website,
      initialDate: moment(),
      planholderDisplayType: {
        value: agencyData.legalAds[0].planholderDisplayType,
        label: agencyData.legalAds[0].planholderDisplayDesc,
      },
      memberStatus: agencyData.memberInfo[0].memberStatus,
      isFreeDocDownload: agencyData.memberInfo[0].isFreeDocDownload,
    };
    const payload = {
      generalInfoFormInitialValues: generalInfoInitialValues,
      agencybidTypes: agencyData.bidTypes,
      agencyProducts: agencyData.products,
    };
    yield put({ type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER, payload });
    if (!pagefor) {
      yield put({
        type: actionTypes.POPULATE_METRO_DETAILS.TRIGGER,
        payload: { zip: agencyData.memberInfo[0].postalCode },
      });
    }
    yield put({ type: actionTypes.AGENCY_GENERAL_INFO_INITIAL_VALUES.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.AGENCY_GENERAL_INFO_INITIAL_VALUES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchAgencyRemoveBidtype() {
  yield takeLatest(actionTypes.AGENCY_REMOVE_BIDTYPE.TRIGGER, agencyRemoveBidtype);
}
function* agencyRemoveBidtype(action: RegistrationPayload) {
  yield put({ type: actionTypes.AGENCY_REMOVE_BIDTYPE.REQUEST, meta: action.payload });

  try {
    yield call(agencyApi.agencyRemoveBidtype, action.payload);
    yield put({ type: actionTypes.AGENCY_REMOVE_BIDTYPE.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.AGENCY_REMOVE_BIDTYPE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchAgencyRemovePublication() {
  yield takeLatest(actionTypes.AGENCY_REMOVE_PUBLICATION.TRIGGER, agencyRemovePublication);
}
function* agencyRemovePublication(action: RegistrationPayload): any {
  yield put({ type: actionTypes.AGENCY_REMOVE_PUBLICATION.REQUEST, meta: action.payload });

  try {
    yield call(agencyApi.agencyRemovePublication, action.payload);
    yield put({ type: actionTypes.AGENCY_REMOVE_PUBLICATION.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.AGENCY_REMOVE_PUBLICATION.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchGetAgencySettings() {
  yield takeLatest(actionTypes.GET_AGENCY_SETTINGS.TRIGGER, getAgencySettings);
}
function* getAgencySettings(action: RegistrationPayload): any {
  yield put({ type: actionTypes.GET_AGENCY_SETTINGS.REQUEST, meta: action.payload });

  try {
    const response = yield call(agencyApi.agencySettings, action.payload);
    let logodata = {};
    if (response?.data?.result?.logo) {
      logodata = {
        name: response.data.result.logo,
        imageFrom: 'api',
        baseUrl: constants.contentBlobUrl + 'logos/',
      };
    }
    let bannerdata = {};
    if (response?.data?.result?.banner) {
      bannerdata = {
        name: response.data.result.banner,
        imageFrom: 'api',
        baseUrl: constants.contentBlobUrl + 'banners/',
      };
    }
    const payload = {
      pageSettingFields: {
        logo: logodata,
        banner: bannerdata,
        theme: response.data.result.theme
          ? agencyPageTheme.find(item => item.value === response.data.result.theme)
          : null,
        imgPos:
          response.data.result.position && response.data.result.position !== 'undefined'
            ? parseInt(response.data.result.position)
              ? { value: response.data.result.position, type: 'pixel' }
              : imagePositions.find(item => item.value === response.data.result.position)
            : { label: 'Center', value: 'center', type: 'position' },
      },
      escapedagencyname: response.data.result.escapedAgencyName,
      state: response.data.result.state,
      guid: response.data.result.id,
      existingSettings: {
        logo: response.data.result.logo,
        banner: response.data.result.banner,
        theme: response.data.result.theme,
        imgPos: response.data.result.position,
      },
    };
    yield put({ type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER, payload });
    yield put({ type: actionTypes.GET_AGENCY_SETTINGS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_AGENCY_SETTINGS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* watchUpdateAgencySettings() {
  yield takeLatest(actionTypes.UPDATE_AGENCY_SETTINGS.TRIGGER, updateAgencySettings);
}
function* updateAgencySettings(action: {
  payload: { type: string; memberId: number; redirectPath?: string };
}): any {
  yield put({ type: actionTypes.UPDATE_AGENCY_SETTINGS.REQUEST, meta: action.payload });

  try {
    const { type = '', memberId = '', redirectPath = '' } = action.payload || {};
    const agencyregistration = yield select(state => state.agencyregistration);
    const { pageSettingFields, existingSettings } = agencyregistration;
    const response = yield call(agencyApi.updateAgencySettings, {
      ...pageSettingFields,
      theme: pageSettingFields.theme.value,
      memberId,
    });
    if (response.data.status === 'Success') {
      toastFn('success', 'Updated', 'Agency Page Settings');
      yield put({
        type: actionTypes.UPDATE_AGENCY_SETTINGS.SUCCESS,
        payload: { pagehasValues: false },
      });
      yield put({
        type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER,
        payload: { pagehasValues: false },
      });
      if (type === 'createAgency') {
        history.push(`/ops/agency/registration/summary/${memberId}`);
      } else {
        if (
          existingSettings.logo !== pageSettingFields.logo.name ||
          existingSettings.banner !== pageSettingFields.banner.name ||
          existingSettings.theme !== pageSettingFields.theme.value ||
          existingSettings.imgPos !== pageSettingFields.imgPos.value
        ) {
          const auth = yield select(state => state && state.auth);
          const { mi: memberId } = auth;
          const trackPayload = {
            eventId: userEvent.BannerChange,
            parentId: memberId,
            value: existingSettings,
          };
          yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });
        }
        if (redirectPath !== '') {
          history.push(redirectPath);
        }
      }
    } else {
      toastFn('error', 'Failed', 'Agency Page Settings');
      yield put({ type: actionTypes.UPDATE_AGENCY_SETTINGS.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', 'Agency Page Settings');
    yield put({
      type: actionTypes.UPDATE_AGENCY_SETTINGS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* agencyRegistrationSaga() {
  yield fork(watchCreateBuyerSaga);
  yield fork(watchAddAgencyBidTypes);
  yield fork(watchGetAddagencyLegalads);
  yield fork(watchagencyAddLegalAd);
  yield fork(watchAgencyAddPublications);
  yield fork(watchAgencyAddEbidDocuments);
  yield fork(watchAgencyGetPublications);
  yield fork(watchAgencyGetRequiredDocs);
  yield fork(watchAgencyGetBidTypes);
  yield fork(watchAgencyBuyerInformation);
  yield fork(watchAgencyRemoveBidtype);
  yield fork(watchAgencyRemovePublication);
  yield fork(watchGetAgencySettings);
  yield fork(watchUpdateAgencySettings);
}
