import { useEffect, useMemo } from 'react';
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import dayjs from 'dayjs';

import {
  allProductsState,
  cartTotalState,
  selectedProductsState,
} from 'src/store/recoil/productState';
import {
  calculateMaxCartDiscount,
  calculateProratedProductPrices,
} from 'src/utils/helpers/proration';
import {
  CartProduct,
  NationalProductId,
  NationalProductPrice,
  ProductApiResponse,
  ProductType,
  SubscriptionProducts,
} from 'src/types/products';

import { alertPercentage } from 'src/utils/constants';
import { CartAlertType } from 'src/types/registration';
import { compareObjectsByKey } from 'src/utils';
import { removeDuplicates } from 'src/utils/helpers';
import { useAccountInfo } from 'src/shared/hooks/useAccountInfo';
import { UserType } from 'src/types/accountinfo';

/**
 * @description serves as a wrapper for Cart functionality and values
 * @example const { cartItems, discountedCartTotal, recurringCartTotal } = useCart();
 */
export function useCart() {
  const allProducts = useRecoilValueLoadable<ProductApiResponse[]>(allProductsState);

  const cartTotal = useRecoilValue<number>(cartTotalState);
  const selectedProducts = useRecoilValue<SubscriptionProducts>(selectedProductsState);

  const {
    accountInfo: { products: subscribedProducts, expiryDate: acctExpiryDate, userType },
  } = useAccountInfo();

  /** Memoized boolean indicating whether user has an active subscription */
  const hasActiveSubscription = useMemo(() => {
    return (
      acctExpiryDate >= dayjs() &&
      subscribedProducts?.length > 0 &&
      userType === UserType.PaidSupplier
    );
  }, [acctExpiryDate, subscribedProducts?.length, userType]);

  /** Memoized expiration date */
  const expiryDate = useMemo(() => {
    return !hasActiveSubscription ? dayjs().add(1, 'year') : acctExpiryDate;
  }, [acctExpiryDate, hasActiveSubscription]);

  /** Memoized conversion of account's `products` to `ProductApiResponse` types */
  const expandedSubscribedProducts: ProductApiResponse[] = useMemo(() => {
    const expanded = [] as ProductApiResponse[];

    if (allProducts.state === 'hasValue' && subscribedProducts?.length) {
      subscribedProducts.forEach(sp => {
        const prd = allProducts.contents.find(ap => ap.productId === sp.productId);
        if (prd) expanded.push(prd);
      });
    }

    return expanded;
  }, [allProducts.contents, allProducts.state, subscribedProducts]);

  /** Memoized national product retrieved from loadable state */
  const nationalProduct: ProductApiResponse | undefined = useMemo(() => {
    if (allProducts.state === 'hasValue') {
      return allProducts.contents.find(x => x.productId === NationalProductId.UnitedStates);
    }
    return undefined;
  }, [allProducts.contents, allProducts.state]);

  /** Memoized boolean indicating a new product is selected */
  const showCart = useMemo(() => {
    return selectedProducts.county?.length ||
      selectedProducts.state?.length ||
      selectedProducts?.national
      ? true
      : false;
  }, [selectedProducts]);

  /**
   * Memoized conversion of selected products to a filtered list of items in the cart (based on product hierarchy)
   * Prorated prices for each product are also calculated here
   */
  const cartItems: CartProduct[] = useMemo(() => {
    let items = [] as ProductApiResponse[];

    if (selectedProducts.national) {
      items = nationalProduct ? [nationalProduct] : [];
    }

    if (!selectedProducts.national && selectedProducts.state?.length) {
      items = [...selectedProducts.state].sort(compareObjectsByKey('productName'));
    }

    if (!selectedProducts.national && selectedProducts.county?.length) {
      const counties = selectedProducts.county
        .filter(c => {
          return c.parentId && !items.find(i => i.productId === c.parentId);
        })
        .sort(compareObjectsByKey('productName'));
      items = items.concat(counties);
    }

    return !hasActiveSubscription
      ? items.map(p => {
          return {
            ...p,
            calculatedPrice: p.price,
          };
        })
      : calculateProratedProductPrices(items, expandedSubscribedProducts, expiryDate);
  }, [
    expandedSubscribedProducts,
    expiryDate,
    hasActiveSubscription,
    nationalProduct,
    selectedProducts.county,
    selectedProducts.national,
    selectedProducts.state,
  ]);

  /** A list of products to which the user is subscribed & which remain unchanged by their additions */
  const unchangedSubscribedProducts = useMemo(() => {
    return expandedSubscribedProducts.filter(product => {
      if (cartItems.filter(p => p.productType === ProductType.National)?.length) return false;

      if (
        [ProductType.County, ProductType.FreeAgency].includes(product.productType) &&
        product.parentId
      ) {
        return cartItems.filter(p => p.productId === product.parentId)?.length ? false : true;
      }

      return true;
    });
  }, [cartItems, expandedSubscribedProducts]);

  /**
   * The maximum amount of discount to be applied to the cart based on "removed" products for which the member has
   * previously paid
   */
  const maximumCartDiscount = useMemo(() => {
    return calculateMaxCartDiscount(
      [...cartItems, ...unchangedSubscribedProducts],
      expandedSubscribedProducts,
      expiryDate,
    );
  }, [cartItems, expandedSubscribedProducts, expiryDate, unchangedSubscribedProducts]);

  /** The cart total less the maximum discount amount, limited at being >= 0 */
  const discountedCartTotal = useMemo(() => {
    if (maximumCartDiscount >= cartTotal) return 0;

    return Math.round((cartTotal - maximumCartDiscount) * 100) / 100.0;
  }, [cartTotal, maximumCartDiscount]);

  /** The projected subscription amount at the time of renewal */
  const recurringTotal = useMemo(() => {
    const unchangedSubscribedProductSum = unchangedSubscribedProducts
      .map(p => p.price)
      .reduce((last, curr) => last + curr, 0);

    const cartSum = cartItems.map(p => p.price).reduce((last, curr) => last + curr, 0);

    return unchangedSubscribedProductSum + cartSum;
  }, [cartItems, unchangedSubscribedProducts]);

  /** The distinct list of State Products for which there are selected County Products in the cart */
  const stateUpgrades = useMemo(() => {
    if (selectedProducts.county && allProducts.state === 'hasValue') {
      // Get all unique states that are parents of selected counties
      const upgrades: ProductApiResponse[] = removeDuplicates(
        selectedProducts.county
          .filter(c => c.parentId)
          .map(c => {
            const state = allProducts.contents.filter(s => c.parentId === s.productId)[0];
            return state;
          })
          .filter(s => s),
        'productId',
      );

      // Only return states for which the selected counties pass the threshold for an alert
      return upgrades.filter(({ productId, price }) => {
        const selectedCountyPrice =
          selectedProducts.county
            ?.filter(county => county.parentId === productId)
            .map(county => county.price)
            .reduce((last, current) => last + current, 0) ?? 0;

        const alreadySubscribedCountyPrice =
          unchangedSubscribedProducts
            .filter(p => p.parentId === productId && p.productType === ProductType.County)
            .map(county => county.price)
            .reduce((last, current) => last + current, 0) ?? 0;

        return (
          ((selectedCountyPrice + alreadySubscribedCountyPrice) / (Number(price) || 1)) * 100 >=
          alertPercentage
        );
      });
    }

    return [];
  }, [
    allProducts.contents,
    allProducts.state,
    selectedProducts.county,
    unchangedSubscribedProducts,
  ]);

  /** The type of `CartAlert` to display, if any */
  const alertType = useMemo(() => {
    if (selectedProducts?.national) {
      if (selectedProducts.county?.length || selectedProducts.state?.length)
        return CartAlertType.National;
      else return CartAlertType.None;
    }

    if ((recurringTotal / NationalProductPrice.UnitedStates) * 100 >= alertPercentage) {
      return CartAlertType.NationalUpgrade;
    }

    if (stateUpgrades?.length) {
      return CartAlertType.StateUpgrade;
    }

    return CartAlertType.None;
  }, [
    recurringTotal,
    selectedProducts.county?.length,
    selectedProducts?.national,
    selectedProducts.state?.length,
    stateUpgrades?.length,
  ]);

  /**
   * @description removes the provided product from the cart
   * @param product - ProductApiResponse - the product to remove from the cart
   * @returns void
   * @example removeProduct({
   *  productId: 1,
   *  productType: ProductType.County,
   * })
   */
  const removeProduct = useRecoilCallback(
    ({ set }) =>
      ({ productId, productType, parentId }: ProductApiResponse) => {
        if (allProducts.state !== 'hasValue') {
          return;
        }

        // Remove a sub-product from a national subscription
        if (selectedProducts.national && productType !== ProductType.National) {
          const selectedStates = allProducts.contents.filter(
            st => st.productType === ProductType.State,
          );

          if (productType === ProductType.State) {
            set(selectedProductsState, {
              ...selectedProducts,
              national: NationalProductId.None,
              state: selectedStates.filter(x => x.productId !== productId),
              county: selectedProducts.county?.filter(c => c.parentId !== productId),
            });
          } else if (productType === ProductType.County) {
            const selectedCounties = allProducts.contents.filter(
              ct =>
                ct.productType === ProductType.County &&
                ct.parentId &&
                parentId &&
                ct.parentId === parentId &&
                ct.productId !== productId,
            );

            set(selectedProductsState, {
              ...selectedProducts,
              national: NationalProductId.None,
              state: selectedStates.filter(x => x.productId !== parentId),
              county: selectedCounties,
            });
          }

          return;
        }
        switch (productType) {
          case ProductType.County:
            const selectedParent = selectedProducts.state?.find(
              st => parentId && st.productId === parentId,
            );
            let counties = selectedProducts.county;

            if (selectedParent) {
              counties = allProducts.contents
                .filter(
                  ct =>
                    ct.productType === ProductType.County &&
                    ct.parentId &&
                    parentId &&
                    ct.parentId === parentId &&
                    ct.productId !== productId,
                )
                .concat(selectedProducts.county?.filter(c => c.parentId !== parentId) ?? []);
            } else {
              counties = selectedProducts.county?.filter(c => c.productId !== productId) ?? [];
            }
            set(selectedProductsState, {
              ...selectedProducts,
              state: selectedProducts.state?.filter(
                st => st.productId !== selectedParent?.productId,
              ),
              county: counties,
            });

            break;
          case ProductType.State:
            const states = selectedProducts.state?.filter(s => s.productId !== productId) ?? [];
            set(selectedProductsState, {
              ...selectedProducts,
              state: states,
            });
            break;
          case ProductType.National:
            set(selectedProductsState, {
              ...selectedProducts,
              national: NationalProductId.None,
            });
            break;
        }
      },
    [selectedProducts, allProducts.state],
  );

  /**
   * @description updates the cart total based on the selected products
   * @returns void
   * @example refreshCartTotal()
   */
  const refreshCartTotal = useRecoilCallback(
    ({ set }) =>
      async () => {
        let proratedTotal = 0;

        if (cartItems?.length) {
          proratedTotal = cartItems
            .map(ci => ci.calculatedPrice)
            .filter(ci => ci)
            .reduce((sum, number) => {
              return (sum ?? 0) + (number ?? 0);
            }, 0);
        }

        if (proratedTotal !== cartTotal) {
          set(cartTotalState, Math.round(proratedTotal * 100) / 100.0);
        }
      },
    [cartItems],
  );

  /**
   * @description clears any accidentally added duplicates from the cart
   * @returns void
   * @example clearDuplicateProducts()
   */
  const clearDuplicateProducts = useRecoilCallback(
    ({ set }) =>
      () => {
        const uniqueCounties = removeDuplicates(selectedProducts.county ?? [], 'productId');

        const uniqueStates = removeDuplicates(selectedProducts.state ?? [], 'productId');
        if (
          uniqueStates.length < (selectedProducts.state?.length ?? 0) ||
          uniqueCounties.length < (selectedProducts.county?.length ?? 0)
        ) {
          set(selectedProductsState, {
            ...selectedProducts,
            county: uniqueCounties,
            state: uniqueStates,
          });
        }
      },
    [selectedProducts.county, selectedProducts.state],
  );

  return {
    /** Indicates what type of `CartAlert` should be displayed */
    alertType,
    /** A filtered list of products that should appear in the cart */
    cartItems,
    /** The total cost of all products in the cart */
    cartTotal,
    /** The discounted total of the cart (cartTotal - maximumCartDiscount), guaranteed >= 0 */
    discountedCartTotal,
    /** Removes any duplicates from selected products  */
    clearDuplicateProducts,
    /** The calculated expiration day for new products
     * From account info if there are active subscriptions or 1 year from today
     */
    expiryDate,
    /**
     * The maximum amount the cart total can be reduced due to "removed" child products
     */
    maximumCartDiscount,
    /** The total annual renewal fee for newly selected products and subscribed products, accounting for
     * newly added parent products & national subscriptions
     */
    recurringTotal,
    /** Re-calculates the cart total based on selected products */
    refreshCartTotal,
    /** Gracefully removes a selected product from the cart & updates state */
    removeProduct,
    /** Contains a boolean indicating if the cart should be shown */
    showCart,
    /** Contains a list of state products that meet the conditions for cart upsell */
    stateUpgrades,
    /** Contains a list of subscribed products that are not impacted by items in cart */
    unchangedSubscribedProducts,
  };
}

/**
 * @description uses `useCart` functionality to keep cartTotal in sync and duplicate products out of the cart
 * @example useRefreshCartTotal();
 */
export function useRefreshCartTotal() {
  const { clearDuplicateProducts, refreshCartTotal } = useCart();

  useEffect(() => {
    refreshCartTotal();
  }, [refreshCartTotal]);

  useEffect(() => {
    clearDuplicateProducts();
  }, [clearDuplicateProducts]);
}
