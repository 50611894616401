export enum BorderRadius {
  /** Used for pointy corners */
  Square = '0',
  /** Used for eased, slightly rounded corners - e.g. inputs (2px)*/
  Eased = '0.125rem',
  /** Used for buttons, and eased corners of larger blocks - e.g. Dashboard content */
  Base = '0.25rem',
  /** Used for flat top & bottom, with rounded left/ride sides - e.g. pills */
  Rounded = '1.5rem',
  /** Fully circular (or oval) items */
  Circular = '50%',
}

export enum BorderThickness {
  None = '0',
  Base = '0.0625rem',
  Thick = '0.125rem',
}
