import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilStateLoadable, useRecoilValue } from 'recoil';

import { DSSelect } from '@demandstar/components/inputs';

import {
  allProductsState,
  initialStateProduct,
  parentStateState,
  selectedProductsState,
} from '../../../store/recoil/productState';
import { getStates, isSubscribed } from '../helpers';
import { ProductApiResponse, ProductType, SubscriptionProducts } from '../../../types/products';

import { productBundles } from './bundles';
import { SelectBundle } from './SelectBundle';
import { SelectCounty } from './SelectCounty';
import { UpsellType } from 'src/types/registration';
import { useAccountInfo } from '../../../shared/hooks/useAccountInfo';
import { useUpsellProducts } from '../upsell/useUpsellProducts';

interface SelectStateProps {
  /** optional name of state to be set initially */
  prepopulatedState?: string;
}
export interface StateDropdownItem {
  key: number;
  label: string;
  title: string;
  value: string;
}

/**
 * @description a selector for state-level products that renders bundles and counties as child components
 * @returns JSX.Element
 *
 * @example <SelectState prepopulatedState={'Florida'} />
 */
export const SelectState = ({ prepopulatedState }: SelectStateProps) => {
  const [allProducts] = useRecoilStateLoadable(allProductsState);

  const [parentState, setParentState] = useRecoilState<ProductApiResponse>(parentStateState);
  const selectedProducts = useRecoilValue<SubscriptionProducts>(selectedProductsState);
  const { addUpsellProduct, upsellProducts } = useUpsellProducts();

  const [hasBundle, setHasBundle] = useState(false);
  const [selectedState, setSelectedState] = useState({
    label: prepopulatedState,
    value: '',
  });
  const [needState, setNeedState] = useState(true);

  const stateProducts = useMemo(() => {
    return allProducts.state === 'hasValue'
      ? allProducts.contents.filter(p => p.productType === ProductType.State)
      : [initialStateProduct];
  }, [allProducts.contents, allProducts.state]);

  const stateDropdownOptions = useMemo(() => {
    return getStates(stateProducts);
  }, [stateProducts]);

  const {
    accountInfo: { products: subscribedProducts },
  } = useAccountInfo();

  const isProductSubscribed = useCallback(
    (product: ProductApiResponse) => {
      return isSubscribed(product, subscribedProducts);
    },
    [subscribedProducts],
  );

  const handleSelect = useCallback(
    (value: StateDropdownItem) => {
      const dropdownState = stateProducts.filter(state => state.productName === value.label);

      addUpsellProduct(dropdownState[0].productId, UpsellType.Popular);

      setSelectedState({ label: value.label, value: value.value });
      setParentState(dropdownState[0]);
    },
    [addUpsellProduct, setParentState, stateProducts],
  );

  useEffect(() => {
    if (needState && allProducts.state === 'hasValue') {
      let state = undefined;

      if (selectedProducts.state?.length) {
        state = selectedProducts.state[selectedProducts.state.length - 1];
      }

      if (!state && prepopulatedState) {
        state = allProducts.contents?.find(
          p => p.productName === prepopulatedState && p.productType === ProductType.State,
        );
      }

      if (!state && upsellProducts?.length && allProducts.state === 'hasValue') {
        state = upsellProducts?.filter(u => u.product.productType === ProductType.State)[0]
          ?.product;
      }

      if (state) {
        setNeedState(false);
        setParentState(state);
        setSelectedState({
          label: state.productName,
          value: state.productId.toString(),
        });
      }
    }
  }, [
    allProducts.contents,
    allProducts.state,
    needState,
    prepopulatedState,
    selectedProducts.state,
    setParentState,
    upsellProducts,
  ]);

  useEffect(() => {
    if (selectedState) {
      const stateName = selectedState.label;
      if (stateName && productBundles.find(bundleCategory => bundleCategory.title === stateName)) {
        setHasBundle(true);
      } else setHasBundle(false);
    }
  }, [setHasBundle, selectedState]);

  return (
    <form data-testid='select-product'>
      <h4>Please select a state to begin</h4>
      <div data-testid='div-select-product-state'>
        <DSSelect
          dataTestId='select-product-state'
          onSelect={(value: StateDropdownItem) => handleSelect(value)}
          label='State'
          name='state'
          options={stateDropdownOptions}
          value={selectedState}
        />
      </div>

      {hasBundle && selectedState.label && !isProductSubscribed(parentState) && (
        <div>
          <SelectBundle parentState={parentState} />
        </div>
      )}

      {selectedState.label && (
        <div>
          <SelectCounty parentState={parentState} hasBundle={hasBundle} />
        </div>
      )}
    </form>
  );
};
