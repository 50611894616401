import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { Table } from 'reactstrap';

import { DSButton } from '@demandstar/components/button';
import { DSTextInput } from '@demandstar/components/inputs';

import {
  AwardBidEventName,
  useAwardBidEvents,
} from '../../../../shared/hooks/amplitude/awardBid.events';
import {
  AwardWizardId,
  selectedBidAwardeeState,
  unselectedBiddersSelector,
} from '../../../../store/recoil/award-bid';
import { NoResult, SelectBox } from '../../../customcontrols';
import { PanelRow, PanelRowRight } from '../../../../shared/styles';
import { AddNewAwardee } from './AddNewAwardee';
import { Assert } from '../../../../utils/helpers';
import { AwardBidLabels } from 'src/shared/constants';
import { Awardee } from '../../../../types/biddetail';
import { AwardeeRow } from './AwardeeRow';
import { getLoadableContents } from '../../../../utils';
import { LoadableWrapper } from '../../../common/loading/LoadableWrapper';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { useAwardBidWizard } from 'src/shared/hooks';
import { useBidAwardee } from '../../../../shared/hooks/useBidAwardee';
import { useSupplierMemberId } from '../../../../shared/hooks/useMemberInfo';
import { WizardButtons } from '../../../customcontrols/wizard/WizardButtons';

const divStyle = {
  // TODO: Replace with style component.
  td1: {
    width: '170px',
    valign: 'top',
  },
  td2: {
    width: '60%',
  },
  td3: {
    width: '35%',
  },
  td4: {
    width: '5%',
  },
};

export function SelectAwardees() {
  const { logEvent } = useAwardBidEvents();
  const { saveAwardBidWizard, returnToBidSummary } = useAwardBidWizard();

  const { bidAwardeeList, refreshBidAwardeeList, setBidAwardeeList } = useBidAwardee();
  const potentialAwardees = useRecoilValueLoadable(unselectedBiddersSelector);
  const potentialAwardeeList = getLoadableContents(potentialAwardees) || [];

  const [selectedBidAwardee, setSelectedBidAwardee] = useRecoilState(selectedBidAwardeeState);
  const { setSupplierMemberId, supplierMemberId } = useSupplierMemberId();

  // Local state
  const [addSupplierModalOpen, setAddSupplierModalOpen] = useState(false);

  /**
   * Select a default `selectedBidAwardee` and `selectedmemberId`.
   */
  useEffect(() => {
    if (!selectedBidAwardee && bidAwardeeList[0]) {
      setSelectedBidAwardee(bidAwardeeList[0]);
      setSupplierMemberId(bidAwardeeList[0].awardedToMemberId);
    }
  }, [
    bidAwardeeList,
    selectedBidAwardee,
    setSelectedBidAwardee,
    setSupplierMemberId,
    supplierMemberId,
  ]);

  /**
   * Refresh awardee list
   */
  useEffect(() => {
    refreshBidAwardeeList();
  }, [refreshBidAwardeeList]);

  const nextPage = useCallback(async () => {
    logEvent({ eventName: AwardBidEventName.SaveAwardee });
    saveAwardBidWizard(AwardWizardId.AddVendorDocs);
  }, [logEvent, saveAwardBidWizard]);

  function toggleAddSupplierModal() {
    setAddSupplierModalOpen(!addSupplierModalOpen);
  }

  function addAwardee(awardee: { value: number; label: string }) {
    Assert(!!bidAwardeeList, 'Expected: awardees to be loaded in SelectAwardees.tsx');
    const newAwardee: Awardee = {
      awardedToMemberId: awardee.value,
      supplierName: awardee.label,
      amount: 0,
    };
    setBidAwardeeList([...bidAwardeeList, newAwardee]);
  }

  return (
    <>
      {bidAwardeeList.length === 0 && <PanelRow>{AwardBidLabels.awardeeRequired}</PanelRow>}

      {potentialAwardeeList.length || bidAwardeeList.length ? (
        <Table className='tableHData'>
          <thead>
            <tr>
              <th style={divStyle.td2}>Award To</th>
              <th className='text-right' style={divStyle.td3}>
                Award Amount
              </th>
              <th style={divStyle.td4}></th>
            </tr>
          </thead>
          {/**TODO: Convert to DSTable */}
          <tbody>
            {bidAwardeeList.map((awardee, index) => (
              <AwardeeRow
                awardee={awardee}
                index={index}
                key={awardee.awardedToMemberId}
              ></AwardeeRow>
            ))}
            {potentialAwardeeList.length === 0 ? (
              <tr></tr>
            ) : (
              <tr>
                <td>
                  <SelectBox
                    reactselect={true}
                    label=''
                    name='awardedToMemberId'
                    options={potentialAwardeeList || []}
                    handleSelect={(name, value) => addAwardee(value)}
                  />
                </td>
                <td>
                  <DSTextInput name='amount' inactive value={'No Awardee Selected'} isNumeric />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      ) : (
        <LoadableWrapper loadable={potentialAwardees}>
          <NoResult message={'No Potential Awardees Available'} />
        </LoadableWrapper>
      )}
      <PanelRowRight paddingHorizontal={'0'} paddingBottom={'1.5rem'}>
        <DSButton
          title={'Award to non-DemandStar Supplier'}
          onClick={toggleAddSupplierModal}
          buttonType='secondary'
        >
          Award to non-DemandStar Supplier
        </DSButton>
      </PanelRowRight>

      <ModalPopUp
        title={'Add Supplier'}
        size={'md'}
        isOpen={addSupplierModalOpen}
        closeModal={toggleAddSupplierModal}
      >
        <AddNewAwardee closeModal={toggleAddSupplierModal} />
      </ModalPopUp>

      <WizardButtons
        inactiveNext={bidAwardeeList.length === 0}
        onNext={nextPage}
        save={returnToBidSummary}
      />
    </>
  );
}
