export type programsParamType = {
  programId: number;
  certificationStatus: string;
  statusName: string;
  supplierMemberId: string;
  statusChangedDate: string;
  name: string;
  acceptApplications: boolean;
  certificationRequired: boolean;
  certifiedMemberCount: number;
  attributeName: string;
  id: number;
  attributeType?: string;
  description?: unknown;
};

export enum UserType {
  BasicSupplier = 'SB',
  FreeAgencySupplier = 'FA',
  PaidSupplier = 'SS',
  Agency = 'AB',
  SupplementalSupplier = 'SV',
  AgencyAddedPlanholder = 'PL',
}
