import styled from 'styled-components';

// TOREFACTOR: Move to a library implementation
export const Checkbox = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  width: 0;

  &:checked ~ .checkmark {
    background: #3395a4;
  }

  &:not(:checked) ~ .checkmark {
    border: 0.125rem solid #3395a4;
  }

  & ~ .checkmark {
    background-color: #fff;
    border-radius: 0.125rem 0.125rem 0.125rem 0.125rem;
    cursor: pointer;
    display: inline-block;
    height: 1.125rem;
    position: relative;
    top: 0.25rem;
    width: 1.125rem;

    &:after {
      border-bottom: 0.18rem solid #ffffff;
      border-left: 0rem solid #ffffff;
      border-right: 0.18rem solid #ffffff;
      border-top: 0rem solid #ffffff;
      content: '';
      display: block;
      height: 0.7rem;
      left: 0.374rem;
      position: absolute;
      top: 0.1rem;
      transform: rotate(40deg);
      width: 0.35rem;
    }
  }
`;
