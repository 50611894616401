import { ProductApiResponse } from './products';
import { RegistrationData } from './supplierregistration';

export type RegistrationPayload = { payload: Record<string, unknown> | undefined };

export interface UpsellCardData {
  /** name of the child product, used for building a message */
  baseProductName: string;
  /** the object for the product being upsold */
  product: ProductApiResponse;
  /** number of agencies included in this product */
  totalAgencies: number;
  /** indicates type of upsell & determines message format */
  upsellType: UpsellType;
}

export enum UpsellType {
  National = 'national',
  Nearby = 'nearby',
  Parent = 'parent',
  Popular = 'popular',
}

export enum CartAlertType {
  None = 'none',
  County = 'county',
  National = 'national',
  NationalUpgrade = 'nationalUpgrade',
  StateUpgrade = 'stateUpgrade',
}

export type IncompleteRegistration = {
  email: string;
  JsonData: {
    regData: {
      currentComponent: string;
      registrationData: Partial<RegistrationData>;
    };
  };
};
