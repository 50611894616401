export function calculateRem(number: number): string {
  return number / 16 + 'rem';
}

/**
 * Assume 16px base font size.
 * @param rem
 */
export function pxToRem(px: number, basePx = 16): string {
  return px / basePx + 'rem';
}

/**
 * Assume 16px base font size.
 * @param rem
 */
export function remToPx(rem: number, basePx = 16): number {
  return basePx * rem;
}
