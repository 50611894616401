import { FieldError } from 'react-hook-form';
import { PropsWithChildren } from 'react';

import { BaseFieldContainer, FormFieldError, FormFieldMessage } from './FieldContainerStyles';
import { DataTestId } from '../types';
import { getErrorMessage } from '../utils';
import { Helper } from '../styles';
import { Label } from '../label/Label';

export type FieldContainerProps = DataTestId &
  PropsWithChildren<{
    /** Required name for all fields and inputs */
    name: string;
    /** RHF error with type and message */
    error?: FieldError;
    /** Label displayed above the field or input */
    label?: string;
    /** Optional message displayed below the field or input */
    message?: string;
    /** determines if the field is optional  */
    optional?: boolean;
  }>;

/** function to pass on FieldContainerProps to FieldContainer */
export function getFieldContainerProps(props: FieldContainerProps): FieldContainerProps {
  const { dataTestId, error, label, message, name, optional } = props;
  return {
    dataTestId,
    error,
    label,
    message,
    name,
    optional,
  };
}

export const FieldContainer = (props: FieldContainerProps) => {
  const { error, label, name, message, children, dataTestId, optional } = props;

  const errorMsg = getErrorMessage(error, label);

  return (
    <BaseFieldContainer dataTestId={`${dataTestId}-container`} error={error} marginBottom={!!label}>
      {label && (
        <Label htmlFor={name} dataTestId={dataTestId && `${dataTestId}-label`}>
          {label} {optional && <Helper>(Optional)</Helper>}
        </Label>
      )}
      {message && (
        <FormFieldMessage dataTestId={`${dataTestId}-message`}>{message}</FormFieldMessage>
      )}
      {children}
      {errorMsg && (
        <FormFieldError dataTestId={`${dataTestId}-error`} id={`${name}-error`}>
          {errorMsg}
        </FormFieldError>
      )}
    </BaseFieldContainer>
  );
};
