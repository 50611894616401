import { useEffect, useState } from 'react';
import { useRecoilStateLoadable, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { allProductsState } from '../../../store/recoil/productState';
import { Link } from 'react-router-dom';
import { PrepopulatedCounties } from '../../../types/subscriptions';
import { prepopulatedCountiesState } from '../../../store/recoil/subscriptionState';
import { ProductType } from 'src/types/products';
import { supplierTexts } from '../../../utils/texts';
import { useAmplitude } from '../../amplitude';

type UpsellBasicProps = {
  accountInfo: {
    membersinfo: {
      county: {
        name: string;
      };
      state: {
        name: string;
      };
    };
    mtn: string;
  };
};

const NotificationWrapper = styled.div`
  background: #f5f5f5;
  display: block;
  padding: 1rem;
  text-align: center;

  h1 {
    line-height: 1.5rem;
    margin-bottom: 1.25rem;
  }
`;

export function UpsellBasic({ accountInfo }: UpsellBasicProps) {
  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const setPrepopulatedCounties =
    useSetRecoilState<PrepopulatedCounties>(prepopulatedCountiesState);
  const { logEvent } = useAmplitude();
  const [upsellCount, setUpsellCount] = useState<number>(0);

  useEffect(() => {
    if (allProducts.state === 'hasValue') {
      const county = allProducts?.contents?.find(
        product =>
          product.productName === accountInfo.membersinfo.county.name &&
          product.parentName === accountInfo.membersinfo.state.name,
      );
      const stateId = county?.parentId || 0;
      if (county) {
        const products = allProducts?.contents?.filter(product => {
          return (
            product.productType === ProductType.FreeAgency && product.parentId === county?.productId
          );
        });

        setUpsellCount(products.length);
        setPrepopulatedCounties({
          state: stateId,
          counties: [county.productId],
        });
      }
    }
  }, [allProducts, setPrepopulatedCounties, accountInfo]);

  return accountInfo.membersinfo?.county?.name && upsellCount > 0 ? (
    <div className='basic-upsell-card' data-testid='basic-upsell-card'>
      <div className='row'>
        <div className='col-lg-6'>
          <NotificationWrapper>
            <p>{supplierTexts.dashboard.upsellBasic.notifications}</p>
            <h1 data-testid='basic-upsell-count'>{upsellCount}</h1>
            <p>{supplierTexts.dashboard.upsellBasic.agenciesInCounty}</p>
            <Link
              data-testid='basic-upsell-btn'
              className='bttn-accent w-100 mb-2 btn-block text-center'
              title={supplierTexts.dashboard.upsellBasic.getMoreNotifications}
              rel='noopener noreferrer'
              to='/suppliers/plans'
              onClick={() =>
                logEvent('upgrade - click fomobox', { 'Member Type': accountInfo.mtn })
              }
            >
              {supplierTexts.dashboard.upsellBasic.getMoreNotifications}
            </Link>
          </NotificationWrapper>
        </div>
      </div>
    </div>
  ) : null;
}
