import styled from 'styled-components';

import { DataTestId, OnClick, Title, Width } from '../types';
import { Color } from '../styles';

/**
 * Assume px height and width values.
 * Convert to rems
 */
export type DSSVGIconProps = DataTestId &
  OnClick &
  Title &
  Width & {
    color?: Color | string;
    height?: number;
    viewBox?: string;
  };

/**
 * Base SVG tags
 */
export const BaseSVG = styled.svg.attrs<DSSVGIconProps>(
  ({ dataTestId, color, onClick, title }: DSSVGIconProps) => ({
    'data-testid': `${dataTestId}`,
    fill: `${color}`,
    onClick,
    title: title || '',
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
  }),
)`
  cursor: pointer;
`;
