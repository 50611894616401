import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DSAlert } from '@demandstar/components/alert';
import { DSButton } from '@demandstar/components/button';
import { FlexContainer } from '@demandstar/components/styles';
import { Status } from '@demandstar/components/constants';

import LogoDSLoader from 'src/assets/images/loader';

import AddressLookup, { AddressInfo } from 'src/components/common/formcomponents/AddressLookup';
import { CheckoutErrorType, useCheckout } from './useCheckout';
import { PaymentInstance, PaymentMethod } from './PaymentMethod';
import { ProductsCopy, ProductsPaths } from 'src/utils/texts/supplier/subscription';

import { appKeys } from 'src/utils/constants';
import { Cart } from '../cart';
import { CompleteOrderModal } from '../ops-actions';
import { prorationMismatchState } from 'src/store/recoil/subscriptionState';
import { scrollToTop } from 'src/utils/helpers';
import { useAuth } from 'src/shared/hooks/useAuth';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';

interface BillingInfoFormInputs {
  addressInfo: AddressInfo;
}

interface ReviewOrderFormProps {
  /** the default billing address to use */
  defaultAddress?: AddressInfo;
  /** function to call after checkout error */
  handleError?: (type: CheckoutErrorType) => void;
  /** toggles a payment error alert at the top of the page */
  paymentError?: boolean;
  /** function to call when `Go Back` is clicked */
  previous?: () => void;
  /** function to call after successful payment, used to set Redux navigation state in registration */
  next?: () => void;
}

/**
 * @description shared form for checkout pages
 * @returns JSX.Element
 * @default { paymentError = false }
 *
 * @example <ReviewOrderForm
 * paymentError={false}
 * />
 */
export const ReviewOrderForm = ({
  handleError,
  defaultAddress,
  paymentError = false,
  previous,
  next,
}: ReviewOrderFormProps) => {
  const header = `${appKeys.headers.paymentMethod} - Subscription - ${appKeys.name}`;
  const [paymentInstance, setPaymentInstance] = useState<PaymentInstance>();
  const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false);
  const prorationMismatch = useRecoilValue(prorationMismatchState);

  const { checkout } = useCheckout();
  const { memberId } = useAuth();
  const history = useHistory();

  const methods = useForm<BillingInfoFormInputs>({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { getValues, setValue, trigger, formState } = methods;

  const goBack = useMemo(() => {
    return previous
      ? previous
      : () => {
          history.goBack();
        };
  }, [history, previous]);

  const proceed = useMemo(() => {
    return next
      ? next
      : () => {
          history.push(ProductsPaths.CurrentSubscription);
        };
  }, [history, next]);

  const onError = useCallback(
    (errorType: CheckoutErrorType) => {
      setPaymentProcessing(false);
      if (handleError) {
        handleError(errorType);
      }
    },
    [handleError],
  );

  const handleSubmit = useCallback(
    async (opsConfirmed = false) => {
      setPaymentProcessing(true);

      await trigger();

      try {
        const token: { nonce: string } | undefined = paymentInstance
          ? await paymentInstance.requestPaymentMethod()
          : undefined;

        const billingAddressData: AddressInfo = {
          ...defaultAddress,
          address1: String(getValues('addressInfo.address1')).trim(),
          address2: String(getValues('addressInfo.address2')).trim(),
          addressType: 'BA',
          city: String(getValues('addressInfo.city')).trim(),
          county: getValues('addressInfo.county'),
          country: getValues('addressInfo.country'),
          countryId: getValues('addressInfo.countryId'),
          countyId: getValues('addressInfo.countyId'),
          countyName: getValues('addressInfo.countyName'),
          postalCode: getValues('addressInfo.postalCode'),
          stateId: getValues('addressInfo.stateId'),
          stateName: getValues('addressInfo.stateName'),
          state: getValues('addressInfo.state'),
        };

        if (Object.keys(formState.errors).length === 0) {
          const result = await checkout({
            billingAddress: billingAddressData,
            token,
            memberId,
            opsConfirmed,
          });

          if (result?.status) {
            proceed();
          } else {
            onError(result?.errorType);
          }
        } else {
          onError(CheckoutErrorType.InvalidForm);
        }
      } catch (e) {
        scrollToTop();
        onError(CheckoutErrorType.Other);
      }
    },
    [
      checkout,
      defaultAddress,
      formState.errors,
      getValues,
      memberId,
      onError,
      paymentInstance,
      proceed,
      trigger,
    ],
  );

  // This sets the initial formState equal to the provided address prop
  useEffect(() => {
    if (defaultAddress) {
      Object.keys(defaultAddress).forEach(key => {
        const typedKey = key as keyof typeof defaultAddress;

        if (defaultAddress[typedKey] !== undefined) {
          let fieldValue = defaultAddress[typedKey];

          if (typeof fieldValue === 'string') {
            fieldValue = fieldValue.trim();
          }

          setValue(`addressInfo.${typedKey}`, fieldValue);
        }
      });
    }
  }, [defaultAddress, setValue]);

  return (
    <>
      {paymentError && !prorationMismatch ? (
        <DSAlert
          header={ProductsCopy.CardErrorHeader}
          message={ProductsCopy.CardErrorMessage}
          type={Status.Error}
        />
      ) : (
        <p>{ProductsCopy.Checkout}</p>
      )}

      <FormProvider {...methods}>
        <div className='row mb-5'>
          <div className='col-md-12 col-lg-12'>
            <h4 className='no-bottom-margin'>Payment</h4>
            <div className='w-100'>
              <PaymentMethod setPaymentInstance={setPaymentInstance} title={header} />
            </div>
          </div>
          <div className='col-md-12 col-lg-12 pt-4'>
            <h4 className='no-bottom-margin'>Billing Address:</h4>
            <AddressLookup />
          </div>
        </div>
      </FormProvider>

      <div className='row'>
        <div className='col-12'>
          <div className='px-6'>
            <Cart isReview={true} />
          </div>
        </div>
      </div>
      <CompleteOrderModal completeOrder={() => handleSubmit(true)} />
      <FlexContainer justifyContent='space-between'>
        <DSButton buttonType='secondary' onClick={goBack} inactive={paymentProcessing}>
          Go Back
        </DSButton>
        <DSButton
          buttonType='primary'
          onClick={() => handleSubmit(false)}
          inactive={paymentProcessing || prorationMismatch !== undefined}
        >
          Complete Order
        </DSButton>
      </FlexContainer>

      {paymentProcessing && (
        <div className='overlay'>
          <div className='progressbox'>
            <div>
              <LogoDSLoader alt='Processing Payment' />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
