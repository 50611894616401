import React, { useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { Flex, FlexContainer } from '@demandstar/components/styles';
import { DSRadio } from '@demandstar/components/inputs';

import {
  AwardWizardId,
  bidAwardeeListState,
  notifyColleaguesState,
} from '../../../../store/recoil/award-bid';
import { AwardWizardComponentProps } from '../AwardBid';
import { BoldText } from '../../../../shared/styles';
import { finalizeAwardTexts } from '../../../../utils/texts/agency/awardBid';
import { hasValue } from '../../../../utils/helpers';
import { useAwardBidWizard } from '../../../../shared/hooks';
import { useBid } from 'src/shared/hooks/useBid';
import { WizardButtons } from '../../../customcontrols/wizard/WizardButtons';

enum FinalizeOption {
  None,
  RecommendationOfAward,
  AwardBid,
}

type FinalizeAwardProps = Pick<AwardWizardComponentProps, 'completeWizard'>;

export const FinalizeAward = (props: FinalizeAwardProps): JSX.Element => {
  // Hooks
  const { saveAwardBidWizard, returnToBidSummary, submitting, recommendAward } =
    useAwardBidWizard();
  const { bid } = useBid();

  // Recoil
  const emailInfo = useRecoilValueLoadable(notifyColleaguesState);
  const hasColleagues = !!(hasValue(emailInfo) && emailInfo.contents.recipients.length);
  const bidName = bid?.bidName;
  const bidNumber = bid?.bidNumber;
  const awardees = useRecoilValue(bidAwardeeListState);
  const pluralAwardees = awardees.length > 1;

  // Props
  const { completeWizard } = props;

  // Local State
  const [finalizeOption, setFinalizeOption] = useState<FinalizeOption>(FinalizeOption.None);

  function onPrevious() {
    if (hasColleagues) {
      saveAwardBidWizard(AwardWizardId.NotifyColleagues, true);
    } else {
      saveAwardBidWizard(AwardWizardId.NotifyColleagues, false);
    }
  }

  function onNext() {
    if (finalizeOption === FinalizeOption.AwardBid) {
      completeWizard();
    } else {
      recommendAward();
    }
  }

  return (
    <div>
      <FlexContainer>
        <Flex>
          <p>
            {finalizeAwardTexts.recommendationCopy[0](pluralAwardees)}{' '}
            <BoldText>{finalizeAwardTexts.recommendationOfAward}</BoldText>{' '}
            {finalizeAwardTexts.recommendationCopy[1](pluralAwardees)}
          </p>
          <p>{finalizeAwardTexts.recommendationCopy[2](pluralAwardees)}</p>
          <p>
            <BoldText>{finalizeAwardTexts.recommendationOfAward}</BoldText>{' '}
            {finalizeAwardTexts.recommendationCopy[3](pluralAwardees)}
          </p>
        </Flex>
        <Flex>
          <p>
            {finalizeAwardTexts.awardBidCopy[0](pluralAwardees)}{' '}
            <BoldText>{finalizeAwardTexts.awarded}</BoldText>{' '}
            {finalizeAwardTexts.awardBidCopy[1](pluralAwardees)}
          </p>
          <p>{finalizeAwardTexts.awardBidCopy[2](pluralAwardees)}</p>
        </Flex>
      </FlexContainer>
      <FlexContainer>
        <Flex>
          <DSRadio
            name='recommendAward'
            checked={finalizeOption === FinalizeOption.RecommendationOfAward}
            onSelect={() => setFinalizeOption(FinalizeOption.RecommendationOfAward)}
            label={finalizeAwardTexts.recommendationOfAward}
          />
        </Flex>
        <Flex>
          <DSRadio
            name='awardBid'
            checked={finalizeOption === FinalizeOption.AwardBid}
            onSelect={() => setFinalizeOption(FinalizeOption.AwardBid)}
            label={`Award ${bidNumber}: ${bidName}`}
          />
        </Flex>
      </FlexContainer>
      <WizardButtons
        onPrevious={onPrevious}
        onNext={onNext}
        altNext={
          finalizeOption === FinalizeOption.RecommendationOfAward ? 'Recommend Award' : 'Award Bid'
        }
        inactiveNext={submitting || finalizeOption === FinalizeOption.None}
        inactivePrev={submitting}
        save={returnToBidSummary}
      ></WizardButtons>
    </div>
  );
};
