export const AgencyMemberId = 'AgencyMemberId';
export const AgencyMemberName = 'AgencyMemberName';
export const appstatetokenName = 'state';
export const CookieredirectUrl = 'redirectUrl';
export const limitedloginPreviousUrlName = 'lmt_lgn_pre_url';
export const loginPreviousUrlName = 'lgn_pre_url';
export const loginPreviousUrlSearch = 'lgn_pre_url_search';
export const nextFiscalYear = 4;
export const memberCookieName = 'MEMBERID';
export const OpsTokenName = 'OpsToken';
export const orderBidPackageSession = 'orderBidPackage';
export const previousFiscalYear = 2;
export const relogintokenName = 'alw_re_log';
export const selectedBidDocId = 'selectedBidDocId';
export const storedCommoditiestokenName = 'storedCommodities';
export const themetokenName = 'currentDSTheme';
export const tokenCookieName = 'DemandStarToken';
export const isLoggingOut = 'isloggingout';
