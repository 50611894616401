import { useHistory } from 'react-router';

import { ProductsCopy, ProductsPaths } from 'src/utils/texts/supplier/subscription';

import { SubmitButton } from '../../../common/controls/buttons/SubmitButton';
import { UpsellList } from '../../upsell/UpsellList';
import { UpsellWrapper } from './styles';

/**
 * @description A wrapper for the `UpsellList` that adds an 'Explore all products' button
 * @returns JSX.Element
 * @example <UpsellSection />
 */
export const UpsellSection = () => {
  const history = useHistory();

  return (
    <UpsellWrapper>
      <UpsellList />
      <div>
        <SubmitButton
          title={ProductsCopy.ExploreAvailableProductsText}
          onClick={() => {
            history.push(ProductsPaths.ExploreProducts);
          }}
        >
          {ProductsCopy.ExploreAvailableProductsText}
        </SubmitButton>
      </div>
    </UpsellWrapper>
  );
};
