import { getTypeSafeObject } from '../type';

export enum authKeys {
  accountId = 'us',
  accountLocked = 'lkd',
  accountLockedMinutes = 'lm',
  accountPermissions = 'prms',
  email = 'eml',
  hasError = 'her',
  firstName = 'fn',
  fullName = 'fln',
  lastName = 'ln',
  mainContact = 'mc',
  memberBlackListed = 'mbl',
  memberId = 'mi',
  membershipLevels = 'ml',
  memberStatus = 'ms',
  memberType = 'mt',
  migrated = 'mgrtd',
  primaryMemberId = 'pmid',
  username = 'un',
  userType = 'ut',
  token = 'tk',
}

export const memberInfoKeys = {
  acceptHardCopy: 'ahc',
  address1: 'a1',
  address2: 'a2',
  city: 'ct',
  country: 'c',
  countryAbbr: 'ca',
  countryId: 'ci',
  county: 'cu',
  countyId: 'cui',
  fax: 'f',
  faxExtension: 'fe',
  federalTaxId: 'fti',
  isCorporate: 'ic',
  isFreeDocDownload: 'ifd',
  memberId: 'mi',
  memberName: 'mn',
  memberStatus: 'ms',
  memberType: 'mt',
  phone: 'p',
  phoneExtension: 'pe',
  postalCode: 'pc',
  state: 'st',
  stateAbbr: 'sta',
  stateId: 'sti',
};

export const MemberStatus = [
  { label: 'Active', value: 'AC' },
  { label: 'Suspended', value: 'SU' },
];

export const memberTypes = getTypeSafeObject({
  agencyBuyer: 'AB',
  migratedNonSubscriber: 'MS',
  agencyCreatedPlanholder: 'PL',
  basicSupplier: 'SB',
  subscribingSupplier: 'SS',
  supplementalSupplier: 'SV',
  suspendedSupplier: 'SU',
  contentExtractor: 'CE',
});
