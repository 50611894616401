import React from 'react';

import { DSSVGIcon, DSSVGIconProps } from '@demandstar/components/icon';

export const CheckmarkIcon = ({
  color,
  dataTestId,
  height,
  onClick,
  title,
  viewBox = '0 0 24 24',
  width,
}: DSSVGIconProps) => (
  <DSSVGIcon
    height={height}
    onClick={onClick}
    viewBox={viewBox}
    width={width}
    dataTestId={dataTestId}
    title={title}
  >
    <path fill={`${color}`} d='M21 7L9 19L3.5 13.5L4.91 12.09L9 16.17L19.59 5.59L21 7Z' />
  </DSSVGIcon>
);
