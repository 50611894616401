import { commonFieldNames } from '../../../../shared/constants/common';
import { documentLabels } from '../../../../shared/constants/document';
import { getTypeSafeKey } from '../../../../utils/type';
import { GetVendorSpecificDocumentsAPIResponse } from '../../../../types';

export const vendorDocLabels = {
  titlePlural: 'Vendor-specific Documents',
  documentsForYou: 'Documents for You',
  documentListDescription:
    'This bid has multiple awardees associated with it. Please select an awardee, then you can upload documents to send to them. Only you and the selected awardee will be able to see these documents.',
  downloadProvidedDocuments: 'Download the provided documents',
  requiredActionMessageDownloadPrefix: 'Please download documents provided by',
  requiredActionMessageDownloadInstructionsSuffix:
    'has uploaded the following documents specific to your organization. These are available only to you and the agency. Click on the filename to download the file.',
  theSelectedAwardee: 'the Selected Awardee',
};

export const requiredDocLabels = {
  addEditDocumentTypes: 'Add / Edit Document Types',
  addNewDocumentType: 'Define a New Document Type',
  documentFromSupplier: 'Document You Want From Supplier',
  documentsFromSupplier: 'Documents You Want From Supplier',
  documentTypeDescription: 'Document Type Description',
  documentTypeName: 'Document Type Name',
  documentTypes: 'Document Types',
  documentTypeTitle: 'Document Type Title',
  editDocumentTypes: 'Edit Document Types',
  editRequiredDocument: 'Edit Required Document',
  messageUploadSuffix: 'requires you to upload the following document to DemandStar:',
  newRequiredDocument: 'Request a Document from Awardees',
  requiredActionMessageUpload: 'Please upload the required document',
  requiredActionMessageUploadConplete: 'Required document uploaded',
  requiredActionMessageUploadPrefix: 'The following document is required by the agency:',
  requiredActionMessageUploadSuccessSuffix: 'has been uploaded successfully.',
  requiredDocumentName: 'Required Document Name',
  responseDocumentTitle: 'Response Document Title',
  title: 'Required Document',
  titlePlural: 'Required Documents',
  updateAllDueDates: 'Update All Due Dates',
  updateRequiredTypes: 'Update Required Document Types',
} as const;
// Tables

export const vendorDocumentTableHeaders: { [key: string]: string } = {
  [getTypeSafeKey<GetVendorSpecificDocumentsAPIResponse>('fileName')]: documentLabels.docTitle,
  [getTypeSafeKey<GetVendorSpecificDocumentsAPIResponse>('type')]: commonFieldNames.type,
  [getTypeSafeKey<GetVendorSpecificDocumentsAPIResponse>('docType')]: documentLabels.docType,
  [getTypeSafeKey<GetVendorSpecificDocumentsAPIResponse>('modifiedDate')]:
    documentLabels.dateModified,
  [getTypeSafeKey<GetVendorSpecificDocumentsAPIResponse>('requireConversion')]:
    documentLabels.convertedToPdf,
  delete: '',
};
