import { Cell, Column } from 'react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import dayjs from 'dayjs';

import { DSDatePicker } from '@demandstar/components/inputs';
import { DSTable } from '@demandstar/components/table/DSTable';

import { Assert, convertResponseToRequiredDoc, isTodayOrEarlier } from '../../../../utils/helpers';
import { BoldText, SpanSpacerLeft, SpanSpacerRight } from '../../../../shared/styles';
import { commonFieldNames, commonLabels, ControlLabels } from '../../../../shared/constants';
import {
  requiredDocumentResponsesState,
  selectedRequiredDocumentState,
} from '../../../../store/recoil/award-bid';
import { useRequiredDocumentResponses, useRequiredDocuments } from '../../../../shared/hooks';
import { ConfirmModal } from '../../../common/modals/ConfirmModal';
import { DeleteIconCell } from '../../../common/table/renderers/DeleteIconCell';
import { Id } from '../../../../types/shared';
import { requiredDocLabels } from '../add-vendor-docs/constants';
import { RequiredDocumentResponseGetAPIResponse } from '../../../../types';
import { TextInput } from '../../../forms';
import { useResetOnUnmount } from '../../../../shared/hooks/useResetOnUnmount';

/**
 * Table for the display of Required Documents for all vendors in the Award Bid process
 * @param props: DocumentListTableProps
 * @returns
 */
export const RequiredDocumentsTable = () => {
  // Hooks //
  const { refreshRequiredDocumentResponses, requiredDocumentResponses } =
    useRequiredDocumentResponses();
  const { deleteRequiredDoc, updateRequiredDocs } = useRequiredDocuments();

  useResetOnUnmount(requiredDocumentResponsesState);

  /// Application state
  const [selectedRequiredDocument, setSelectedRequiredDocument] = useRecoilState(
    selectedRequiredDocumentState,
  );
  const resetSelectedRequiredDocument = useResetRecoilState(selectedRequiredDocumentState);

  // Local state //
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  // Constants //

  const hideDeleteModal = useCallback(() => {
    setDeleteModalIsOpen(false);
    resetSelectedRequiredDocument();
  }, [resetSelectedRequiredDocument]);

  const deleteReqDoc = useCallback(async () => {
    if (selectedRequiredDocument) {
      const { bidAwardRequiredDocId, docTitle, dueDate, isDelete } = selectedRequiredDocument;
      await deleteRequiredDoc({
        bidAwardRequiredDocId,
        docName: '',
        docTitle,
        docDescription: '',
        dueDate,
        isDelete,
      });
    }
    hideDeleteModal();
  }, [deleteRequiredDoc, hideDeleteModal, selectedRequiredDocument]);

  const showDeleteDocModal = useCallback(
    ({ id }: Id<number>) => {
      const selectedDoc = requiredDocumentResponses.find(doc => {
        return doc?.bidAwardRequiredDocId === id;
      });
      Assert(
        !!selectedDoc,
        'Expected: `selectedDoc` to exist in requiredDocuments (for reqDoc - delete)',
        'src/components/buyer/awardbid/add-vendor-docs/RequiredDocumentsTable.tsx',
      );

      if (selectedDoc) {
        setSelectedRequiredDocument({ ...selectedDoc, isDelete: false });
        setDeleteModalIsOpen(true);
      } else {
        deleteReqDoc();
      }
    },
    [deleteReqDoc, requiredDocumentResponses, setSelectedRequiredDocument],
  );

  const updateRow = useCallback(
    (id: number, field: 'docTitle' | 'dueDate', value: string | null) => {
      if (value === '' && field === 'docTitle') {
        showDeleteDocModal({ id });
        return;
      }
      Assert(!!value, 'We must pass a value', 'RequiredDocumentsTable');

      const selectedDocResponse = requiredDocumentResponses.find(doc => {
        return doc.bidAwardRequiredDocId === id;
      });
      Assert(
        !!selectedDocResponse,
        'We must have a selectedDocResponse',
        'RequiredDocumentsTable.tsx',
      );
      const updatedRequiredDoc = convertResponseToRequiredDoc(selectedDocResponse);
      updatedRequiredDoc[field] = value;
      updateRequiredDocs([updatedRequiredDoc]);
    },
    [requiredDocumentResponses, showDeleteDocModal, updateRequiredDocs],
  );

  const columnConfig: Column<RequiredDocumentResponseGetAPIResponse>[] = useMemo(
    () => [
      {
        accessor: 'docTitle',
        Header: requiredDocLabels.documentsFromSupplier,
        minWidth: 300,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: Cell<RequiredDocumentResponseGetAPIResponse>) => {
          const requiredDoc = { ...cellData.row.original };
          return (
            // TODO: Replace with DSTextInput
            <TextInput
              key={requiredDoc.bidAwardRequiredDocId}
              fullWidth
              value={requiredDoc['docTitle']}
              name={'docTitle'}
              onChange={false}
              required
              onBlur={value => {
                updateRow(requiredDoc.bidAwardRequiredDocId, 'docTitle', value);
              }}
            ></TextInput>
          );
        },
      },
      {
        accessor: 'dueDate',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: Cell<RequiredDocumentResponseGetAPIResponse>) => {
          const requiredDoc = { ...cellData.row.original };
          return (
            <DSDatePicker
              name=''
              onChange={value => {
                updateRow(requiredDoc.bidAwardRequiredDocId, 'dueDate', value?.format() || '');
              }}
              disabledDate={isTodayOrEarlier}
              value={dayjs(requiredDoc.dueDate)}
            />
          );
        },
        Header: commonFieldNames.dueDate,
        minWidth: 200,
      },
      {
        Header: '',
        accessor: 'isDelete',
        Cell: DeleteIconCell<number, RequiredDocumentResponseGetAPIResponse>({
          idField: 'bidAwardRequiredDocId',
          labelField: 'docName',
          handleClick: showDeleteDocModal,
        }),
        maxWidth: 30,
        disableSortBy: true,
      },
    ],
    [showDeleteDocModal, updateRow],
  );

  // Hooks //
  useEffect(() => {
    refreshRequiredDocumentResponses();
  }, [refreshRequiredDocumentResponses]);

  // Functions //
  /** Delete row */

  function getConfirmDeleteMessageComponent() {
    const docDisplayName = selectedRequiredDocument
      ? selectedRequiredDocument['docTitle']
      : 'this document';
    return (
      <>
        <SpanSpacerRight>{ControlLabels.confirmDeletePrefix}</SpanSpacerRight>
        <BoldText>{docDisplayName}</BoldText>
        <SpanSpacerLeft>{'?'}</SpanSpacerLeft>
      </>
    );
  }

  return (
    <>
      <DSTable
        columns={columnConfig}
        data={requiredDocumentResponses}
        emptyMessage={commonLabels.notFound}
        sort='dueDate'
      />

      {/* Delete Required Document confirmation modal */}
      <ConfirmModal
        confirmMessage={getConfirmDeleteMessageComponent()}
        approveAction={deleteReqDoc}
        title={commonLabels.confirmDelete}
        closeModal={hideDeleteModal}
        isOpen={deleteModalIsOpen}
        size='md'
      />
    </>
  );
};
