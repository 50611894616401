import React, { memo, useEffect } from 'react';
import { Table } from 'reactstrap';
import { CheckBox } from '../../../../customcontrols';
import NoResult from '../../../../customcontrols/noresult';
import { loadingMsg } from '../../../../../utils/texts';
import { bidssummaryParamType, supplierItemFields } from '../../../../../types/biddetail';
import { sharedTypes } from '../../../../../types/shared';

const tdWidth = {
  contact: {
    width: '150px',
  },
  sendBid: {
    width: '100px',
  },
};

interface PropsTypes {
  setBidSummaryDetails?: any;
  sendBidSelectedSupplier?: any;
  loadBidsSimilarSuppliers?: any;
  similarSuppliers?: supplierItemFields[];
  bidId: number;
  bidssummary?: bidssummaryParamType;
  pagfor: string;
}

function Similarsuppliers(props: PropsTypes) {
  const {
    setBidSummaryDetails,
    sendBidSelectedSupplier,
    loadBidsSimilarSuppliers,
    bidId = '',
    bidssummary,
  } = props;
  const { currentsupplier, similarSuppliers = [], spLoader = false } = bidssummary || {};

  const SelectSuppliers = (value: boolean, index: number) => {
    const newsimilar = similarSuppliers.find((item, indexs) => indexs === index);
    if (newsimilar) {
      newsimilar.selected = value;
    }
    setBidSummaryDetails({ similarSuppliers });
  };

  const enablesendbtn = similarSuppliers.find(item => item.selected);

  const SendBidSelectedSuppliers = () => {
    if (enablesendbtn && currentsupplier) {
      sendBidSelectedSupplier({ similarSuppliers, bidId, supplierId: currentsupplier.mi });
    }
  };

  useEffect(() => {
    if (currentsupplier && currentsupplier.mi && bidId) {
      loadBidsSimilarSuppliers({ bidId, supplierId: currentsupplier && currentsupplier.mi });
    }
  }, [bidId, currentsupplier, loadBidsSimilarSuppliers]);

  return (
    <>
      <h4>Similar Planholders</h4>
      {similarSuppliers.length > 0 ? (
        <>
          <header className='blockControls clearfix'>
            <ul className='list-unstyled'>
              <li
                className={enablesendbtn ? '' : 'disabled-link'}
                onClick={SendBidSelectedSuppliers}
              >
                <i className='mdi mdi-content-save-outline' /> Send Bid to Selected Suppliers
              </li>
            </ul>
          </header>
          <div className='innerColIndent'>
            <Table className='tableHData'>
              <tbody>
                <tr>
                  <th>Supplier</th>
                  <th style={tdWidth.contact}>Contact Person</th>
                  {/* <th width='150'>Email</th> */}
                  {/*	<th>City, State</th>
									<th width='170'>Self Declarations</th>*/}
                  <th style={tdWidth.sendBid}>Send Bid</th>
                </tr>
                {similarSuppliers
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.supplierName}</td>
                      <td>
                        {item.fn} {item.ln}
                      </td>
                      {/* <td>{item.email}</td> */}
                      <td>
                        <CheckBox
                          handleChecked={(name: string, value: boolean) =>
                            SelectSuppliers(value, index)
                          }
                          checked={item.selected}
                        />
                      </td>
                    </tr>
                  ))
                  .slice(0, 10)}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <NoResult message={spLoader ? loadingMsg : 'No Similar Planholders  Available'} />
      )}
      {/* <Paging
				totalRecords={similarSuppliers.length}
				currentPage={currentPage}
				onPagingClick={value => {
					if (value !== currentPage) setCurrentPage(Number(value))
				}}
				pageLimit={listPerPage}
			/> */}
    </>
  );
}

export default memo(Similarsuppliers);
