import { commonEmail } from '../texts/common';
import { DocStatus } from '../../types/addbid';

export type AllowedExtension =
  | 'csv'
  | 'doc'
  | 'docx'
  | 'dwg'
  | 'htm'
  | 'html'
  | 'jpeg'
  | 'jpg'
  | 'm4a'
  | 'mov'
  | 'mp3'
  | 'mp4'
  | 'ods'
  | 'odt'
  | 'pdf'
  | 'png'
  | 'ppt'
  | 'pptx'
  | 'shx'
  | 'tif'
  | 'tiff'
  | 'txt'
  | 'xlr'
  | 'xls'
  | 'xlsb'
  | 'xlsm'
  | 'xlsx'
  | 'xltx'
  | 'wpd'
  | 'wma'
  | 'zip';

export interface BulkUploadErrorMessage {
  id: number;
  message: string;
  hyperlink: boolean;
  linkText: string;
}

export const bulkUploadErrorMsg: BulkUploadErrorMessage[] = [
  {
    id: 1,
    message:
      'We couldn’t find any valid email addresses in the document you uploaded. Please try another file, and make sure there is one email address per row.',
    hyperlink: false,
    linkText: '',
  },
  {
    id: 2,
    message:
      'We found multiple email addresses on at least one row. Please refer to our help file ',
    hyperlink: true,
    linkText: ' and try again.',
  },
  {
    id: 4,
    message:
      'The file you uploaded has too many rows. Please break it into multiple files that have fewer than 500 rows and upload those one at a time.',
    hyperlink: false,
    linkText: '',
  },
  {
    id: 5,
    message: 'We had trouble processing your file. Please refer to our help file ',
    hyperlink: true,
    linkText: `, resave or reexport it, and try again. If you continue to have trouble, please email ${commonEmail.agencyServices}`,
  },
  { id: 6, message: 'Internal server error. Please try again', hyperlink: false, linkText: '' },
  { id: 7, message: 'Error: the uploaded file contains no data.', hyperlink: false, linkText: '' },
  {
    id: 8,
    message:
      'The file you uploaded is not a CSV or Excel file. Please convert to one of these filetypes and upload it again.',
    hyperlink: false,
    linkText: '',
  },
];

export enum BulkUploadModalComponent {
  UploadFile = 1,
  ColumnCheck = 2,
  ReviewList = 3,
}

export const documentNonDeleteStatus = ['AC', 'AW', 'OP', 'RA', 'CP'];

// TODO: What are these other non-DocStatus strings? Do we use these?
export const documentProcessStatus = [
  DocStatus.PdfProcessPending,
  'CD',
  DocStatus.ProcessPending,
  'UP',
  'PN',
  'BC',
];

// TODO: Why is this called that? Why these three status? Is there a better name?
export type DocumentStatusNames =
  | DocStatus.ProcessingError
  | DocStatus.ApprovalPending
  | DocStatus.Complete;

export const documentStatusNames = {
  CE: 'Processing Error',
  RV: 'Approval Pending',
  AC: 'Complete',
};

export const maxUploadInMB = 105;
export const maxUploadInText = 100;
export const fileUploadSize = maxUploadInMB * 1024 * 1024; // 105MB
export const fileUploadSizeError = `This file is too big. Please upload a file smaller than ${maxUploadInText}MB instead.`;
export const supportedImageFormat = ['jpeg', 'jpg', 'png'];
export const supportSupplimentalDocTypes = ['csv', 'xls', 'xlsx'];
