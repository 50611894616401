import { ReactNode } from 'react';

import { Flex, FlexContainer } from '@demandstar/components/styles';
import { DSButton } from '@demandstar/components/button';

import { ModalPopUp, ModalPopUpProps } from './ModalPopUp';

/** This interface is to enforce that if onlyApprove is true, we do not allow
 * cancelAction, cancelText, or size to passed into the component.
 *
 * cancelAction and cancelText would be useless because we do not display a cancel button.
 *
 * size we want to be consistently 'md' sitewide for OK dialog boxes.
 */

/**
 * @example Valid props:
 *  <ConfirmModal
 *    onlyApprove={true}
 *    approveAction={approve}
 *    closeModal={toggleClose}
 *    title={'Confirm'}
 *    isOpen={modalOpen}
 *    confirmMessage={'Do you confirm?'}
 *  />
 */

/**
 * @example Invalid props:
 *  <ConfirmModal
 *    onlyApprove={true}
 *    approveAction={approve}
 *    cancelText={'Go Back'} // This text would not be seen.
 *    closeModal={toggleClose}
 *    title={'Confirm'}
 *    isOpen={modalOpen}
 *    confirmMessage={'Do you confirm?'}
 *    size={'xxl'} // This is too big for a single button dialog box.
 *  />
 */

/**
 * @param {function}          approveAction - onClick for the approve button.
 * @param {string='Confirm'}  approveText - optional button text.
 *                            Will be 'OK' if 'onlyApprove' is true.
 * @param {function=}         cancelAction - onClick for the cancel button.
 *                            If not passed in, we use closeModal instead.
 * @param {string='Cancel'}   cancelText - optional button text.
 * @param {function}          closeModal - function that closes the modal.
 * @param {ReactNode}         confirmMessage - the message in the dialog box. Can be JSX.Element or string.
 * @param {boolean}           isOpen - whether or not the modal is open.
 * @param {boolean=}          onlyApprove - if true, we do not use the cancel button.
 * @param {ModalSize=}        size - the size of the modal.
 *                            will be 'lg' if not passed in, or 'md' for 'onlyApprove' dialog
 * @param {string=}           subText - optional additional text.
 * @param {string}            title - title text of the modal.
 * */

type BaseConfirmModalProps = ConfirmDialogProps &
  Omit<ModalPopUpProps, 'className' | 'backdrop' | 'children'>;
interface CancelableConfirmModalProps extends BaseConfirmModalProps {
  onlyApprove?: false;
}
interface NonCancelableModalProps extends BaseConfirmModalProps {
  onlyApprove: true;
  // if onlyApprove is true, we should do the following:
  cancelAction?: never;
  cancelText?: never;
  size?: never;
}

export type ConfirmModalProps = CancelableConfirmModalProps | NonCancelableModalProps;

export function ConfirmModal(props: ConfirmModalProps) {
  const {
    approveAction,
    approveText,
    cancelAction,
    cancelText,
    closeModal,
    confirmMessage,
    isOpen,
    onlyApprove,
    subText,
    title,
  } = props;

  const size = props.onlyApprove ? 'md' : props.size;

  return (
    <ModalPopUp title={title} size={size} isOpen={isOpen} closeModal={closeModal}>
      <ConfirmDialog
        approveAction={approveAction}
        approveText={approveText}
        cancelAction={cancelAction}
        cancelText={cancelText}
        closeModal={closeModal}
        confirmMessage={confirmMessage}
        subText={subText}
        onlyApprove={onlyApprove}
      />
    </ModalPopUp>
  );
}

export interface ConfirmDialogProps {
  approveAction: () => void;
  approveText?: string;
  cancelAction?: () => void;
  cancelText?: string;
  closeModal: () => void;
  confirmMessage: ReactNode;
  subText?: string;
  onlyApprove?: boolean;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    approveAction,
    cancelAction,
    cancelText = 'Cancel',
    closeModal,
    confirmMessage,
    subText,
    onlyApprove,
  } = props;

  const approveText = props.approveText || (onlyApprove ? 'OK' : 'Confirm');

  function cancel() {
    if (cancelAction) {
      cancelAction();
    } else {
      closeModal();
    }
  }

  return (
    <>
      <div data-testid='confirm-dialog.modal.head'>{confirmMessage}</div>
      {subText ? <p data-testid='confirm-dialog.modal.text'>{subText}</p> : ''}
      <FlexContainer
        data-testid='confirm-dialog.modal.buttons'
        wrap='wrap'
        justifyContent={'space-around'}
      >
        {onlyApprove ? (
          <></>
        ) : (
          <Flex grow={0}>
            <DSButton
              buttonType={'secondary'}
              title={cancelText}
              onClick={cancel}
              dataTestId='confirm-dialog.modal.cancel'
            >
              {cancelText}
            </DSButton>
          </Flex>
        )}
        <Flex grow={0}>
          <DSButton
            title={approveText}
            onClick={approveAction}
            dataTestId='confirm-dialog.modal.approve'
          >
            {approveText}
          </DSButton>
        </Flex>
      </FlexContainer>
    </>
  );
}
