import React, { useEffect, useState } from 'react';

import { DSButton } from '@demandstar/components/button';

import { CustomDocumentTypeAddForm } from '../add-vendor-docs/CustomDocumentTypeAddForm';
import { ModalPopUp } from '../../../common/modals';
import { PanelRowRight } from '../../../../shared/styles';
import { requiredDocLabels } from '../add-vendor-docs/constants';
import { useRequiredDocumentTypes } from '../../../../shared/hooks/award-bid/useRequiredDocumentTypes';

/**
 * Required Document List table with editable/deletable rows,
 * a date picker to change all row dates,
 * and a toggle button to allow addition of new Required Documents
 */
export function AddRequiredDocumentTypePanel() {
  // Local state
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  // Hooks
  const { refreshRequiredDocTypes } = useRequiredDocumentTypes();

  // useEffect Hooks

  useEffect(() => {
    refreshRequiredDocTypes();
  }, [refreshRequiredDocTypes]);

  // Functions

  function hideEditModal() {
    setEditModalIsOpen(false);
    refreshRequiredDocTypes();
  }

  function showAddModal() {
    setEditModalIsOpen(true);
  }

  return (
    <>
      <PanelRowRight paddingHorizontal={'0'} paddingVertical={'0.5rem'}>
        <DSButton buttonType='secondary' onClick={showAddModal}>
          {requiredDocLabels.addEditDocumentTypes}
        </DSButton>
      </PanelRowRight>

      <ModalPopUp
        title={requiredDocLabels.addEditDocumentTypes}
        closeModal={hideEditModal}
        isOpen={editModalIsOpen}
      >
        <CustomDocumentTypeAddForm closeModal={hideEditModal} />
      </ModalPopUp>
    </>
  );
}
