import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useRecoilCallback } from 'recoil';

import {
  calculateSubscriptionRequest,
  createFreeSubscription,
  createPaidSubscription,
} from 'src/store/services/subscriptions';
import {
  cancelSupplierSubscription,
  modifySubscription as modifySupplierSubscription,
  updateSubscriptionFreeAgencies,
} from '../../store/services';

import { accountInfoState } from 'src/store/recoil/accountInfoState';
import { ProductApiResponse } from 'src/types/products';
import { prorationMismatchState } from 'src/store/recoil/subscriptionState';
import { track } from '../../utils/telemetry';
import { useAccountInfo } from './useAccountInfo';
import { useAuth } from './useAuth';

export enum CreateSubscriptionError {
  Api = 'API',
  InvalidOperation = 'Invalid Operation',
  ProrationMismatch = 'Proration Mismatch',
}

interface CreateSubscriptionParameters {
  /** the amount displayed in the front-end, if this doesn't match back-end calculation
   * the process fails */
  expectedTotal?: number;
  /** if the order is complimentary or not (OPS only) */
  isComplimentary?: boolean;
  /** if this is an upgrade or not */
  isUpgrade: boolean;
  /** the member ID for whom to add the subscription */
  memberId?: number;
  /**
   * In the event of a cost conflict between the front and back ends, this indicates
   * whether the OPS user has reviewed the discrepancy and elected to confirm
   */
  opsConfirmed?: boolean;
  /** all products that should part of the subscription */
  products: ProductApiResponse[];
  /** the sales note for complimentary orders */
  salesNotes?: string;
  /** the payment method nonce to use, if a new payment method */
  token?: string;
}

interface ModifySubscriptionParameters {
  /** the new date to extend expiration */
  expirationDate: dayjs.Dayjs;
  /** the sales note for the extension */
  notes?: string;
  /** the payment method nonce to use, if a new payment method */
  nonce?: string;
}

/**
 * @description wrapper for subscription-related service layer interactions
 * @example const { createSubscription, cancelSubscription } = useSubscription();
 */
export function useSubscription() {
  const { accountInfo } = useAccountInfo();
  const {
    auth: { opsId = 0 },
  } = useAuth();

  const isOpsUser = useMemo(() => {
    return opsId > 0;
  }, [opsId]);

  /**
   * @description creates a subscription and, if necessary, processes payment for it
   * @returns boolean indicating success or failure
   * @default { isComplimentary = false, salesNotes = '', token = '' }
   * @example const result = await createSubscription({
   * expectedTotal: 25,
   * isUpgrade: false,
   * memberId: 1,
   * products: [{ productId: 1, price: 25, productType: ProductType.County, productName: 'County' }]});
   */
  const createSubscription = useRecoilCallback(
    ({ set }) =>
      async ({
        expectedTotal,
        isComplimentary = false,
        isUpgrade,
        memberId,
        opsConfirmed = false,
        products,
        salesNotes = '',
        token = '',
      }: CreateSubscriptionParameters) => {
        const productIds = products.map(p => p.productId).join(',');

        // Failsafe to prevent OPS-specific actions from being called by non-OPS users
        if ((isComplimentary || opsConfirmed) && !isOpsUser) {
          return {
            status: false,
            errorType: CreateSubscriptionError.InvalidOperation,
          };
        }

        // Check if front-end proration doesn't match back-end proration
        if (expectedTotal && !isComplimentary && !opsConfirmed) {
          const calculateResult = await calculateSubscriptionRequest({
            isUpgrade,
            products: productIds,
          });

          const calculateResultData = JSON.parse(calculateResult?.data);

          if (calculateResultData['TotalCost'] !== expectedTotal) {
            set(prorationMismatchState, {
              expectedCost: expectedTotal,
              calculatedCost: calculateResultData['TotalCost'],
            });
            return {
              status: false,
              errorType: CreateSubscriptionError.ProrationMismatch,
            };
          }
        }

        const createFn = isComplimentary ? createFreeSubscription : createPaidSubscription;
        const result = await createFn({
          isUpgrade: true,
          isRenewalUpgrade: isUpgrade,
          products: productIds,
          memberId: memberId,
          pagefor: isUpgrade ? 'review' : 'registration',
          salesNotes,
          token,
        });

        // For some reason, this returns as a string
        const resultData = JSON.parse(result?.data);
        const success = resultData['Result'] === 'SUCCESS';

        if (success) {
          const newProductIds = products
            .map(p => p.productId)
            .filter(p => !accountInfo.products.map(ac => ac.productId).includes(p));

          set(accountInfoState, {
            ...accountInfo,
            newProductIds: [...newProductIds],
          });
        }

        return {
          status: success,
          errorType: success ? undefined : CreateSubscriptionError.Api,
        };
      },
    [isOpsUser],
  );

  /**
   * @description determines the price of an extension
   * @returns Promise<void>
   * @default { isComplimentary = false, salesNotes = '', token = '' }
   * @example const result = await modifySubscription({
      expirationDate: dayjs(),
      notes: '',
      nonce: ''});
   */
  const previewSubscriptionExtension = useRecoilCallback(
    () =>
      async (
        { expirationDate, nonce, notes }: ModifySubscriptionParameters,
        setNewAmount?: (amt: number) => void,
      ) => {
        try {
          const result = await modifySupplierSubscription({
            subscriptionRule: 'Modify',
            accountingRule: 'Charge',
            isreadonly: true,
            existingExpiryDate: accountInfo.expiryDate.toDate(),
            expirationDate: expirationDate.toDate(),
            nonce,
            notes,
          });

          if (result?.data && setNewAmount) {
            setNewAmount(result?.data?.SubscriptionAmount);
          }
        } catch (error: any) {
          track('modifySubscription -> modifySupplierSubscription() ERROR:', {
            error,
            method: 'POST',
            errorMessage: error.message,
          });

          // eslint-disable-next-line no-console
          console.error(`useSubscription -> modifySupplierSubscription() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
        }
      },
    [accountInfo.expiryDate],
  );

  /**
   * @description extends a subscription and, if necessary, processes payment for it
   * @returns Promise<void>
   * @default { isComplimentary = false, salesNotes = '', token = '' }
   * @example const result = await modifySubscription({
      expirationDate: dayjs(),
      notes: '',
      nonce: ''});
   */
  const extendSubscription = useRecoilCallback(
    () =>
      async ({ expirationDate, nonce, notes }: ModifySubscriptionParameters, isFree = false) => {
        try {
          await modifySupplierSubscription({
            subscriptionRule: 'Modify',
            accountingRule: isFree && isOpsUser ? 'Free' : 'Charge',
            isreadonly: false,
            existingExpiryDate: accountInfo.expiryDate.toDate(),
            expirationDate: expirationDate.toDate(),
            nonce,
            notes,
          });
        } catch (error: any) {
          track('modifySubscription -> modifySupplierSubscription() ERROR:', {
            error,
            method: 'POST',
            errorMessage: error.message,
          });

          // eslint-disable-next-line no-console
          console.error(`useSubscription -> modifySupplierSubscription() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
        }
      },
    [accountInfo.expiryDate, isOpsUser],
  );

  /**
   * @description updates a supplier's selected free agency
   * @param products - string - a comma-separated list of product ids (should really only be one)
   * @param pageFor - string - page being called from
   * @returns Promise<void>
   * @example await modifySubscription(products);
   */
  const updateSupplierFreeAgency = useRecoilCallback(
    ({ set }) =>
      async (products: ProductApiResponse[], isRegistration = false) => {
        try {
          const productIds = products.map(p => p.productId);
          await updateSubscriptionFreeAgencies({
            products: productIds.join(','),
            pageFor: isRegistration ? 'registration' : 'subscription',
          });

          set(accountInfoState, {
            ...accountInfo,
            newProductIds: [...productIds],
          });
        } catch (error: any) {
          track('updateSupplierFreeAgencies -> updateSupplierFreeAgencies() ERROR:', {
            error,
            method: 'POST',
            errorMessage: error.message,
          });

          // eslint-disable-next-line no-console
          console.error(`useSubscription -> updateSupplierFreeAgencies() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
        }
      },
    [],
  );

  /**
   * @description cancels a member's subscription
   * @returns Promise<void>
   * @example await cancelSubscription();
   */
  const cancelSubscription = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          await cancelSupplierSubscription();

          set(accountInfoState, {
            ...accountInfo,
            products: [],
            newProductIds: [],
          });
        } catch (error: any) {
          track('cancelSubscription -> cancelSubscription() ERROR:', {
            error,
            method: 'POST',
            errorMessage: error.message,
          });

          // eslint-disable-next-line no-console
          console.error(`useSubscription -> cancelSubscription() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
        }
      },
    [],
  );

  return {
    /** Cancels all active subscriptions */
    cancelSubscription,
    /** Adds new products to a subscription */
    createSubscription,
    /** Extends a subscription's expiration date */
    extendSubscription,
    /** Calculates the cost of extending a subscription */
    previewSubscriptionExtension,
    /** Sets or modifies the selected free agency for a supplier */
    updateSupplierFreeAgency,
  };
}
