import React, { MouseEvent, useEffect, useState } from 'react';

import {
  ActionMessage,
  PanelItemWrapper,
  PanelLabelDashboard,
  PanelListItem,
  PanelRowRight,
  SolicitationTitle,
} from '../../../shared/styles';
import {
  commonLabels,
  navigationPaths,
  requiredActionLabels,
  SubPaths,
} from '../../../shared/constants';
import { RequiredAction, RequiredActionType } from '../../../types/required-action';
import {
  requiredDocLabels,
  vendorDocLabels,
} from '../../../components/buyer/awardbid/add-vendor-docs/constants';

import { DueDatePill } from '../../../components/common/controls/static/DueDatePill';
import { getDefaultLoadingMessage } from '../../../utils/message';
import { LoadingPanel } from '../../../components/common/loading';
import { NavLink } from 'react-router-dom';
import { NotFoundPanel } from '../../../components/common/loading/NotFoundPanel';
import { Pages } from '../../../utils/constants';
import { useRequiredActions } from '../../../shared/hooks/award-bid/useRequiredActions';
import { useRouter } from '../../../shared/hooks';
import { useSelectedBidId } from '../../../shared/hooks/useSelectedBidId';

export type RequiredActionsDashboardPanelProps = {
  label?: string;
  listLength?: number;
  selectedRequiredActionId?: string;
};

const defaultDashboardListLength = 5;

/**
 * Presentation component for the Required Actions list
 * @param props
 * @returns
 */
export function RequiredActionsDashboardPanel(props: RequiredActionsDashboardPanelProps) {
  const {
    listLength: listLengthMax = defaultDashboardListLength,
    label = requiredActionLabels.titlePlural,
    selectedRequiredActionId,
  } = props;

  const { selectedBidId } = useSelectedBidId();

  const { history } = useRouter();

  const {
    refreshRequiredActions,
    requiredActionIncompleteList,
    requiredActionList,
    requiredActionsLoading,
  } = useRequiredActions();

  // Local state
  const [requiredActionDisplayList, setRequiredActionDisplayList] = useState<RequiredAction[]>([]);

  // useEffect Hooks

  /**
   * Show all RequiredActions: Refresh only for the default `selectedBidId` (-1)
   */
  useEffect(() => {
    if (selectedBidId <= 0) {
      refreshRequiredActions();
    }
  }, [refreshRequiredActions, selectedBidId]);

  /**
   * Filter actions to show only incomplete items.
   * Limit displayed actions to the max display count.
   */
  useEffect(() => {
    setRequiredActionDisplayList(requiredActionIncompleteList.slice(0, listLengthMax));
  }, [listLengthMax, requiredActionIncompleteList]);

  const requiredActionIncompleteCount = requiredActionIncompleteList.length;

  // Return empty content if there are no `requiredActionList` items.
  if (requiredActionIncompleteCount <= 0) {
    return null;
  }

  /**
   * Curried function to navigate to individual action details.
   * @param event
   * @param bidId: number,
   * @param actionId: number
   * @returns
   */
  const handleClick = (bidId: number, actionId: string) => (event: MouseEvent<HTMLElement>) => {
    history.push(`${Pages.supplierBids}/${bidId}/${SubPaths.management}/${actionId}`);
  };

  function renderRequiredAction(requiredAction: RequiredAction, index: number) {
    const { actionId, bidId, bidTitle, dueDate, label, type } = requiredAction;

    const message =
      (type === RequiredActionType.RequiredDocument
        ? label
        : vendorDocLabels.downloadProvidedDocuments) +
      ' for ' +
      bidTitle;
    const displayLabel =
      type === RequiredActionType.RequiredDocument
        ? requiredDocLabels.requiredActionMessageUpload
        : vendorDocLabels.documentsForYou;

    return (
      <PanelListItem
        key={`${actionId}_${index}`}
        onClick={handleClick(bidId, actionId)}
        title={`${message}`}
        selected={actionId === selectedRequiredActionId}
      >
        {type === RequiredActionType.RequiredDocument && <DueDatePill dueDate={dueDate} />}
        <SolicitationTitle title={message}>{displayLabel}</SolicitationTitle>
        <ActionMessage>{message}</ActionMessage>
      </PanelListItem>
    );
  }

  const returnContent = requiredActionsLoading ? (
    <LoadingPanel message={getDefaultLoadingMessage(requiredActionLabels.titlePlural)} />
  ) : requiredActionIncompleteCount > 0 ? (
    requiredActionDisplayList.map(renderRequiredAction)
  ) : (
    <NotFoundPanel message={requiredActionLabels.notFound} />
  );

  const requiredActionCountDisplay =
    !requiredActionsLoading && requiredActionIncompleteCount > 0
      ? `(${requiredActionIncompleteCount})`
      : '';

  return (
    <>
      <PanelLabelDashboard>{`${label} ${requiredActionCountDisplay}`}</PanelLabelDashboard>
      <PanelItemWrapper>
        {returnContent}
        {requiredActionList.length > requiredActionIncompleteCount && (
          <PanelRowRight>
            <NavLink
              activeClassName='mdi mdi-launch'
              to={`${navigationPaths.suppliers.responses}/${SubPaths.requiredActions}`}
            >
              {commonLabels.viewMore}
              <i className='mdi mdi-launch' />
            </NavLink>
          </PanelRowRight>
        )}
      </PanelItemWrapper>
    </>
  );
}
