import React, { useEffect, useState } from 'react';

interface LogoDSLoaderProps {
  alt: string;
}

// DEPRECATED. Until we replace older loader uses, new code should use `src/library/icon/DSLogoIcon.tsx`
function LogoDSLoader(props: LogoDSLoaderProps) {
  const [frame, setFrame] = useState(0);
  const duration = 2000; // how long is this animation?
  const totalFrames = 16; // this is how many frames the animation takes up.
  // don't change this unless you know what you're doing.

  useEffect(() => {
    const frameInterval = setInterval(() => {
      setFrame(frame => frame + 1);
    }, duration / totalFrames);
    return () => clearInterval(frameInterval);
  }, []);

  const shouldHighlight = (point: number) => {
    const frameNum = frame % totalFrames;
    if (frameNum >= point && frameNum < point + totalFrames / 2) {
      return ' highlight';
    }
    return '';
  };

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' role='img'>
      <title>{props.alt}</title>
      <g
        id='DemandStar_-_Logo-Loader'
        data-name='DemandStar - Logo'
        transform='translate(139.289 24)'
      >
        <g id='Group_29' data-name='Group 29' transform='translate(-139.289 -24)'>
          <g id='Group_27' data-name='Group 27' transform='translate(0 11.353)'>
            <path
              id='Path_109'
              data-name='Path 109'
              className={'cls-1 point6' + shouldHighlight(8)}
              d='M30.361,29.029a2.61,2.61,0,0,1-2.336,1.622,2.29,2.29,0,0,1-1.645-.72c-1.229-1.279-5.446-6.432-4.354-7.61a.838.838,0,0,1,.609-.24c1.617,0,5.879,3.089,7.19,4.4A2.256,2.256,0,0,1,30.361,29.029Z'
              transform='translate(-10.614 -22.08)'
            />
            <path
              id='Path_110'
              data-name='Path 110'
              className={'cls-1 point5' + shouldHighlight(7)}
              d='M17.307,44.357A2.334,2.334,0,0,1,14.934,46.8c-.5,0-4.151-.134-7.864-.509C.732,45.647,0,44.879,0,44.357s.732-1.285,7.069-1.923c3.7-.38,7.359-.514,7.864-.514a2.33,2.33,0,0,1,2.373,2.437Z'
              transform='translate(0 -31.719)'
            />
          </g>
          <g id='Group_28' data-name='Group 28' transform='translate(11.362)'>
            <path
              id='Path_111'
              data-name='Path 111'
              className={'cls-1 point8' + shouldHighlight(2)}
              d='M63.163,22.722c0,1.617-3.093,5.877-4.4,7.182a2.3,2.3,0,0,1-1.641.724,2.373,2.373,0,0,1-.9-.189,2.608,2.608,0,0,1-1.62-2.336,2.265,2.265,0,0,1,.72-1.639c.617-.622,5.269-4.538,7.065-4.543a.771.771,0,0,1,.547.19.823.823,0,0,1,.238.612Z'
              transform='translate(-37.885 -10.649)'
            />
            <path
              id='Path_112'
              data-name='Path 112'
              className={'cls-1 point4' + shouldHighlight(6)}
              d='M30.665,57.084a2.288,2.288,0,0,1-.72,1.645c-.618.626-5.276,4.538-7.071,4.538a.735.735,0,0,1-.537-.187.811.811,0,0,1-.239-.606c0-1.626,3.09-5.881,4.4-7.187a2.279,2.279,0,0,1,1.645-.723,2.431,2.431,0,0,1,.9.184A2.606,2.606,0,0,1,30.665,57.084Z'
              transform='translate(-22.097 -26.509)'
            />
            <path
              id='Path_113'
              data-name='Path 113'
              className={'cls-1 point3' + shouldHighlight(5)}
              d='M46.812,62.063c0,.5-.133,4.16-.511,7.867C45.661,76.264,44.892,77,44.374,77s-1.285-.737-1.931-7.071c-.375-3.67-.505-7.353-.505-7.867a2.439,2.439,0,0,1,4.875,0Z'
              transform='translate(-31.736 -29.001)'
            />
            <path
              id='Path_114'
              data-name='Path 114'
              className={'cls-1 point2' + shouldHighlight(4)}
              d='M63.028,62.913a.832.832,0,0,1-.611.237c-1.623,0-5.879-3.091-7.188-4.4a2.239,2.239,0,0,1-.533-2.541,2.605,2.605,0,0,1,2.335-1.624,2.3,2.3,0,0,1,1.645.714C59.947,56.636,64.109,61.745,63.028,62.913Z'
              transform='translate(-37.843 -26.517)'
            />
            <path
              id='Path_115'
              data-name='Path 115'
              className={'cls-1 point1' + shouldHighlight(3)}
              d='M76.989,44.386c0,.512-.738,1.272-7.074,1.909-3.693.376-7.353.508-7.858.508a2.441,2.441,0,0,1,0-4.881c2.731,0,13.471.722,14.722,1.984a.613.613,0,0,1,.21.481Z'
              transform='translate(-40.354 -20.366)'
            />
            <path
              id='Path_116'
              data-name='Path 116'
              className={'cls-1 point7' + shouldHighlight(1)}
              d='M46.812,14.934a2.439,2.439,0,0,1-4.875,0c0-.514.131-4.2.505-7.867C43.087.73,43.853,0,44.374,0S45.659.73,46.3,7.067C46.68,10.768,46.812,14.427,46.812,14.934Z'
              transform='translate(-31.736 0)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LogoDSLoader;
