import dayjs from 'dayjs';
import { useState } from 'react';

import { DSButton } from '@demandstar/components/button';

import { cardParamType } from 'src/types/subscriptions';
import { ModalPopUp } from 'src/components/common/modals';
import { ModifySubscriptionContent } from './ModifySubscriptionContent';
import { OpsActionsCopy } from 'src/utils/texts/supplier/subscription';

interface ModifySubscriptionProps {
  /** the member's card-on-file (for display purposes) */
  card?: cardParamType;
  /** the expiration date for the member's current subscription */
  expiryDate: dayjs.Dayjs;
}

/**
 * @description an OPS-styled button that opens a modal that allows for
 * the extension of a subscription's expiration date
 * @returns JSX.Element
 *
 * @example <ModifySubscription
 *  card={{
 *    cardType: 'Visa',
 *    lastFour: 1111,
 *  }}
 *  expiryDate={dayjs().endOf('year')}
 * />
 */
export const ModifySubscription = ({ card, expiryDate }: ModifySubscriptionProps) => {
  const [modifySubscriptionModal, setModifySubscriptionModal] = useState(false);

  const toggleModifySubscription = () => {
    setModifySubscriptionModal(!modifySubscriptionModal);
  };

  return (
    <>
      <DSButton
        buttonType={'ops'}
        title={OpsActionsCopy.ModifySubscription}
        onClick={() => toggleModifySubscription()}
      >
        {OpsActionsCopy.ModifySubscription}
      </DSButton>
      <ModalPopUp // Existing Modify Subscription
        size='md'
        title={OpsActionsCopy.ModifySubscription}
        closeModal={toggleModifySubscription}
        isOpen={modifySubscriptionModal === true}
        backdrop='static'
      >
        <ModifySubscriptionContent
          card={card ?? { cardType: 'N/A', cardLastFour: 0 }}
          expiryDate={expiryDate}
          toggleModifySubscription={toggleModifySubscription}
        />
      </ModalPopUp>
    </>
  );
};
