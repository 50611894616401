import { FormProvider, useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { DSDollarField } from '@demandstar/components/fields/';

import { Assert } from '../../../../utils/helpers';
import { Awardee } from '../../../../types/biddetail';
import { selectedBidAwardeeState } from '../../../../store/recoil/award-bid';
import { useBidAwardee } from '../../../../shared/hooks/useBidAwardee';
import { useSetRecoilState } from 'recoil';

interface AwardeeRowProps {
  awardee: Awardee;
  index: number;
}

export const AwardeeRow = (props: AwardeeRowProps) => {
  const { awardee, index } = props;
  const { bidAwardeeList, setBidAwardeeList } = useBidAwardee();

  const fieldName = `amount-${awardee.awardedToMemberId}`;

  const methods = useForm({
    defaultValues: {
      [fieldName]: bidAwardeeList[index].amount,
    },
  });

  const { getValues } = methods;

  const setSelectedBidAwardee = useSetRecoilState(selectedBidAwardeeState);

  const updateAwardAmount = useCallback(
    (amount: number) => {
      Assert(!!bidAwardeeList, 'Expected: awardees to be loaded in SelectAwardees.tsx');
      const newAwardees = [...bidAwardeeList];
      newAwardees[index] = { ...newAwardees[index], amount };
      setBidAwardeeList(newAwardees);
    },
    [bidAwardeeList, index, setBidAwardeeList],
  );

  function onUpdate() {
    const dollarInput = getValues(fieldName);
    updateAwardAmount(dollarInput === undefined ? 0 : dollarInput);
  }

  function deleteAwardee(memberId: number) {
    Assert(!!bidAwardeeList, 'Expected: awardees to be loaded in SelectAwardees.tsx');
    setBidAwardeeList(
      [...bidAwardeeList].filter(awardee => awardee.awardedToMemberId !== memberId),
    );
    // Reset the selectedBidAwardee in case the deleted record was selected.
    // The `useEffect` handler should catch and reset this.
    setSelectedBidAwardee(null);
  }

  return (
    <tr key={awardee.awardedToMemberId}>
      <td>{awardee.supplierName}</td>
      <td>
        <FormProvider {...methods}>
          <DSDollarField
            autoFocus={index === bidAwardeeList.length - 1}
            name={fieldName}
            onUpdate={onUpdate}
            allowNA
            optional
          />
        </FormProvider>
      </td>
      <td>
        {/** TODO: Replace this with delete icon. */}
        <span
          className='mdi mdi-delete-forever-outline mdi-24px staticLink'
          title='Delete'
          onClick={() => deleteAwardee(awardee.awardedToMemberId)}
        />
      </td>
    </tr>
  );
};
