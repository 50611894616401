import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useEffect } from 'react';

import { getProfile, updateSubscriptionAutoRenewal } from '../../store/services';
import { ProfileInfoState, profileInfoState } from '../../store/recoil/accountInfoState';
import { track } from '../../utils/telemetry';

/**
 * @description wraps profileInfoState & related functionality
 * @example const { profileInfo } = useAccountInfo();
 */
export function useProfileInfo(): {
  profileInfo: ProfileInfoState;
  refreshProfileInfo: () => Promise<void>;
  updateAutoRenewalSubscription: (state: boolean) => Promise<void>;
} {
  const profileInfo = useRecoilValue(profileInfoState);

  /**
   * @description pulls the profile information from the service layer & refreshes state
   * @returns void
   * @example refreshProfileInfo();
   */
  const refreshProfileInfo = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const profInfo = await getProfile();

          if (profInfo) {
            set(profileInfoState, profInfo);
          }
        } catch (error: any) {
          track('refreshProfileInfo -> getProfile() ERROR:', {
            error,
            method: 'GET',
            errorMessage: error.message,
          });

          // eslint-disable-next-line no-console
          console.error(`profileInfoAtom -> getProfile() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.

          throw new Error(error.message);
        }
      },
    [],
  );

  /**
   * @description updates the accounts `Autorenewal` status with the provided value
   * @param state - boolean - the state (true -> on)/(false -> off) with which to update autorenewal
   * @returns Promise<void>
   * @example updateAutoRenewalSubscription({state: true});
   */
  const updateAutoRenewalSubscription = useRecoilCallback(
    ({ set }) =>
      async (state: boolean) => {
        try {
          const result = updateSubscriptionAutoRenewal({ state });
          set(profileInfoState, {
            ...profileInfo,
            card: {
              ...profileInfo.card,
              isAutoRenewalOn: state,
            },
          });
          await result;
        } catch (error: any) {
          track('updateAutoRenewalSubscription -> updateSubscriptionAutoRenewal() ERROR:', {
            error,
            method: 'POST',
            errorMessage: error.message,
          });

          // eslint-disable-next-line no-console
          console.error(`profileInfoAtom -> updateSubscriptionAutoRenewal() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.

          throw new Error(error.message);
        }
      },
    [],
  );

  return {
    profileInfo,
    refreshProfileInfo,
    updateAutoRenewalSubscription,
  };
}

/**
 * @description re-loads the profile state on component's first render
 * @returns void
 * @example useRefreshProfileInfo();
 */
export function useRefreshProfileInfo(): void {
  const { refreshProfileInfo } = useProfileInfo();

  useEffect(() => {
    refreshProfileInfo();
  }, [refreshProfileInfo]);
}
