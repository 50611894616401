import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import styled from 'styled-components';

import { DSButton } from '@demandstar/components/button';

import { AwardBidLabels, commonLabels, documentLabels } from '../../../../shared/constants';
import { bidAwardeeListState, selectedBidAwardeeState } from '../../../../store/recoil/award-bid';
import {
  BoldText,
  DocumentUploadPanelWrapper,
  PanelLabel,
  PanelRowRight,
  PanelText,
  SpanSpacerLeft,
  SpanSpacerRight,
} from '../../../../shared/styles';
import { AwardWizardId } from '../../../../store/recoil/award-bid';
import { BidAwardeeSelect } from '../../../common/bid/BidAwardeeSelect';
import { ConfirmModal } from '../../../common/modals';
import { documentUploadFormChangedState } from '../../../../store/recoil/documentState';
import { getLoadableContents } from '../../../../utils/recoil';
import { getVendorDocumentUploadMessage } from './utils';
import { useAwardBidWizard } from '../../../../shared/hooks';
import { useRequiredDocumentTypes } from '../../../../shared/hooks/award-bid/useRequiredDocumentTypes';
import { VendorDocumentsTable } from './VendorDocumentsTable';
import { VendorDocumentUploadForm } from './VendorDocumentUploadForm';
import { WizardButtons } from '../../../customcontrols/wizard/WizardButtons';

// TODO: standardize form layout  wrapper into a shared styles location
const VendorDocFormWrapper = styled.div`
  padding: 1rem 0 0.5rem 0;
`;

export function AddVendorDocs() {
  /// Hooks
  const { refreshRequiredDocTypes, setRequiredDocTypes } = useRequiredDocumentTypes();
  const { saveAwardBidWizard, returnToBidSummary } = useAwardBidWizard();

  /// Application state
  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const awardeeList = getLoadableContents(useRecoilValueLoadable(bidAwardeeListState)) || [];
  const [selectedBidAwardee, setSelectedBidAwardee] = useRecoilState(selectedBidAwardeeState);

  // Get/Set dirty upload status
  const documentUploadFormStarted = useRecoilValue(documentUploadFormChangedState);

  /// Local state
  const [showUploadForm, toggleUploadForm] = useState(false);
  /** Confirm navigation when doc upload form has changes.
   *  TODO: wrap this into the useWizard hook */
  const [showConfirmNavigationModal, setShowConfirmNavigationModal] = useState(false);
  const [navToWizardPage, setNavToWizardPage] = useState<AwardWizardId>();

  const continueNavigation = useCallback(() => {
    hideNavigationModal();
    saveAwardBidWizard(navToWizardPage);
  }, [navToWizardPage, saveAwardBidWizard]);

  const confirmNavigation = useCallback(
    (wizardId: AwardWizardId) => () => {
      setNavToWizardPage(wizardId);
      if (documentUploadFormStarted) {
        setShowConfirmNavigationModal(true);
      } else {
        hideNavigationModal();
        saveAwardBidWizard(wizardId);
      }
    },
    [documentUploadFormStarted, saveAwardBidWizard],
  );

  const vendorSpecificDocPanelTitle = `${AwardBidLabels.awardDocsSpecificVendorTitlePrefix} ${
    selectedBidAwardee?.supplierName || 'the Selected Awardee'
  } `;

  const toggleFormLabel = showUploadForm
    ? documentLabels.cancelUpload
    : documentLabels.addMoreDocuments;

  // Refresh requiredDocType Recoil state on mount, reset when unmounted.
  // TODO: wrap this function into the custom hook.
  useEffect(() => {
    refreshRequiredDocTypes();
    return () => {
      setRequiredDocTypes([]);
    };
  }, [refreshRequiredDocTypes, setRequiredDocTypes]);

  useEffect(() => {
    if (!selectedBidAwardee) {
      // console.log('AddVendorDocs -onMount: No selectedBidAwardee');
      setSelectedBidAwardee(awardeeList[0]);
    }
  }, [awardeeList, selectedBidAwardee, setSelectedBidAwardee]);

  function getVendorSpecificDocPanelDescription() {
    return (
      <>
        <SpanSpacerRight>{'Only you and'}</SpanSpacerRight>
        <BoldText>{selectedBidAwardee?.supplierName}</BoldText>
        <SpanSpacerLeft>{'will be able to see documents in this list.'}</SpanSpacerLeft>
      </>
    );
  }

  function handleUploadFormToggle() {
    toggleUploadForm(!showUploadForm);
  }

  function hideNavigationModal() {
    setShowConfirmNavigationModal(false);
  }

  return (
    <>
      <BidAwardeeSelect />

      {/* Vendor-Specific Award Docs */}
      {selectedBidAwardee && (
        <>
          <PanelLabel>{vendorSpecificDocPanelTitle}</PanelLabel>
          <PanelText>{getVendorSpecificDocPanelDescription()}</PanelText>
          <VendorDocumentsTable />
          <DocumentUploadPanelWrapper visible={showUploadForm}>
            <PanelLabel>{documentLabels.uploadTitle}</PanelLabel>

            <span>{getVendorDocumentUploadMessage(selectedBidAwardee?.supplierName)}</span>

            <VendorDocFormWrapper>
              <VendorDocumentUploadForm />
            </VendorDocFormWrapper>
          </DocumentUploadPanelWrapper>
          <PanelRowRight paddingHorizontal={'0'} paddingTop={'1rem'} paddingBottom={'1.5rem'}>
            <DSButton buttonType='secondary' onClick={handleUploadFormToggle}>
              {toggleFormLabel}
            </DSButton>
          </PanelRowRight>
        </>
      )}

      <WizardButtons
        onPrevious={confirmNavigation(AwardWizardId.SelectAwardees)}
        onNext={confirmNavigation(AwardWizardId.ListRequiredDocs)}
        save={returnToBidSummary}
      ></WizardButtons>

      {/* Navigation confirmation modal
       * TODO: move conditional navigation into a hook for wider use
       */}
      <ConfirmModal
        approveAction={continueNavigation}
        closeModal={hideNavigationModal}
        confirmMessage={documentLabels.confirmNavigationUnfinishedUpload}
        isOpen={showConfirmNavigationModal}
        title={`${commonLabels.confirmNavigation} `}
      />
    </>
  );
}
