/* TOREFACTOR
  Exceeds 500 lines.
*/

import moment from 'moment-timezone';
import React, { memo, useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { History } from 'history';
import { defaultFilter } from '../../../store/reducers/bids';
import { dynamicSort } from '../../../store/sagas/utils';
import { authTypes } from '../../../types/auth';
import {
  bidsStateTypes,
  filtersParamType,
  getBidsTypes,
  onChangeParamTypes,
} from '../../../types/bids';
import { nargsfnTypes } from '../../../types/functions';
import { anyParamType } from '../../../types/paymentdetails';
import { sharedTypes } from '../../../types/shared';
import { memberTypes, radius, TourId, usaDateFormat } from '../../../utils/constants';
import { decodeParams, encodeFilters, scrollToTop, toastFn } from '../../../utils/helpers';
import { Autocomplete, Buttons, ComboBox, DeprecatedInput, SelectBox } from '../../customcontrols';
import CheckBox from '../../customcontrols/BasicCheckbox';
import DateRangePickerComponent from '../../customcontrols/daterangepicker';
import { bidSearchConfig, bidSearchConfig2 } from '../../../utils/tourconfig';
import { resetBasicTouractionTypes } from '../../../types/tours';
import { useForceUpdate } from '../../../utils/helperHooks';
import { ToursState } from '../../../store/reducers/tours';

const bidstoastID = 'dcc2e022-1304-46f0-9a21-a6b3f4e631ab';

interface PropsTypes {
  bidListFilterChange: anyParamType;
  setBidDetails: nargsfnTypes;
  getBids: getBidsTypes;
  bids: bidsStateTypes;
  shared: sharedTypes;
  auth: authTypes;
  setToursDetails: nargsfnTypes;
  tours: ToursState;
  resetBasicTour: resetBasicTouractionTypes;
  history: History;
  location: Location;
  //trackAmplitudeUserActions: nargsfnTypes;
}

const Filters = (props: PropsTypes) => {
  const forceUpdate = useForceUpdate();
  const [isOpen, setIsOpen] = useState(false);
  const [dateshow, setDateshow] = useState(true);
  const search: string = props?.location?.search || '';
  const [update, setUpdate] = useState(true);
  const {
    bidListFilterChange,
    setBidDetails,
    getBids,
    bids,
    shared,
    auth,
    tours,
    setToursDetails,
    resetBasicTour,
    /* trackAmplitudeUserActions, */
  } = props;
  const { filters, results = [], isBidsLoaded = false } = bids || {};
  const { mt = '' } = auth || {};
  const { tourStart, activateTour, tourList = [] } = tours || {};
  const { fiscalYear: fiscalYearList, bidStatusList = [], invaliddateerror } = shared || {};
  const {
    bidStatus,
    myBids = false,
    bidsNotified = false,
    orderedBids = false,
    watchedBids = false,
    commodityMatches = false,
    ebiddingAvailable = false,
  } = filters || {};

  const getBindData = (filters: filtersParamType, shared: sharedTypes) => {
    const {
      location: filterLocation,
      locationType,
      locationText,
      radius: filterRadius,
      industry,
      industryText,
      agencyMemberId,
      agencyText,
    } = filters || {};

    const { agencies = [], commodities = [], location = [] } = shared || {};

    const agenciesMap = agencies
      .map((agency, index) => ({
        id: index,
        title: agency.agencyName,
        value: agency.agencyId,
        selected: agencyMemberId === agency.agencyId,
        key: 'agencyMemberId',
      }))
      .filter(f => f.title.toLowerCase().indexOf(agencyText.toLowerCase().trim()) > -1)
      .sort(dynamicSort('title'))
      .sort(dynamicSort('selected'));

    const commoditiesMap = commodities
      .map((commodity, index) => ({
        id: index,
        title: `${commodity.commodityDescription} [${commodity.formattedCode}]`,
        value: commodity.commodityId,
        selected: industry.indexOf(commodity.commodityId) > -1,
        key: 'industry',
      }))
      .filter(f => f.title.toLowerCase().indexOf(industryText.toLowerCase().trim()) > -1)
      .sort(dynamicSort('title'))
      .sort(dynamicSort('selected'))
      .slice(0, 100);

    let locationMap = [];

    if (locationText) {
      locationMap = location
        .map((loc, index) => ({
          id: index,
          title: loc.county || loc.state,
          value: loc.countyId || loc.stateId,
          selected:
            filterLocation ===
            (locationType === 'county' ? loc.countyId : loc.countyId ? -1 : loc.stateId),
          type: loc.countyId ? 'county' : 'state',
          key: 'location',
        }))
        .filter(f => f.title.toLowerCase().indexOf(locationText.toLowerCase().trim()) > -1)
        .sort(dynamicSort('selected'))
        .slice(0, 100);
    } else {
      locationMap = location
        .map((loc, index) => ({
          id: index,
          title: loc.county || loc.state,
          value: loc.countyId || loc.stateId,
          selected:
            filterLocation ===
            (locationType === 'county' ? loc.countyId : loc.countyId ? -1 : loc.stateId),
          type: loc.countyId ? 'county' : 'state',
          key: 'location',
        }))
        .filter(
          f =>
            f.title.toLowerCase().indexOf(locationText.toLowerCase().trim()) > -1 &&
            (f.type === 'state' || f.selected === true),
        )
        .sort(dynamicSort('selected'))
        .slice(0, 100);
    }

    const radiusMap = radius.map(rad => ({
      ...rad,
      selected: filterRadius === rad.value,
    }));

    return {
      agencies: agenciesMap,
      commodities: commoditiesMap,
      location: locationMap,
      sRadius: radiusMap,
    };
  };

  const {
    bidIdentifier,
    bidName,
    startDueDate,
    endDueDate,
    locationText,
    industryText,
    locationType,
    agencyText,
    fiscalYear,
    radius: fRadius,
  } = filters || {};

  const { agencies, commodities, location, sRadius } = getBindData(filters, shared);

  const onInputChange = (key: string, value: any) => {
    bidListFilterChange({ [key]: value });
  };

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange(e.target.name, e.target.checked);
  };

  const onComboChange = (loc: onChangeParamTypes) => {
    const filterUpdate = {
      [loc.key]: loc.selected ? '' : loc.value,
      locationType: loc.selected ? '' : loc.type,
      locationText: '',
      radius: loc.type === 'county' ? '5' : '',
    };
    bidListFilterChange({ ...filterUpdate });
  };

  const onIndustryComboChange = (loc: onChangeParamTypes) => {
    const industry = [...filters.industry];
    let newIndustry = [];
    if (industry.indexOf(loc.value) > -1) {
      newIndustry = [...industry.filter(f => f !== loc.value)];
    } else {
      newIndustry = [...industry, loc.value];
    }
    bidListFilterChange({ [loc.key]: newIndustry });
  };

  const onIndustryClear = () => {
    bidListFilterChange({ industry: [], industryText: '' });
  };

  const onSelectChange = (key: string, value: string | number) => {
    bidListFilterChange({ [key]: value });
  };

  const onAgencyComboChange = (agncy: onChangeParamTypes) => {
    bidListFilterChange({ [agncy.key]: agncy.selected ? '' : agncy.value, agencyText: '' });
  };

  const searchFns = () => {
    setDateshow(false);
    //getBids({ preserveFilters: true });
    setTimeout(() => {
      setDateshow(true);
    }, 10);
    //trackAmplitudeUserActions({title:'Bid search', desc:'bids - search button clicked', data:filters});
  };

  useEffect(() => {
    if (!search && results.length === 0) {
      getBids({ initialRequest: true });
    }
  }, [getBids, search, results.length]);

  useEffect(() => {
    if (search) {
      const filters = decodeParams(search, bidStatusList);
      bidListFilterChange(filters);
      console.log('filters', filters);
      setBidDetails({ results: [], bidscurrentPage: 1, selectedBidIds: '', loadedBids: [] });
      setIsOpen(true);
      getBids({
        ...filters,
        preserveFilters: false,
      });
    }
  }, [search, getBids, bidListFilterChange, bidStatusList, setBidDetails]);

  const onSearchClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (activateTour && tourStart) {
      resetBasicTour();
    }
    if (invaliddateerror === '') {
      if (startDueDate && endDueDate) {
        if (
          moment(moment(startDueDate).format(usaDateFormat)).isSameOrBefore(
            moment(endDueDate).format(usaDateFormat),
          )
        ) {
          searchFns();
        } else {
          toastFn(
            'error',
            'End due date must be greater than or equal to start due date',
            bidstoastID,
          );
        }
      } else {
        searchFns();
      }
    }

    const newLocation = {
      ...props.location,
      search: encodeFilters(filters),
    };
    props.history.push(newLocation);
  };

  const onClearFilterClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setDateshow(false);
    setBidDetails({
      results: [],
      filters: defaultFilter,
      bidscurrentPage: 1,
      selectedBidIds: '',
      loadedBids: [],
    });
    bidListFilterChange({ showBidReset: 1 });
    //getBids({ initialRequest: true });
    setTimeout(() => {
      setDateshow(true);
    }, 10);
    const newLocation = {
      ...props.location,
      search: '',
    };
    props.history.push(newLocation);
  };

  const searchCollapse = () => {
    setIsOpen(false);
    forceUpdate();
    // Tour: functions
    if (activateTour && tourStart) {
      const Element = document.getElementsByClassName('tourMask')[0] as any;
      const Element2 = document.getElementsByClassName('tourInfoBox')[0] as any;
      Element.style.visibility = 'hidden';
      Element2.style.visibility = 'hidden';
      setTimeout(() => {
        setToursDetails({ currentStep: 8 });
        Element.style.visibility = 'visible';
        Element2.style.visibility = 'visible';
      }, 700);
    } else {
      scrollToTop();
    }
  };

  const searchExpand = () => {
    setIsOpen(true);
    // Tour: functions
    if (activateTour && tourStart) {
      setTimeout(() => {
        setToursDetails({ currentStep: 5 });
      }, 700);
    }
  };

  // Tour: Configs
  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const internaltourConfig = [
    { step: 0, prevButton: <></>, nextButton: <button>Take the tour</button> },
    { step: 1, prevButton: <></>, nextButton: <button>Next</button> },
    { step: 2, prevButton: <></>, nextButton: <button>Next</button> },
    { step: 3, prevButton: <></>, nextButton: <button>Next</button> },
    { step: 4, prevButton: <></>, nextButton: <></> },
    { step: 5, prevButton: <></>, nextButton: <button>Next</button> },
    { step: 6, prevButton: <></>, nextButton: <button>Next</button> },
    { step: 7, prevButton: <></>, nextButton: <></> },
    { step: 8, prevButton: <></>, nextButton: <button>All Done!</button> },
  ];
  let bidSearchTour = false;
  if (
    activateTour &&
    !bidSearchTour &&
    tourList.length > 0 &&
    !tourStart &&
    isBidsLoaded === true
  ) {
    const item: any = tourList.find((item: { tourId: number }) => item.tourId === TourId.bidSearch);
    if (item?.tourId) {
      bidSearchTour = true;
    }
  }

  // Tour: useEffect functions
  useEffect(() => {
    if (bidSearchTour) {
      const newtourList =
        tourList.filter((item: { tourId: number }) => item.tourId !== TourId.bidSearch) || [];
      setToursDetails({
        tourStart: true,
        tourConfig: mt === memberTypes.agencyBuyer ? bidSearchConfig : bidSearchConfig2,
        lastStepNextButton: <button>All done!</button>,
        tourId: TourId.bidSearch,
        tourList: newtourList.length > 0 ? newtourList : [],
        currentStep: 0,
        internalConfig: true,
        internaltourConfig,
        nextButton: <button>Take the tour</button>,
        prevButton: <></>,
        showCloseButton: true,
        showButtons: true,
      });
    }
  }, [bidSearchTour, internaltourConfig, setToursDetails, tourList, mt]);

  // Tour: useEffect functions
  useEffect(() => {
    return () => {
      setToursDetails({
        tourStart: false,
        tourConfig: [],
        tourId: 0,
        currentStep: 0,
        internalConfig: false,
        internaltourConfig: [],
      });
    };
  }, [setToursDetails]);

  return (
    <div className='col-12 col-lg-3 col-xl-3 mb-3 mb-lg-0 mb-xl-0'>
      <div className='sideBarSpacing'>
        <form id='filterBar'>
          <h3 className='lineHR mb-3'>Search</h3>
          <div className='rt_BS_stpe1'>
            <div className='rt_BS_stpe2'>
              <h6 className='mb-2'>Filter By</h6>
              {mt === memberTypes.agencyBuyer ? (
                <CheckBox
                  parentClass='mb-3'
                  title='Only Show My Bids'
                  name='myBids'
                  checked={myBids}
                  value={myBids}
                  handleChange={handleChecked}
                />
              ) : mt !== memberTypes.basicSupplier ? (
                <CheckBox
                  parentClass='mb-3'
                  title='Bids Notifications'
                  name='bidsNotified'
                  checked={bidsNotified}
                  value={bidsNotified}
                  handleChange={handleChecked}
                />
              ) : null}
              {mt !== memberTypes.agencyBuyer ? (
                <>
                  <CheckBox
                    parentClass='mb-3'
                    title='Ordered Bids'
                    name='orderedBids'
                    checked={orderedBids}
                    value={orderedBids}
                    handleChange={handleChecked}
                  />
                  <CheckBox
                    parentClass='mb-3'
                    title='Watched Bids'
                    name='watchedBids'
                    checked={watchedBids}
                    value={watchedBids}
                    handleChange={handleChecked}
                  />
                </>
              ) : null}
              {mt === memberTypes.subscribingSupplier ? (
                <CheckBox
                  parentClass='mb-3'
                  title='Commodity Code Matches'
                  name='commodityMatches'
                  checked={commodityMatches}
                  value={commodityMatches}
                  handleChange={handleChecked}
                />
              ) : null}
              <CheckBox
                parentClass='mb-3'
                title='eBidding Available'
                name='ebiddingAvailable'
                checked={ebiddingAvailable}
                value={ebiddingAvailable}
                handleChange={handleChecked}
              />
            </div>
            <div className='rt_BS_stpe3'>
              <ComboBox
                iconType='search'
                label='Government Agency'
                placeholder='Search for agencies'
                options={agencies}
                name='agencyText'
                value={agencyText}
                onChange={onInputChange}
                onComboChange={onAgencyComboChange}
                isAutoPopulate={true}
                parentClass='mt-5 searchTxtResp hideErr'
              />
            </div>
            <div className='rt_BS_stpe3BS'>
              <SelectBox
                reactselect={true}
                label='Bid Status'
                name='bidStatus'
                options={bidStatusList}
                handleSelect={onInputChange}
                value={bidStatus}
                clear={true}
                parentClass='hideErr'
              />
            </div>
            <div className='rt_BS_stpe4 '>
              <h3 className='lineHR clear-both'>Advanced Search</h3>
              <div className='rt_BS_stpe5'>
                <Autocomplete
                  iconType='search'
                  label='Bid Name'
                  placeholder='Bid Name'
                  name='bidName'
                  value={bidName}
                  handleChange={onInputChange}
                />
                <ComboBox
                  iconType='search'
                  label='Location'
                  placeholder='Search for state or county'
                  options={location}
                  name='locationText'
                  value={locationText}
                  onChange={onInputChange}
                  onComboChange={onComboChange}
                  isAutoPopulate={true}
                />
                {!isOpen ? (
                  <div className='position-relative clearfix' style={{ height: '30px' }}>
                    <div
                      className='searchToggle w-100 clearfix cursorPointer'
                      onClick={searchExpand}
                    >
                      <span />
                      <h5 className='mb-0'>
                        <label
                        // onClick={searchExpand}
                        >
                          See More Options
                        </label>
                      </h5>
                    </div>
                  </div>
                ) : null}
                <Collapse isOpen={isOpen}>
                  {locationType === 'county' ? (
                    <SelectBox
                      label='Radius'
                      name='radius'
                      value={fRadius}
                      options={sRadius}
                      parentClass='w-50'
                      onChange={onSelectChange}
                    />
                  ) : null}
                  <DeprecatedInput
                    label='Bid Identifier'
                    name='bidIdentifier'
                    type='text'
                    classNames='class'
                    value={bidIdentifier}
                    placeholder='Bid Identifier'
                    handleChange={onInputChange}
                    optional={'Enter Bid ID, e.g. "RFP-5454-0-2000/CWD"'}
                    parentClass='mb-4'
                  />
                  <ComboBox
                    iconType='filter'
                    label='Commodity Code / Industry'
                    placeholder='Filter by Industry'
                    options={commodities}
                    name='industryText'
                    value={industryText}
                    onChange={onInputChange}
                    onComboChange={onIndustryComboChange}
                    isMultiSelect={true}
                    isAutoPopulate={true}
                    titleHelper='Industries'
                    onIndustryClear={onIndustryClear}
                    clearIcon={true}
                    parentClass='searchTxtResp'
                  />
                  <SelectBox
                    reactselect={true}
                    label='Fiscal Year'
                    parentClass='w-75'
                    name='fiscalYear'
                    options={fiscalYearList}
                    handleSelect={onInputChange}
                    value={fiscalYear}
                    clear={true}
                  />
                  {dateshow ? (
                    <div className='rt_BS_stpe6'>
                      <DateRangePickerComponent
                        label='Due Date Range'
                        startDatename='startDueDate'
                        endDatename='endDueDate'
                        handleChange={onInputChange}
                        isOutsideRange={true}
                        startDatevalue={startDueDate}
                        endDatevalue={endDueDate}
                        showClearDate={true}
                        optional={`(e.g. "${moment().format(usaDateFormat)}" - "${moment(
                          moment().format(usaDateFormat),
                        )
                          .add(5, 'days')
                          .format(usaDateFormat)}")`}
                        parentClass='clearfix mb-0'
                      />
                    </div>
                  ) : null}
                </Collapse>
              </div>
              {isOpen ? (
                <div
                  className='position-relative clearfix rt_BS_stpe7 mt-3'
                  style={{ height: '30px' }}
                >
                  <div className='searchToggle searchExp w-100' id='fewerBtn'>
                    <h5>
                      <label onClick={searchCollapse}>Fewer Options</label>
                    </h5>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='rt_BS_stpe8 mt-3'>
            <Buttons
              classNames='bttn-primary mt-1 w-100'
              text='Search'
              title='Search'
              onClick={onSearchClick}
              disable={invaliddateerror}
            />
          </div>
          <Buttons
            classNames='bttn-secondary mt-4 w-100'
            text='Reset Filter'
            title='Reset Filter'
            onClick={onClearFilterClick}
            type='button'
          />
        </form>
      </div>
    </div>
  );
};

export default memo(Filters);
