import { useRecoilCallback, useRecoilState } from 'recoil';

import { axiosPostAuthenticated } from '../../../store/services/api';
import { constants } from '../../../utils/settings';
import { nowISO } from '../../../utils/helpers';
import { Paths } from '../../../utils/constants';
import { requiredDocTypesState } from '../../../store/recoil/award-bid';
import { RequiredDocument } from '../../../store/services/required-document';
import { RequiredDocumentGetAPIResponse } from '../../../types/required-document';
import { selectedBidIdState } from '../../../store/recoil/bidState';
import { useRequiredDocuments } from './';

export type RequiredDocType = {
  memberAwardRequiredDocResponseItemId: number;
  title: string;
  description: string;
  sortOrder: number;
  isDelete: boolean;
};

export type NewRequiredDocType = Omit<RequiredDocType, 'memberAwardRequiredDocResponseItemId'>;

/** A hook which provides the ability to reference and manipulate RequiredDocTypes. */
export function useRequiredDocumentTypes() {
  const { updateRequiredDocs } = useRequiredDocuments();

  const [requiredDocTypes, setRequiredDocTypes] = useRecoilState(requiredDocTypesState);

  /** Gets and refreshes existing doc types */
  const refreshRequiredDocTypes = useRecoilCallback(
    ({ set }) =>
      async () => {
        const requiredDocTypes = await axiosPostAuthenticated<RequiredDocType[]>({
          baseURL: constants.api.url,
          data: {},
          url: Paths.Award.RequiredDocTypes.get,
        });

        set(requiredDocTypesState, requiredDocTypes);
        return requiredDocTypes;
      },
    [],
  );

  /**
   * Adds one (or more) document types, as well as updates Required Documents with the new doctype.
   */
  const addRequiredDocTypes = useRecoilCallback(
    ({ snapshot }) =>
      async (documents: NewRequiredDocType[]) => {
        const numDocs = documents.length;
        const bidId = await snapshot.getPromise(selectedBidIdState);
        await axiosPostAuthenticated<RequiredDocumentGetAPIResponse[]>({
          baseURL: constants.api.url,
          data: { bidId, documents },
          url: Paths.Award.RequiredDocTypes.update,
        });
        const docTypes = await refreshRequiredDocTypes();
        let tempDocTypes = [...docTypes];
        tempDocTypes = tempDocTypes.sort((a, b) => {
          return a.memberAwardRequiredDocResponseItemId - b.memberAwardRequiredDocResponseItemId;
        });
        tempDocTypes = tempDocTypes.slice(0 - numDocs);
        const newDocuments = tempDocTypes.map(docType => {
          return {
            docName: docType.title,
            docTitle: docType.title,
            docDescription: docType.description,
            dueDate: nowISO(),
            isDelete: false,
          } as RequiredDocument;
        });
        updateRequiredDocs(newDocuments);
      },
    [],
  );

  /**
   * Updates database with a passed in value.
   * @param {RequiredDocType[]} documents - array of RequiredDocTypes
   * @returns deleted docs array, so that we can show a record of deleted docs.
   */
  const saveRequiredDocTypes = useRecoilCallback(
    ({ snapshot }) =>
      async (documents: RequiredDocType[]) => {
        const bidId = await snapshot.getPromise(selectedBidIdState);
        const deletedDocs = documents.filter(doc => {
          return doc.isDelete;
        });
        // const documents = await snapshot.getPromise(requiredDocTypesState);
        await axiosPostAuthenticated<RequiredDocumentGetAPIResponse[]>({
          baseURL: constants.api.url,
          data: { bidId, documents },
          url: Paths.Award.RequiredDocTypes.update,
        });
        refreshRequiredDocTypes();
        return deletedDocs;
      },
    [],
  );

  return {
    addRequiredDocTypes,
    refreshRequiredDocTypes,
    requiredDocTypes,
    saveRequiredDocTypes,
    setRequiredDocTypes,
  };
}
