import { connect, ConnectedProps } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import * as actionCreators from '../../../store/actions';

import {
  appstatetokenName,
  memberTypes,
  notificationExpandCount,
  notificationInitialCount,
} from '../../../utils/constants';

import DashboardLeftPanel from '../../shared/dashboard/dashboardleftpanel';
import Notifications from './notifications';
import RightsideBottom from './rightsidebottom';
import RightsideTop from './rightsidetop';

import { authTypes } from '../../../types/auth';
import { commonLabels } from '../../../shared/constants';
import { constants } from '../../../utils/settings';
import { dismisstoastAllFn } from '../../../utils/helpers';
import { getBidsTypes } from '../../../types/bids';
import { loadingMsg } from '../../../utils/texts';
import { memberinfoTypes } from '../../../types/dashboard';
import { nargsfnTypes } from '../../../types/functions';
import { NoResult } from '../../customcontrols';
import { ProductType } from 'src/types/products';
import { RequiredActionsDashboardPanel } from '../../../features/bids/management/RequiredActionsDashboardPanel';
import { sharedTypes } from '../../../types/shared';

interface SupplierDashboardProps extends PropsFromRedux {
  accountinfo: any;
  auth: authTypes;
  getBids: getBidsTypes;
  getCanUpgrade: any;
  bidssummary: { canSupplierUpgradeAPICALL: boolean };
  getDashboardData: any;
  getdashboardNotifications: any;
  getQuotesList: any;
  getWatchedBidIds: any;
  history: any;
  memberInfo: any;
  memberinfo: memberinfoTypes;
  setBidDetails: any;
  setBidSummaryDetails: any;
  setBreadcrumb: any;
  setQuotesDetails: any;
  setSharedDetails: nargsfnTypes;
  shared: sharedTypes;
  submitDisablenotification: any;
  getAccountInfo: any;
  getDashboardNetwork: any;
  getRelatedBids: any;
  getSiblingProducts: any;
  setAccountInfoDetails: any;
  trackAmplitudeUserActions: any;
}

interface commodityTypes {
  formattedCode: string | number;
  fullCode: string | number;
  commodityDescription: string;
}

interface Product {
  productType: string;
  productId: number;
}

function selectPid(products: Product[] = []): number | null {
  const product = products.find(product => product.productType === ProductType.FreeAgency);
  if (product) {
    return product.productId;
  } else {
    return null;
  }
}

function renderRequiredActionsListPanel() {
  return <RequiredActionsDashboardPanel listLength={5} />;
}

function SupplierDashboardComponent(props: SupplierDashboardProps) {
  const {
    getDashboardNetwork,
    getSiblingProducts,
    getRelatedBids,
    memberInfo,
    memberinfo,
    getDashboardData,
    setBreadcrumb,
    getdashboardNotifications,
    submitDisablenotification,
    setBidSummaryDetails,
    shared,
    auth,
    history,
    setSharedDetails,
    getCanUpgrade,
    getWatchedBidIds,
    bidssummary,
    accountinfo,
    trackAmplitudeUserActions,
  } = props;
  const { canSupplierUpgradeAPICALL = true } = bidssummary;
  const { mt: memberType, opi = '' } = auth;
  const profileInfo = accountinfo || {};
  const {
    dashboardnotifications = [],
    dashboardnotifications_internalLoader,
    dashboardCommodities_internalLoader,
    dashboardCommodities: commodityCodes = [],
    userCommoditiesCount = 0,
  } = shared;
  const { mn = '', mi = '', mtn: memberTypeName } = memberinfo;
  const products: Product[] = accountinfo?.accountinfo?.products;
  const pid: number | null = selectPid(products);

  useEffect(() => {
    setBreadcrumb({
      page: 'Dashboard',
      altname: 'Dashboard',
      title: 'Dashboard',
      name: 'Dashboard',
      menuactive: 'dashboard',
      component: '',
    });
  }, [setBreadcrumb]);

  useEffect(() => {
    getDashboardData({});
  }, [getDashboardData]);

  useEffect(() => {
    if (!pid || memberTypeName !== 'Free Agency') return;
    getSiblingProducts({ productId: pid });
  }, [getSiblingProducts, pid, memberTypeName]);

  useEffect(() => {
    if (memberTypeName !== 'Free Agency') return;
    getRelatedBids();
  }, [getRelatedBids, memberTypeName]);

  useEffect(() => {
    getDashboardNetwork({});
  }, [getDashboardNetwork]);

  useEffect(() => {
    getWatchedBidIds();
  }, [getWatchedBidIds]);

  useEffect(() => {
    if (memberType !== memberTypes.basicSupplier) {
      getdashboardNotifications({});
    }
  }, [getdashboardNotifications, memberType]);

  useEffect(() => {
    const l_storage = localStorage.getItem(appstatetokenName) || '';
    if (l_storage) {
      const { auth: localauth } = (l_storage && JSON.parse(l_storage)) || {};
      if (localauth && localauth.opi) {
        if (mi && mi !== localauth.mi) {
          memberInfo({ isCurrentMember: true });
        }
      }
    }
  }, [memberInfo, mi, mn]);

  useEffect(() => {
    dismisstoastAllFn();
  }, []);

  const commoditieslist: commodityTypes[] | [] =
    commodityCodes.length > 0 ? commodityCodes.slice(0, 5) : [];

  const [notificationlistCount, setNotificationlistCount] = useState(notificationInitialCount);

  const CommoditieslistComponent = () => {
    return (
      <div className='commodityWrapper mt-3 clearfix p-4'>
        <h3 className='lineHR clearfix'>My Commodity Codes ({userCommoditiesCount})</h3>
        {commoditieslist.length > 0 ? (
          <>
            {commoditieslist.map((commodity: commodityTypes, id: number) => (
              <p key={id} className='mb-3'>
                <i className='staticTxt'>[{commodity.formattedCode || commodity.fullCode}]</i>
                {commodity.commodityDescription}
              </p>
            ))}
            {userCommoditiesCount > 5 ? (
              <div
                data-testid='view.more.commodity.codes'
                title={commonLabels.viewMore}
                className='float-right staticLink'
                onClick={() => history.push('/suppliers/account/commoditycodes')}
              >
                {`${commonLabels.viewMore}`} <i className='mdi mdi-launch' />
              </div>
            ) : null}
          </>
        ) : (
          <NoResult
            onClick={() => {
              if (!dashboardCommodities_internalLoader) {
                history.push('/suppliers/account/commoditycodes/edit');
                setSharedDetails({ pathfromdashboard: true });
              }
            }}
            message={
              dashboardCommodities_internalLoader
                ? loadingMsg
                : 'Select Commodity Codes to receive bid notifications.'
            }
            pClass='staticLink'
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (canSupplierUpgradeAPICALL) {
      getCanUpgrade();
      setBidSummaryDetails({ canSupplierUpgradeAPICALL: false });
    }
  }, [canSupplierUpgradeAPICALL, getCanUpgrade, setBidSummaryDetails]);

  /* const toggleAgencySelection = useCallback(() => {
    setAccountInfoDetails({ agencySelection: !agencySelection });
  }, [setAccountInfoDetails, agencySelection]); */

  return (
    <div className='container dashboardWrapper'>
      <div className='row justify-content-center'>
        <div className='col-12 col-lg-3 mb-3 mb-lg-0'>
          <div className='sideBarSpacing dashboardSidebar'>
            <DashboardLeftPanel {...props} />
          </div>
        </div>
        <div className='col-12 col-lg-9'>
          <div className='colWrapper'>
            <h2 className='arrowWrapper'>{mn}</h2>
            <div className='row'>
              {memberType === memberTypes.basicSupplier ? (
                <>
                  <div className='col-lg-12'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <RightsideTop {...props} />
                      </div>
                      <div className='col-12 col-lg-6 mt-4 mt-lg-0'>
                        <div className='notificationWrapper d-flex flex-column justify-content-center px-4'>
                          {renderRequiredActionsListPanel()}

                          <h3 className='text-center'>Upgrade Your Subscription</h3>
                          <ul>
                            <li>Free bid documents</li>
                            <li>Instant email notifications for future bid opportunities</li>
                            <li>Customized notifications tailored to your business</li>
                            <li>Free and Paid Subscriptions available</li>
                          </ul>
                          <Link
                            className='bttn-accent w-100 mb-2 btn-block text-center'
                            title='Upgrade Subscription'
                            rel='noopener noreferrer'
                            to='/suppliers/plans'
                            onClick={() => {
                              trackAmplitudeUserActions({
                                title: 'upgrade - click basic upgrade',
                                desc: 'Click button on basic user dashboard',
                                data: {
                                  Source: `${constants.networkUrl}/suppliers/dashboard`,
                                },
                              });
                            }}
                          >
                            Upgrade Subscription
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <RightsideBottom {...props} />
                  </div>
                </>
              ) : (
                <>
                  <div className='col-lg-6'>
                    <RightsideTop {...props} />
                    <RightsideBottom {...props} />
                  </div>

                  <div className='col-lg-6 mt-3 mt-lg-0'>
                    <div className='notificationWrapper clearfix text-left p-4'>
                      {renderRequiredActionsListPanel()}

                      <h3 className='lineHR'>Notifications ({dashboardnotifications.length})</h3>
                      {dashboardnotifications.length > 0 ? (
                        <>
                          {dashboardnotifications
                            .map((item: any, index: number) => {
                              return (
                                <Notifications
                                  history={history}
                                  key={index}
                                  item={item}
                                  submitDisablenotification={submitDisablenotification}
                                  setSharedDetails={setSharedDetails}
                                />
                              );
                            })
                            .slice(0, notificationlistCount)}
                          {notificationlistCount < dashboardnotifications.length ? (
                            <>
                              <span
                                className='float-right staticLink clearfix'
                                title='Show More'
                                onClick={() =>
                                  setNotificationlistCount(
                                    notificationlistCount + notificationExpandCount,
                                  )
                                }
                              >
                                Show More <i className='mdi mdi-chevron-double-down' />
                              </span>
                              <p className='clearfix' />
                            </>
                          ) : (
                            <>
                              {dashboardnotifications.length > 5 &&
                              notificationlistCount >= dashboardnotifications.length ? (
                                <>
                                  <span
                                    className='float-right staticLink clearfix'
                                    title='Collapse All'
                                    onClick={() =>
                                      setNotificationlistCount(notificationInitialCount)
                                    }
                                  >
                                    Collapse All <i className='mdi mdi-chevron-double-up' />
                                  </span>
                                  <p className='clearfix' />
                                </>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : (
                        <NoResult
                          message={
                            dashboardnotifications_internalLoader
                              ? loadingMsg
                              : 'No Notifications Available'
                          }
                        />
                      )}
                      {dashboardnotifications.length <= 2 ? <CommoditieslistComponent /> : null}
                    </div>
                  </div>
                </>
              )}
            </div>
            {memberType === memberTypes.basicSupplier ? null : (
              <div className='row'>
                <div className='col-12'>
                  {dashboardnotifications.length > 2 ? <CommoditieslistComponent /> : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <ModalPopUp
        size='lg'
        title={'Upgrade to free agency'}
        closeModal={toggleAgencySelection}
        isOpen={agencySelection === true}
        backdrop='static'
      >
        <AgencySelection
          {...props}
          userType={opi ? 'ops' : 'supplier'}
          toggleAgencySelection={toggleAgencySelection}
          products={[]}
          pageFor='dashboard'
        />
      </ModalPopUp> */}
    </div>
  );
}

const connector = connect(
  (state: any) => ({
    memberinfo: state.memberinfo,
    shared: state.shared,
    auth: state.auth,
    bidssummary: state.bidssummary,
    accountinfo: state.accountinfo,
    subscriptions: state.subscriptions,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const SupplierDashboard = connector(memo(SupplierDashboardComponent));
