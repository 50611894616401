import { FieldError } from 'react-hook-form';
import styled from 'styled-components';

import { AntDatePicker } from './AntDatePickerConfig';

import {
  BackgroundColor,
  BorderColor,
  FontFamily,
  ParagraphFontSize,
  TextColor,
} from './../../styles';

interface BaseDatePickerProps {
  error?: FieldError;
  inactive?: boolean;
}

/**
 * This is the base Input control, intended to be themed and extended
 * by specific implementations, e.g. TextInput, PasswordInput.
 */
export const BaseDatePicker = styled(AntDatePicker)<BaseDatePickerProps>`
  border: 0.0625rem solid ${({ error }) => (error ? BorderColor.Error : BorderColor.Base)};
  &.ant-picker-disabled {
    border: 0.0625rem solid ${({ error }) => (error ? BorderColor.Error : BorderColor.Base)};
  }
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  color: ${TextColor.Base};
  line-height: 2rem;
  height: 2.5rem;
  display: block;
  box-shadow: none;
  background: ${({ inactive }) => (inactive ? BackgroundColor.Inactive : BackgroundColor.Input)};
  &:hover {
    border: 0.0625rem solid ${({ error }) => (error ? BorderColor.Error : BorderColor.Base)};
  }
  input {
    font-size: ${ParagraphFontSize.Base};
    font-family: ${FontFamily.Base};
    color: ${({ inactive }) => (inactive ? TextColor.Inactive : TextColor.Base)};
  }
`;
