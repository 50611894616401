import { memo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { LayoutType } from '@demandstar/components/constants';

import { authTypes, Permission } from '../types/auth';
import { clearStorageFn, isExpiredToken } from '../utils/helpers';
import { Default, LoggedIn } from '../components/customcontrols';
import {
  loginPreviousUrlName,
  loginPreviousUrlSearch,
  memberTypes,
  supplierTypes,
} from '../utils/constants';
import { actionPayloadTypes } from '../types/actiontypedef';
import { ComponentRoutes } from './ComponentRoutes';
import Configurations from '../settings';
import MaintananceSplash from '../components/common/maintanancesplash';
import { PageNotFound } from '../components/common/pagenotfound';
import { ReduxAction } from '../store/actions/utils';

//TODO can either of these `any`'s be updated?
interface AuthenticatedRouteTypes {
  component: any;
  //could be exact?: boolean
  exact: any;
  isMigrated: boolean;
  layout: LayoutType;
  memberType: string;
  opsUser: boolean;
  path: string;
  resetAuth: (payload?: actionPayloadTypes) => ReduxAction;
  token: string;
}

const isLoggedInUser = (token: string | any[], isMigrated: boolean, opsUser: boolean) => {
  if (token && token.length > 0) {
    if (opsUser) {
      return true;
    }
    if (!isMigrated) {
      window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const AuthenticatedRoute = (authProps: AuthenticatedRouteTypes) => {
  const { component: Component, exact, token, isMigrated, opsUser, resetAuth, ...rest } = authProps;
  const [layout, setLayout] = useState(authProps.layout);

  !layout && setLayout(LayoutType.None);

  return (
    <Route
      exact
      {...rest}
      render={props =>
        isLoggedInUser(token, isMigrated, opsUser) ? (
          !isExpiredToken(token) ? (
            <LoggedIn layout={layout}>
              <Component {...props} />
            </LoggedIn>
          ) : (
            <>
              {resetAuth()}
              {sessionStorage.setItem(loginPreviousUrlName, props.history.location.pathname)}
              {sessionStorage.setItem(loginPreviousUrlSearch, props.history.location.search)}
              {clearStorageFn(true, false)}
            </>
          )
        ) : (
          <>
            {sessionStorage.setItem(loginPreviousUrlName, props.history.location.pathname)}
            {sessionStorage.setItem(loginPreviousUrlSearch, props.history.location.search)}
            {(window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL)}
          </>
        )
      }
    />
  );
};

const DefaultRoute = ({ component: Component, ...rest }: any) => (
  <Route
    exact
    {...rest}
    render={props => (
      <Default>
        <Component {...props} />
      </Default>
    )}
  />
);

interface propsTypes {
  auth: authTypes;
  resetAuth: any;
}

const Routes = (props: propsTypes) => {
  const { auth, resetAuth } = props;
  const { tk: token, mt: memberType, mgrtd: isMigrated, mi, prms = '' } = auth;
  const permissions = prms.replace(/ /g, '').split(',') as Permission[];
  let opsUser = false;

  let privateRoutes: any = [];
  if (auth && token && memberType && mi) {
    if (memberType === memberTypes.agencyBuyer) {
      privateRoutes = ComponentRoutes.buyer;
    } else if (supplierTypes.includes(memberType)) {
      privateRoutes = ComponentRoutes.supplier;
    }
  } else if (auth && token && auth.us && permissions.includes(Permission.Ops)) {
    privateRoutes = ComponentRoutes.ops;
    opsUser = true;
  }

  return (
    <>
      {/*<Suspense fallback={<Suspenseloader />}>*/}
      <Switch>
        {ComponentRoutes.limited.map((item, index) => (
          <DefaultRoute key={index} path={item.path} exact component={item.component} />
        ))}
        {privateRoutes.map(
          (item: { path: string; component: any; layout: LayoutType }, index: number) => (
            <AuthenticatedRoute
              component={item.component}
              exact
              isMigrated={isMigrated}
              key={index}
              layout={item.layout}
              memberType={memberType}
              opsUser={opsUser}
              path={item.path}
              resetAuth={resetAuth}
              token={token}
            />
          ),
        )}
        <Route path='/maintanance' component={MaintananceSplash} />
        <Route path='*' component={PageNotFound} />
      </Switch>
      {/*</Suspense>*/}
    </>
  );
};

export default memo(Routes);
