import styled from 'styled-components';

import { FontFamily, FontWeight, LineHeight, ParagraphFontSize, TextColor } from '../styles';

interface BaseLinkProps {
  large?: boolean;
  contrast?: boolean;
}

function linkStyle(props: BaseLinkProps) {
  return `
  font-family: ${FontFamily.Base};
  font-weight: ${FontWeight.Bold};
  font-style: normal;
  font-size: ${props.large ? ParagraphFontSize.Large : ParagraphFontSize.Base};
  line-height: ${LineHeight.Base};
  color: ${props.contrast ? TextColor.ContrastAction : TextColor.Action};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${TextColor.ActionHover};
  }
`;
}

export const BaseLinkA = styled.a<BaseLinkProps>`
  ${props => linkStyle(props)}
`;
export const BaseLinkSpan = styled.span<BaseLinkProps>`
  ${props => linkStyle(props)}
`;
