import styled from 'styled-components';

import { LineWithHRule, PanelLabel } from '@demandstar/components/styles';
import { FontFamily } from '@demandstar/components/styles/typography';

import { Colors, FontWeight } from './';
import { InnerColumnNarrow, InnerColumnWide } from './layout';

import { Selected, Visible } from '../../types/shared';
import { ErrorColor } from './colors';
import { FontSize } from './common';

type PaddingProps = {
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
};

export const PanelLabelDashboard = styled(PanelLabel)`
  font-size: 1.42375rem;
  font-weight: ${FontWeight.header};
`;

export const PanelLabelSmall = styled(PanelLabel)`
  font-size: ${FontSize.base};
  font-style: normal;
  font-weight: ${FontWeight.bold};
  line-height: 1.5rem;
  padding-bottom: 0.25rem;
  padding-top: 1.75rem;
  &:after {
    border-bottom: 0.0625rem solid ${Colors.grayTableRowBorder};
  }
`;

export const PanelTitle = styled('div')`
  font-family: ${FontFamily.Header};
  font-size: 1.67rem; // TODO: configure font sizes with named vars, similar to color vars
  font-style: normal;
  font-weight: ${FontWeight.bold};
  line-height: 2rem;
  overflow: hidden;
  padding-bottom: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PanelText = styled.div`
  padding-bottom: 1.5rem;
`;

export const PanelItemWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

export const PanelListItem = styled.div<Selected>`
  background-color: ${Colors.white};
  border-style: inset;
  border: ${props =>
    props.selected
      ? `0.1875rem solid ${Colors.grayBgHighlight}`
      : `0.0625rem solid ${Colors.grayLight}`};
  ${props => (props.selected ? 'border-right: none;' : '')}
  border-radius: 0.125rem;
  color: ${Colors.action};
  cursor: pointer;
  margin-bottom: 0.5rem;
  padding: ${props => (props.selected ? '0.5rem 2.75rem 0.5rem 0.75rem' : '0.625rem 0.875rem')};
  width: ${props => (props.selected ? 'calc( 100% + 1.7rem )' : 'inherit')};
  &:hover {
    border-color: ${Colors.blueGreenLight};
  }
  &:active {
    border-color: ${Colors.blueGreenMedium};
  }
`;

export interface PanelRowProps {
  paddingBottom?: string;
  paddingHorizontal?: string;
  paddingTop?: string;
  paddingVertical?: string;
}
export const PanelRow = styled.div<PanelRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: ${FontFamily.Base};
  font-size: ${FontSize.base};
  justify-content: flex-start;
  padding: ${({ paddingVertical }) => paddingVertical || '0'};
  ${({ paddingHorizontal }) => paddingHorizontal || '2rem'};
  ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop};` : '')};
  ${({ paddingBottom }) => (paddingBottom ? `padding-bottom: ${paddingBottom};` : '')};
`;

export const PanelRowRight = styled(PanelRow)`
  justify-content: flex-end;
`;

interface RowColumn {
  full?: boolean;
}

export const PanelRowHeader = styled.div<RowColumn>`
  display: flex;
  flex-basis: ${props => (props.full ? '100%' : '25%')};
  flex-grow: ${props => (props.full ? 'inherit' : 1)};
  font-family: ${FontFamily.Header};
  font-style: normal;
  font-weight: ${FontWeight.bold};
  justify-content: space-between;
  line-height: 1.5rem;
  min-width: 7rem;
  padding: 0 0.5rem 0.75rem 0;
  width: ${props => (props.full ? '100%' : '25%')};
`;

export const PanelRowValue = styled(PanelRowHeader)`
  flex-basis: ${props => (props.full ? '100%' : '75%')};
  flex-grow: ${props => (props.full ? 'inherit' : 2)};
  font-family: ${FontFamily.Base};
  font-style: normal;
  font-weight: 400;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  width: ${props => (props.full ? '100%' : '75%')};
`;

interface PanelRowFlexSpaceBetweenProps {
  height?: string;
}

export const PanelRowFlexSpaceBetween = styled.div<PanelRowFlexSpaceBetweenProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${props => (props.height ? `height: ${props.height}` : '')};
`;

export const DocumentUploadPanelWrapper = styled.div<Visible>`
  display: ${props => (props.visible ? 'inherit' : 'none')};
  padding-top: 2rem;
  transition: display 0.5s;
`;

export const DueDateRowValue = styled(PanelRowValue)`
  color: ${Colors.redText};
`;

export const DashboardLeftColumn = styled(InnerColumnNarrow)`
  padding-right: 1.5rem;
`;

// We need to place the list above the details to show
// the selected item's border overlay.
export const LeftColumnAbove = styled(DashboardLeftColumn)`
  z-index: 1;
`;

export const DashboardRightColumn = styled(InnerColumnWide)`
  padding-left: 1.5rem;
`;

export const TextWrapper = styled.div`
  background-color: ${Colors.grayMediumLight};
  color: inherit;
  padding: 0.6rem 3rem;
`;

export const LoadingTextWrapper = styled(TextWrapper)`
  padding-left: 1rem;
`;

export const NotFoundTextWrapper = styled(TextWrapper)`
  padding: 1rem;
`;

export const ErrorTextWrapper = styled(TextWrapper)`
  background-color: ${ErrorColor.highlight};
  color: ${Colors.white};
  svg path {
    fill: ${Colors.white};
  }
`;

export const NotFoundText = styled.span`
  padding-left: 0.5rem;
`;

export const SolicitationTitle = styled.div`
  color: ${Colors.grayDark};
  font-family: ${FontFamily.Header};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
`;

export const ActionMessage = styled.div`
  color: ${Colors.grayMediumDark};
  font-family: ${FontFamily.Base};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  padding: 0;
  &:hover {
    color: ${Colors.action};
  }
`;

export const BoldText = styled.span`
  font-weight: ${FontWeight.bold};
`;

export const SpanSpacerLeft = styled.span`
  padding-left: 0.325rem;
`;
export const SpanSpacerRight = styled.span`
  padding-right: 0.325rem;
`;

export const CenteredContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export { LineWithHRule, PanelLabel };
