import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { authErrorState, authPermissionsState, authState } from 'src/store/recoil/authState';
import { isLoggingOut, memberCookieName, tokenCookieName } from 'src/utils/constants';

import { authMessaging } from '../constants';
import { getAuthToken } from 'src/service/auth';
import { GetAuthTokenRequest } from 'src/types';
import { getTokenData } from 'src/utils/token';
import { setCookie } from 'src/utils/cookie';
import { track } from 'src/utils/telemetry';

export function useAuth() {
  const [auth, setAuth] = useRecoilState(authState);
  const [authError, setAuthError] = useRecoilState(authErrorState);
  const [authPermissions] = useRecoilValue(authPermissionsState);

  async function handleLogin({ userName, password }: GetAuthTokenRequest) {
    try {
      const loginResponse = await getAuthToken({ userName, password });
      const tokenData = getTokenData(loginResponse.token);

      // Set recoil auth state.
      setAuth(tokenData);
      // Set cookie auth
      setCookie(memberCookieName, tokenData.memberId, 1);
      setCookie(tokenCookieName, tokenData.token, 1);
      sessionStorage.removeItem(isLoggingOut);
    } catch (error: any) {
      track('handleLogin -> getAuthToken() ERROR:', {
        error,
        method: 'GET',
        errorMessage: error.message,
      });
      if (error.toString().indexOf('401')) {
        setAuthError(authMessaging.loginError);
      }
      if (error.message) console.error('handleLogin -> getAuthToken() ERROR:', error);
    }
  }

  const resetAuthError = useResetRecoilState(authErrorState);

  const { memberId } = auth;

  return {
    auth,
    authError,
    authPermissions,
    handleLogin,
    memberId,
    resetAuthError,
    setAuth,
    setAuthError,
  };
}
