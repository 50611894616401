// TODO: This component should probably have tests
import { Suspense, useState } from 'react';

import { DSButton } from '@demandstar/components/button';
import { FlexContainer } from '@demandstar/components/styles';

// TODO: kill Redux
import { addInCompleteRegistrationData, setRegistrationData } from 'src/store/actions';
import { AgencySelection } from '../agency-selection';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { supplierRegistrationTypes } from '../../../types/supplierregistration';
import { useAmplitude } from '../../amplitude';
import { useDispatch } from 'react-redux';

interface SelectFreeAgencyProps {
  /** Current registration data */
  registration: supplierRegistrationTypes;
}

/**
 * Page to select a free agency during registration
 */
export const SelectFreeAgency = (props: SelectFreeAgencyProps) => {
  const dispatch = useDispatch();
  const { logEvent } = useAmplitude();
  const { registration } = props;
  const { registrationData } = registration;
  const [confirmationPopup, toggleConfirmationPopup] = useState(false);
  const [freeAgencyInfo, setFreeAgencyInfo] = useState({
    page: '',
    freeAgency: '',
    agencyName: '',
  });

  const updateIncompleteRegistration = (page: string, freeAgency: any, agencyName: string) => {
    const incompletePayload = {
      email: registrationData.emailAddress,
      JsonData: {
        regData: {
          currentComponent: page,
          registrationData: { ...registrationData, freeAgency, selectedAgencyName: agencyName },
        },
      },
    };
    dispatch(addInCompleteRegistrationData(incompletePayload));
  };

  const movetoNextPage = (
    page: string,
    agencies: any = [],
    type = true,
    agencyName = '',
    agencyData?: { agencyName: string; agencyState: string; agencyCounty: string },
  ) => {
    let freeAgency = '';
    if (agencies.length > 0) {
      freeAgency = agencies[0];
      if (type) {
        updateIncompleteRegistration(page, freeAgency, agencyName);
      }
      dispatch(
        setRegistrationData({
          currentComponent: page,
          registrationData: { ...registrationData, freeAgency, freeAgencyInfo: agencyData },
        }),
      );
      logEvent('registration (supplier) - select agency', {
        Description: 'Select a free agency',
        'Free Agency': agencyName,
        'State (Agency)': agencyData?.agencyState,
        'County (Agency)': agencyData?.agencyCounty,
      });
    } else {
      toggleConfirmationPopup(true);
      setFreeAgencyInfo({ page, freeAgency, agencyName });
    }
  };

  const confirmSkip = () => {
    logEvent('registration (supplier) - skip agency', {
      Description:
        'Clicks the button in the modal to confirm they want to skip adding a free agency',
    });

    updateIncompleteRegistration(
      freeAgencyInfo.page,
      freeAgencyInfo.freeAgency,
      freeAgencyInfo.agencyName,
    );

    dispatch(
      setRegistrationData({
        currentComponent: freeAgencyInfo.page,
        registrationData: {
          ...registrationData,
          freeAgency: freeAgencyInfo.freeAgency,
          freeAgencyInfo: {},
        },
      }),
    );
    toggleConfirmationPopup(false);
  };

  return (
    <>
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row d-flex justify-content-center regWrapper'>
            <div className='col-12'>
              <div className='colWrapper'>
                <h2 className='arrowWrapper'>1 of 3: Choose your free agency</h2>
                <div className='row d-flex regWrapper'>
                  <div className='col-12 col-md-10 col-lg-8'>
                    <Suspense fallback={<div>Agency Selection Loading..</div>}>
                      <AgencySelection
                        products={registrationData.freeAgency ? [registrationData.freeAgency] : []}
                        toggleAgencySelection={() => {}}
                        pageFor='registration'
                        movetoNextPage={movetoNextPage}
                      />
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp
        size='lg'
        title='Are you sure?'
        closeModal={() => toggleConfirmationPopup(false)}
        isOpen={confirmationPopup === true}
        backdrop='static'
      >
        <p>
          To receive proactive notifications from DemandStar, you must select an agency. Selecting a
          single agency on this step is free.
        </p>
        <p>
          Filter by State and County to see what’s available near you, then select an agency. The
          moment this agency posts a new bid, we’ll send you an email with the details.
        </p>
        <p>Make sure you never miss out on a government contract again!</p>

        <FlexContainer justifyContent='space-between'>
          <DSButton buttonType='secondary' onClick={confirmSkip}>
            Skip getting free notifications
          </DSButton>
          <DSButton
            onClick={() => {
              toggleConfirmationPopup(false);
            }}
          >
            Choose a free agency now
          </DSButton>
        </FlexContainer>
      </ModalPopUp>
    </>
  );
};
