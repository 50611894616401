import { Suspense, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { DSButton } from '@demandstar/components/button';
import { DSLink } from '@demandstar/components/link';
import { FlexContainer } from '@demandstar/components/styles';

import { initialAgencyProduct, selectedProductsState } from '../../../store/recoil/productState';

import { actionPayloadTypes } from 'src/types/actiontypedef';
import { AgencyByProductModal } from '../agency-by-product';
import { Cart } from '../cart/Cart';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { PrepopulatedProducts } from '../../../types/subscriptions';
import { prepopulatedProductsState } from '../../../store/recoil/subscriptionState';
import { recoilRegistrationDataState } from '../../../store/recoil/registrationState';
import { ReduxAction } from 'src/store/actions/utils';
import { registrationComponent } from '../../../utils/constants';
import { scrollToTop } from 'src/utils/helpers';
import { SelectState } from './SelectState';
import { SubscriptionProducts } from '../../../types/products';
import { UpsellList } from '../upsell/UpsellList';
import { useCart } from '../cart/useCart';
import { useUpsellProducts } from '../upsell/useUpsellProducts';

interface SelectProductsProps {
  /** used to set Redux navigation state in registration */
  setRegistrationData?: (registration: actionPayloadTypes) => ReduxAction;
}

/**
 * @description the page for selecting products in registration
 * @returns JSX.Element
 *
 * @example <SelectProducts />
 */
export const SelectProducts = (props: SelectProductsProps) => {
  const selectedProducts = useRecoilValue<SubscriptionProducts>(selectedProductsState);
  const prepopulatedProducts = useRecoilValue<PrepopulatedProducts>(prepopulatedProductsState);
  const registrationData = useRecoilValue(recoilRegistrationDataState);

  const [confirmationPopup, toggleConfirmationPopup] = useState(false);

  const { setRegistrationData } = props;

  const { clearExtraUpsellProducts } = useUpsellProducts();

  const { showCart } = useCart();

  let prepopulatedState = prepopulatedProducts.stateName;
  /* istanbul ignore else */
  if (selectedProducts.state) {
    prepopulatedState = selectedProducts.state[selectedProducts.state.length - 1]
      ? selectedProducts.state[selectedProducts.state.length - 1].productName
      : prepopulatedProducts.stateName;
  }

  const checkout = () => {
    if (showCart && setRegistrationData) {
      setRegistrationData({
        ...registrationData,
        currentComponent: registrationComponent.CompleteProfile,
      });
    } else toggleConfirmationPopup(true);
  };

  const goBack = () => {
    clearExtraUpsellProducts();
    if (setRegistrationData)
      setRegistrationData({
        ...registrationData,
        currentComponent: registrationComponent.ChooseAgency,
      });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className='container'>
        <div className='row regWrapper'>
          <div className='col-12'>
            <div className='colWrapper subscriptionWrapper'>
              <div className='row'>
                <div className='col col-8'>
                  <h2 className='arrowWrapper'>2 of 3: Choose Subscriptions</h2>
                  {selectedProducts.agency && selectedProducts.agency !== initialAgencyProduct ? (
                    <>
                      <p className='reg-intro no-bottom-margin'>
                        Now that you've chosen&nbsp;
                        <DSLink onClick={() => goBack()}>
                          {selectedProducts.agency?.productName}
                        </DSLink>{' '}
                        as your free agency, add more counties to grow your network of potential
                        clients.
                      </p>
                      <p className='reg-intro no-top-padding'>
                        County subscriptions start at $25 per year.
                      </p>
                    </>
                  ) : (
                    <p className='reg-intro no-top-padding'>
                      Build your network by adding county subscriptions, starting at $25 a year. Or,{' '}
                      <DSLink onClick={() => goBack()}>
                        go back and add a single agency for free
                      </DSLink>
                      !
                    </p>
                  )}
                  <SelectState prepopulatedState={prepopulatedState} />
                  {showCart ? (
                    <>
                      <Suspense fallback={<></>}>
                        <Cart />
                      </Suspense>
                      <FlexContainer justifyContent='space-between'>
                        <DSButton buttonType='secondary' onClick={goBack}>
                          Go Back
                        </DSButton>
                        <DSButton buttonType='primary' onClick={checkout}>
                          Next
                        </DSButton>
                      </FlexContainer>
                    </>
                  ) : (
                    <FlexContainer justifyContent='space-between'>
                      <DSButton buttonType='secondary' onClick={goBack}>
                        Go Back
                      </DSButton>
                      <DSButton buttonType='primary' onClick={checkout}>
                        Continue without adding subscriptions
                      </DSButton>
                    </FlexContainer>
                  )}
                </div>
                <div className='col col-4'>
                  <UpsellList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AgencyByProductModal />
      <ModalPopUp
        size='lg'
        title={`${selectedProducts.agency?.productName ? 'Notice' : 'Warning'}`}
        closeModal={() => toggleConfirmationPopup(false)}
        isOpen={confirmationPopup === true}
        backdrop='static'
      >
        {selectedProducts.agency?.productName ? (
          <>
            <p>
              By not subscribing to any counties or states, you risk missing out on opportunities
              from other agencies near you.
            </p>
            <p>
              Find and subscribe to counties near you and DemandStar will send you an email the
              moment any agency, not just <b>{selectedProducts.agency?.productName}</b>, in those
              counties posts a new bid.
            </p>
          </>
        ) : (
          <>
            <p>
              To receive proactive notifications about opportunities near you, you must subscribe to
              a geographic region.
            </p>
            <p>
              Find and subscribe to counties near you and DemandStar will send you an email the
              moment any agency in those counties posts a new bid.
            </p>
          </>
        )}
        <p>Make sure you never miss out on a government contract again!</p>

        <FlexContainer justifyContent='space-between'>
          <DSButton
            buttonType='secondary'
            title='Notice'
            onClick={() => {
              toggleConfirmationPopup(false);
              if (setRegistrationData)
                setRegistrationData({
                  ...registrationData,
                  currentComponent: registrationComponent.CompleteProfile,
                });
            }}
          >
            Skip getting notifications
          </DSButton>
          <DSButton
            buttonType='primary'
            title='I want to subscribe to counties!'
            onClick={() => {
              toggleConfirmationPopup(false);
            }}
          >
            I want to subscribe to counties!
          </DSButton>
        </FlexContainer>
      </ModalPopUp>
    </>
  );
};
