import { memo, useState } from 'react';

import {
  BulkUploadErrorMessage,
  bulkUploadErrorMsg,
  supportSupplimentalDocTypes,
} from '../../../../utils/constants';
import { ActionColor } from '../../../../shared/styles';
import { addbidParamType } from '../../../../types/addbid';
import { Buttons } from '../../../customcontrols';
import { CircularProgressbar } from 'react-circular-progressbar';
import { DeprecatedInput } from '../../../customcontrols/input';
import { getFileExtension } from '../../../../utils/helpers';

import ConfigurationText from './configurationtext';
import ErrorBlock from './errorblock';
import SelectSheet from './selectsheet';

type propsTypes = {
  bulkUploadDocument?: any;
  addbid: addbidParamType;
  setAddBidDetails: any;
  match?: any;
};

function getErrorMessage(errorType: number): BulkUploadErrorMessage | undefined {
  return bulkUploadErrorMsg.find(item => item.id === errorType);
}

const UploadFile = (props: propsTypes) => {
  const { bulkUploadDocument, addbid, setAddBidDetails } = props;
  const {
    multipleSheet = false,
    docValidation = false,
    errorMsg = '',
    configurationInstruction = false,
    uploadProgress = false,
    progress = 0,
    errorType,
  } = addbid || {};
  const [uploadedFile, setUploadedFile] = useState('') as any;
  const BidId = props?.match?.params?.bidId;
  let error;
  // eslint-disable-next-line
  if (errorType != undefined) {
    // != intentional since errorType could be 0, which is falsey
    error = getErrorMessage(errorType);
  }
  if (error && uploadedFile) {
    setUploadedFile('');
  }

  const onChangeFn = (name: string, value: any) => {
    setAddBidDetails({ docValidation: false, errorMsg: '', errorType: '' });
    const extension = getFileExtension([value]);
    if (supportSupplimentalDocTypes.includes(extension.toLowerCase())) {
      setUploadedFile(value);
    } else {
      setAddBidDetails({ docValidation: true, errorMsg: 'Invalid file: Not a CSV or Excel file.' });
    }
  };

  const ShowCircular = () => {
    if (!uploadProgress) {
      return null;
    }

    return (
      <div style={{ width: '40px', marginLeft: '20px' }}>
        <CircularProgressbar
          styles={{
            text: {
              fill: ActionColor.primary,
              fontSize: '28px',
            },
          }}
          value={progress}
          text={`${progress}%`}
        />
      </div>
    );
  };
  const errorBlockProps = { ...props, error };
  return configurationInstruction === true ? (
    <ConfigurationText
      setConfigurationInstruction={() => setAddBidDetails({ configurationInstruction: false })}
    />
  ) : multipleSheet === true ? (
    <SelectSheet {...props} />
  ) : (
    <>
      <h5 data-testid='addbid.uploadfile.head'>Import from CSV or Excel file</h5>
      <ErrorBlock {...errorBlockProps} />
      {uploadedFile ? (
        <span className='d-flex align-items-center'>
          {uploadedFile.name}
          {uploadProgress ? (
            ShowCircular()
          ) : (
            <i
              className='mdi mdi-close-box-outline mdi-24px ml-2 mt-1 staticLink'
              title='Cancel/Replace File'
              onClick={() => {
                setUploadedFile('');
                setAddBidDetails({ errorType: '' });
              }}
            />
          )}
        </span>
      ) : (
        <DeprecatedInput
          type='file'
          label='Upload File'
          parentClass='mt-3'
          handleChange={(name: string, value: any) => onChangeFn(name, value && value[0])}
          meta={{ touched: docValidation, error: errorMsg }}
        />
      )}
      <p className='mb-2 mt-3'>Acceptable file types: CSV, XLS, XLSX</p>
      {/* <p className='mb-2'>Duplicate suppliers will be removed</p> */}
      <p
        className='staticLink'
        onClick={() => setAddBidDetails({ configurationInstruction: true })}
      >
        How do I configure my CSV or Excel file?
      </p>
      <div className='d-flex justify-content-center mt-5'>
        <Buttons
          data-testid='addbid.uploadfile.upload.button'
          type='button'
          text='Upload List'
          classNames='bttn-primary w-50'
          onClick={() => bulkUploadDocument({ BidId, SupplierFile: uploadedFile })}
          disable={uploadedFile && !uploadProgress ? false : true}
        />
      </div>
    </>
  );
};

export default memo(UploadFile);
