// TOREFACTOR - consolidate paths. we have several constants describing paths. most confusing is this file vs `src/shared/constants/paths.ts`
export const Pages = {
  buyerBids: '/buyers/bids',
  buyerDashboard: '/buyers/dashboard',
  limitedBids: '/limited/bids',
  login: '/',
  supplierBids: '/suppliers/bids',
  supplierDashboard: '/suppliers/dashboard',
};

export const Paths = {
  //Users
  //account
  accountinfo: '/account/getaccountinfo',
  addBulkSupplimentalSuppliers: '/member/addmultiplesupplemental',
  addMemberProgramCertification: '/account/addMemberProgramCertification',
  addUpdateUserAccount: '/account/addupdateuseraccount',
  agencySettings: '/account/getAgencyDetails',
  removeAgencyImages: '/account/removeImages',
  bidWriter: '/account/bidwriter',
  dashboardnotifications: '/account/notifications',
  disablenotification: '/account/disablenotification',
  ForgotLogin: '/account/ForgotLogin',
  forgotPassword: '/account/forgotpassword',
  GetLegalTemplates: '/registration/GetLegalTemplates',
  getProgramAttributes: '/account/getProgramAttributes',
  getSiblingProductsData: '/account/getsiblingproducts',
  getUserAccounts: '/account/getUserAccounts',
  planholderTypes: '/registration/planholderDisplays',
  reactivateUserAccount: '/account/reactivateUserAccount',
  saveSelfDeclarations: '/account/saveselfdeclarations',
  searchPrograms: '/account/searchPrograms',
  themeget: '/account/theme/get',
  themeset: '/account/theme/set',
  unblockUserAccount: '/account/unblockUserAccount',
  updateAgencySettings: '/account/settings',
  VerifySecurityCode: '/Account/VerifySecurityCode',

  //agency
  agencyAwards: '/agency/awards',
  agencycommodityByType: '/agency/commodityByType',
  agencydocuments: '/agency/documents',
  agencyLanding: '/agency/search',
  agencylegal: '/agency/legal',
  agencyplanholders: '/agency/planholders',
  agencyselfDeclarations: '/agency/selfDeclarations',
  agencysummary: '/agency/summary',
  exportBidActivityReport: '/agency/exportbidactivityreport',
  exportLegalAd: '/agency/exportlegalad',
  exportQuoteActivityReport: '/agency/exportquoteactivityreport',
  getAgencyDetails: '/agency/getAgencydetails',
  getBidActivityReport: '/agency/getbidactivityreport',
  getQuoteActivityReport: '/agency/getquoteactivityreport',

  //Auth
  Authorization: '/auth/gettoken',
  RefreshToken: '/auth/refreshToken',
  ResetPassword: '/auth/ResetPassword',

  // Award
  Award: {
    VendorSpecificDocs: {
      all: 'bids/GetDashBoardVendorSpecificDocuments',
      delete: 'bids/vendordoc/delete',
      get: 'bids/GetVendorSpecificDocuments',
      upload: 'document/bidaward/upload',
    },
    RequiredDocs: {
      get: 'bids/awardrequiredDocs/get',
      update: 'bids/awardrequiredDocs/update',
    },
    RequiredDocTypes: {
      get: 'bids/requiredDocs/get',
      update: 'bids/requiredDocs/update',
    },
    RequiredDocResponses: {
      all: 'bids/award/GetDashboardBidAwardRequiredDocResponse',
      get: 'bids/award/requiredDocResponses',
      upload: 'document/bidawardrequireddocument/upload',
      delete: 'document/bidawardrequireddocument/upload',
    },
    CustomAwardNotifications: {
      get: 'bids/GetCustomAwardNotification',
      add: 'bids/AddUpdateCustomAwardNotification',
    },

    // Award Bid
    markBidAsAwarded: 'bids/markbidasawarded',
    markBidAsRecommendationOfAward: 'bids/MarkAsRecommendationOfAward',
  },

  //Auth
  auth: {
    authorization: '/auth/gettoken',
    refreshToken: '/auth/refreshToken',
    resetPassword: '/auth/ResetPassword',
  },

  //member
  member: {
    activePrograms: '/member/activePrograms',
    validateEmail: '/registration/validate',
  },

  //bids
  addAwardees: 'bids/addawards',
  AddBidInfo: '/bids/AddBidInfo',
  addBidPlanholder: '/bids/addPlanholder',
  awardBid: 'bids/awardBid',
  awards: '/bids/awards',
  bidResponseItems: '/bids/bidResponseItems',
  bids: '/bids/search',
  bidsBidders: '/bids/bidders',
  bidsbroadcasthistory: '/broadcast/history',
  bidsCounters: 'bids/counters',
  bidsDocuments: '/bids/documents',
  bidsLegalAd: '/bids/legal',
  bidsplanholderDocuments: '/bids/planholderDocuments',
  getPlanholders: '/bids/planholders',
  planholdersDetail: 'member/planholdersdetails',
  bidsPostbidViewers: '/bids/postviewers',
  bidsPurchaseInfo: '/bids/purchaseinfo',
  bidsremove: '/bids/remove',
  bidsRequiredActions: 'bids/requiredActions', // TOREFACTOR - break this beast up by functionality (1K+ lines...)?
  bidsSimilarBids: '/bids/similarbids',
  bidsSimilarSuppliers: '/bids/similarsuppliers',
  bidsStats: '/bids/stats',
  bidsSummary: '/bids/summary',
  bidsTabulation: '/bids/bidtabulation',
  canComplete: '/bids/canComplete',
  changedue: '/bids/changedue',
  clearDashboardCache: 'cache/cleardashboardcache',
  complete: '/bids/complete',
  conversionTypes: '/bids/conversionTypes',
  dashboardBids: '/bids/dashboard',
  docprocessingstatus: 'bids/docprocessingstatus',
  doctypes: '/bids/doctypes',
  documentapprove: '/bids/document/approve',
  documentdelete: '/bids/document/delete',
  exportPlanholders: '/bids/exportplanholders',
  finishStates: 'bids/finishStates',
  isConstruction: '/bids/isConstruction',
  getBidStatus: '/bids/status',
  getRelatedBidsData: '/bids/relatedbids',
  memberPublications: '/bids/memberPublications',
  orderinfo: '/bids/orderinfo',
  orders: '/bids/orders',
  permissions: '/bids/permissions',
  publications: '/bids/publications',
  removePlanholder: '/bids/removePlanholder',
  responseformats: '/bids/responseformats',
  supplimentalSupplierSave: '/bids/supplementalsupplier/save',
  supplimentalSupplierStatus: '/bids/supplementalsupplier/status',
  supplimentalsupplierupload: '/bids/supplementalsupplier/upload',
  updateBidPublication: '/bids/updateBidPublication',
  updateBidStatus: 'bids/CloseBidOrQuote',
  UpdateEbidInfo: '/bids/updateEbid',
  updateLegal: '/bids/updateLegal',
  updateMemo: '/bids/updateMemo',
  updatePlan: '/bids/updatePlan',
  updateSheet: '/bids/supplementalsupplier/updatesheet',

  //broadcast
  addList: '/broadcast/addList',
  addListBL: '/broadcast/addListBL',
  addToBid: '/broadcast/listsToParent',
  broadcastFilters: '/broadcast/broadcastFilters',
  broadcastListId: '/broadcast/broadcastListId',
  broadcastMembers: '/broadcast/broadcastMembers',
  broadcastMembersBL: '/broadcast/savedlist/members',
  conditionspending: '/broadcast/conditions/pending',
  deleteList: '/broadcast/deleteList',
  exportBroadcastList: '/broadcast/exportbroadcast',
  exportSupplementalSuppliers: '/broadcast/exportSupplementalSuppliers',
  getBLFilters: '/broadcast/savedlist/filters',
  getList: '/broadcast/getLists',
  getListCount: '/broadcast/lists/counters',
  getListsBL: '/broadcast/getListsBL',
  isFilterStatus: '/broadcast/isFilterStatus',
  reBroadcastBid: '/broadcast/reBroadcastBid',
  removesupplemental: '/broadcast/removesupplemental',
  removeSupplier: '/broadcast/removeSupplier',
  searchVendors: '/broadcast/searchVendors',
  searchVendorsBL: '/broadcast/searchVendorsBL',
  similarbroadcast: '/broadcast/similarbroadcast',
  supplementalsuppliers: '/broadcast/supplementalsuppliers',
  supplierCount: '/broadcast/supplierCount',

  //common
  accountpublications: '/common/publications',
  accountpublicationsadd: '/common/publications/add',
  accountpublicationsremove: '/common/publications/remove',
  activitiesall: '/common/activities/all',
  agencies: '/common/getAgencies',
  bidStatuses: '/common/getBidStatuses',
  commodities: '/common/getCommodities',
  commodityByType: '/common/commodityByType',
  FiscalYear: '/common/FiscalYear',
  getaccountInfo: '/common/getaccountInfo',
  GetAmplitudeUserProperties: '/common/amplitude/userproperties',
  getDashboardData: '/common/dashboard',
  getdashboardnetwork: '/common/network',
  getdashboardnotifications: '/common/notifications',
  getScrapers: '/common/getScrapers',
  locations: '/common/getLocations',
  movenext: '/common/movenext',
  requiredDocs: '/common/requiredDocs',
  requiredDocsUpdate: '/common/requiredDocs/update',
  ResponseDisplays: '/common/responseDisplays',
  showbids: '/common/showbids',
  topCommodities: '/common/topCommodities',

  //document
  bidsDocumentDownload: '/document/download',
  bidsDocumentsAll: '/document/downloadall',
  documentUpload: '/bids/document/upload',
  ebidupload: '/ebids/update/upload',
  quotesdocumentupload: '/quotes/document/upload',
  supplierTabulation: '/bids/tabulation',

  //ebids
  getEBidResponses: '/ebids/getallresponses',
  ebitupdate: '/ebids/update',
  getresponse: '/ebids/getresponse',

  //member
  activePrograms: '/member/activePrograms',
  addsupplemental: '/member/addsupplemental',
  bidResponderInfo: '/member/bidresponderinfo',
  BidTypes: '/member/BidTypes',
  BidTypesAdd: '/member/bidTypes/add',
  BidTypesRemove: '/member/bidTypes/remove',
  BidWriters: '/member/BidWriters',
  changebilladdress: '/member/changebilladdress',
  contractInterest: '/member/contract/interest',
  getDocAutoApprovalStatus: '/member/getdocautoapprovalstatus',
  getSelfDeclarations: '/member/selfdeclarations',
  getSuppDocDownloadStatus: '/member/SuppDocDownloadstatus',
  legalUpdate: '/member/legal/update',
  memberactivitiesall: '/member/activities/all',
  memberInfo: '/member/memberInfo',
  memberLegal: '/member/legal',
  operationUpdate: '/member/operation/update',
  savedLegal: '/member/legal',
  searchPlanholder: '/member/searchPlanholder',
  toggleDocAutoApproval: '/member/toggledocautoapproval',
  toggleSuppDocDownload: '/member/toggleSuppDocDownload',
  validateEmail: '/registration/validate',

  //payments
  addCharge: '/payment/addCharge',
  cardinfo: '/payment/cardinfo',
  cardtypes: '/payment/cardtypes',
  createcardprofile: '/payment/createcardprofile',
  paymentHistory: '/payment/history',
  processCreditTransaction: '/payment/processcredittransaction',
  purchasebid: '/payment/purchasebid',
  removeCard: '/payment/removeCard',

  //program
  addUpdateProgram: '/program/AddUpdateProgram',
  getPrograms: '/program/GetPrograms',
  getSuppliers: '/program/GetSuppliers',
  respondCertRequest: '/program/RespondCertRequest',

  //quotes
  addQuoteInfo: '/quotes/addInfo',
  changeDuedate: '/quotes/changeDuedate',
  changeStatus: '/quotes/changeStatus',
  contacts: '/quotes/contacts',
  dashboardQuotes: '/quotes/dashboard',
  deleteQuote: '/quotes/delete',
  deleteLineItem: '/quotes/lineitem/remove',
  lineItemSuppliers: '/quotes/lineItemSuppliers',
  notSubmitQuote: '/quotes/notsubmit',
  quoteaward: '/quotes/award',
  quoteCanComplete: '/quotes/cancomplete',
  quoteItems: '/quotes/quoteItems',
  quoteLineItems: '/quotes/quoteItems',
  quoteResponder: '/quotes/responder',
  quoteResponse: '/quotes/responses',
  quotes: '/quotes/search',
  quotescomplete: '/quotes/complete',
  quotesdocumentdelete: '/quotes/document/delete',
  quotesdocuments: '/quotes/documents',
  quotesnumbers: '/quotes/numbers',
  quoteStatistics: '/quotes/stats',
  quoteStatus: '/quotes/status',
  quoteSummary: '/quotes/summary',
  quotesview: '/quotes/view',
  responsesStatus: '/quotes/responsestatus',
  statusOptions: '/quotes/statusOptions',
  submitQuoteLater: '/quotes/later',
  submitQuoteReply: '/quotes/submit',
  supplierQuote: '/quotes/supplierQuote',
  updateWire: '/quotes/updateWire',

  //registration
  addInCompleteRegistrationData: '/registration/AddIncompleteRegistration',
  AddMemberAccount: '/registration/AddMemberAccount',
  addMemberAccount: '/registration/AddMemberAccount',
  agencyAddEbidDocuments: 'registration/requiredDocs/update',
  agencyAddLegalAd: '/registration/legal/update',
  agencyAddPublications: '/registration/publications/add',
  agencyBidTypes: '/registration/bidtypes',
  agencyBuyerInformation: '/registration/buyer',
  agencyCounties: '/registration/GetCounties',
  agencyFinishRegistration: '/registration/complete',
  agencyGetPublications: '/registration/publications',
  agencyGetRequiredDocs: '/registration/requiredDocs',
  agencyLegalad: '/registration/legal',
  agencyRemoveBidtype: '/registration/bidTypes/remove',
  agencyRemovePublication: '/registration/publications/remove',
  agencyStates: '/registration/GetStates',
  bidTypesUpdate: '/registration/bidTypes/add',
  claimPlanholderAccount: '/registration/SendRegMailToPlanholder',
  createBuyer: '/registration/createBuyer',
  freeAgencies: '/registration/GetFreeAgencies',
  geoCounties: '/registration/getgeocounties',
  geoStates: '/registration/getgeostates',
  getAllProducts: '/registration/GetAllProducts',
  getCommodityCodes: '/registration/getCommodityCodes',
  GetCounties: '/registration/GetCounties',
  GetIncompleteRegistration: '/registration/GetIncompleteRegistration',
  getIncompleteRegistration: '/registration/GetIncompleteRegistration',
  getPermissions: 'registration/getPermissions',
  getProductDynamicPrices: '/registration/GetProductsMinimumPrices',
  GetStates: '/registration/GetStates',
  getSubscriptionMemberDetails: '/registration/GetMemberDetails',
  getversion: '/version',
  IsCompanyOrAccountExists: '/registration/IsCompanyOrAccountExists',
  metro: '/registration/Metro',
  searchCommodityCodes: '/registration/searchCommodityDescription',
  timezones: '/registration/timezones',
  updateCommodityCodes: '/registration/updateCommodityCodes',
  updateMemberAddress: '/registration/updatememberaddress',
  Wappagencies: '/registration/Wappagencies',

  //subscription
  autoRenewSwitch: '/subscription/autorenewswitch',
  calculateSubscriptionRequest: '/subscription/CalculateSubscription',
  cancelSubscription: '/subscription/cancelSubscription',
  canUpgrade: '/subscription/canupgrade',
  changePrimaryContact: '/subscription/changeprimarycontact',
  createUpdateBTProfile: '/subscription/CreateOrUpdateCardProfile',
  getProfileInfo: '/subscription/getprofileinfo',
  modifysubscription: '/subscription/modifysubscription',
  processComplementary: 'Subscription/CreateFreeSubscription',
  processPayment: '/Subscription/CreateSubscription',
  updateSupplierFreeAgencies: '/subscription/upgradefreeagency',

  //tracking
  bidsreminders: '/bids/reminders',
  getWatchers: '/bids/watchers',
  bidswatchstatus: '/bids/watchstatus',
  bidView: '/bids/bidview',
  getBidAuditTrialView: '/bids/audittrail',
  getBidswatchersall: '/bids/watchers/all',
  getCampaignStatus: '/bids/ebidcampaign/status',
  responseEvents: '/bids/responseEvents',
  track: '/bids/track',
  updateCampaignFeedbackPopup: '/bids/ebidcampaign/update/feedback',
  updateCampaignFirstPopup: '/bids/ebidcampaign/update/learn',
  updateCampaignNotNowPopup: '/bids/ebidcampaign/update/notnow',
  updatereminders: '/bids/updatereminders',
  watchBids: '/bids/watch',
  watchedBids: '/bids/watched',

  // tour
  tourget: '/tour/get',
  tourupdate: '/tour/update',

  // ops
  ops: {
    agencyRegistration: '/ops/agency/registration',
  },
};
