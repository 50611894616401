import { DSButton, DSButtonProps } from '@demandstar/components/button';

/**
 * Styled Button with default type = 'submit'
 */
export type SubmitButtonProps = Omit<DSButtonProps, 'buttonType'>;

export const SubmitButton = (props: SubmitButtonProps) => {
  return (
    <DSButton {...props} buttonType='submit'>
      {props.children}
    </DSButton>
  );
};
