import { Column, SortingRule, useSortBy, useTable } from 'react-table';
import { Flex, FlexContainer } from '..';
import { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import {
  BaseTable,
  BaseTableBody,
  BaseTableCell,
  BaseTableFooter,
  BaseTableFooterCell,
  BaseTableFooterRow,
  BaseTableHead,
  BaseTableRow,
} from './DSTableStyles';
import { ColumnHeader } from './ColumnHeader';
import { DataTestId } from '../types';

const CenterFlex = styled(Flex)`
  text-align: center;
`;

export type DSTableProps<T extends Record<string, unknown>> = DataTestId & {
  /** Must be memoized. The core columns configuration object for the entire table.
   * https://react-table.tanstack.com/docs/api/useTable#column-options */
  columns: Column<T>[];
  /** Must be memoized. The data array that you want to display on the table. */
  data: T[];
  /** Optional string to indicate an empty table. */
  emptyMessage?: ReactNode;
  /** Optional key to determine rowId */
  rowKey?: keyof T;
  /** simple string for default sort, can also take an array of sorting rules */
  sort: keyof T | SortingRule<T>[];
};

/** Basic table using react-table library.
 * @example
 * <DSTable
 *  columns={customDocTableConfig}
 *  data={requiredDocTypes}
 *  sort='title'
 *  emptyMessage={commonLabels.notFound}/>
 */
export function DSTable<D extends Record<string, unknown>>({
  columns,
  data,
  emptyMessage,
  sort,
  dataTestId,
  rowKey,
}: DSTableProps<D>) {
  const initialState = useMemo(() => {
    const sortBy =
      typeof sort === 'string' ? [{ id: sort, dec: false }] : (sort as SortingRule<D>[]);
    return { sortBy };
  }, [sort]);

  const getRowId = useMemo(() => {
    return rowKey
      ? (row: D, relativeIndex: number) => {
          return String(row[rowKey]);
        }
      : undefined;
  }, [rowKey]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } =
    useTable(
      {
        columns,
        data,
        initialState,
        getRowId,
        autoResetSortBy: false,
      },
      useSortBy,
    );

  const hasFooter = useMemo(() => columns.some(column => column.Footer), [columns]);

  if (data.length || emptyMessage) {
    return (
      <BaseTable {...getTableProps()} data-testid={dataTestId}>
        <BaseTableHead>
          {headerGroups.map(headerGroup => (
            <BaseTableRow
              {...headerGroup.getHeaderGroupProps()}
              key={`${headerGroup.groupedIndex}-${headerGroup.id}`}
            >
              {headerGroup.headers.map(header => (
                <ColumnHeader header={header} key={header.id} />
              ))}
            </BaseTableRow>
          ))}
        </BaseTableHead>
        <BaseTableBody {...getTableBodyProps()}>
          {data.length ? (
            rows.map(row => {
              prepareRow(row);
              return (
                <BaseTableRow {...row.getRowProps()} key={row.id}>
                  {row.cells.map(cell => {
                    return (
                      <BaseTableCell
                        {...cell.getCellProps()}
                        key={`${cell.column.id}-${cell.row.id}`}
                      >
                        {cell.render('Cell')}
                      </BaseTableCell>
                    );
                  })}
                </BaseTableRow>
              );
            })
          ) : (
            <BaseTableRow>
              <BaseTableCell colSpan={columns.length}>
                <FlexContainer>
                  <CenterFlex>{emptyMessage}</CenterFlex>
                </FlexContainer>
              </BaseTableCell>
            </BaseTableRow>
          )}
        </BaseTableBody>
        {hasFooter ? (
          <BaseTableFooter data-testid={`${dataTestId}-footer`}>
            {footerGroups.map(footer => (
              <BaseTableFooterRow {...footer.getFooterGroupProps()} key={footer.id}>
                {footer.headers.map(cell => (
                  <BaseTableFooterCell {...cell.getFooterProps()} key={cell.id}>
                    {cell.render('Footer')}
                  </BaseTableFooterCell>
                ))}
              </BaseTableFooterRow>
            ))}
          </BaseTableFooter>
        ) : (
          <></>
        )}
      </BaseTable>
    );
  } else {
    return <></>;
  }
}
