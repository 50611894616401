import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import {
  allProductsState,
  initialAgencyProduct,
  initialCountyProduct,
  initialStateProduct,
  selectedProductsState,
} from 'src/store/recoil/productState';
import {
  incompleteRegistrationState,
  recoilRegistrationDataState,
  updateIncompleteRegistration,
} from 'src/store/recoil/registrationState';
import { NationalProductId, ProductApiResponse, ProductType } from 'src/types/products';

import { compareLists } from 'src/utils/helpers';
import { getIncompleteRegistration } from 'src/store/services';
import { initialRegistrationData } from 'src/store/reducers/registration';
import { registrationComponent } from 'src/utils/constants';
import { RegistrationData } from 'src/types/supplierregistration';

/**
 * @description wrapper for recoil registration state
 * @example const { refreshRegistrationSubscriptions, saveIncompleteRegistration } = useRegistration();
 */
export function useRegistration() {
  const allProducts = useRecoilValueLoadable<ProductApiResponse[]>(allProductsState);
  const registration = useRecoilValue<RegistrationData>(recoilRegistrationDataState);
  const incompleteRegistration = useRecoilValue(incompleteRegistrationState);
  const selectedProducts = useRecoilValue(selectedProductsState);

  /**
   * @description sets selectedProductsState based on incompleteRegistrationState subscriptionData
   * @returns void
   * @example initializeSelectedProductsFromRegistration();
   */
  const initializeSelectedProductsFromRegistration = useRecoilCallback(
    ({ set }) =>
      () => {
        let selectUpdated = false;

        if (allProducts.state === 'hasValue' && registration.emailAddress?.length) {
          const newSelectedProducts = { ...selectedProducts };

          if (registration.freeAgency && selectedProducts.agency === initialAgencyProduct) {
            const agency = allProducts.contents.find(p => p.productId === registration.freeAgency);
            newSelectedProducts.agency = agency;
            selectUpdated = true;
          }

          if (registration.subscriptionData && registration.subscriptionData.length > 0) {
            const previouslySelected = registration.subscriptionData as ProductApiResponse[];

            const counties = previouslySelected.filter(
              s => s.productType === ProductType.County && s.productId > 0,
            );
            const states = previouslySelected.filter(
              s => s.productType === ProductType.State && s.productId > 0,
            );
            const national = previouslySelected.filter(
              s => s.productType === ProductType.National && s.productId > 0,
            );

            if (
              !selectedProducts.county?.filter(x => x !== initialCountyProduct)?.length &&
              counties.length
            ) {
              newSelectedProducts.county = [...counties];
              selectUpdated = true;
            }

            if (
              !selectedProducts.state?.filter(x => x !== initialStateProduct)?.length &&
              states.length
            ) {
              newSelectedProducts.state = [...states];
              selectUpdated = true;
            }

            if (!selectedProducts.national && national.length) {
              newSelectedProducts.national = NationalProductId.UnitedStates;
              selectUpdated = true;
            }
          }

          if (selectUpdated) {
            set(selectedProductsState, { ...newSelectedProducts });
          }
        }
        return selectUpdated || false;
      },
    [allProducts.state, registration],
  );

  /**
   * @description checks for an email URL param & updates incompleteRegistrationDataState if needed
   * @returns Promise<void>
   * @example await populateRegistrationEmail();
   */
  const populateRegistrationEmail = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');

        const registrationData = await snapshot.getPromise(recoilRegistrationDataState);

        if (email && registrationData.email !== email) {
          set(incompleteRegistrationState, {
            ...registrationData,
            acceptTerms: true,
            emailAddress: email,
          });
        }
      },
    [],
  );

  /**
   * @description loads incomplete registration data from service layer
   * @returns Promise<void>
   * @example await refreshIncompleteRegistration();
   */
  const refreshIncompleteRegistration = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const incompleteRegData = await snapshot.getPromise(incompleteRegistrationState);

        if (incompleteRegData?.emailAddress?.length) {
          try {
            const response = await getIncompleteRegistration({
              email: incompleteRegData.emailAddress,
            });
            const data = JSON.parse(response?.data.result);

            if (data?.regData?.registrationData) {
              set(recoilRegistrationDataState, { ...data.regData.registrationData });
            } else if (data?.regData) {
              const registrationData = {
                ...data.regData,
                emailAddress: data.regData.emailId,
                companyName: data.regData.companyName,
                acceptTerms: data.regData.isAcceptTerms,
                freeAgency: parseInt(data.regData.selectedAgencyId),
                selectedAgencyName: data.regData.selectedAgencyName
                  ? data.regData.selectedAgencyName
                  : '',
              };

              set(recoilRegistrationDataState, { ...registrationData });
            } else {
              set(recoilRegistrationDataState, {
                ...incompleteRegData,
              });
            }
          } catch (error: any) {
            throw new Error(
              `Error in 'getIncompleteRegistration() for registrationDataSelector': ${error.message}`,
            );
          }
        } else {
          set(recoilRegistrationDataState, initialRegistrationData);
        }
      },
    [incompleteRegistration.emailAddress],
  );

  /**
   * @description saves selectedProductsState to incompleteRegistrationData & updates information
   * saved on the back-end
   * @returns Promise<void>
   * @example await refreshRegistrationSubscriptions();
   */
  const refreshRegistrationSubscriptions = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (
          selectedProducts.county?.length ||
          selectedProducts.state?.length ||
          selectedProducts.national ||
          registration.subscriptionData?.length
        ) {
          const subscriptionData: ProductApiResponse[] = [];
          let update = false;

          if (selectedProducts.county) {
            selectedProducts.county.map(county => subscriptionData.push(county));

            const { firstOnly, secondOnly } = compareLists(
              selectedProducts.county,
              registration.subscriptionData?.filter(s => s.productType === ProductType.County) ??
                [],
              (a, b) => {
                return a.productId === b.productId;
              },
            );

            update = update || firstOnly.length + secondOnly.length > 0;
          }

          if (selectedProducts.state) {
            selectedProducts.state.map(state => subscriptionData.push(state));

            const { firstOnly, secondOnly } = compareLists(
              selectedProducts.state,
              registration.subscriptionData?.filter(s => s.productType === ProductType.State) ?? [],
              (a, b) => {
                return a.productId === b.productId;
              },
            );

            update = update || firstOnly.length + secondOnly.length > 0;
          }

          update =
            update ||
            selectedProducts.national !==
              (registration.subscriptionData?.find(x => x.productType === ProductType.National)
                ?.productId ?? NationalProductId.None);

          const subscriptions = {
            subscribedCounties: selectedProducts.county
              ? selectedProducts.county.map((county: { productId: number }) => county.productId)
              : [],
            // Since National subscriptions are handled differently, simply taking what's there
            subscribedNational:
              registration.subscriptions && registration.subscriptions.subscribedNational
                ? registration.subscriptions.subscribedNational
                : [],
            subscribedStates: selectedProducts.state
              ? selectedProducts.state.map((state: { productId: number }) => state.productId)
              : [],
            subscriptionSelected: true,
          };

          if (update) {
            try {
              const regData = {
                ...registration,
                subscriptionData: subscriptionData,
                subscriptions: subscriptions,
              };

              set(recoilRegistrationDataState, regData);

              await saveIncompleteRegistration(registrationComponent.ChooseSubscription, regData);
            } catch (error) {
              console.error(
                `updateSubscriptions -> updateIncompleteRegistration() ERROR: \n${error}`,
              );
              return [];
            }
          }
        }
      },
    [selectedProducts.county, selectedProducts.national, selectedProducts.state],
  );

  /**
   * @description saves incompleteRegistrationDataState back to the server in case user does not
   * complete registration
   * @returns Promise<void>
   * @example await saveIncompleteRegistration();
   */
  const saveIncompleteRegistration = useRecoilCallback(
    ({ set }) =>
      async (currentComponent: string, regData: RegistrationData) => {
        try {
          const response = await updateIncompleteRegistration({
            email: regData.emailAddress,
            JsonData: {
              regData: {
                currentComponent: currentComponent,
                registrationData: {
                  ...regData,
                },
              },
            },
          });

          if (response) set(incompleteRegistrationState, response);
        } catch (error) {
          console.error(
            `saveIncompleteRegistration -> updateIncompleteRegistration() ERROR: \n${error}`,
          );
        }
      },
    [],
  );

  /**
   * @description Resets registration state
   */
  const resetRegistrationState = useRecoilCallback(
    ({ set }) =>
      () => {
        set(incompleteRegistrationState, initialRegistrationData);
        set(recoilRegistrationDataState, initialRegistrationData);
      },
    [],
  );

  return {
    incompleteRegistration,
    registration,
    initializeSelectedProductsFromRegistration,
    populateRegistrationEmail,
    refreshIncompleteRegistration,
    refreshRegistrationSubscriptions,
    resetRegistrationState,
    saveIncompleteRegistration,
  };
}

/**
 * @description loads incomplete registration data from the back end on registration start
 * @returns void
 * @example useRefreshIncompleteRegistration();
 */
export function useRefreshIncompleteRegistration() {
  const { refreshIncompleteRegistration, incompleteRegistration, registration } = useRegistration();

  useEffect(() => {
    if (registration === initialRegistrationData) {
      refreshIncompleteRegistration();
    }
  }, [refreshIncompleteRegistration, incompleteRegistration.emailAddress, registration]);
}

/**
 * @description keeps selectedProductsState up-to-date with registration data state & server-stored
 * incomplete registration data
 * @returns void
 * @example useRefreshRegistrationSubscriptions();
 */
export function useRefreshRegistrationSubscriptions() {
  const { refreshRegistrationSubscriptions } = useRegistration();

  useEffect(() => {
    refreshRegistrationSubscriptions();
  }, [refreshRegistrationSubscriptions]);
}

/**
 * @description sets selected products one time (as data becomes available) when member loads into registration
 * w/ an incomplete registration state
 * @returns void
 * @example useInitializeSelectedProductsFromRegistration();
 */
export function useInitializeSelectedProductsFromRegistration() {
  const { initializeSelectedProductsFromRegistration } = useRegistration();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      const update = initializeSelectedProductsFromRegistration();
      setIsInitialized(update);
    }
  }, [initializeSelectedProductsFromRegistration, isInitialized]);
}
