import { ForwardedRef, forwardRef } from 'react';

import { BaseRadio } from './DSRadioStyled';
import { ChoiceLabel } from '../../assets/ChoiceStyles';
import { Inactive } from '../../types';
import { InvisibleInput } from '../../assets/CommonStyles';
import { onEnter } from '../../utils';

export type RadioValue = string | number;

export interface DSRadioProps<Value> extends Inactive {
  /** Indicate if the radio is selected */
  checked?: boolean;
  /** HTML id (must be unique) */
  id?: string;
  /** HTML label for the radio */
  label: string;
  /** HTML name */
  name: string;
  /** Custom behavior for the Select action */
  onSelect: (value?: Value) => void;
  /** Value of the input */
  value?: Value;
}

/**
 * @description A single radio input with a label
 * @example <DSRadio name='acceptTerms' label='Accept Terms and Conditions' />
 */
export const DSRadio = forwardRef(
  (props: DSRadioProps<RadioValue>, ref: ForwardedRef<HTMLInputElement>) => {
    const { checked, id, inactive, label, name, onSelect, value } = props;

    function onClick() {
      if (!inactive) {
        onSelect(value);
      }
    }

    return (
      <ChoiceLabel inactive={inactive}>
        {label}
        <InvisibleInput
          aria-checked={checked}
          checked={checked}
          id={id}
          name={name}
          onClick={onClick}
          onKeyDown={onEnter(onClick)}
          readOnly={true}
          ref={ref}
          type='radio'
          value={value}
        />
        <BaseRadio checked={checked} inactive={inactive} />
      </ChoiceLabel>
    );
  },
);
