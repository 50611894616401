import { Controller, get, RegisterOptions, useFormContext } from 'react-hook-form';
import { InputHTMLAttributes } from 'react';

import { emailMaxLength, emailVal, emailValErrorMsg } from '../../utils';
import { FieldContainer, FieldContainerProps } from '../../field-container';
import { BaseTextInput } from '../../inputs';
import { Inactive } from 'src/types/shared';
import { RelativeContainer } from '../../assets/CommonStyles';

export type DSEmailFieldProps = Omit<FieldContainerProps, 'children' | 'error'> &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'autoFocus' | 'placeholder'> &
  Inactive & {
    /** HTML name (must be unique) */
    name: string;
    /** react-testing-library test id */
    dataTestId?: string;
    /** HTML label. Will also add additional margin at bottom. */
    label?: string;
    /** additional description */
    message?: string;
    /** Rules in the format of RHF `RegisterOptions` */
    rules?: RegisterOptions;
    /** default value for input */
    value?: string;
  };

/** Email field with built in validation, exclusively used with react-hook-form
 * @example <DSEmailField name='email' label='Email' />
 * */
export const DSEmailField = (props: DSEmailFieldProps) => {
  const {
    autoFocus,
    inactive,
    label,
    message,
    name,
    placeholder,
    dataTestId,
    optional,
    rules,
    value = '',
  } = props;

  const methods = useFormContext();
  const { control, errors } = methods;
  const error = get(errors || methods.formState.errors, name);

  const registerOptions: RegisterOptions = {
    required: !optional,
    pattern: {
      value: emailVal,
      message: emailValErrorMsg,
    },
    maxLength: emailMaxLength,
    ...rules,
  };

  const fieldContainerProps: FieldContainerProps = {
    message,
    label,
    name,
    dataTestId,
    error,
    optional: !registerOptions.required,
  };

  return (
    <Controller
      control={control}
      defaultValue={value}
      name={name}
      render={({ name, onChange, ref, value, onBlur }) => (
        <FieldContainer {...fieldContainerProps}>
          <RelativeContainer>
            <BaseTextInput
              autoFocus={autoFocus}
              data-testid={dataTestId}
              error={error}
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              readOnly={inactive}
              ref={ref}
              value={value}
            />
          </RelativeContainer>
        </FieldContainer>
      )}
      rules={registerOptions}
    />
  );
};
