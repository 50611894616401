import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actionTypes';
import * as Api from '../services';
import * as sharedApi from '../services/shared';
import * as subscriptionApi from '../services/subscriptions';

import { memberTypes, registrationComponent } from '../../utils/constants';

import { ProductType } from 'src/types/products';

function* watchFetchCommodityCodes() {
  yield takeLatest(actionTypes.FETCH_COMMODITY_CODES.TRIGGER, fetchCommodityCodes);
}

function* fetchCommodityCodes(action: { payload: any }): any {
  yield put({ type: actionTypes.FETCH_COMMODITY_CODES.REQUEST, meta: action.payload });
  try {
    const response = yield call(subscriptionApi.getAgencies);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.FETCH_COMMODITY_CODES.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_COMMODITY_CODES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetAllProducts() {
  yield takeLatest(actionTypes.GET_ALL_PRODUCTS.TRIGGER, getAllProducts);
}

function* getAllProducts(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_ALL_PRODUCTS.REQUEST, meta: action.payload });
  try {
    const response = yield call(subscriptionApi.getAllProducts);
    const payload = response.data.result || [];
    const parentProducts = payload
      .filter((item: any) => item.productType === ProductType.State)
      .sort((a: any, b: any) =>
        a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0,
      );
    const productsList = parentProducts
      .map((item: any, index: number) => {
        const products = payload
          .filter(
            (Pitem: any) =>
              Pitem.productType !== ProductType.FreeAgency &&
              Pitem.parentId === item.productId &&
              Pitem.productGroupId === item.productGroupId,
          )
          .sort((a: any, b: any) =>
            a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0,
          );
        parentProducts[index]['products'] = products;
        return parentProducts[index];
      })
      .filter((proItem: any) => proItem.products.length > 0);

    yield put({
      type: actionTypes.SET_SUBSCRIPTIONS_DETAILS.TRIGGER,
      payload: { products: productsList, filteredProducts: productsList, allProducts: payload },
    });
    yield put({ type: actionTypes.GET_ALL_PRODUCTS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_ALL_PRODUCTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetSubscriptionMemberDetails() {
  yield takeLatest(
    actionTypes.GET_SUBSCRIPTION_MEMBER_DETAILS.TRIGGER,
    getSubscriptionMemberDetails,
  );
}

function* getSubscriptionMemberDetails(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_SUBSCRIPTION_MEMBER_DETAILS.REQUEST, meta: action.payload });
  try {
    const { memberId = '', pagefor = '' } = action.payload || {};

    const response = yield call(subscriptionApi.getSubscriptionMemberDetails, { memberId });
    const payload = response.data.result || [];
    if (pagefor === 'registration') {
      const memberDetails = payload.memberDetails;
      const registration = yield select(state => state && state.registration);
      const { registrationData = {} } = registration || {};
      registrationData.companyName = memberDetails.shortName;
      registrationData.emailAddress = memberDetails.email;
      registrationData.freeAgency = memberDetails.freeAgencyId;

      if (payload.memberDetails && memberDetails.stateId) {
        yield put({ type: actionTypes.GET_COUNTIES_LIST.TRIGGER, payload: memberDetails.stateId });
        yield delay(1000);
      }

      const shared = yield select(state => state && state.shared);
      const { countieslist = [], countriesist = [] } = shared;

      registrationData.companyAddress = {
        address1: memberDetails.billingAddress1,
        address2: memberDetails.billingAddress2,
        city: memberDetails.billingCity,
        stateId: memberDetails.stateId,
        stateName: memberDetails.stateId
          ? { label: memberDetails.state, value: memberDetails.stateId }
          : '',
        postalCode: memberDetails.postalCode,
        countyId: memberDetails.countyId,
        countyName: memberDetails.countyId
          ? countieslist.find((item: any) => item.value === memberDetails.countyId)
          : '',
        countryId: memberDetails.countryId,
        country: countriesist.find((item: any) => item.value === memberDetails.countyId),
      };

      registrationData.companyInfo = {
        contactName: memberDetails.shortName,
        contactPhone: memberDetails.memberPhone,
        fax: '',
        website: '',
      };

      registrationData.mainContact = {
        firstName: memberDetails.firstName ? memberDetails.firstName : memberDetails.firstname,
        lastName: memberDetails.lastName,
        phone: memberDetails.memberPhone,
        email: memberDetails.email,
      };

      yield put({
        type: actionTypes.SET_REGISTRATION_DATA.TRIGGER,
        payload: { registrationData, memberId },
      });
    }
    const subscribedCounties = payload.memberDetails.countyProducts.map(
      (item: any) => item.productId,
    );
    const subscribedCountiesGroup = payload.memberDetails.countyProducts.map(
      (item: any) => `${item.productGroupId}_${item.parentId}_${item.productId}`,
    );
    const subscribedStates = payload.memberDetails.stateProducts.map((item: any) => item.productId);
    const subscribedNational =
      payload.memberDetails.nationalProduct && payload.memberDetails.nationalProduct.productId
        ? [payload.memberDetails.nationalProduct.productId]
        : [];
    let existingSubscriptionDetails = [
      ...payload.memberDetails.countyProducts,
      ...payload.memberDetails.stateProducts,
    ];

    if (payload.memberDetails.nationalProduct) {
      existingSubscriptionDetails = [
        ...existingSubscriptionDetails,
        payload.memberDetails.nationalProduct,
      ];
    }

    yield put({
      type: actionTypes.SET_SUBSCRIPTIONS_DETAILS.TRIGGER,
      payload: {
        memberSubscriptionInfo: payload,
        subscribedCounties,
        subscribedCountiesGroup,
        subscribedStates,
        subscribedNational: subscribedNational,
        currentSubscribedCounties: subscribedCounties,
        currentSubscribedStates: subscribedStates,
        currentSubscribedNational: subscribedNational,
        existingSubscriptionDetails,
      },
    });
    yield put({ type: actionTypes.GET_SUBSCRIPTION_MEMBER_DETAILS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SUBSCRIPTION_MEMBER_DETAILS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchCalculateSubscription() {
  yield takeLatest(actionTypes.CALCULATE_SUBSCRIPTION.TRIGGER, calculateSubscription);
}

function* calculateSubscription(action: { payload: any }): any {
  yield put({ type: actionTypes.CALCULATE_SUBSCRIPTION.REQUEST, meta: action.payload });
  try {
    const subscriptions = yield select(state => state && state.subscriptions);
    const {
      memberSubscriptionInfo = {},
      subscribedStates,
      subscribedCounties,
      subscribedNational,
    } = subscriptions;
    const { memberDetails = {} } = memberSubscriptionInfo;
    const { isUpgrade = false } = action.payload;
    let products = '';
    if (subscribedNational > 0) {
      products = subscribedNational[0];
    } else {
      products = [...subscribedCounties, ...subscribedStates].join(',');
    }
    const requestPayload = { memberId: memberDetails.memberId, products, isUpgrade };
    const response = yield call(subscriptionApi.calculateSubscriptionRequest, requestPayload);
    const responseData = JSON.parse(response.data);
    if (responseData.TotalCost < 0) {
      responseData.TotalCost = 0;
    }
    const payload = {
      currentExpiryDate: responseData.CurrentExpiryDate,
      expiryDate: responseData.ExpiryDate,
      calculatedPrice: parseFloat(responseData.TotalCost).toFixed(2),
      isUpgrade,
    };

    yield put({ type: actionTypes.SET_SUBSCRIPTIONS_DETAILS.TRIGGER, payload });
    yield put({ type: actionTypes.CALCULATE_SUBSCRIPTION.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.CALCULATE_SUBSCRIPTION.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchProcessPayment() {
  yield takeLatest(actionTypes.PROCESS_PAYMENT.TRIGGER, processPayment);
}

function* processPayment(action: { payload: any }): any {
  yield put({ type: actionTypes.PROCESS_PAYMENT.REQUEST, meta: action.payload });
  try {
    const { salesNotes = '', pagefor = '' } = action.payload || {};
    const subscriptions = yield select(state => state && state.subscriptions);
    const {
      memberSubscriptionInfo = {},
      subscribedStates = [],
      subscribedCounties = [],
      subscribedNational = [],
      isUpgrade = false,
      complimentary = false,
    } = subscriptions;
    const { memberDetails = {}, isRenewalAllowed = false } = memberSubscriptionInfo;
    const registration = yield select(state => state && state.registration);
    const { memberId = '', registrationData = {} } = registration || {};

    /* const accountinfo = yield select((state) => state && state.accountinfo);
        const { profileInfo = {} } = accountinfo || {};
        const{ card = {} } = profileInfo; */

    let products = '';
    if (subscribedNational > 0) {
      products = subscribedNational[0];
    } else {
      products = [...subscribedCounties, ...subscribedStates].join(',');
    }

    if (pagefor === 'registration' && registrationData.freeAgency) {
      products = `${products},${registrationData.freeAgency}`;
    }

    const payload = {
      token: action.payload.token,
      memberId: pagefor === 'registration' ? memberId : memberDetails.memberId,
      products,
      IsRenewalUpgrade: isRenewalAllowed,
      isUpgrade:
        pagefor === 'registration'
          ? true
          : memberDetails.memberType === memberTypes.basicSupplier
          ? true
          : isUpgrade,
      salesNotes,
      pagefor: action.payload.pagefor,
    };

    const response = yield call(
      complimentary ? subscriptionApi.processComplementary : subscriptionApi.processPayment,
      payload,
    );
    const responseData = JSON.parse(response.data);
    let responsePayload = {};
    if (responseData.Result === 'SUCCESS') {
      responsePayload = {
        OrderNumber: responseData.OrderNumber,
        transaction: responseData.Transaction ? responseData.Transaction : {},
        currentStep: 3,
      };
      if (pagefor !== 'registration') {
        yield put({ type: actionTypes.GET_REFRESH_TOKEN.TRIGGER });
        yield put({ type: actionTypes.LOAD_ACCOUNT_INFO.TRIGGER });
      }
    } else {
      responsePayload = {
        paymentErrorModal: true,
      };
    }
    yield put({ type: actionTypes.SET_SUBSCRIPTIONS_DETAILS.TRIGGER, payload: responsePayload });

    if (pagefor === 'registration' && responseData.Result === 'SUCCESS') {
      yield put({
        type: actionTypes.SET_REGISTRATION_DATA.TRIGGER,
        payload: { currentComponent: registrationComponent.OrderConfirmation },
      });
    }

    yield put({ type: actionTypes.PROCESS_PAYMENT.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.PROCESS_PAYMENT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchPopulatePostalcodeData() {
  yield takeLatest(actionTypes.POPULATE_POSTALCODE_DATA.TRIGGER, populatePostalcodeData);
}

function* populatePostalcodeData(action: { payload: any }): any {
  yield put({ type: actionTypes.POPULATE_POSTALCODE_DATA.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.populateMetroDetails, { zip: action.payload.postalCode });
    const data = response.data.result;
    if (data && data.stateId) {
      yield put({ type: actionTypes.GET_COUNTIES_LIST.TRIGGER, payload: data.stateId });
      yield delay(1500);
      const shared = yield select(state => state && state.shared);
      const { stateslist = [], countrieslist = {}, countieslist = [] } = shared;
      const selectedState = stateslist.find((item: any) => item.id === data.stateId) || '';
      const selectedCounty = countieslist.find((item: any) => item.id === data.countyId) || '';

      const addressPayload = {
        ...action.payload.formValues,
        city: data.city,
        stateId: data.stateId,
        stateName: selectedState,
        countyName: selectedCounty,
        countyId: data.countyId,
        countryId: data.countryId,
        country: countrieslist.find((item: any) => item.value === data.countryId),
        postalCode: action.payload.postalCode,
      };
      yield put({
        type: actionTypes.SET_SUBSCRIPTIONS_DETAILS.TRIGGER,
        payload: { addressInitialForm: addressPayload },
      });
    }
    yield put({ type: actionTypes.POPULATE_POSTALCODE_DATA.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.POPULATE_POSTALCODE_DATA.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchUpdateSubscriptionMemberAddress() {
  yield takeLatest(
    actionTypes.UPDATE_SUBSCRIPTION_MEMBER_ADDRESS.TRIGGER,
    updateSubscriptionMemberAddress,
  );
}

function* updateSubscriptionMemberAddress(action: { payload: any }): any {
  yield put({ type: actionTypes.UPDATE_SUBSCRIPTION_MEMBER_ADDRESS.REQUEST, meta: action.payload });
  try {
    yield call(Api.updateMemberAddress, action.payload);
    const auth = yield select(state => state && state.auth);
    const { mi = '' } = auth || {};
    if (mi && action.payload.pagefor === 'reviewRegister') {
      yield put({ type: actionTypes.LOAD_ACCOUNT_INFO.TRIGGER });
    } else {
      const registration = yield select(state => state && state.registration);
      const { registrationData = {} } = registration;
      const updatedPayload =
        action.payload.addressType === 'MA'
          ? { companyAddress: { ...action.payload } }
          : { billingAddress: { ...action.payload } };
      yield put({
        type: actionTypes.SET_REGISTRATION_DATA.TRIGGER,
        payload: { registrationData: { ...registrationData, ...updatedPayload } },
      });
    }
    yield put({ type: actionTypes.UPDATE_SUBSCRIPTION_MEMBER_ADDRESS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_SUBSCRIPTION_MEMBER_ADDRESS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* subscriptionsSaga() {
  yield fork(watchFetchCommodityCodes);
  yield fork(watchGetAllProducts);
  yield fork(watchGetSubscriptionMemberDetails);
  yield fork(watchCalculateSubscription);
  yield fork(watchProcessPayment);
  yield fork(watchPopulatePostalcodeData);
  yield fork(watchUpdateSubscriptionMemberAddress);
}
