import { useRecoilStateLoadable, useSetRecoilState } from 'recoil';
import { useMemo } from 'react';

import { DSLink } from '@demandstar/components/link';

import { CartItemColumn, CartItemWrapper } from './styles';
import { CartProduct, ProductType } from 'src/types/products';
import { getAgenciesByProduct, getAgencyCounts } from '../helpers';

import { agenciesByProductState } from 'src/store/recoil/agencyState';
import { allProductsState } from 'src/store/recoil/productState';
import { CrossIcon } from 'src/components/common/icons/CrossIcon';
import { ProductApiResponse } from 'src/types/products';
import { useCart } from './useCart';

interface CartItemProps {
  /** hides the agency count on the cart item */
  hideAgencyCount?: boolean;
  /** indicates if the delete icon should be shown */
  isRemovable: boolean;
  /** the product that is in the cart */
  product: CartProduct;
}

/**
 * @description display for a single line-item in a `Cart`
 * @returns JSX.Element
 * @default { hideAgencyCount = false }
 * @example <CartItem
 *    isRemovable={false}
 *    product={{
 *      productId: 1,
 *      productName: 'Product',
 *      productType=ProductType.County,
 *      price: 25,
 *      calculatedPrice: 12.50,
 *    }}/>
 */
export const CartItem = ({ hideAgencyCount = false, isRemovable, product }: CartItemProps) => {
  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const setAgenciesByProduct = useSetRecoilState<ProductApiResponse[]>(agenciesByProductState);
  const { removeProduct } = useCart();
  const isNational = product.productType === ProductType.National;
  const isProrated = product.calculatedPrice !== product.price;

  const agencyCount = useMemo(() => {
    if (allProducts.state === 'hasValue') {
      return getAgencyCounts(allProducts.contents, product.productId, product.productType);
    }

    return 0;
  }, [allProducts.contents, allProducts.state, product]);

  const handleAgencyCountClick = () => {
    if (allProducts.state === 'hasValue')
      getAgenciesByProduct(
        allProducts.contents,
        product.productId,
        setAgenciesByProduct,
        product.productType,
      );
  };

  return (
    <CartItemWrapper key={product.productId}>
      <CartItemColumn isProrated={isProrated}>
        {isNational ? 'Nationwide' : product.productName}
        {product?.productType === ProductType.County && `, ${product.parentName}`}
      </CartItemColumn>
      {!isNational && !isProrated && !hideAgencyCount && (
        <CartItemColumn>
          <DSLink onClick={handleAgencyCountClick}>
            {`View ${agencyCount} ${agencyCount > 1 ? 'Agencies' : 'Agency'}`}
          </DSLink>
        </CartItemColumn>
      )}
      <CartItemColumn isBold={!isProrated} isProrated={isProrated}>
        ${product.price} / year
        {isRemovable && !isProrated && (
          <CrossIcon
            dataTestId={'delete-' + product.productId}
            onClick={() => removeProduct(product)}
          />
        )}
      </CartItemColumn>
      {isProrated && (
        <CartItemColumn isBold={true} isProrated={true}>
          Pay Today: ${product.calculatedPrice}
          {isRemovable && (
            <CrossIcon
              dataTestId={'delete-' + product.productId}
              onClick={() => removeProduct(product)}
            />
          )}
        </CartItemColumn>
      )}
    </CartItemWrapper>
  );
};
