import { Suspense, useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { FlexContainer, H2 } from '@demandstar/components/styles';

import { CancelSubscription, ModifyFreeAgency, ModifySubscription } from '../ops-actions';
import { selectedProductsState, upsellProductsState } from 'src/store/recoil/productState';
import { SubscriptionStatusWrapper, SubscriptionTableWrapper } from './styles';
import { useAccountInfo, useRefreshAccountInfo } from '../../../shared/hooks/useAccountInfo';
import { useProfileInfo, useRefreshProfileInfo } from '../../../shared/hooks/useProfileInfo';

import { Page } from '../styles';
import { ProductsTable } from '../products-table';
import { SubscriptionStatus } from '../subscription-status';
import { UpsellSection } from './upsell-section';
import { useAuth } from '../../../shared/hooks/useAuth';
import { UserType } from 'src/types/accountinfo';
import { useSetBreadcrumb } from '../../../shared/hooks';

/**
 * @description Page to display a user's current subscription status
 * @returns JSX.Element
 * @example CurrentSubscriptions()
 */
export const CurrentSubscriptions = () => {
  useRefreshAccountInfo();
  useRefreshProfileInfo();

  const { accountInfo } = useAccountInfo();
  const { profileInfo } = useProfileInfo();
  const {
    auth: { mainContact: isMainContact = false, opsId = 0 },
  } = useAuth();

  const isOpsUser = opsId > 0;

  const resetSelectedProducts = useResetRecoilState(selectedProductsState);
  const resetUpsellProducts = useResetRecoilState(upsellProductsState);

  const {
    expiryDate,
    products = [],
    newProductIds = [],
    userType = UserType.BasicSupplier,
  } = accountInfo || {};

  useEffect(() => {
    resetSelectedProducts();
  }, [resetSelectedProducts]);

  useEffect(() => {
    resetUpsellProducts();
  }, [resetUpsellProducts]);

  useSetBreadcrumb({
    page: 'Subscriptions',
    title: 'Subscriptions',
    altname: 'Subscriptions',
    name: 'Subscriptions',
  });

  return (
    <Suspense fallback={<></>}>
      <Page>
        <H2>Your DemandStar Subscription</H2>
        <UpsellSection />
        <SubscriptionTableWrapper>
          <ProductsTable products={products} newProductIds={newProductIds} />
        </SubscriptionTableWrapper>
        {products?.length > 0 && (
          <SubscriptionStatusWrapper>
            <SubscriptionStatus
              profileInfo={profileInfo}
              expiryDate={expiryDate}
              userType={userType}
              isMainContact={isMainContact}
            />
          </SubscriptionStatusWrapper>
        )}
        {isOpsUser && (
          <FlexContainer justifyContent='center' style={{ width: '100%' }}>
            <ModifyFreeAgency products={products} />

            {userType === UserType.PaidSupplier && (
              <ModifySubscription card={profileInfo && profileInfo?.card} expiryDate={expiryDate} />
            )}

            {userType === UserType.PaidSupplier && <CancelSubscription />}
          </FlexContainer>
        )}
      </Page>
    </Suspense>
  );
};
