import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { DSAlert } from '@demandstar/components/alert';
import { DSButton } from '@demandstar/components/button';
import { DSEmail } from '@demandstar/components/email';
import { FlexContainer } from '@demandstar/components/styles';
import { Status } from '@demandstar/components/constants';

import { commonEmail, TollFreeNumber } from 'src/utils/texts/common';
import { commonLabels } from 'src/shared/constants';
import { ModalPopUp } from 'src/components/common/modals';
import { ProductsCopy } from 'src/utils/texts/supplier/subscription';
import { prorationMismatchState } from 'src/store/recoil/subscriptionState';
import { useAuth } from 'src/shared/hooks/useAuth';

interface CompleteOrderModalProps {
  /** The function called to confirm order should proceed */
  completeOrder: () => void | Promise<void>;
}

/** Displays an alert to contact support if a proration mismatch is encountered between the front & back ends */
export const ProrationErrorAlert = () => {
  const prorationMismatch = useRecoilValue(prorationMismatchState);
  const prorationMessage = (
    <>
      Please <DSEmail address={commonEmail.support}>email us</DSEmail> or call us at{' '}
      {TollFreeNumber} to complete your order.
    </>
  );

  return (
    <>
      {prorationMismatch !== undefined && (
        <DSAlert
          header={ProductsCopy.SomethingWentWrong}
          message={prorationMessage}
          type={Status.Error}
        />
      )}
    </>
  );
};

/**
 * @description displays an OPS-only modal in the event that an upgrade order cannot be completed
 * due to a mismatch between front-end & back-end cost
 * @example <CompleteOrderModal />
 * @returns A modal that toggles based on Recoil prorationMismatchState allowing an OPS
 */
export const CompleteOrderModal = ({ completeOrder }: CompleteOrderModalProps) => {
  const {
    auth: { opsId },
  } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [prorationMismatch, setProrationMismatch] = useRecoilState(prorationMismatchState);

  const confirm = async () => {
    await completeOrder();
    closeModal();
  };

  useEffect(() => {
    if (prorationMismatch && opsId > 0) {
      setShowModal(true);
    }
  }, [opsId, prorationMismatch]);

  function closeModal() {
    // Closing the Modal also clears the state
    setProrationMismatch(undefined);
    setShowModal(false);
  }

  return (
    <>
      <ProrationErrorAlert />
      <ModalPopUp size='lg' title='Complete Order' closeModal={closeModal} isOpen={showModal}>
        <p>
          There is a discrepancy between the displayed cost and the amount that will be charged.
          Would you like to continue?
        </p>
        <p>Displayed cost: ${prorationMismatch?.expectedCost?.toFixed(2)}</p>
        <p>Member will be charged: ${prorationMismatch?.calculatedCost?.toFixed(2)}</p>
        <FlexContainer justifyContent={'space-between'}>
          <DSButton
            buttonType={'secondary'}
            onClick={closeModal}
            title={commonLabels.cancel}
            data-testid={'upgrade.ops.complete-order-modal.cancel-btn'}
          >
            {commonLabels.cancel}
          </DSButton>
          <DSButton
            inactive={(prorationMismatch?.calculatedCost ?? 0) <= 0}
            onClick={confirm}
            title={commonLabels.confirm}
            data-testid={'upgrade.ops.complete-order-modal.confirm-btn'}
          >
            {commonLabels.confirm}
          </DSButton>
        </FlexContainer>
      </ModalPopUp>
    </>
  );
};
