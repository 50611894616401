import styled from 'styled-components';

import { Flex, FlexContainer } from '@demandstar/components/styles';
import { DSButton } from '@demandstar/components/button';
import { DSLink } from '@demandstar/components/link';

import { Margin } from '../../../shared/styles';
import { navigation } from '../../../utils/texts/common';
import { scrollToTop } from '../../../utils/helpers';

const WizardButtonsWrapper = styled(FlexContainer)`
  margin-top: ${Margin.extra};
`;

interface WizardSaveSpanProps {
  /** sets the flex grow factor of a flex item's main size */
  align?: 'left' | 'right';
}

const WizardSaveSpan = styled(Flex)<WizardSaveSpanProps>`
  text-align: ${({ align }) => align || 'right'};
`;

interface WizardButtonsProps {
  onPrevious?: () => void;
  onNext: () => void;
  save?: () => void;
  inactiveNext?: boolean;
  altNext?: string;
  altPrev?: string;
  inactivePrev?: boolean;
  inactiveAll?: boolean;
}

export const WizardButtons = (props: WizardButtonsProps) => {
  const { onPrevious, onNext, save, inactiveNext, inactivePrev, inactiveAll, altNext, altPrev } =
    props;
  function prev() {
    scrollToTop();
    if (onPrevious) {
      onPrevious();
    }
  }
  function next() {
    scrollToTop();
    onNext();
  }
  return (
    <WizardButtonsWrapper>
      {onPrevious ? (
        <Flex grow={0}>
          <DSButton
            buttonType='secondary'
            inactive={inactivePrev || inactiveAll}
            title={altPrev || navigation.previousPage}
            onClick={prev}
          >
            {altPrev || navigation.previousPage}
          </DSButton>
        </Flex>
      ) : (
        <></>
      )}
      <Flex>
        <FlexContainer>
          {save && !inactiveAll && (
            <WizardSaveSpan align={onPrevious ? 'right' : 'left'}>
              <DSLink onClick={save}>{navigation.saveAndFinishLater}</DSLink>
            </WizardSaveSpan>
          )}
        </FlexContainer>
      </Flex>
      <Flex grow={0}>
        <DSButton
          inactive={inactiveNext || inactiveAll}
          title={altNext || navigation.nextPage}
          onClick={next}
        >
          {altNext || navigation.nextPage}
        </DSButton>
      </Flex>
    </WizardButtonsWrapper>
  );
};
