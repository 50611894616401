import { Column } from 'react-table';
import { useMemo } from 'react';

import { DSPill } from '@demandstar/components/pill';
import { DSTable } from '@demandstar/components/table';
import { H3 } from '@demandstar/components/styles';
import { Status } from '@demandstar/components/constants';

import { getProductTypeExpanded, ProductType } from '../../../types/products';
import {
  ProductsCopy,
  productsTableHeaders,
  SubscriptionStatusLabels,
} from '../../../utils/texts/supplier/subscription';

import { FooterLabel } from './styles';
import { formatPrice } from '../../../utils/helpers';
import { FreeAgencyFooter } from './free-agency-footer';
import { NoProductsMessage } from './no-products-message';
import { Product } from 'src/types/subscriptions';

interface ProductsTableProps {
  /** a list of products to display */
  products?: Product[];
  /** a list of `productId`s of products to be displayed as 'NEW' */
  newProductIds?: number[];
}

/**
 * @description renders a table of products
 * @returns JSX.Element
 *
 * @example <ProductsTable
 * newProductIds={[1]}
 * products={[{
 *  productId: 2,
 *  productType: ProductType.FreeAgency,
 *  productName: 'Old Product',
 *  price: 0,
 * }, {
 *  productId: 1,
 *  productType: ProductType.County,
 *  productName: 'New Product',
 *  price: 25,
 * }]}
 * />
 */
export const ProductsTable = ({ newProductIds = [], products = [] }: ProductsTableProps) => {
  const getProductsTableColumns = useMemo((): Column<Product & { [key: string]: unknown }>[] => {
    const columns: Column<Product>[] = [
      {
        accessor: 'productName',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: cell => {
          const isNew =
            newProductIds?.length > 0 && newProductIds.includes(cell.row.original.productId);
          return (
            <>
              {cell.value}{' '}
              {isNew && <DSPill label={SubscriptionStatusLabels.New} status={Status.Success} />}
            </>
          );
        },
        Header: productsTableHeaders.productName,
      },
      {
        accessor: 'productType',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: cell =>
          getProductTypeExpanded(cell.value) + (cell.value === ProductType.FreeAgency ? '*' : ''),
        Header: productsTableHeaders.productType,
      },
      {
        accessor: 'price',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: cell => `$${formatPrice(cell.value) || '0.00'}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Footer: table => {
          const totalCost = useMemo(
            () => table.rows.reduce((sum, row) => row.values.price + sum, 0),
            [table.rows],
          );

          return (
            <span>
              <FooterLabel>{ProductsCopy.TotalAnnualCost}:</FooterLabel>$
              {formatPrice(totalCost) || '0.00'}
            </span>
          );
        },
        Header: productsTableHeaders.price,
      },
    ];
    return columns;
  }, [newProductIds]);

  // react-table expects memo-ized props
  const memoizedProducts = useMemo(() => {
    return products;
  }, [products]);

  const freeAgency = products.filter(p => p.productType === ProductType.FreeAgency)[0];

  return (
    <>
      <H3>Your subscription includes:</H3>
      <DSTable
        columns={getProductsTableColumns as Column<Record<string, unknown>>[]}
        data={memoizedProducts}
        emptyMessage={<NoProductsMessage />}
        sort={'productName'}
      />
      {memoizedProducts?.length > 0 && <FreeAgencyFooter freeAgency={freeAgency} />}
    </>
  );
};
