import { ForwardedRef, forwardRef } from 'react';

import { Controller, get, RegisterOptions, useFormContext } from 'react-hook-form';
import Select, { Props as ReactSelectProps } from 'react-select';

import { DSSelectStyled, IconOption, styleOverrides } from '../../inputs/select';
import { FieldContainer, FieldContainerProps } from '../../field-container/FieldContainer';

// Reference the react-select docs before adding props to this list https://react-select.com/props
// If anything listed needs to be exposed, do it in the function, not the interface.
// onChange prop is reserved by React Hook Form's Controller. Use onSelect instead.
export type DSSelectFieldProps = Omit<ReactSelectProps, 'onChange'> &
  Omit<FieldContainerProps, 'children'> & {
    /** Default value needed for RHF's controller */
    defaultValue: string | number | Record<string, unknown>;
    /** Keys for mapping label/value pair to data object */
    optionKeys?: { label: string; value: string };
    /** Array of options for the dropdown */
    optionValues?: Record<string, unknown>[];
    /** Custom behavior for the Select action; onChange should not be used */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelect?: (value: any) => void;
    /** Validation rules, these are sent to the Controller */
    rules?: RegisterOptions;
  };

export const DSSelectField = forwardRef(
  (props: DSSelectFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      dataTestId,
      defaultValue,
      label,
      message,
      name,
      optional,
      options,
      optionKeys,
      optionValues,
      onSelect,
      rules,
    } = props;

    const methods = useFormContext();
    const { control, errors } = methods;
    const error = get(errors || methods.formState.errors, name);

    // These are the props that will not be sent as part of the props spread directly to react-select
    const selectProps = {
      dataTestId,
      label,
      message,
      onSelect,
      optionKeys,
      optionValues,
      rules,
      ...props,
    };

    let formattedOptions: Record<string, unknown>[];
    const optionLabelKey = optionKeys ? optionKeys.label.toString() : 'label';
    const optionValueKey = optionKeys ? optionKeys.value.toString() : 'value';

    if (optionValues && (optionLabelKey !== 'label' || optionValueKey !== 'value')) {
      formattedOptions = optionValues.map((option: any) => {
        return {
          ...option,
          label: option[optionLabelKey],
          value: option[optionValueKey],
        };
      });
    } else {
      formattedOptions = options as Record<string, unknown>[];
    }

    return (
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        options={formattedOptions}
        render={({ name, onBlur, onChange, ref, value }) => (
          <FieldContainer
            dataTestId={dataTestId}
            error={error}
            label={label}
            message={message}
            name={name}
            optional={optional}
          >
            <DSSelectStyled error={error}>
              <Select
                aria-errormessage={`${name}-error`}
                aria-label={name}
                components={{ Option: IconOption }}
                inputId={name}
                onBlur={onBlur}
                onChange={value => {
                  onChange(value);
                  onSelect && onSelect(value);
                }}
                options={formattedOptions}
                ref={ref}
                styles={{ ...styleOverrides, ...props.styles }}
                value={value}
                {...selectProps}
              />
            </DSSelectStyled>
          </FieldContainer>
        )}
        rules={{
          required: !optional,
          ...rules,
        }}
      />
    );
  },
);
