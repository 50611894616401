import React, { ChangeEvent, InputHTMLAttributes } from 'react';

import { FileInputWidgetWrapper, FileInputWrapper, FileNameWrapper } from './styles';

import { FileInput } from '../controls/inputs/FileInput';
import { DSButton } from '@demandstar/components/button';

interface FileInputProperties extends InputHTMLAttributes<HTMLInputElement> {
  fieldRef: any; // pass-through for react-hook-form field registration
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  name: string;
  fileName?: string;
}

export function FileInputWidget({
  fieldRef,
  name,
  handleInputChange,
  label,
  fileName,
}: FileInputProperties) {
  return (
    <FileInputWidgetWrapper title={label}>
      <FileInputWrapper>
        <DSButton>{label}</DSButton>
        <FileInput
          name={name}
          onChange={handleInputChange}
          title={label}
          type='file'
          ref={fieldRef}
        />
      </FileInputWrapper>
      <FileNameWrapper title={fileName || label} hasValue={!!fileName}>
        {fileName}
      </FileNameWrapper>
    </FileInputWidgetWrapper>
  );
}
