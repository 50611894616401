import styled from 'styled-components';

import { BaseSVG, DSSVGIconProps } from './DSSVGIconStyles';

import { height, width } from '../constants';
import { pxToRem } from '../utils/conversions';

/**
 * FInal exported component, wraps the styled svg and applies default props.
 */
export const DSSVGIcon = styled(BaseSVG).attrs<DSSVGIconProps>(
  ({ dataTestId, color, onClick, title }: DSSVGIconProps) => ({
    'data-testid': `${dataTestId}`,
    fill: `${color}`,
    onClick,
    title: title || '',
  }),
)`
  color: ${(props: DSSVGIconProps) => (props?.color ? props.color : 'currentColor')};
  height: ${(props: DSSVGIconProps) =>
    props?.height ? pxToRem(props.height) : height.iconBaseRem}rem;
  width: ${(props: DSSVGIconProps) =>
    props?.width ? pxToRem(Number(props.width)) : width.iconBaseRem}rem;
`;
