// Color definitions, background and border color classes live here.

/** Neutral Tones. Do not export. */
enum Neutral {
  Black = '#111111',
  Darkest = '#3F3F3F',
  Darker = '#4F4F4F',
  Dark = '#7A7A7A',
  Gray = '#B3B3B3',
  Light = '#E0E0E0',
  Lighter = '#EAEAEA',
  Lightest = '#F5F5F5',
  AlmostWhite = '#FAFAFA',
  White = '#FFFFFF',
  Transparent = 'transparent',
}

/** Brand Tones. Do not export. */
enum Teal {
  Lighter = '#6AE9fC',
  Light = '#50BFCF',
  Base = '#3395A4', // this is "brand teal"
  Dark = '#1F6A85',
  Darker = '#044266',
}

/** Brand Tones. Do not export. */
enum CoolGray {
  Lighter = '#e1eced',
  Light = '#d2dfe1',
  Base = '#C3D4D6',
  Dark = '#93a2ad',
  Darker = '#627085',
}

/** Accent Tones. Do not export. */
enum Green {
  Lighter = '#c2e375',
  Light = '#a5d574',
  Base = '#8ac675',
  Dark = '#609b58',
  Darker = '#366f3c',
}

/** Accent Tones. Do not export. */
enum Yellow {
  Lighter = '#fff0b1',
  Light = '#ffd67d',
  Base = '#ffb84a',
  Dark = '#e39011',
  Darker = '#c16f00',
}

/** Accent Tones. Do not export. */
enum Orange {
  Lighter = '#fdcda9',
  Light = '#f8af79',
  Base = '#eb8954',
  Dark = '#c36531',
  Darker = '#914012',
}

/** Accent Tones. Do not export. */
enum Red {
  Lighter = '#fab2b2',
  Light = '#e88686',
  Base = '#c76060',
  Dark = '#a23d3d',
  Darker = '#5c1515',
  Error = '#D82121', // Does this belong here? It is *not* an accent tone.
}

/** Accent Tones. Do not export. */
enum Purple {
  Lighter = '#DDCBFF',
  Light = '#AF93DA',
  Base = '#7E5CB5',
  Dark = '#593E87',
  Darker = '#372458',
}

export type BrandColor = Teal | CoolGray;

export type AccentColor = Yellow | Orange | Red | Purple | Green;

export type Color = BrandColor | AccentColor | Neutral | TextColor | BackgroundColor;

export enum TextColor {
  //TODO: Cut down on options.
  Action = Teal.Dark,
  ActionHover = Teal.Base,
  ActionMessage = Neutral.Darker, // FIXME: Too specific. Use existing color.
  Base = Neutral.Black,
  Contrast = Neutral.AlmostWhite,
  ContrastAction = Teal.Light,
  Error = Red.Error,
  Helper = Neutral.Dark,
  Inactive = Neutral.Dark,
  Label = Neutral.Black,
}

export enum BorderColor {
  Action = Teal.Dark,
  Base = Neutral.Dark,
  /** To be used for radio, checkbox, wizard, etc. Clickable form components */
  Checked = Teal.Darker,
  CheckedFocus = Teal.Dark,
  Error = Red.Error,
  Highlight = Neutral.Lighter,
  None = Neutral.Transparent,
  Table = Neutral.Light,
  Wizard = Neutral.Gray,
}

export enum BackgroundColor {
  // TODO: Cut down on options.
  // TODO: Add Highlights.
  BaseGray = Neutral.Gray,
  BaseOrange = Orange.Base,
  BaseTeal = Teal.Darker,
  BaseWhite = Neutral.White,
  Inactive = Neutral.Lightest,
  Error = Red.Dark, // should this be Red.Error? (Yes.)
  HighlightPurple = Purple.Darker,
  HighlightTeal = Teal.Light,
  Input = Neutral.White,
  LoadingText = Neutral.Lighter, // FIXME: Too specific.
  Success = Green.Base,
  Transparent = Neutral.Transparent,
  Warning = Yellow.Light,
}
export enum TableBackground {
  Base = Neutral.Transparent,
  Footer = CoolGray.Lighter,
}

export enum DeprecatedColors {
  BlueGreenArrowHighlight = CoolGray.Light,
  BlueGreenLight = CoolGray.Base,
  BlueGreenMedium = Teal.Base,
  GrayBgHighlight = Neutral.Lighter,
  GrayLight = Neutral.Lightest,
}

export enum ButtonColor {
  Primary = Purple.Dark,
  Secondary = Neutral.Transparent,
  Tertiary = Green.Dark,
  Danger = Red.Error,
  Inactive = BackgroundColor.Inactive,
}

export enum ButtonHighlight {
  Primary = Purple.Darker,
  Secondary = Neutral.Transparent,
  Tertiary = Green.Darker,
  Danger = Red.Base,
  Inactive = BackgroundColor.Inactive,
}
