import {
  atom,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';
import { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';

import { BidApiResponse, Commodity } from '../../types/bidssummary';
import { BidExternalStatusType, Paths } from 'src/utils/constants';
import { Assert } from 'src/utils/helpers';
import { axiosPostAuthenticated } from 'src/store/services/api';
import { constants } from 'src/utils/settings';
import { defaultNumericId } from '../../shared/constants';
import { selectedBidIdState } from '../../store/recoil/bidState';
import { track } from 'src/utils/telemetry';
import { useRouter } from '.';

/** Necessary information for a single bid. To replace BidSummary.
 * This is to be expanded as needed. But we avoid using BidSummaryApiResponse type,
 *  which has
 * - redundant properties
 * - miscapitalized properties
 * - unclearly labeled properties
 * */
interface BidState {
  accountId: number;
  agencyName: string;
  bidExternalStatusType: BidExternalStatusType;
  bidId: number;
  bidName: string;
  bidNumber: string;
  bidWriter: string;
  commodities: Commodity[];
  memberId: number;
}

/**
 */
export const bidState = atom<BidState | null>({
  key: 'bidState',
  default: null,
});

/**
 * Typed Bid Summary request
 * @param bidId
 * @returns a Promise with a BidSummaryApiResponse type
 */
export function getBid(bidId: number): Promise<BidApiResponse> {
  // Pass an empty config if the bidId is the default value (defaultNumericId).
  const requestConfig: AxiosRequestConfig =
    bidId === defaultNumericId
      ? {}
      : {
          baseURL: constants.api.url,
          data: {
            bidId,
            checkfor: '',
            otherapis: true,
          },
          url: Paths.bidsSummary,
        };
  return axiosPostAuthenticated<BidApiResponse>(requestConfig);
}

function convertToBidState(apiResponse: BidApiResponse): BidState {
  const {
    accountID,
    agencyName,
    bidExternalStatusType,
    bidID,
    bidName,
    bidNumber,
    bidWriter,
    commodities,
    memberID,
  } = apiResponse;
  Assert(!!accountID, 'All bids should have an accountId');
  Assert(!!bidID, 'All bids should have a bidId');
  Assert(!!agencyName, 'All bids should have an agencyName');
  Assert(!!bidName, 'All bids should have a bidName');
  Assert(!!bidWriter, 'All bids should have a bidWriter');
  Assert(!!bidNumber, 'All bids should have a bidNumber');
  Assert(!!memberID, 'All bids should have a memberId');
  Assert(!!bidExternalStatusType, 'All bids should have a bidExternalStatusType');

  return {
    accountId: accountID,
    agencyName,
    bidExternalStatusType,
    bidId: bidID,
    bidName,
    bidNumber,
    bidWriter,
    commodities,
    memberId: memberID,
  };
}

/**
 * Manages the current bid information (replaces Redux's bidSummary)
 * Include `useRefreshBid()` at a high level component, and use in sync with selectedBidIdState.
 */
export function useBid() {
  const bid = useRecoilValue(bidState);

  const refreshBid = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const bidId = await snapshot.getPromise(selectedBidIdState);
        try {
          // API call
          const apiBidSummaryResponse = bidId !== defaultNumericId ? await getBid(bidId) : null;
          if (apiBidSummaryResponse) {
            set(bidState, convertToBidState(apiBidSummaryResponse));
          }
        } catch (error: any) {
          track(`refreshBid -> getBidSummary(${bidId}) ERROR:`, {
            error,
            method: 'GET',
            errorMessage: error.message,
          });
          // eslint-disable-next-line no-console
          console.error(`bidSummarySelector -> getBidSummary() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
          // return {};
          throw new Error(error.message);
        }
      },
    [],
  );

  return {
    bid,
    refreshBid,
  };
}

export const mockBidState: BidState = {
  accountId: 1234,
  agencyName: 'Agency Name',
  bidExternalStatusType: BidExternalStatusType.Active,
  bidId: 12345,
  bidName: 'Bid Name',
  bidNumber: 'Bid Number',
  bidWriter: 'Bid Writer',
  commodities: [],
  memberId: 123456,
};

/** Establishes the bid information, and ensures that it remains in sync with the current bidId */
export function useRefreshBid(): void {
  const [bidId, setBidId] = useRecoilState(selectedBidIdState);
  const bid = useRecoilValue(bidState);
  const resetBid = useResetRecoilState(bidState);
  const { refreshBid } = useBid();
  const { bidId: bidIdFromUrl } = useRouter().routerParams;

  useEffect(() => {
    if (bidId === defaultNumericId) {
      // Check for a bidId from the URL if state has been lost (e.g. reload)
      if (bidIdFromUrl) {
        setBidId(Number(bidIdFromUrl));
      } else {
        resetBid();
      }
    } else if (!bid || bid.bidId !== bidId) {
      refreshBid();
    }
  }, [bid, bidId, bidIdFromUrl, resetBid, refreshBid, setBidId]);
}
